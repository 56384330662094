import { LoaderFunction } from "react-router-dom";

import { selectIsAuth } from "@/selectors/global";
import { store } from "@/store";

/**
 * Wraps a loader function to only execute in case isAuth is true
 * @param loader - The loader function to be wrapped.
 * @returns The wrapped loader function.
 */
export function authRequiredWrapper(loader: LoaderFunction) {
  const loaderFunction: LoaderFunction = (...args) => {
    const isAuth = selectIsAuth(store.getState());
    if (isAuth === false) {
      return null;
    } else {
      return loader(...args);
    }
  };
  return loaderFunction;
}
