import React, { useEffect, useState } from "react";
import { concatClassNames as cn } from "@system42/core";
import Autolinker from "autolinker";

import {
  Checkbox,
  RadioSelect,
  RadioSelectItem,
  TextArea,
} from "@/design-system";
import { isVerboseMode, sendMessage } from "../../helpers";
import RatingScale from "../RatingScale";

import designSystemStyles from "../../designSystem.module.css";
import styles from "./styles.module.css";

const verboseMode = isVerboseMode();

function nl2br(str, is_xhtml = false) {
  const breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  let result = (str + "")
    .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2")
    .replace(/(?:<br>\s*){2,}/g, "<p>");
  if (result.indexOf("<p>") !== 0) {
    result = "<p>" + result;
  }
  return result;
}

function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

function filterTask(task) {
  task = escapeHtml(task);

  // Convert Links
  task = Autolinker.link(task, {
    className: "autolinked",
    stripPrefix: true,
    phone: false,
    twitter: false,
    email: false,
    hashtag: false,
    newWindow: true,
    replaceFn: (match) => {
      const tag = match.buildTag(); // returns an `Autolinker.HtmlTag` instance for an <a> tag
      return tag;
    },
  });

  // Convert Newlines
  task = nl2br(task);

  return task;
}

function getResponseKey(step) {
  return {
    task: "completed",
    rating_scale: "rating",
    multiple_choice: "choice",
    single_choice: "choice",
    written_response: "response",
  }[step?.type];
}

export default function Index(props) {
  const {
    step,
    taskMeta,
    syncPopup,
    stepResponse,
    allTasksComplete,
    onSaveTaskStepResponse,
  } = props;
  const responseKey = getResponseKey(step);
  const taskStepId = step.id;

  const [responseValue, setResponseValue] = useState(
    stepResponse?.[responseKey],
  );

  function handleChangeResponseValue(event, parseType) {
    let value;

    if (typeof event?.target?.value === "undefined") {
      value = event;
    } else {
      value = event.target.value;
    }

    switch (parseType) {
      case "int":
        value = parseInt(value, 10);
        break;
      case "boolean":
        value =
          value === "1" || value === true || value === 1 || value === "true";
        break;
      default:
    }

    if (event?.target?.type === "checkbox") {
      // Checkbox
      const checked = event.target.checked;
      setResponseValue((responseValue) => {
        let newResponseValue;

        if (!Array.isArray(responseValue)) {
          newResponseValue = [];
        } else {
          newResponseValue = [...responseValue];
        }

        if (checked) {
          const existing =
            typeof newResponseValue.find((item) => item === value) !==
            "undefined";
          if (!existing) {
            newResponseValue.push(value);
          }
        } else {
          newResponseValue = responseValue.filter((item) => item !== value);
        }

        return newResponseValue;
      });
    } else {
      // Value
      setResponseValue(value);
    }
  }

  function handleChangeResponseValueInt(e) {
    return handleChangeResponseValue(e, "int");
  }

  function handleChangeResponseValueBoolean(e) {
    return handleChangeResponseValue(e, "boolean");
  }

  function handleClickUnskip() {
    setResponseValue(undefined);
  }

  function handleClickSkip() {
    setResponseValue(null);
  }

  useEffect(() => {
    if (responseKey) {
      onSaveTaskStepResponse(taskStepId, responseKey, responseValue); // simulator only

      sendMessage({
        request: "saveTaskStepResponse",
        taskStepId,
        responseKey,
        responseValue,
      }).then(() => {
        // So that taskMeta gets updated
        syncPopup();
      });
    }
  }, [
    responseValue,
    taskStepId,
    responseKey,
    syncPopup,
    onSaveTaskStepResponse,
  ]);

  return (
    <div>
      {step.type === "task" && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: filterTask(step?.task) }} />
          {step.options?.ask_completed && (
            <div>
              <RadioSelect
                onChange={handleChangeResponseValueBoolean}
                value={responseValue?.toString() || responseValue}
              >
                <RadioSelectItem value={false.toString()}>
                  I could not complete the task
                </RadioSelectItem>
                <RadioSelectItem value={true.toString()}>
                  I have completed the task
                </RadioSelectItem>
              </RadioSelect>
            </div>
          )}
        </div>
      )}

      {step.type === "rating_scale" && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: filterTask(step?.task) }} />
          <RatingScale
            scaleStartLabel={step.options?.scale_start_label || "low"}
            scaleEndLabel={step.options?.scale_end_label || "high"}
            scaleStart={step.options?.scale_start || 0}
            scaleEnd={step.options?.scale_end || 10}
            onChange={handleChangeResponseValueInt}
            value={responseValue}
          />
        </div>
      )}

      {step.type === "multiple_choice" && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: filterTask(step?.task) }} />
          {responseValue === null ? (
            <div>
              <i>You have chosen not to answer this question.</i>
              <> </>
              <button
                className={cn(
                  designSystemStyles["button-link"],
                  styles.undoButton,
                )}
                onClick={handleClickUnskip}
              >
                Undo
              </button>
            </div>
          ) : (
            <>
              {step.options?.answers?.map((answer, answerIndex) => (
                <Checkbox
                  key={answerIndex}
                  className={styles.checkbox}
                  value={answerIndex.toString()}
                  checked={
                    typeof responseValue?.find?.(
                      (item) => item === answerIndex,
                    ) !== "undefined"
                  }
                  onChange={handleChangeResponseValueInt}
                >
                  {answer}
                </Checkbox>
              ))}
              <button
                className={cn(
                  designSystemStyles["button-link"],
                  styles.skipButton,
                )}
                onClick={handleClickSkip}
              >
                I can’t answer this question.
              </button>
            </>
          )}
        </div>
      )}

      {step.type === "single_choice" && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: filterTask(step?.task) }} />

          {responseValue === null ? (
            <div>
              <i>You have chosen not to answer this question.</i>
              <> </>
              <button
                className={cn(
                  designSystemStyles["button-link"],
                  styles.undoButton,
                )}
                onClick={handleClickUnskip}
              >
                Undo
              </button>
            </div>
          ) : (
            <>
              <RadioSelect
                onChange={handleChangeResponseValueInt}
                value={responseValue?.toString() || responseValue}
              >
                {step.options?.answers?.map((answer, answerIndex) => (
                  <RadioSelectItem
                    value={answerIndex.toString()}
                    key={answerIndex}
                  >
                    {answer}
                  </RadioSelectItem>
                ))}
              </RadioSelect>
              <button
                className={cn(
                  designSystemStyles["button-link"],
                  styles.skipButton,
                )}
                onClick={handleClickSkip}
              >
                I can’t answer this question.
              </button>
            </>
          )}
        </div>
      )}

      {step.type === "written_response" && (
        <div>
          <div dangerouslySetInnerHTML={{ __html: filterTask(step?.task) }} />
          <TextArea
            fullWidth
            onChange={handleChangeResponseValue}
            className={styles.textarea}
            value={responseValue}
          />
        </div>
      )}

      {step.type === "redirect" && (
        <div>
          {allTasksComplete ? (
            <p>You have completed all steps.</p>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: filterTask(step?.task) }} />
          )}
        </div>
      )}

      {verboseMode && (
        <pre style={{ whiteSpace: "normal" }}>
          {JSON.stringify(
            taskMeta?.steps?.filter(
              (stepInMeta) => stepInMeta.teststep_id === step.id,
            ),
          )}
        </pre>
      )}
    </div>
  );
}
