const chrome = null;

const verboseModeOn = false;

export function sendMessage(message) {
  return new Promise((resolve, reject) => {
    if (
      chrome &&
      chrome.runtime &&
      typeof chrome.runtime.sendMessage === "function"
    ) {
      chrome.runtime.sendMessage(message, resolve);
    } else {
      resolve();
      // reject('chrome.runtime.sendMessage not available');
    }
  });
}

export function addMessageListener(listener) {
  if (
    chrome &&
    chrome.runtime &&
    chrome.runtime.onMessage &&
    typeof chrome.runtime.onMessage.addListener === "function"
  ) {
    chrome.runtime.onMessage.addListener(listener);
  }
}

export function removeMessageListener(listener) {
  if (
    chrome &&
    chrome.runtime &&
    chrome.runtime.onMessage &&
    typeof chrome.runtime.onMessage.removeListener === "function"
  ) {
    chrome.runtime.onMessage.removeListener(listener);
  }
}

let isVerboseModeCached = null;
export function isVerboseMode() {
  if (isVerboseModeCached === null) {
    isVerboseModeCached = isDevMode() && verboseModeOn;
  }
  return isVerboseModeCached;
}

export function getChromeExtensionVersion() {
  if (chrome && chrome.app && typeof chrome.app.getDetails === "function") {
    const appDetails = chrome.app.getDetails();
    if (appDetails && appDetails.version) {
      return appDetails.version;
    }
  }
  return "not available";
}

let isDevModeCached = null;
export function isDevMode() {
  if (isDevModeCached === null) {
    isDevModeCached = process.env.REACT_APP_HOST_ENV === "development";
  }
  return isDevModeCached;
}
