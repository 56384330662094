import React from "react";
import { concatClassNames } from "@system42/core";
import PropTypes from "prop-types";

import { extractContainerProps, noop } from "../../helpers";

import styles from "./styles.module.css";

class Alert extends React.Component {
  static defaultProps = {
    styles,
    onClose: noop,
  };

  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClose("clickOutside");
    }
  }

  render() {
    const { actions, children, styles, className } = this.props;
    return (
      <div {...extractContainerProps(this.props)} className={styles.root}>
        <div
          className={concatClassNames(className, styles.alert)}
          ref={this.setWrapperRef}
        >
          {children}
          <div className={styles.actions}>{actions}</div>
        </div>
      </div>
    );
  }
}

export default Alert;
