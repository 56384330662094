import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Heading1, SubNavigationItem, SubNavigationNav } from "@/design-system";
import { SHOW_DEV_TOOLS } from "../../helpers";

import styles from "./styles.module.css";

class SubNavigationAccount extends Component {
  render() {
    const { currentNavItem, isSuperAdmin, isOwnerOrAdmin } = this.props;
    return (
      <SubNavigationNav>
        <Heading1 className={styles.mainHeading}>Account</Heading1>
        <SubNavigationItem active={currentNavItem === "account"}>
          <Link to={"/account"}>Account Details</Link>
        </SubNavigationItem>
        {isOwnerOrAdmin && (
          <SubNavigationItem active={currentNavItem === "buy-credits"}>
            <Link to={"/account/buy-credits"}>Buy&nbsp;Credits</Link>
          </SubNavigationItem>
        )}
        {isOwnerOrAdmin && (
          <SubNavigationItem active={currentNavItem === "billing"}>
            <Link to={"/account/billing"}>Usage &amp; Billing</Link>
          </SubNavigationItem>
        )}
        {isOwnerOrAdmin && (
          <SubNavigationItem active={currentNavItem === "team"}>
            <Link to={"/account/team"}>Team</Link>
          </SubNavigationItem>
        )}
        {isSuperAdmin && (
          <SubNavigationItem active={currentNavItem === "super-admin"}>
            <Link to={"/super-admin"}>Superadmin</Link>
          </SubNavigationItem>
        )}
        {SHOW_DEV_TOOLS && (
          <SubNavigationItem active={currentNavItem === "account-dev"}>
            <Link to={"/account/dev"}>Account Dev</Link>
          </SubNavigationItem>
        )}
      </SubNavigationNav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSuperAdmin: state.user.user && state.user.user.role === "superadmin",
    isOwnerOrAdmin:
      state.user.user &&
      (state.user.user.role === "owner" ||
        state.user.user.role === "superadmin" ||
        state.user.user.role === "admin"),
  };
};

export default connect(mapStateToProps)(SubNavigationAccount);
