import { LoaderFunctionArgs } from "react-router-dom";

import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";

async function loader({ params }: LoaderFunctionArgs) {
  const currentInsightsTestId = store.getState().testInsights.data?.testId;
  if (currentInsightsTestId === Number(params.testId)) {
    store.dispatch({
      type: "TEST_INSIGHTS_REQUEST",
      testId: Number(params.testId),
      isKeepData: true,
    });
  } else {
    store.dispatch({
      type: "TEST_INSIGHTS_REQUEST",
      testId: Number(params.testId),
    });
  }
  return null;
}

export default authRequiredWrapper(loader);
