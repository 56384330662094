import { MutableRefObject } from "react";

import TaskTimeline from "@/components/TaskTimeline";
import { TaskStep, Video } from "../types";

interface TaskProps {
  currentVideoSecond: number;
  video: Video;
  taskStepsWithResponse: TaskStep[];
  onClickTime: (timestamp: number) => void;
  isLegacyTask: boolean;
  scrollerRef: MutableRefObject<HTMLDivElement | null>;
  isCurrentItemVisible: boolean;
  onChangeCurrentItemVisible: (isVisible: boolean) => void;
  onClickDownloadVideoClip: (
    timestampStart: number,
    timestampEnd: number,
  ) => void;
}

/**
 *
 * Task
 */
export function Task({
  video,
  currentVideoSecond,
  taskStepsWithResponse,
  onClickTime,
  isLegacyTask,
  scrollerRef,
  isCurrentItemVisible,
  onChangeCurrentItemVisible,
  onClickDownloadVideoClip,
}: TaskProps) {
  return (
    <TaskTimeline
      videoDuration={video.duration || 0}
      currentVideoTime={currentVideoSecond}
      taskStepsWithResponse={taskStepsWithResponse}
      onClickTime={onClickTime}
      onClickDownloadVideoClip={onClickDownloadVideoClip}
      isLegacyTask={isLegacyTask}
      scrollerRef={scrollerRef}
      preventScrollIntoView={!isCurrentItemVisible}
      onChangeCurrentTimelineItemVisible={onChangeCurrentItemVisible}
    />
  );
}
