import React, { Component } from "react";

import { noop } from "../../../../helpers";

import { Task } from "./Task";

import styles from "./styles.module.css";

class Popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: "https://not-needed.anymore", // For simulator
      taskMetaSteps: [], // For simulator
      allTasksComplete: false,
      recorderState: "recording",
    };
  }

  stopTest = () => {};

  cancelTest = () => {};

  pauseOrResumeTest = () => {
    this.setState((prevState) => ({
      recorderState:
        prevState.recorderState === "paused" ? "recording" : "paused",
    }));
  };

  handleRedirect = (currentUrl, isLastStep) => {
    this.setState({ currentUrl, allTasksComplete: !!isLastStep });
  };

  handleReset = () => {
    this.setState({
      taskMetaSteps: [],
      allTasksComplete: false,
    });
  };

  handleSaveTaskStepResponse = (taskStepId, responseKey, responseValue) => {
    this.setState((prevState) => {
      const { taskMetaSteps } = prevState;

      let step = taskMetaSteps.find((step) => step.teststep_id === taskStepId);

      let taskMetaStepsUpdated;

      if (!step) {
        taskMetaStepsUpdated = [
          ...taskMetaSteps,
          {
            teststep_id: taskStepId,
            response: { [responseKey]: responseValue },
          },
        ];
      } else {
        taskMetaStepsUpdated = taskMetaSteps.map((step2) => {
          if (step === step2) {
            return {
              ...step2,
              response: { ...step2.response, [responseKey]: responseValue },
            };
          }
          return step2;
        });
      }

      return {
        taskMetaSteps: taskMetaStepsUpdated,
      };
    });
  };

  render() {
    const id = 12345;
    const { recorderState, taskMetaSteps } = this.state;
    const isDevelopment = false;

    const testRecording = {
      id,
      title: "#" + id,
      task: this.props.task.map((taskStep) => ({
        ...taskStep,
        id: taskStep.id || taskStep.localId,
      })),
      url: this.state.currentUrl,
    };

    return (
      <div className={`${styles.Popup} ${styles["TestDetail"]}`}>
        <Task
          taskMeta={{ steps: taskMetaSteps }}
          test={testRecording}
          isDevelopment={isDevelopment}
          recorderState={recorderState}
          allTasksComplete={this.state.allTasksComplete}
          key={testRecording.id}
          stopTest={this.stopTest}
          cancelTest={this.cancelTest}
          pauseOrResumeTest={this.pauseOrResumeTest}
          isRecording={true}
          onReset={this.handleReset}
          initialStep={this.props.initialStep}
          syncPopup={noop} /* Should not change */
          onRedirect={this.handleRedirect} // For simulator only
          onSaveTaskStepResponse={this.handleSaveTaskStepResponse} // For simulator only
        />
      </div>
    );
  }
}

export default Popup;
