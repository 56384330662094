import React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames } from "@system42/core";

import ButtonLink from "../ButtonLink";
import TextArea from "../TextArea";

import styles from "./styles.module.css";

const taskDefaultEn = `Please remember to think out loud during your test and tell us if there’s any information missing. You can input fake information if you need to sign up or anything.\n\n###TASK###\n\nTake a look at this site and tell us what you think it is. (You can scroll if you want but please don’t click on anything yet.)\n\n-- What’s the first thing you notice?\n-- What can you do on this site?\n-- What products or services are offered on this site?\n-- Who is this site intended for?\n\n###TASK###\n\nNow imagine the products or services offered on this site are intended for you and go ahead to do whatever you think you’re supposed to do on this site (e.g. buy a product, make a booking, create an account, etc.).\n\nThank you for your help!`;
const taskDefaultDe = `Erinnere dich bitte daran während deines Tests laut mitzudenken und sag uns, wenn es irgendwelche Informationen gibt, die dir fehlen. Gib einfach Fantasiedaten ein, wenn du dich zum Beispiel anmelden musst.\n\nWirf einen Blick auf diese Seite und sag uns, worum es sich dabei deiner Meinung nach handelt. (Du kannst scrollen, wenn du willst, aber bitte klicke noch nicht.)\n\n-- Was fällt dir als Erstes auf?\n-- Was kannst du auf dieser Seite tun?\n-- Welche Produkte bzw. Dienstleistungen werden auf dieser Seite angeboten?\n-- Für wen ist diese Seite gedacht?\n\n###TASK###\n\nStell dir jetzt vor, die Produkte bzw. Dienstleistungen auf dieser Seite wären für dich bestimmt und mach einfach, was du deiner Meinung nach auf dieser Seite machen solltest (z.B. ein Produkt kaufen, eine Buchung vornehmen, ein Konto erstellen, etc.).\n\nVielen Dank für deine Hilfe!`;
const taskEcommerceEn = `Imagine you’re interested in buying [***ABCXYZ***] online. A friend has referred you to this site and and you decided to see if you can find something here. You want to spend between [***123$£€***] and [***456$£€***].\n\nTry to find out more about the different offers on this site and choose your favourite. Please tell us if there’s any information missing to help make your decision or anything else you would need to know before you purchase.\n\n###TASK###\n\nNow go ahead and try to buy your favourite product. You can input fake information if you need to. Please stop the test before making any payment.\n\n###TASK###\n\nWhen you’re done, please answer the following questions:\n\n-- If there’s one thing you could improve on this site, what would that be?\n-- Have you ever bought [***ABCXYZ***] online before? If yes, how did the experience on this site compare to your experiences in the past?\n-- How would you rate your experience from 1 (poor) to 5 (excellent)?\n\nThank you for your help!`;
const taskEcommerceDe = `Stell dir vor, du würdest gerne [***ABCXYZ***] online kaufen. Ein Freund hat dir diese Seite empfohlen und du hast entschieden, dich hier umzusehen, ob du etwas Passendes finden kannst. Du möchtest zwischen [***123$£€***] und maximal [***456$£€***] ausgeben.\n\nVersuche mehr über die verschiedenen Angebote auf dieser Seite herauszufinden und wähle deinen Favoriten. Sag uns bitte, wenn es irgendwelche Informationen gibt, die dir fehlen, um eine Entscheidung treffen zu können bzw. um tatsächlich einzukaufen.\n\n###TASK###\n\nVersuche jetzt bitte dein Lieblingsprodukt zu kaufen. Gib wenn nötig einfach Fantasiedaten ein und beende deinen Test bevor du eine Zahlung durchführst.\n\n###TASK###\n\nWenn du fertig bist, beantworte bitte folgende Fragen:\n\n-- Wenn es eine Sache gibt, die du auf dieser Seite verbessern würdest, was wäre das?\n-- Hast du schon einmal [***ABCXYZ***] online gekauft? Wenn ja, wie war diese Erfahrung im Vergleich zu deinen früheren Erfahrungen?\n-- Wie würdest du deine Erfahrung von 1 (schlecht) bis 5 (ausgezeichnet) bewerten?\n\nVielen Dank für deine Hilfe!`;
const taskBookingEn = `Imagine you’re interested in booking [***ABCXYZ***] online. A friend has referred you to this site and and you decided to see if you can find something here. You’re looking for an offer for [***??? days***] for [***??? persons***] and you have a maximum budget of [***456$£€***].\n\nTry to find out more about the different offers on this site and choose your favourite. Please tell us if there’s any information missing to make your decision or anything else you would need to know before you book.\n\n###TASK###\n\nNow go ahead and try to book your favourite offer. You can input fake information if you need to. Please stop the test before making any payment.\n\n###TASK###\n\nWhen you’re done, please answer the following questions:\n\n-- If there’s one thing you could improve on this site, what would that be?\n-- Have you ever booked [booking-item] online before? If yes, how did the experience on this site compare to your experiences in the past.\n-- How would you rate your experience from 1 (poor) to 5 (excellent)?\n\nThank you for your help!`;
const taskBookingDe = `Stell dir vor, du würdest gerne [***ABCXYZ***] online buchen. Ein Freund hat dir diese Seite empfohlen und du hast entschieden, dich hier umzusehen, ob du etwas Passendes finden kannst. Du bist auf der Suche nach einem Angebot für [***??? Tage***] für [***??? Personen***] und möchtest maximal [***456$£€***] ausgeben.\n\nVersuche mehr über die verschiedenen Angebote auf dieser Seite herauszufinden und wähle deinen Favoriten. Sag uns bitte, wenn es irgendwelche Informationen gibt, die dir fehlen, um eine Entscheidung treffen zu können bzw. um tatsächlich zu buchen.\n\n###TASK###\n\nVersuche jetzt bitte dein Lieblingsangebot zu buchen. Gib wenn nötig einfach Fantasiedaten ein und beende deinen Test bevor du eine Zahlung durchführst.\n\nWenn du fertig bist, beantworte bitte folgende Fragen:\n\n###TASK###\n\n-- Wenn es eine Sache gibt, die du auf dieser Seite verbessern würdest, was wäre das?\n-- Hast du schon einmal [***ABCXYZ***] online gebucht? Wenn ja, wie war diese Erfahrung im Vergleich zu deinen früheren Erfahrungen?\n-- Wie würdest du deine Erfahrung von 1 (schlecht) bis 5 (ausgezeichnet) bewerten?\n\nVielen Dank für deine Hilfe!`;
const taskSaasEn = `A friend has sent you this site and said that this could be very useful for you. Unfortunately, you can't really remember what your friend was telling you about this site.\n\nTry to find out more about the products or services by exploring the site for a few minutes. Please tell us if there’s any information missing and describe how you think everything works in your own words.\n\n###TASK###\n\nNow go ahead and find out more about the actual costs. What exactly will you get and how much will you have to pay for that? What are the differences between the various offers, if there are any? Please be as specific as you can.\n\n###TASK###\n\nCreate an account and do whatever you think you’re supposed to do and see how far you can go without entering any payment information.\n\n###TASK###\n\nWhen you’re done, please answer the following questions:\n\n-- If there’s one thing you could improve on this site, what would that be?\n-- Have you ever seen anything like this before? If yes, can you remember the name and whether it's better or worse than what's offered on this site?\n\nThank you for your help!`;
const taskSaasDe = `Ein Freund hat dir diese Seite geschickt und gemeint, dass das sehr nützlich für dich sein könnte. Leider kannst du dich nicht mehr wirklich daran erinnern, was dir dein Freund über diese Seite erzählt hat.\n\nVersuche herauszufinden, welche Produkte bzw. Dienstleistungen auf dieser Seite angeboten werden. Sag uns bitte, wenn es irgendwelche Informationen gibt, die dir fehlen und beschreibe in deinen eigenen Worten, wie du glaubst, dass die beschriebenen Produkte bzw. Dienstleistungen funktionieren.\n\n###TASK###\n\nVersuche jetzt mehr über die tatsächlichen Kosten herauszufinden. Was genau würdest du für dein Geld bekommen und wieviel müsstest du dafür bezahlen? Was sind die Unterschiede zwischen den einzelnen Angebote. Sei bitte so genau wie möglich.\n\nErstelle ein Konto und tu, was auch immer du glaubst, dass man auf dieser Seite tun sollte und versuche dabei soweit wie möglich zu kommen, ohne Bezahldaten einzugeben.\n\n###TASK###\n\nWenn du fertig bist, beantworte bitte folgende Fragen:\n\n-- Wenn es eine Sache gibt, die du auf dieser Seite verbessern würdest, was wäre das?\n-- Hast du schon einmal etwas Vergleichbares gesehen? Wenn ja, kannst du dich noch an den Namen erinnern und, ob du es als besser oder schlechter empfunden hast, als das, was auf dieser Seite angeboten wird?\n\nVielen Dank für deine Hilfe!`;
const taskCompanyEn = `Take a look at this site and tell us what you think it is (you can scroll if you want but please don’t click on anything yet):\n\n-- What’s the first thing you notice?\n-- What can you do on this site?\n-- What products or services are offered on this site?\n-- Who is this site intended for?\n\nFind out more about the products or services by exploring the rest of the site for a few minutes. Please tell us if there’s any information missing and describe what’s offered on this site in your own words.\n\n###TASK###\n\nNow go ahead and try to find a way to contact the people behind this site and explain what exactly you would do to get in contact.\n\n###TASK###\n\nWhen you’re done, please answer the following questions:\n\n-- If there’s one thing you could improve on this site, what would it be?\n-- Have you ever seen anything like this before? If yes, can you remember the name and whether it's better or worse than what's offered on this site?\n\nThank you for your help!`;
const taskCompanyDe = `Wirf einen Blick auf diese Seite und sag uns, worum es sich dabei deiner Meinung nach handelt. (Du kannst scrollen, wenn du willst, aber bitte klicke noch nicht.)\n\n-- Was fällt dir als Erstes auf?\n-- Was kannst du auf dieser Seite tun?\n-- Welche Produkte bzw. Dienstleistungen werden auf dieser Seite angeboten?\n-- Für wen ist diese Seite gedacht?\n\nFinde mehr über die Produkte bzw. Dienstleistungen heraus, indem du den Rest der Seite für ein paar Minuten erforscht. Sag uns bitte, wenn es irgendwelche Informationen gibt, die dir fehlen und beschreibe das, was angeboten wird, in deinen eigenen Worten.\n\n###TASK###\n\nVersuche jetzt einen Weg zu finden, um die Menschen, die hinter dieser Seite stecken zu kontaktieren und erkläre, was genau du tun würdest, um Kontakt mit ihnen aufzunehmen.\n\n###TASK###\n\nWenn du fertig bist, beantworte bitte folgende Fragen:\n\n-- Wenn es eine Sache gibt, die du auf dieser Seite verbessern würdest, was wäre das?\n-- Hast du schon einmal etwas Vergleichbares gesehen? Wenn ja, kannst du dich noch an den Namen erinnern und, ob du es als besser oder schlechter empfunden hast, als das, was auf dieser Seite angeboten wird?\n\nVielen Dank für deine Hilfe!`;

class InputTestInstructions extends React.Component {
  static defaultProps = { styles };

  constructor(props) {
    super(props);
    this.state = {
      activeTaskTemplate: "taskCustomEn",
      taskCustomEn: props.value,
      taskCustomDe: props.value,
      taskDefaultEn,
      taskDefaultDe,
      taskEcommerceEn,
      taskEcommerceDe,
      taskBookingEn,
      taskBookingDe,
      taskSaasEn,
      taskSaasDe,
      taskCompanyEn,
      taskCompanyDe,
      restoreCustomEnabled: !!props.value,
      customizeTaskActive: false,
    };
    this.refTextareaTask = React.createRef();
  }

  componentDidMount() {
    if (!this.props.value) {
      this.onClickSetTaskTemplateDefault();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.language !== this.props.language) {
      const newTemplateName =
        this.state.activeTaskTemplate.substr(
          0,
          this.state.activeTaskTemplate.length - 2,
        ) +
        this.props.language.charAt(0).toUpperCase() +
        this.props.language.slice(1);
      const newState = {
        activeTaskTemplate: newTemplateName,
      };
      this.setState(newState);
      this.props.onChange({ target: { value: this.state[newTemplateName] } });
    }
  }

  onChangeTask = (e, cb) => {
    switch (this.state.activeTaskTemplate) {
      case "taskCustomEn":
        this.setState({ taskCustomEn: e.target.value });
        break;
      case "taskCustomDe":
        this.setState({ taskCustomDe: e.target.value });
        break;
      case "taskDefaultEn":
        this.setState({ taskDefaultEn: e.target.value });
        break;
      case "taskDefaultDe":
        this.setState({ taskDefaultDe: e.target.value });
        break;
      case "taskEcommerceEn":
        this.setState({ taskEcommerceEn: e.target.value });
        break;
      case "taskEcommerceDe":
        this.setState({ taskEcommerceDe: e.target.value });
        break;
      case "taskBookingEn":
        this.setState({ taskBookingEn: e.target.value });
        break;
      case "taskBookingDe":
        this.setState({ taskBookingDe: e.target.value });
        break;
      case "taskSaasEn":
        this.setState({ taskSaasEn: e.target.value });
        break;
      case "taskSaasDe":
        this.setState({ taskSaasDe: e.target.value });
        break;
      case "taskCompanyEn":
        this.setState({ taskCompanyEn: e.target.value });
        break;
      case "taskCompanyDe":
        this.setState({ taskCompanyDe: e.target.value });
        break;
      default:
        this.setState({ taskCustomEn: e.target.value });
        break;
    }
    this.props.onChange(e, cb);
  };

  toggleCustomizeTask = () => {
    this.setState((prevState) => ({
      customizeTaskActive: !prevState.customizeTaskActive,
    }));
  };

  onClickSetTaskTemplateCustom = () => {
    this.setState({
      activeTaskTemplate:
        this.props.language === "de" ? "taskCustomDe" : "taskCustomEn",
    });
    this.props.onChange({
      target: {
        value:
          this.props.language === "de"
            ? this.state.taskCustomDe
            : this.state.taskCustomEn,
      },
    });
  };

  onClickSetTaskTemplateDefault = () => {
    this.setState({
      activeTaskTemplate:
        this.props.language === "de" ? "taskDefaultDe" : "taskDefaultEn",
    });
    this.props.onChange({
      target: {
        value:
          this.props.language === "de"
            ? this.state.taskDefaultDe
            : this.state.taskDefaultEn,
      },
    });
  };

  onClickSetTaskTemplateEcommerce = () => {
    this.setState({
      activeTaskTemplate:
        this.props.language === "de" ? "taskEcommerceDe" : "taskEcommerceEn",
    });
    this.props.onChange({
      target: {
        value:
          this.props.language === "de"
            ? this.state.taskEcommerceDe
            : this.state.taskEcommerceEn,
      },
    });
  };

  onClickSetTaskTemplateBooking = () => {
    this.setState({
      activeTaskTemplate:
        this.props.language === "de" ? "taskBookingDe" : "taskBookingEn",
    });
    this.props.onChange({
      target: {
        value:
          this.props.language === "de"
            ? this.state.taskBookingDe
            : this.state.taskBookingEn,
      },
    });
  };

  onClickSetTaskTemplateSaas = () => {
    this.setState({
      activeTaskTemplate:
        this.props.language === "de" ? "taskSaasDe" : "taskSaasEn",
    });
    this.props.onChange({
      target: {
        value:
          this.props.language === "de"
            ? this.state.taskSaasDe
            : this.state.taskSaasEn,
      },
    });
  };

  onClickSetTaskTemplateCompany = () => {
    this.setState({
      activeTaskTemplate:
        this.props.language === "de" ? "taskCompanyDe" : "taskCompanyEn",
    });
    this.props.onChange({
      target: {
        value:
          this.props.language === "de"
            ? this.state.taskCompanyDe
            : this.state.taskCompanyEn,
      },
    });
  };

  onClickAddTask = () => {
    let selectionStart = this.refTextareaTask.current.selectionStart;
    const oldValue = this.props.value;
    let textToInsert = "###TASK###";
    const currentLineIndex =
      oldValue.substr(0, selectionStart).split("\n").length - 1;
    const lines = oldValue.split("\n");
    const currentLine = lines[currentLineIndex];

    let insertPos = currentLineIndex + 1;

    if (currentLine.trim() === "") {
      insertPos = currentLineIndex;
    }

    if (
      currentLineIndex === 0 &&
      (lines[0].trim() === "" || selectionStart === 0)
    ) {
      insertPos = 0;
    }

    lines.splice(insertPos, 0, textToInsert);

    // Add a empty line below if next line is not a empty line
    if (
      (insertPos + 1 < lines.length && lines[insertPos + 1].trim() !== "") ||
      insertPos + 1 > lines.length - 1
    ) {
      //console.log('Added a empty line below if next line is not a empty line');
      lines.splice(insertPos + 1, 0, "");
    }

    // Add a empty line below if there is no next line
    if (insertPos + 2 > lines.length - 1) {
      //console.log('Added a empty line below if there is no next line');
      lines.splice(insertPos + 1, 0, "");
    }

    if (insertPos - 1 >= 0 && lines[insertPos - 1].trim() !== "") {
      //console.log('Added a empty line above because the line above is not empty.');
      lines.splice(insertPos, 0, "");
      insertPos++;
    }

    const value = lines.join("\n");
    this.onChangeTask({ target: { value } }, () => {
      this.refTextareaTask.current.focus();
      const startOfNextNextLine = lines
        .filter((value, index) => index <= insertPos + 1)
        .reduce((prev, curr) => prev + curr.length + 1, 0);
      this.refTextareaTask.current.setSelectionRange(
        startOfNextNextLine,
        startOfNextNextLine,
      );
    });
  };

  render() {
    const { styles, id, placeholder, value, error } = this.props;
    const { activeTaskTemplate, restoreCustomEnabled, customizeTaskActive } =
      this.state;
    return (
      <div>
        <>
          <div className={styles.taskTypeSelect}>
            <div className={styles.customizeTaskButtonWrapper}>
              <ButtonLink
                onClick={this.toggleCustomizeTask}
                className={styles.customizeTaskButton}
              >
                Templates
                <FontAwesomeIcon
                  icon={solid("caret-down")}
                  className={styles.customizeTaskButton__caret}
                />
              </ButtonLink>
            </div>
            {customizeTaskActive && (
              <div className={styles.taskTypeSelectItems}>
                <div
                  className={concatClassNames(
                    styles.taskTypeSelect__item,
                    styles.taskTypeSelect__itemDefault,
                    activeTaskTemplate.indexOf("Default") !== -1 &&
                      styles["taskTypeSelect__item--active"],
                  )}
                  onClick={this.onClickSetTaskTemplateDefault}
                >
                  Default
                </div>
                <div
                  className={concatClassNames(
                    styles.taskTypeSelect__item,
                    styles.taskTypeSelect__itemEcommerce,
                    activeTaskTemplate.indexOf("Ecommerce") !== -1 &&
                      styles["taskTypeSelect__item--active"],
                  )}
                  onClick={this.onClickSetTaskTemplateEcommerce}
                >
                  E-commerce
                </div>
                <div
                  className={concatClassNames(
                    styles.taskTypeSelect__item,
                    styles.taskTypeSelect__itemBooking,
                    activeTaskTemplate.indexOf("Booking") !== -1 &&
                      styles["taskTypeSelect__item--active"],
                  )}
                  onClick={this.onClickSetTaskTemplateBooking}
                >
                  Booking
                </div>
                <div
                  className={concatClassNames(
                    styles.taskTypeSelect__item,
                    styles.taskTypeSelect__itemSaas,
                    activeTaskTemplate.indexOf("Saas") !== -1 &&
                      styles["taskTypeSelect__item--active"],
                  )}
                  onClick={this.onClickSetTaskTemplateSaas}
                >
                  <abbr title={"Software as a Service"}>SaaS</abbr> site
                </div>
                <div
                  className={concatClassNames(
                    styles.taskTypeSelect__item,
                    styles.taskTypeSelect__itemCompany,
                    activeTaskTemplate.indexOf("Company") !== -1 &&
                      styles["taskTypeSelect__item--active"],
                  )}
                  onClick={this.onClickSetTaskTemplateCompany}
                >
                  Company site
                </div>
                {activeTaskTemplate.indexOf("Custom") === -1 &&
                  restoreCustomEnabled && (
                    <ButtonLink
                      className={styles.restoreTaskButton}
                      onClick={this.onClickSetTaskTemplateCustom}
                    >
                      <FontAwesomeIcon
                        icon={solid("undo")}
                        className={styles.restoreTaskButtonIcon}
                      />
                      Restore previous version
                    </ButtonLink>
                  )}
              </div>
            )}
          </div>
          <div className={styles.taskToolbar}>
            <button
              className={styles.taskToolbarButton}
              onClick={this.onClickAddTask}
              type="button"
            >
              <FontAwesomeIcon icon={regular("plus")} fixedWidth /> Task Step
            </button>
            <button
              className={`${styles.taskToolbarButton} ${styles.taskToolbarButtonPreview}`}
              onClick={this.props.onClickShowPreview}
              type="button"
            >
              <FontAwesomeIcon icon={regular("eye")} fixedWidth /> Preview Test
            </button>
          </div>
        </>

        <TextArea
          className={`${styles.textarea}`}
          error={error}
          id={id}
          onChange={this.onChangeTask}
          placeholder={placeholder}
          rows={10}
          value={value}
          innerRef={this.refTextareaTask}
          fullWidth
          autoSize
        />
      </div>
    );
  }
}

export default InputTestInstructions;
