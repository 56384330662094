import { z } from "zod";

export const VideoClipTaskFilterItemApiSchema = z.object({
  id: z.string(),
  type: z.enum([
    "task",
    "rating_scale",
    "multiple_choice",
    "single_choice",
    "written_response",
    "redirect",
  ]),
  task: z.string(),
  count: z.number(),
  position: z.number(), // step number index
});
export type VideoClipTaskFilterItemApi = z.infer<
  typeof VideoClipTaskFilterItemApiSchema
>;

export type VideoClipTaskFilterItem = {
  id: string;
  type: z.infer<typeof VideoClipTaskFilterItemApiSchema.shape.type>;
  description: string;
  count: number;
  position: number;
};

export function parseVideoClipTaskFilterItemApi(
  taskFilterItemApi: VideoClipTaskFilterItemApi,
): VideoClipTaskFilterItem {
  const { type, task, count, id, position } =
    VideoClipTaskFilterItemApiSchema.parse(taskFilterItemApi);
  return {
    id: id,
    type: type,
    description: task,
    count: count ?? 0,
    position,
  };
}
