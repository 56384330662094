import React, { useCallback, useEffect, useRef, useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import produce from "immer";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useUrlHash } from "use-url-hash";

import { Button } from "@/design-system";
import ConfirmModal from "../ConfirmModal";

import StepSeparator from "./StepSeparator";
import TaskStep from "./TaskStep";

import styles from "./styles.module.css";

const defaultTaskRedirect =
  "After clicking the button below, you will be directed to another page. Please open the test instructions afterwards.";

const emptyStep = {
  type: "task",
  task: "",
  options: {
    ask_completed: true,
  },
};

function isStepEmpty(step) {
  return !step.task.trim();
}

function nextLocalTaskId(steps) {
  const max = steps.reduce((acc, curr) => Math.max(acc, curr.localId || 0), 0);
  return max + 1;
}

export default function TaskEditor(props) {
  const {
    steps,
    originalSteps,
    fieldFeedback = {},
    onChangeWithProduce,
    onClickPreviewTaskStep,
    isDisabled,
  } = props;

  const [confirmDeleteStep, setConfirmDeleteStep] = useState(null);

  const urlHash = useUrlHash();

  const refTaskStepToScrollIntoView = useRef();

  const handleChangeTask = useCallback(
    (e, stepIndex) => {
      const producer = (steps) =>
        produce(steps, (draft) => {
          draft[stepIndex].task = e.target.value;
        });
      onChangeWithProduce(producer);
    },
    [onChangeWithProduce],
  );

  const handleChangeOption = useCallback(
    (event, key, stepIndex, atIndex) => {
      let value;
      if (event.target.type === "checkbox") {
        // Checkbox
        value = event.target.checked;
      } else {
        // Value
        value = event.target.value;
      }

      const producer = (steps) =>
        produce(steps, (draft) => {
          const step = draft[stepIndex];

          let updatedOptions = { ...step.options };

          if (typeof atIndex === "undefined") {
            updatedOptions[key] = value;
          } else {
            if (!Array.isArray(updatedOptions[key])) {
              updatedOptions[key] = [];
            }
            if (atIndex === updatedOptions[key].length) {
              // Append if index is one out of range above
              updatedOptions[key].push(value);
            } else if (typeof updatedOptions[key][atIndex] !== "undefined") {
              updatedOptions[key][atIndex] = value;
            }
          }

          draft[stepIndex] = {
            ...step,
            options: updatedOptions,
          };
        });
      onChangeWithProduce(producer);
    },
    [onChangeWithProduce],
  );

  const handleChangeTaskType = useCallback(
    (e, stepIndex) => {
      const taskType = e.target.value;

      const producer = (steps) =>
        produce(steps, (draft) => {
          const step = steps[stepIndex];

          // Default task for redirect
          let task = step.task;
          if (taskType === "redirect") {
            if (task.trim().length === 0) {
              task = defaultTaskRedirect;
            }
          } else if (task === defaultTaskRedirect) {
            task = "";
          }

          // Add needed options according to taskType
          let options = step.options;
          if (taskType === "rating_scale") {
            options = {
              ...options,
              scale_end: options.scale_end ?? 10,
              scale_start: options.scale_start ?? 1,
              scale_start_label: options.scale_start_label ?? "",
              scale_end_label: options.scale_end_label ?? "",
            };
          } else if (taskType === "redirect") {
            options = {
              ...options,
              url: options.url ?? "",
            };
          } else if (
            taskType === "task" &&
            step.options.ask_completed !== "boolean"
          ) {
            options = {
              ...options,
              ask_completed: true,
            };
          }

          draft[stepIndex] = {
            ...step,
            type: taskType,
            task,
            options,
          };
        });
      onChangeWithProduce(producer);
    },
    [onChangeWithProduce],
  );

  // Duplicate step
  const handleClickDuplicate = useCallback(
    (stepIndex) => {
      const producer = (steps) =>
        produce(steps, (draft) => {
          draft.forEach((step) => (step.localAddedRecently = false));

          const duplicatedStep = {
            ...steps[stepIndex],
            id: null,
            localId: nextLocalTaskId(steps),
            localAddedRecently: true,
          };

          delete duplicatedStep.response_count;

          draft.splice(stepIndex + 1, 0, duplicatedStep);
        });
      onChangeWithProduce(producer);
    },
    [onChangeWithProduce],
  );

  // Add step
  const handleClickAddStep = useCallback(
    (stepIndex) => {
      const producer = (steps) =>
        produce(steps, (draft) => {
          draft.forEach((step) => (step.localAddedRecently = false));
          draft.splice(stepIndex, 0, {
            ...emptyStep,
            localId: nextLocalTaskId(steps),
            localAddedRecently: true,
          });
        });
      onChangeWithProduce(producer);
    },
    [onChangeWithProduce],
  );

  const deleteStep = useCallback(
    (stepIndex) => {
      const producer = (steps) =>
        produce(steps, (draft) => {
          draft.splice(stepIndex, 1);
        });
      onChangeWithProduce(producer);
    },
    [onChangeWithProduce],
  );

  const handleClickDelete = useCallback(
    (stepIndex, step) => {
      if (isStepEmpty(step)) {
        deleteStep(stepIndex);
      } else {
        setConfirmDeleteStep(stepIndex);
      }
    },
    [deleteStep],
  );

  const handleClickDeleteAnswer = useCallback(
    (stepIndex, answerIndex) => {
      const producer = (steps) =>
        produce(steps, (draft) => {
          draft[stepIndex].options.answers.splice(answerIndex, 1);
        });
      onChangeWithProduce(producer);
    },
    [onChangeWithProduce],
  );

  const handleDragEnd = useCallback(
    (result) => {
      const { destination, source } = result;
      // Dropped outside the list
      if (!destination) {
        return;
      }
      const answerTypePrefix = "ANSWER-";
      if (result.type === "STEP") {
        // Move step
        const producer = (steps) =>
          produce(steps, (draft) => {
            const movingSteps = draft.splice(source.index, 1);
            draft.splice(destination.index, 0, ...movingSteps);
          });
        onChangeWithProduce(producer);
      } else if (result.type.indexOf(answerTypePrefix) === 0) {
        // Move answer
        const stepIndexToUpdate = parseInt(
          result.type.substring(answerTypePrefix.length),
          10,
        );
        const producer = (steps) =>
          produce(steps, (draft) => {
            const movingAnswers = draft[
              stepIndexToUpdate
            ].options.answers.splice(source.index, 1);
            draft[stepIndexToUpdate].options.answers.splice(
              destination.index,
              0,
              ...movingAnswers,
            );
          });
        onChangeWithProduce(producer);
      }
    },
    [onChangeWithProduce],
  );

  function handleCancelDeleteStep() {
    setConfirmDeleteStep(null);
  }

  function handleConfirmDeleteStep() {
    if (confirmDeleteStep !== null) {
      deleteStep(confirmDeleteStep);
      setConfirmDeleteStep(null);
    }
  }

  useEffect(() => {
    if (refTaskStepToScrollIntoView.current) {
      refTaskStepToScrollIntoView.current.scrollIntoView();
    }
  }, [urlHash]);

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={"task-steps"} type={"STEP"}>
          {(provided, snapshot) => (
            <div
              className={cn(
                styles.wrapper,
                snapshot.isDraggingOver && styles.wrapper_isDraggingOver,
              )}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {
                <StepSeparator
                  onClick={() => handleClickAddStep(0)}
                  className={cn(
                    styles.stepSeparator,
                    isDisabled && styles.stepSeparator_disabled,
                  )}
                />
              }

              {steps.map((step, stepIndex) => {
                const originalStep = originalSteps?.find(
                  (originalStep) => originalStep.id === step.id,
                );
                return (
                  <TaskStep
                    ref={
                      urlHash === `task-${step.id}`
                        ? refTaskStepToScrollIntoView
                        : undefined
                    }
                    key={step.id || `LOCAL_${step.localId}`}
                    step={step}
                    originalStep={originalStep}
                    fieldFeedback={fieldFeedback}
                    stepIndex={stepIndex}
                    onClickDuplicate={handleClickDuplicate}
                    onChangeTaskType={handleChangeTaskType}
                    onClickAddStep={handleClickAddStep}
                    onClickDelete={handleClickDelete}
                    onChangeTask={handleChangeTask}
                    onChangeOption={handleChangeOption}
                    onClickDeleteAnswer={handleClickDeleteAnswer}
                    onClickPreview={onClickPreviewTaskStep}
                    classNameStepSeparator={styles.stepSeparator}
                    hideDeleteButton={steps.length === 1 || isDisabled}
                    hidePreviewButton={isDisabled}
                    hideDuplicateButton={isDisabled}
                    hideDragHandle={isDisabled}
                    isDisabled={isDisabled}
                  />
                );
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {!isDisabled && (
        <Button
          className={styles.addStepButtonBelow}
          type="button"
          onClick={() => handleClickAddStep(steps.length)}
        >
          <FontAwesomeIcon icon={solid("plus")} /> Add task
        </Button>
      )}
      <ConfirmModal
        isActive={confirmDeleteStep !== null}
        labelConfirm={"Delete"}
        onClose={handleCancelDeleteStep}
        onConfirm={handleConfirmDeleteStep}
      >
        Are you sure you want to delete this task?
      </ConfirmModal>
    </>
  );
}
