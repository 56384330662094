import { ReactNode } from "react";

import { ButtonPrimary } from "@/design-system";
import { TestTabCardContentInfoContent } from "../InfoContent";

import styles from "./styles.module.css";

type TestTabCardContentLockedProps = {
  onClickExplorePlans: () => void;
  imageSrc: string;
  imageAlt: string;
  title: string;
  text: ReactNode;
  textWidth: string;
};

export function TestTabCardContentLocked(props: TestTabCardContentLockedProps) {
  const { onClickExplorePlans, imageSrc, imageAlt, title, text, textWidth } =
    props;

  return (
    <TestTabCardContentInfoContent
      imageSrc={imageSrc}
      imageAlt={imageAlt}
      title={title}
      content={
        <>
          <div style={{ maxWidth: textWidth }} className={styles.text}>
            {text}
          </div>
          <ButtonPrimary
            className={styles.buttonExplore}
            onClick={onClickExplorePlans}
          >
            Explore our plans
          </ButtonPrimary>
        </>
      }
    />
  );
}
