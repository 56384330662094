import { LoaderFunctionArgs } from "react-router-dom";

import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";

async function loader({ params, request }: LoaderFunctionArgs) {
  const search = new URL(request.url).searchParams.toString();

  // If the currently loaded test is the same as the one we're trying to load, we can load the new
  // test silently.
  const { videosRequestAction } = store.getState().videos as any;

  if (videosRequestAction?.search === search) {
    // If the search is the same, we can just re-request the videos.
    // loading will not be visible to the user and the browser back button
    // can restore the previous scroll position.
    store.dispatch({
      type: "VIDEOS_RE_REQUEST",
    });
  } else {
    store.dispatch({
      type: "VIDEOS_REQUEST",
      search,
    });
  }

  // Intentionally moved calling VIDEOS_TESTS_REQUEST to the component itself to avoid
  // unnecessary requests when searching. the VIDEOS_REQUEST is throttled in the saga.

  return null;
}

export default authRequiredWrapper(loader);
