import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import {
  ButtonLink,
  Card,
  Heading2,
  MainNavigation,
  MainNavigationContent,
  SubNavigation,
  SubNavigationContent,
} from "@/design-system";
import Spinner from "../../components/Spinner";
import UpdatePasswordForm from "../../components/UpdatePasswordForm";
import UpdateUserForm from "../../components/UpdateUserForm";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordFormVisible: false,
    };
    this.reloadAccount();
    this.onUpdateEmail = this.onUpdateEmail.bind(this);
    this.onUpdatePassword = this.onUpdatePassword.bind(this);
  }

  reloadAccount() {
    this.props.loadAccount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.updateUserPasswordFetching === true &&
      this.props.updateUserPasswordFetching === false
    ) {
      if (!this.props.updateUserPasswordIsError) {
        this.setState({ passwordFormVisible: false });
      }
    }
  }

  onUpdateEmail(formData) {
    this.props.updateEmail(formData.email, formData.name);
  }

  onUpdatePassword(formData) {
    this.props.updatePassword(
      formData.newPassword,
      formData.newPasswordConfirmation,
      formData.currentPassword,
    );
  }

  render() {
    const { user } = this.props;
    const title = "Email and Password";
    return (
      <>
        <Helmet>
          <title>{title} | Userbrain</title>
        </Helmet>
        <MainNavigation>
          <Navigation />
          <MainNavigationContent>
            <Notifications />
            <SubNavigation>
              <SubNavigationAccount currentNavItem={"account"} />
              <SubNavigationContent>
                <div className={styles.content}>
                  {user ? (
                    <Card>
                      <div className={styles.formContent}>
                        <Heading2 className={styles.cardHeading1}>
                          {title}
                        </Heading2>
                        <div className={styles.emailForm}>
                          {user && (
                            <UpdateUserForm
                              errorMessage={this.props.updateUserError?.message}
                              fieldFeedback={
                                this.props.updateUserError?.fieldFeedback
                              }
                              defaultValues={user}
                              saving={this.props.updateUserFetching}
                              onSave={this.onUpdateEmail}
                            />
                          )}
                        </div>
                        <div className={styles.passwordForm}>
                          {this.state.passwordFormVisible ? (
                            <>
                              <h3 className={styles.cardHeading2}>
                                Update your password:
                              </h3>
                              <UpdatePasswordForm
                                errorMessage={
                                  this.props.updateUserPasswordError?.message
                                }
                                fieldFeedback={
                                  this.props.updateUserPasswordError
                                    ?.fieldFeedback
                                }
                                saving={this.props.updateUserPasswordFetching}
                                onSave={this.onUpdatePassword}
                              />
                            </>
                          ) : (
                            <ButtonLink
                              onClick={() =>
                                this.setState({ passwordFormVisible: true })
                              }
                            >
                              Need to change your password?
                            </ButtonLink>
                          )}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <Spinner />
                  )}
                </div>
              </SubNavigationContent>
            </SubNavigation>
          </MainNavigationContent>
        </MainNavigation>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    updateUserFetching: state.user.updateFetching,
    updateUserError: state.user.updateError,

    updateUserPasswordFetching: state.user.updatePasswordFetching,
    updateUserPasswordError: state.user.updatePasswordError,
    updateUserPasswordIsError: state.user.updatePasswordIsError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAccount: () => dispatch({ type: "ACCOUNT_REQUEST" }),
    updatePassword: (newPassword, newPasswordConfirmation, currentPassword) =>
      dispatch({
        type: "USER_UPDATE_PASSWORD_REQUEST",
        newPassword,
        newPasswordConfirmation,
        currentPassword,
      }),
    updateEmail: (email, name) =>
      dispatch({ type: "USER_UPDATE_REQUEST", email, name }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
