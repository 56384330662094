import React, { Component } from "react";
import * as PropTypes from "prop-types";

import { Button } from "@/design-system";
import { sendMessage } from "../helpers";

import TaskStep from "./TaskStep";

import designSystem from "../designSystem.module.css";
import styles from "./styles.module.css";

function taskStepsFromTask(task) {
  let taskSteps;
  if (Array.isArray(task)) {
    taskSteps = task;
  } else {
    taskSteps = task.split("###TASK###").map((task) => ({
      task,
      type: "task",
      options: { ask_completed: false },
    }));
    taskSteps = taskSteps.map((step) => {
      return { ...step, task: step.task.replace("###INTRO###", "").trim() };
    });
    taskSteps = taskSteps.filter((val) => !!val);
  }

  // Show something if taskSteps is empty
  if (taskSteps.length < 1) {
    taskSteps = [
      {
        type: "task",
        task: "What do you see on this site?",
        options: { ask_completed: false },
      },
    ];
  }

  return taskSteps;
}

export class Task extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStepIndex: props.initialStep ? props.initialStep : 0,
      taskSteps: taskStepsFromTask(props.test.task),
      legacyTask: !Array.isArray(props.test.task),
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.test !== this.props.test) {
      this.setState({
        taskSteps: taskStepsFromTask(this.props.test.task),
        legacyTask: !Array.isArray(this.props.test.task),
      });
    }
  }

  onClickPrevStep = () => {
    this.setState(
      { currentStepIndex: Math.max(0, this.state.currentStepIndex - 1) },
      () =>
        sendMessage({
          request: "saveTaskStep",
          taskStep: this.state.currentStepIndex,
        }),
    );
  };

  handleClickNextPreview = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentStepIndex: this.state.currentStepIndex + 1,
    }));
  };

  handleClickBackPreview = () => {
    this.setState((prevState) => ({
      ...prevState,
      currentStepIndex: Math.max(0, prevState.currentStepIndex - 1),
    }));
  };

  onClickRedirect = (isLastStep) => {
    // For simulator
    const currentStep =
      this.state.taskSteps[
        Math.min(this.state.taskSteps.length - 1, this.state.currentStepIndex)
      ];
    this.props.onRedirect(currentStep?.options?.url, isLastStep);
    if (!isLastStep) {
      this.setState({ currentStepIndex: this.state.currentStepIndex + 1 });
    }
    // For simulator end

    /*sendMessage({
      request: 'taskStepRedirectToUrl',
      currentTaskStepIndex: this.state.currentStepIndex
    }).then(() => {
      // This will get called when the popup does not close on redirect (currently: never)
      if (!isLastStep) {
        this.setState({ currentStepIndex: this.state.currentStepIndex + 1 });
      }
      this.props.syncPopup();
    });
    window.close();*/
  };

  onClickReopen = () => {
    sendMessage({
      request: "reopenTestSite",
    });
  };

  onClickStopRecording = () => {
    this.props.stopTest();
  };

  render() {
    const { currentStepIndex, taskSteps } = this.state;
    const {
      isDevelopment,
      recorderState,
      isRecording,
      taskMeta,
      syncPopup,
      allTasksComplete,
    } = this.props;
    let test = this.props.test;
    if (isDevelopment) {
      test = {
        ...test,
        url: "https://projects.invisionapp.com/prototype/desktop-search-ck1urm1ja00fpww011b0rl737/play/4df42290",
      };
    }
    const isInvision = test.url.match(/projects.invisionapp.com/i);

    const currentStep =
      taskSteps[Math.min(taskSteps.length - 1, currentStepIndex)];
    const currentStepResponse =
      taskMeta?.steps?.find(
        (stepInMeta) => stepInMeta.teststep_id === currentStep.id,
      )?.response || null;

    const invisionNotice = (
      <div
        style={{
          background: "#fff7bb",
          padding: "0.5em 1em",
          margin: "0.75em 0",
        }}
      >
        You see an InVision login page instead of a prototype?{" "}
        <button
          className={`${designSystem["button-link"]}`}
          type="button"
          onClick={this.onClickReopen}
        >
          Click here to reload
        </button>
      </div>
    );

    if (recorderState === "paused") {
      return (
        <>
          <div className={`${styles.TestDetail__Task}`}>
            <p className={styles.TestDetail__TaskPaused}>Recording paused...</p>
          </div>
        </>
      );
    } else if (!isRecording) {
      return (
        <div className={`${styles.TestDetail__Task}`}>
          <p className={styles.TestDetail__TaskPlaceholder}>
            You will see your task here after you start recording.
          </p>
        </div>
      );
    } else {
      const isLastStep = currentStepIndex >= taskSteps.length - 1;
      return (
        <>
          <div className={`${styles.TestDetail__Task}`}>
            <div className={`${styles.TestDetail__TaskStepInfo}`}>
              Step {currentStepIndex + 1}/{taskSteps.length}
            </div>
            {isInvision &&
              isRecording &&
              currentStepIndex === 0 &&
              invisionNotice}
            <TaskStep
              step={currentStep}
              taskMeta={taskMeta}
              stepResponse={currentStepResponse}
              key={currentStepIndex}
              syncPopup={syncPopup}
              allTasksComplete={allTasksComplete}
              onSaveTaskStepResponse={this.props.onSaveTaskStepResponse} // simulator only
            />
            <div className={`${styles.TestDetail__Navigation}`}>
              {currentStepIndex > 0 && (
                <Button onClick={this.handleClickBackPreview}>Back</Button>
              )}
              {!isLastStep && (
                <button
                  onClick={this.handleClickNextPreview}
                  className={`${styles.TestDetail__NavigationNext} ${designSystem["button"]} ${designSystem["button--primary"]}`}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </>
      );
    }
  }
}

Task.propTypes = { currentTest: PropTypes.any };
