import { useDispatch, useSelector } from "react-redux";

import { ButtonPrimary, ErrorMessage } from "@/design-system";

import styles from "./styles.module.css";

/**


  Helpers for development.

*/
export function DevTools() {
  const dispatch = useDispatch();
  const confirmEmailResend = useSelector((state) => state.confirmEmailResend);

  function handleClickConfirmEmailResend() {
    dispatch({ type: "CONFIRM_EMAIL_RESEND_REQUEST" });
  }

  return (
    <div className={styles.devToolsContainer}>
      <ButtonPrimary onClick={handleClickConfirmEmailResend}>
        DEV Confirm email resend{confirmEmailResend.isFetching && <>…</>}
      </ButtonPrimary>
      {confirmEmailResend.isError && (
        <ErrorMessage>{confirmEmailResend.error.message}</ErrorMessage>
      )}
      <div>
        <a
          rel="noreferrer"
          href={`/confirm/${confirmEmailResend.data?.token}`}
          target={"_blank"}
        >
          DEV Confirm link
        </a>
      </div>
    </div>
  );
}
