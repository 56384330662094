import React from "react";
import { concatClassNames as cn } from "@system42/core";

import { Button, ButtonPrimary } from "@/design-system";
import { urlContact } from "@/helpers-ts";
import {
  formatPrice,
  getFromYourPlanWording,
  getSubscriptionName,
} from "../../helpers";

import { mainFeaturesDefault, mainFeaturesLegacy } from "./featureLists";

import styles from "./styles.module.css";

import imgPlanAgency from "./agency.png";
import imgPlanLegacy from "./legacy.png";
import imgPlanPayg from "./payAsYouGo.png";
import imgPlanPro from "./pro.png";
import imgPlanStarter from "./starter.png";

export const PLAN_LEGACY = 0;
export const PLAN_STARTER = 1;
export const PLAN_PRO = 2;
export const PLAN_AGENCY = 3;
export const PLAN_PAYG = 4;

export function PlanInfoBox(props) {
  const { plan, ...propsWithoutPlan } = props;

  const {
    className,
    title,
    planImage,
    planImageAlt,
    monthlyPrice,
    annualPrice,
    paygPrice,
    payAsYouGo,
    currency,
    billingCycle,
    mostPopular,
    buttonLabel,
    onClickButton,
    mainFeatures,
    showOutdated,
    isEnterprise,
    buttonDisabled,
    subscriptionQuantity /* For legacy plans */,
    showCancel,
    canCancel,
    onClickCancel,
    ...containerProps
  } = propsWithoutPlan;

  const priceSuffix =
    billingCycle === "annual" ? "/ mo. billed yearly" : "per month";

  switch (plan) {
    case PLAN_LEGACY:
      return (
        <PlanInfoBox
          title={getSubscriptionName(subscriptionQuantity)}
          planImage={imgPlanLegacy}
          mainFeatures={mainFeaturesLegacy(subscriptionQuantity)}
          showOutdated={true}
          {...propsWithoutPlan}
        />
      );
    case PLAN_PAYG:
      return (
        <PlanInfoBox
          title={<>Pay as you&nbsp;go</>}
          planImage={imgPlanPayg}
          planImageAlt={"A pixalated pointing cursor"}
          {...propsWithoutPlan}
        />
      );
    case PLAN_STARTER:
      return (
        <PlanInfoBox
          title={"Starter"}
          planImage={imgPlanStarter}
          planImageAlt={"Hand pressing a buzzer"}
          buttonLabel={"Choose Starter"}
          mostPopular={false}
          mainFeatures={mainFeaturesDefault(billingCycle, 3, 5, 1)}
          {...propsWithoutPlan}
        />
      );
    case PLAN_PRO:
      return (
        <PlanInfoBox
          title={"Pro"}
          planImage={imgPlanPro}
          planImageAlt={"A spaceshuttle on turquoise background"}
          buttonLabel={"Choose Pro"}
          mostPopular={true}
          mainFeatures={mainFeaturesDefault(billingCycle, 10, 20, 3)}
          {...propsWithoutPlan}
        />
      );
    case PLAN_AGENCY:
      return (
        <PlanInfoBox
          title={"Agency"}
          planImage={imgPlanAgency}
          planImageAlt={
            "Old Apple computer with a pixalated open mail envelope."
          }
          buttonLabel={"Choose Agency"}
          mainFeatures={mainFeaturesDefault(billingCycle, 30, 50, 20)}
          {...propsWithoutPlan}
        />
      );
    default:
      const cancelHtml = (
        <>
          {canCancel && (
            <button onClick={onClickCancel} className={styles.cancelLink}>
              Cancel your subscription
            </button>
          )}
          {!canCancel && (
            <div className={styles.onlyOwnerCanCancelInfo}>
              Only the account owner can cancel
            </div>
          )}
        </>
      );

      return (
        <div
          className={cn(
            className,
            styles.planInfoBox,
            showOutdated && styles.planInfoBox_outdated,
          )}
          {...containerProps}
        >
          <div className={styles.planHeader}>
            {planImage && <img src={planImage} alt={planImageAlt} />}
            <div>
              <div className={styles.planTitleWrapper}>
                <h2
                  className={cn(
                    styles.planTitle,
                    showOutdated && styles.planTitle_small,
                  )}
                >
                  {title}
                </h2>
                {mostPopular && (
                  <strong className={styles.mostPopular}>Most popular</strong>
                )}
              </div>
            </div>
          </div>
          {isEnterprise && (
            <>
              <div className={styles.planPrice}>
                <span className={styles.planPricePrice}>Let’s talk</span>
              </div>
              <Button
                fullWidth
                className={styles.startFreeTrialButton}
                target="_blank"
                rel="noreferrer"
                href={urlContact}
              >
                {buttonLabel}
              </Button>
            </>
          )}
          {paygPrice && (
            <>
              <div className={styles.planPrice}>
                <span className={styles.planPricePrice}>
                  {formatPrice(paygPrice, currency)}
                </span>
                <span className={styles.planPricePeriod}>per tester</span>
              </div>
              <Button
                fullWidth
                className={styles.startFreeTrialButton}
                onClick={onClickButton}
                disabled={buttonDisabled}
              >
                {buttonLabel ||
                  `Pay ${formatPrice(
                    paygPrice * payAsYouGo.creditsToBuy,
                    currency,
                  )}`}
              </Button>
            </>
          )}
          {annualPrice && monthlyPrice && (
            <>
              <div className={styles.planPrice}>
                <span className={styles.planPricePrice}>
                  {formatPrice(
                    billingCycle === "annual" ? annualPrice / 12 : monthlyPrice,
                    currency,
                  )}
                </span>
                <span className={styles.planPricePeriod}>{priceSuffix}</span>
              </div>
              <ButtonPrimary
                fullWidth
                className={styles.startFreeTrialButton}
                onClick={onClickButton}
                disabled={buttonDisabled}
              >
                {buttonLabel}
              </ButtonPrimary>
            </>
          )}
          {payAsYouGo && (
            <>
              <div className={styles.paygSummaryRow}>
                <div>Your order</div>
                <div>
                  {payAsYouGo.sessionsToUse +
                    payAsYouGo.creditsToUse +
                    payAsYouGo.creditsToBuy}{" "}
                  tester
                  {payAsYouGo.sessionsToUse +
                    payAsYouGo.creditsToUse +
                    payAsYouGo.creditsToBuy >
                    1 && "s"}
                </div>
              </div>
              {payAsYouGo.sessionsToUse > 0 && (
                <div className={styles.paygSummaryRow}>
                  <div>
                    {getFromYourPlanWording(
                      payAsYouGo.hasUserInvoices,
                      payAsYouGo.isFreeTrial,
                    )}
                  </div>
                  <div>
                    {payAsYouGo.sessionsToUse} tester
                    {payAsYouGo.sessionsToUse > 1 && "s"}
                  </div>
                </div>
              )}
              {payAsYouGo.creditsToUse > 0 && (
                <div className={styles.paygSummaryRow}>
                  <div>From your credits</div>
                  <div>
                    {payAsYouGo.creditsToUse} tester
                    {payAsYouGo.creditsToUse > 1 && "s"}
                  </div>
                </div>
              )}
              <div className={styles.paygSummaryRow}>
                <div>
                  {payAsYouGo.creditsToBuy} tester
                  {payAsYouGo.creditsToBuy > 1 && "s"}
                  <span className={styles.paygSummaryRowPricePerTester}>
                    {" "}
                    × {formatPrice(paygPrice, currency)} per tester
                  </span>
                </div>
                <div>
                  {formatPrice(paygPrice * payAsYouGo.creditsToBuy, currency)}
                </div>
              </div>
              <div
                className={cn(
                  styles.paygSummaryRow,
                  styles.paygSummaryRow_total,
                )}
              >
                <div>Total cost</div>
                <div>
                  {formatPrice(paygPrice * payAsYouGo.creditsToBuy, currency)}
                </div>
              </div>
            </>
          )}

          {mainFeatures && (
            <ul className={cn(styles.featureList, styles.mainFeatures)}>
              {mainFeatures}
            </ul>
          )}

          {showCancel && showOutdated && (
            <div className={styles.outdatedInfo}>
              <div>
                This plan is no longer available. In case you decide to cancel
                it, you won’t be able to subscribe to it again.
              </div>
              {cancelHtml}
            </div>
          )}

          {showCancel && !showOutdated && (
            <div className={styles.cancelLinkContainer}>{cancelHtml}</div>
          )}
        </div>
      );
  }
}
