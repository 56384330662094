import { useEffect, useState } from "react";

/**
 * Use session storage in a similar fashion to useState.
 *
 * @param key The key to use in session storage.
 * @param value The initial value to use if the key is not in session storage.
 * @param isRaw If true, the value will be stored in session storage as a string.
 *
 * ```ts
 *  const [foo, setFoo] = useSessionStorage("foo", true);
 * ```
 */
export function useSessionStorage<T>(
  key: string,
  initialValue?: T,
  isRaw?: boolean,
): [T, (value: T) => void] {
  const [state, setState] = useState<T>(() => {
    try {
      const storedValue = sessionStorage.getItem(key);
      if (typeof storedValue !== "string") {
        sessionStorage.setItem(
          key,
          isRaw ? String(initialValue) : JSON.stringify(initialValue),
        );
        return initialValue;
      } else {
        return isRaw ? storedValue : JSON.parse(storedValue || "null");
      }
    } catch {
      // Storage restrictions or permissions can throw an error.
      // JSON.parse and .stringify can throw an error too.
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      const serialized = isRaw ? String(state) : JSON.stringify(state);
      sessionStorage.setItem(key, serialized);
    } catch {
      // Same reasons as catch above.
    }
  }, [state, key, isRaw]);

  return [state, setState];
}
