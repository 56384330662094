import { LoaderFunctionArgs } from "react-router-dom";

import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";

async function loader({ params }: LoaderFunctionArgs) {
  store.dispatch({ type: "VIDEO_REQUEST", id: params.id });
  return null;
}

export default authRequiredWrapper(loader);
