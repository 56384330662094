import React from "react";
import * as PropTypes from "prop-types";

import { InputNumber, Tooltip } from "@/design-system";

import styles from "./styles.module.css";

export function TesterQuantitySelection({ onChange, onBlur, value }) {
  return (
    <div className={styles.field}>
      <div>
        <label className={styles.fieldLabel}>Number of testers</label>&nbsp;
        <Tooltip
          className={styles.fieldTooltip}
          content={
            "We recommend testing in small rounds of no more than 5 testers and repeat regularly for the best return on investment."
          }
        />
      </div>
      <InputNumber
        className={styles.fieldInput}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        min={0}
      />
    </div>
  );
}

TesterQuantitySelection.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
};
