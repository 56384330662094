import React from "react";
import { concatClassNames as cn } from "@system42/core";

import { Checkbox, FormField } from "@/design-system";
import { urlPrivacy, urlTerms } from "@/helpers-ts";

import styles from "./styles.module.css";

export function FormFieldTermsAndConditions(props) {
  const { className, onChange, checked, ...restProps } = props;

  return (
    <FormField
      {...restProps}
      className={cn(className, styles.formFieldTermsAndConditions)}
    >
      <Checkbox
        className={styles.checkbox}
        onChange={onChange}
        checked={checked}
      >
        I agree to the{" "}
        <a
          href={urlTerms}
          title="Terms and Conditions"
          target="_blank"
          rel="noreferrer"
        >
          Terms & Conditions
        </a>{" "}
        and{" "}
        <a
          href={urlPrivacy}
          title="Privacy Policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy&nbsp;Policy
        </a>
        .
      </Checkbox>
    </FormField>
  );
}
