import { useMemo } from "react";
import { useSelector } from "react-redux";

/**
 * @typedef UserbrainIyopSessions
 * @type {object}
 * @property {number} available
 * @property {number} included
 * @property {number} used_this_month
 * @property {number} refills_at
 */

/**
 * Get IYOP sessions
 * @returns {UserbrainIyopSessions|false|null} Returns the IYOP sessions object
 */
export function useIyopSessions() {
  const iyopSessions = useSelector(({ user }) =>
    user.user ? user.user.sessions_iyop || false : null,
  );
  // Todo: useMemo has no referential guarantee, so return values reference may change even though user.user.subscription does not
  return useMemo(() => {
    if (iyopSessions) {
      return {
        ...iyopSessions,
        // XXX: Passing null to the Date constructor will return unix epoch
        refills_at: iyopSessions.refills_at
          ? new Date(iyopSessions.refills_at)
          : null,
      };
    } else {
      return iyopSessions;
    }
  }, [iyopSessions]);
}
