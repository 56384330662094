import React, { useEffect, useState } from "react";

import { Button, ButtonPrimary } from "@/design-system";
import { usePrevious } from "../../helpers";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";

import styles from "./styles.module.css";

export default function StopDeliveryModal({ isActive, onClose, ...restProps }) {
  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      centered
      content={<StopDeliveryModalContent onClose={onClose} {...restProps} />}
    />
  );
}

function StopDeliveryModalContent({ onClose, onConfirm, isFetching }) {
  const [step, setStep] = useState(1);

  const prevIsFetching = usePrevious(isFetching);
  useEffect(() => {
    if (prevIsFetching === true && isFetching === false) {
      setStep(2);
    }
  }, [prevIsFetching, isFetching]);

  function handleClose() {
    onClose();
  }

  function handleClickStop() {
    onConfirm();
  }
  return (
    <div className={styles.content}>
      {step === 2 ? (
        <>
          <h1 className={styles.titleSmall}>
            Test stopped, you got back all unused testers.
          </h1>
          <ButtonGroup align={"right"} className={styles.buttonGroup}>
            <ButtonPrimary onClick={handleClose}>Done</ButtonPrimary>
          </ButtonGroup>
        </>
      ) : (
        <>
          <h1 className={styles.titleSmall}>
            Are you sure you want to stop this test?
          </h1>
          <ButtonGroup align={"right"} className={styles.buttonGroup}>
            {isFetching ? (
              <ButtonPrimary disabled>Stopping...</ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={handleClickStop}>Stop test</ButtonPrimary>
            )}
            <Button onClick={handleClose}>Cancel</Button>
          </ButtonGroup>
        </>
      )}
    </div>
  );
}
