import React from "react";
import { concatClassNames } from "@system42/core";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

const defaultItems = [{ text: "No options" }];

class MenuButton extends React.Component {
  static defaultProps = { styles, items: defaultItems };

  constructor(props) {
    super(props);
    this.state = {
      open: false, // Can be 'top', 'bottom', true(handled like bottom)
    };
    this.buttonClicked = this.buttonClicked.bind(this);
    this.buttonBlur = this.buttonBlur.bind(this);
  }

  buttonClicked() {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  }

  buttonBlur() {
    this.setState({
      open: false,
    });
  }

  render() {
    const { children, className, styles, items } = this.props;
    const classNameItems = this.state.open
      ? styles.items + " " + styles["items--open"]
      : styles.items;
    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(className, styles.root)}
      >
        <button
          className={styles.button}
          onClick={this.buttonClicked}
          onBlur={this.buttonBlur}
        >
          {children}
        </button>
        <div className={classNameItems}>
          {items.map((item, i) => (
            <div className={styles.item} key={i}>
              {item.text}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MenuButton;
