import update from "immutability-helper";

const DOWNLOAD_VIDEO_CLIP_REQUEST = "DOWNLOAD_VIDEO_CLIP_REQUEST";
const DOWNLOAD_VIDEO_CLIP_STARTED = "DOWNLOAD_VIDEO_CLIP_STARTED";
const DOWNLOAD_VIDEO_CLIP_PROGRESS = "DOWNLOAD_VIDEO_CLIP_PROGRESS";
const DOWNLOAD_VIDEO_CLIP_SUCCESS = "DOWNLOAD_VIDEO_CLIP_SUCCESS";
const DOWNLOAD_VIDEO_CLIP_ERROR = "DOWNLOAD_VIDEO_CLIP_ERROR";

// reducer with initial state
const initialState = {
  downloads: [],
};

export function downloadVideoClipReducer(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_VIDEO_CLIP_REQUEST:
      return state;
    case DOWNLOAD_VIDEO_CLIP_STARTED:
      return {
        ...state,
        downloads: [...state.downloads, { downloadId: action.downloadId }],
      };
    case DOWNLOAD_VIDEO_CLIP_PROGRESS: {
      const indexOfDownload = state.downloads.findIndex(
        (i) => i.downloadId === action.downloadId,
      );
      if (indexOfDownload !== -1) {
        return update(state, {
          downloads: {
            [indexOfDownload]: {
              progress: { $set: action.progress },
              total: { $set: action.total },
              loaded: { $set: action.loaded },
            },
          },
        });
      }
      return state;
    }
    case DOWNLOAD_VIDEO_CLIP_SUCCESS:
      return state;
    case DOWNLOAD_VIDEO_CLIP_ERROR:
    default:
      return state;
  }
}
