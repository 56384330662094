const TEST_REPORT_REQUEST = "TEST_REPORT_REQUEST";
const TEST_REPORT_SUCCESS = "TEST_REPORT_SUCCESS";
const TEST_REPORT_FAILURE = "TEST_REPORT_FAILURE";

// reducer with initial state
const initialState = {
  fetching: false,
  report: null,
  error: null,
};

export function testReportReducer(state = initialState, action) {
  switch (action.type) {
    case TEST_REPORT_REQUEST: {
      const { silent } = action;
      const newState = {
        ...state,
        fetching: true,
        error: null,
      };
      if (silent !== true) {
        newState.report = null;
      }
      return newState;
    }
    case TEST_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        report: action.report,
      };
    case TEST_REPORT_FAILURE:
      return { ...state, fetching: false, error: action.error };
    default:
      return state;
  }
}
