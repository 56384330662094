import { useSelector } from "react-redux";

/**
 * @typedef UserbrainBilling
 * @type {object}
 */

/**
 * Get billing object
 * @returns {UserbrainBilling|false|null} Returns the billing object
 */
export function useBilling() {
  return useSelector(({ user }) =>
    user.user ? user.user.billing || false : null,
  );
}
