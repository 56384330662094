import React, { Component } from "react";
import queryString from "query-string";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import {
  ButtonPrimary,
  Card,
  FieldHelp,
  FormField,
  InputText,
  MainNavigation,
  MainNavigationContent,
  SubNavigation,
  SubNavigationContent,
} from "@/design-system";
import { withRouter } from "../../helpers";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

class SuperAdmin extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(props.router.location.search);
    this.state = {
      impersonateUserId: query.impersonate ?? "",
    };
  }

  impersonate = () => {
    this.props.impersonate(this.state.impersonateUserId);
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.impersonate();
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Superadmin | Userbrain</title>
        </Helmet>
        <MainNavigation>
          <Navigation />
          <MainNavigationContent>
            <Notifications />
            <SubNavigation>
              <SubNavigationAccount currentNavItem={"super-admin"} />
              <SubNavigationContent>
                <div className={styles.content}>
                  <Card>
                    <form
                      className={styles.formContent}
                      onSubmit={this.onSubmit}
                    >
                      <FormField label={"Impersonate"}>
                        <InputText
                          value={this.state.impersonateUserId}
                          onChange={(e) =>
                            this.setState({ impersonateUserId: e.target.value })
                          }
                        />
                        <FieldHelp>
                          The User-Id or E-Mail-Address of the user you want to
                          impersonate.
                        </FieldHelp>
                      </FormField>
                      <ButtonPrimary
                        className={styles.impersonateButton}
                        type={"submit"}
                      >
                        Impersonate
                      </ButtonPrimary>
                    </form>
                  </Card>
                </div>
              </SubNavigationContent>
            </SubNavigation>
          </MainNavigationContent>
        </MainNavigation>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    impersonate: (userId) => dispatch({ type: "ADMIN_IMPERSONATE", userId }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SuperAdmin),
);
