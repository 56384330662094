import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "@/design-system";
import { LayoutCenterWithLogo } from "@/layouts/LayoutCenterWithLogo";
import { SHOW_DEV_TOOLS } from "../../helpers";

import { Card } from "./Card";
import { DevTools } from "./DevTools";

import styles from "./styles.module.css";

/**


  Block access to the dashboard if the user
  has not verified their email yet.
  
*/

export default function VerifyEmail() {
  return (
    <LayoutCenterWithLogo endComponent={<LogoutButton />}>
      <Card />
      {SHOW_DEV_TOOLS && <DevTools />}
    </LayoutCenterWithLogo>
  );
}

/**


  Signout and redirect user to home.

*/

function LogoutButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleClickLogout() {
    navigate("/");
    dispatch({ type: "SIGN_OUT" });
  }

  return (
    <Button className={styles.logoutButton} onClick={handleClickLogout}>
      Logout
    </Button>
  );
}
