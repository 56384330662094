import { useMemo } from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

/**
 * @typedef UserbrainFreeTrial
 * @type {object}
 * @property {Date} expiresAt
 * @property {boolean} isActive
 * @property {int|null} daysLeft
 * @property {int} isExpired
 */

/**
 * Get the free trial object
 * @returns {UserbrainFreeTrial|null} Returns the free trial object
 */
export function useFreeTrial() {
  let freeTrial = useSelector(({ user }) =>
    user.user ? user.user.free_trial : null,
  );
  return useMemo(() => {
    if (freeTrial) {
      const expiresAt = new Date(freeTrial.expires_at);
      const daysLeft = freeTrial.expires_at
        ? dayjs(freeTrial.expires_at)
            .subtract(10, "minutes") // To avoid showing 15 days left when server and client time are not perfectly in sync
            .diff(dayjs(), "day") + 1
        : null;
      return {
        isActive: freeTrial.active,
        expiresAt,
        daysLeft,
        isExpired: freeTrial.active === false && !!freeTrial.expires_at,
      };
    } else {
      return freeTrial;
    }
  }, [freeTrial]);
}

/**
 * Get free trial active
 * @returns {true|false|null} Returns wheter the free trial is active or not
 */
export function useIsFreeTrial() {
  let freeTrial = useSelector(({ user }) =>
    user.user ? user.user.free_trial.active : null,
  );
  return freeTrial;
}
