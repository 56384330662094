import { LoaderFunctionArgs } from "react-router-dom";

import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";

async function loader({ params }: LoaderFunctionArgs) {
  const currentReportTestId = store.getState().testReport.report?.id;
  if (currentReportTestId === Number(params.testId)) {
    store.dispatch({
      type: "TEST_REPORT_REQUEST",
      testId: params.testId,
      silent: true,
    });
  } else {
    store.dispatch({ type: "TEST_REPORT_REQUEST", testId: params.testId });
  }
  return null;
}

export default authRequiredWrapper(loader);
