import { useEffect } from "react";
import isUndefined from "lodash/isUndefined";
import { useDispatch } from "react-redux";

import { usePrevious } from "@/helpers-ts";
import {
  VIDEO_SECOND_WATCHED,
  VideoStatsType,
} from "@/reducers/videoStatsReducer";

// XXX: Not perfect, if the transport is moved forward VIDEO_SECOND_WATCHED will be dispatched as well
export function useSendVideoStats(
  id: number | string,
  videoStatsType: VideoStatsType,
  currentSecond: number | undefined,
  refPlayer: React.MutableRefObject<{
    playing: boolean;
  } | null>,
) {
  const dispatch = useDispatch();
  const prevCurrentSecond = usePrevious(currentSecond);

  useEffect(() => {
    if (
      !isUndefined(currentSecond) &&
      !isUndefined(prevCurrentSecond) &&
      currentSecond > prevCurrentSecond &&
      refPlayer.current?.playing
    ) {
      dispatch({
        type: VIDEO_SECOND_WATCHED,
        videoStatsType,
        id,
        timestamp: prevCurrentSecond,
      });
    }
  }, [
    currentSecond,
    prevCurrentSecond,
    id,
    dispatch,
    videoStatsType,
    refPlayer,
  ]);
}
