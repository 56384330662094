import styles from "../../styles.module.css";

export interface ShowAutomatedInsightsButtonProps {
  count: number;
  onClick: () => void;
}

/**
 *
 * Show Automated Insights Button
 */
export function ShowAutomatedInsightsButton({
  count,
  onClick,
}: ShowAutomatedInsightsButtonProps) {
  return (
    <div className={styles.showAutomatedInsights}>
      {count} Automated insights hidden.{" "}
      <button onClick={onClick} className={styles.showAutomatedInsightsButton}>
        {" "}
        Show
      </button>
    </div>
  );
}
