import React from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";

import { ButtonPrimary } from "@/design-system";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from "./styles.module.css";

export default function ScreenerResponseModal({ isActive, onClose, screener }) {
  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      maxWidth={"25rem"}
      content={
        <ScreenerResponseModalContent onClose={onClose} screener={screener} />
      }
    />
  );
}

function ScreenerResponseModalContent({ onClose, screener }) {
  return (
    <>
      <ModalHeader
        heading={<>Screener response</>}
        onClickCloseIcon={onClose}
      />
      <div className={styles.content}>
        {screener?.map((screenerQuestion) => (
          <>
            <div className={styles.screenerQuestion}>
              {screenerQuestion.question}
            </div>
            {screenerQuestion.answers.map((answer) => (
              <div
                className={cn(
                  styles.screenerAnswer,
                  answer.selected && styles.screenerAnswer_selected,
                )}
              >
                {screenerQuestion.type === "single_choice" && (
                  <FontAwesomeIcon
                    fixedWidth
                    icon={
                      answer.selected
                        ? regular("dot-circle")
                        : regular("circle")
                    }
                  />
                )}
                {screenerQuestion.type === "multiple_choice" && (
                  <FontAwesomeIcon
                    fixedWidth
                    icon={
                      answer.selected
                        ? regular("check-square")
                        : regular("square")
                    }
                  />
                )}
                <div>{answer.title}</div>
              </div>
            ))}
          </>
        ))}
        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
        </ButtonGroup>
      </div>
    </>
  );
}
