import React, { useMemo } from "react";

import { TimelineItem } from "../../components/TimelineItem";

export default function TranscriptTimeline({
  transcript,
  currentVideoTime,
  videoDuration,
  onClickTime,
  scrollerRef,
  onChangeCurrentTimelineItemVisible,
  preventScrollIntoView,
}) {
  const transcriptChunks = useMemo(
    function () {
      let videoEndMarkerInserted = false;

      const timeLineSteps = transcript.map((chunk, index) => {
        const timestampCompleted = Math.round(Number(chunk.end));
        const timestampStart = Math.round(
          Number(transcript[index - 1]?.end ?? 0),
        );
        let videoEndsAfterThisStep = false;
        if (timestampCompleted >= videoDuration && !videoEndMarkerInserted) {
          videoEndsAfterThisStep = true;
          videoEndMarkerInserted = true;
        }
        return {
          ...chunk,
          type: "transcript",
          timestampStart,
          timestampCompleted,
          videoEndsAfterThisStep,
        };
      });

      if (!videoEndMarkerInserted && timeLineSteps.length) {
        // If not inserted insert for last step
        timeLineSteps[timeLineSteps.length - 1].videoEndsAfterThisStep = true;
      }
      return timeLineSteps;
    },
    [transcript, videoDuration],
  );

  return (
    <div>
      {transcriptChunks.map((chunk, index) => (
        <TimelineItem
          key={index}
          step={chunk}
          isLegacyTask={null}
          prevStep={transcriptChunks[index - 1]}
          currentVideoTime={currentVideoTime}
          videoDuration={videoDuration}
          onClickTime={onClickTime}
          scrollerRef={scrollerRef}
          preventScrollIntoView={preventScrollIntoView}
          onChangeCurrentTimelineItemVisible={
            onChangeCurrentTimelineItemVisible
          }
        />
      ))}
    </div>
  );
}
