import {
  extractFieldFeedbackFromApiError,
  extractMessageFromApiError,
} from "../helpers";

import { CHECKOUT_INIT } from "./checkout";

// ORDER TESTS

const ORDER_TESTS_REQUEST = "ORDER_TESTS_REQUEST";
const ORDER_TESTS_WAITING_FOR_CONFIRM = "ORDER_TESTS_WAITING_FOR_CONFIRM";
const ORDER_TESTS_CONFIRM = "ORDER_TESTS_CONFIRM";
const ORDER_TESTS_CONFIRM_FAILED = "ORDER_TESTS_CONFIRM_FAILED";
const ORDER_TESTS_SUCCESS = "ORDER_TESTS_SUCCESS";
const ORDER_TESTS_FAILURE = "ORDER_TESTS_FAILURE";
const ORDER_TESTS_CLEAR_ERRORS = "ORDER_TESTS_CLEAR_ERRORS";

const initialStateOrderTests = {
  error: null,
  errorMessage: "",
  fieldFeedback: {},

  deliveryError: null,
  deliveryErrorMessage: "",

  fetching: false,
  waitingForConfirmation: false,
  clientSecret: "",
  success: false,
};

export function orderTestsReducer(state = initialStateOrderTests, action) {
  switch (action.type) {
    case CHECKOUT_INIT:
      // Reset Error message when new checkout is started
      return {
        ...state,
        error: initialStateOrderTests.error,
        errorMessage: initialStateOrderTests.errorMessage,
        fieldFeedback: initialStateOrderTests.fieldFeedback,
      };
    case ORDER_TESTS_REQUEST:
      return {
        ...state,
        error: null,
        errorMessage: "",
        fieldFeedback: {},
        success: false,
        fetching: true,
        waitingForConfirmation: false,
        deliveryError: initialStateOrderTests.deliveryError,
        deliveryErrorMessage: initialStateOrderTests.deliveryErrorMessage,
      };
    case ORDER_TESTS_WAITING_FOR_CONFIRM:
      return {
        ...state,
        waitingForConfirmation: true,
        clientSecret: action.clientSecret,
      };
    case ORDER_TESTS_CONFIRM:
      return { ...state, waitingForConfirmation: false };
    case ORDER_TESTS_CONFIRM_FAILED:
      return { ...state, waitingForConfirmation: false };
    case ORDER_TESTS_SUCCESS:
      const deliveryError = action.deliveryError ?? null;
      const deliveryErrorMessage = action.deliveryErrorMessage ?? "";
      return {
        ...state,
        fetching: false,
        error: null,
        success: true,
        errorMessage: "",
        fieldFeedback: {},
        waitingForConfirmation: false,
        deliveryError,
        deliveryErrorMessage,
      };
    case ORDER_TESTS_FAILURE:
      const error = action.error;
      const errorMessage = action.errorMessage
        ? action.errorMessage
        : extractMessageFromApiError(error);
      const fieldFeedback = extractFieldFeedbackFromApiError(action.error);
      return {
        ...state,
        fetching: false,
        success: false,
        error,
        errorMessage,
        fieldFeedback,
        waitingForConfirmation: false,
      };
    case ORDER_TESTS_CLEAR_ERRORS: {
      const {
        error,
        cancelSubscriptionError,
        errorMessage,
        fieldFeedback,
        deliveryErrorMessage,
        deliveryError,
      } = initialStateOrderTests;
      return {
        ...state,
        error,
        cancelSubscriptionError,
        errorMessage,
        fieldFeedback,
        deliveryErrorMessage,
        deliveryError,
      };
    }
    default:
      return state;
  }
}
