import { useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import axios from "@/axios";

export function useUrlChecker(url) {
  const [urlCheckerDone, setUrlCheckerDone] = useState(false);
  const [urlCheckerMessage, setUrlCheckerMessage] = useState(null);
  const [urlCheckerOk, setUrlCheckerOk] = useState(null);
  const refUrlCheckController = useRef(null);

  const debouncedCheckUrl = useDebouncedCallback(() => {
    // create new request
    if (url) {
      const abortController = new AbortController();
      refUrlCheckController.current = abortController;
      axios({
        method: "post",
        url: process.env.REACT_APP_API_URL + "/checkurl",
        data: { url },
        signal: abortController.signal,
      })
        .then((response) => {
          setUrlCheckerDone(true);
          setUrlCheckerOk(response.data?.status === "success");
        })
        .catch((error) => {
          if (!abortController.signal.aborted) {
            setUrlCheckerDone(true);
            setUrlCheckerMessage(error.response?.data?.errors?.url);
            setUrlCheckerOk(false);
          }
        });
    }
  }, 500);

  useEffect(() => {
    // abort the current request so there is always only one request running (the latest)
    refUrlCheckController.current?.abort();

    // reset the url checker state
    setUrlCheckerDone(false);
    setUrlCheckerMessage(null);
    setUrlCheckerOk(null);

    // schedule url check
    debouncedCheckUrl();
  }, [url, debouncedCheckUrl]);

  return { urlCheckerDone, urlCheckerOk, urlCheckerMessage };
}
