import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import {
  VIDEO_SHARE_FAILURE,
  VIDEO_SHARE_REQUEST,
  VIDEO_SHARE_SUCCESS,
} from "@/reducers/videoShare";
import { API_URL } from ".";

export function* watchShareVideo() {
  yield takeLatest(VIDEO_SHARE_REQUEST, callShareVideoApi);
}

function* callShareVideoApi(action: any): SagaIterator {
  const { videoId, shared, unshare_clips } = action;
  try {
    // Returns updated video and new_video_count.
    const response = yield call(postShareVideo, videoId, shared, unshare_clips);

    yield put({
      type: VIDEO_SHARE_SUCCESS,
      data: {
        ...response.data,
        videoId,
        shared,
        unshare_clips,
      },
    });
  } catch (error) {
    yield put({
      type: VIDEO_SHARE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function postShareVideo(
  videoId: number,
  shared: boolean | undefined,
  unshare_clips: boolean | undefined,
) {
  const data: { shared?: boolean; unshare_clips?: boolean } = {};

  if (shared !== undefined) {
    data.shared = shared;
  }
  if (unshare_clips !== undefined) {
    data.unshare_clips = unshare_clips;
  }

  return axios({
    method: "post",
    url: API_URL + "/video/" + videoId + "/share",
    data: data,
  });
}
