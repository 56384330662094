import React from "react";
import { concatClassNames } from "@system42/core";
import PropTypes from "prop-types";
import TextareaAutosize from "react-autosize-textarea";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class TextArea extends React.Component {
  static defaultProps = {
    cols: 20,
    rows: 2,
    styles,
  };

  render() {
    const {
      autoFocus,
      autoSize,
      className,
      cols,
      error,
      fullWidth,
      id,
      onChange,
      placeholder,
      rows,
      styles,
      value,
      isDisabled,
    } = this.props;

    let classNameInput = concatClassNames(
      className,
      styles.root,
      error && styles.error,
      fullWidth && styles.fullWidth,
    );

    if (autoSize) {
      return (
        <TextareaAutosize
          {...extractContainerProps(this.props)}
          className={classNameInput}
          autoFocus={autoFocus}
          cols={cols}
          id={id}
          onChange={onChange}
          placeholder={placeholder}
          rows={rows}
          value={value}
          // XXX isDisabled is not ideal here because Button uses disabled
          disabled={isDisabled}
        />
      );
    } else {
      return (
        <textarea
          {...extractContainerProps(this.props)}
          className={classNameInput}
          cols={cols}
          id={id}
          onChange={onChange}
          autoFocus={autoFocus}
          placeholder={placeholder}
          rows={rows}
          value={value}
          // XXX isDisabled is not ideal here because Button uses disabled
          disabled={isDisabled}
        />
      );
    }
  }
}

TextArea.propTypes = {
  onChange: PropTypes.func,
};

export default TextArea;
