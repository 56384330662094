import React from "react";
import { concatClassNames as cn } from "@system42/core";

import { Button } from "@/design-system";

import styles from "./styles.module.css";

export function AccountDataBox(props) {
  const {
    className,
    children,
    title,
    buttonLabel,
    onClickButton,
    ...containerProps
  } = props;

  function handleClickButton(e) {
    onClickButton(e);
  }

  return (
    <section
      className={cn(className, styles.accountDataBox, "card")}
      {...containerProps}
    >
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <Button onClick={handleClickButton}>{buttonLabel}</Button>
      </div>
      <div className={styles.content}>{children}</div>
    </section>
  );
}
