import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import {
  parseVideoClipSharedApi,
  parseVideoClipSharedAuthenticatedApi,
} from "@/entities/video/clip";
import { createReduxApiError } from "@/helpers-ts";
import {
  SHARED_CLIP_FAILURE,
  SHARED_CLIP_RE_REQUEST,
  SHARED_CLIP_REQUEST,
  SHARED_CLIP_SUCCESS,
} from "@/reducers/sharedClip";
import { RootState } from "@/store";
import { API_URL } from ".";

export function* watchSharedClip() {
  yield all([
    takeLatest(SHARED_CLIP_REQUEST, callSharedClipApi),
    takeLatest(SHARED_CLIP_RE_REQUEST, sharedClipReRequest),
  ]);
}

function* sharedClipReRequest() {
  const sharedClip: RootState["sharedClip"] = yield select(
    (state) => state.sharedClip,
  );

  const { requestAction, isFetching, data } = sharedClip;

  const refreshHash = data?.refreshHash;

  if (requestAction && !isFetching) {
    const actionToDispatch = {
      ...requestAction,
      refreshHash,
      isKeepData: true,
    };
    // Dispatch the same action with the refreshHash and isKeepData set to true
    yield put(actionToDispatch);
  }
}

function* callSharedClipApi(action: any): SagaIterator {
  const { sharedHash, refreshHash } = action;
  try {
    const response = yield call(fetchSharedClip, sharedHash, refreshHash);

    let data: RootState["sharedClip"]["data"];
    if (typeof response.data.data === "undefined") {
      // The success action just returns the original data if the response
      // is empty (the API does this when refreshHash was used and nothing has changed)
      data = yield select((state) => state.sharedClip.data);
    } else {
      if (typeof response.data.data.id !== "undefined") {
        data = {
          ...parseVideoClipSharedAuthenticatedApi(response.data.data),
          refreshHash: response.data.meta.refresh_hash,
        };
      } else {
        data = {
          ...parseVideoClipSharedApi(response.data.data),
          refreshHash: response.data.meta.refresh_hash,
        };
      }
    }

    yield put({
      type: SHARED_CLIP_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: SHARED_CLIP_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function fetchSharedClip(shared_hash: string, refreshHash?: string | null) {
  return axios({
    method: "get",
    url: `${API_URL}/sharedclip/${shared_hash}${
      refreshHash ? "?refresh_hash=" + refreshHash : ""
    }`,
  });
}
