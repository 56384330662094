import { useMemo } from "react";

export function useTaskStepsWithResponse(video) {
  const { task, responses, videoDuration } = video || {};

  const isLegacyTask = !responses?.length;

  const taskStepsWithResponse = useMemo(
    function () {
      if (!task) {
        return [];
      }

      let videoEndMarkerInserted = false;
      const timeLineSteps = task.map((taskStep, index) => {
        const response = responses.find(
          (response) => response.teststep_id === taskStep.id,
        );
        const responseOfPreviousStep = responses.find((response) =>
          task[index - 1] ? response.teststep_id === task[index - 1].id : false,
        );
        const timestampCompleted = response?.timestamp_completed ?? 1000000; // 1000000 is my way to say: this was never completed
        const timestampCompletedPreviousStep =
          responseOfPreviousStep?.timestamp_completed ?? 0;
        let videoEndsAfterThisStep = false;
        if (
          timestampCompleted >= videoDuration &&
          !videoEndMarkerInserted &&
          !isLegacyTask
        ) {
          videoEndsAfterThisStep = true;
          videoEndMarkerInserted = true;
        }
        const timestampStart = timestampCompletedPreviousStep;
        return {
          ...taskStep,
          response: response?.response,
          timestampStart,
          timestampCompleted,
          videoEndsAfterThisStep,
        };
      });
      if (!videoEndMarkerInserted && timeLineSteps.length) {
        // If not inserted insert for last step
        timeLineSteps[timeLineSteps.length - 1].videoEndsAfterThisStep = true;
      }
      return timeLineSteps;
    },
    [task, responses, videoDuration, isLegacyTask],
  );

  return { taskStepsWithResponse, isLegacyTask };
}
