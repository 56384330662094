import { concatClassNames as cn } from "@system42/core";

import { AutoLink } from "@/components/AutoLink";
import { urlMarketingWebsite } from "@/helpers-ts";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconLogo } from "@/design-system/images/logo-userbrain-white.svg";

export function HeaderWithLogo({
  isLoggedIn,
  children,
  className,
}: {
  isLoggedIn: boolean;
  children?: React.ReactNode;
  className?: string;
}) {
  let href, to;
  if (isLoggedIn) {
    to = "/";
  } else {
    href = urlMarketingWebsite;
  }
  return (
    <div className={cn(styles.header, className)}>
      <AutoLink to={to} href={href}>
        <SvgIconLogo className={styles.logoSvg} />
      </AutoLink>
      {children}
    </div>
  );
}
