import React from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";

import { LogoFull } from "@/design-system";
import { extractMessageFromApiError } from "../../helpers";

import styles from "./styles.module.css";

export default function Loading(props) {
  const { error, errorMessage } = props;
  let errorMessageToDisplay = false;
  let errorMessageInfo = false;

  const location = useLocation();

  if (error) {
    errorMessageToDisplay = "Oops, something went wrong.";
    if (error.message) {
      errorMessageInfo = error.message;
    }
    if (error.response) {
      if (error.response.status === 410) {
        errorMessageToDisplay = extractMessageFromApiError(error);
        if (errorMessageToDisplay) {
          errorMessageInfo = false;
        } else {
          errorMessageToDisplay = "This resource is gone";
        }
      } else if (error.response.status === 403) {
        errorMessageToDisplay = "Not allowed to access this resource";
      }
    }
  }

  if (errorMessage) {
    errorMessageToDisplay = errorMessage;
  }

  return (
    <>
      <div className={styles.centercenter}>
        <div className={styles.root}>
          <div className={styles["logo-container"]}>
            <LogoFull />
          </div>
          {errorMessageToDisplay ? (
            <div className={styles.message}>
              {errorMessageToDisplay}
              {errorMessageInfo && (
                <>
                  <br />
                  <span className={styles.messageInfo}>{errorMessageInfo}</span>
                </>
              )}
              {location.pathname !== "/" && (
                <p>
                  <Link to={"/"}>Continue</Link>
                </p>
              )}
            </div>
          ) : (
            <>
              <Helmet>
                <title>Loading...</title>
              </Helmet>
              <p className={styles.message}>{props.children}...</p>
            </>
          )}
        </div>
      </div>
    </>
  );
}
