import React, { useCallback, useMemo, useState } from "react";

import { TaskResultsMultipleChoice } from "./TaskResultsMultipleChoice";
import { TaskResultsRatingScale } from "./TaskResultsRatingScale";
import { TaskResultsRedirect } from "./TaskResultsRedirect";
import { TaskResultsSingleChoice } from "./TaskResultsSingleChoice";
import { TaskResultsTask } from "./TaskResultsTask";
import { TaskResultsWrittenResponse } from "./TaskResultsWrittenResponse";

export const TaskResultContext = React.createContext();

const emptyArray = [];

export function TaskResults(props) {
  const {
    stepNumber,
    task,
    testLanguage,
    testId,
    testHasUnfinishedDeliveries,
  } = props;

  const taskId = task?.id;
  const taskType = task?.type;
  const taskText = task?.task ?? "";
  const responses = task?.responses ?? emptyArray;
  const options = task?.options ?? emptyArray;

  const showUpToDateStorageKey = `SHOW_UP_TO_DATE_ONLY_${taskId}`;
  const [showUpToDateOnly, setShowUpToDateOnly] = useState(
    () =>
      !!(
        JSON.parse(window.localStorage.getItem(showUpToDateStorageKey)) ?? false
      ),
  );

  const upToDateResponses = responses.filter(
    (response) => !response.differing_teststep,
  );
  const shownResponses = showUpToDateOnly ? upToDateResponses : responses;

  const handleChangeShowUpToDateOnly = useCallback(
    (e) => {
      setShowUpToDateOnly(e.target.checked);
      window.localStorage.setItem(
        showUpToDateStorageKey,
        JSON.stringify(e.target.checked),
      );
    },
    [showUpToDateStorageKey],
  );

  const averageTimeOnTask = useMemo(() => {
    let responsesWithTimeOnTask = 0;
    let totalTime = 0;
    let averageTimeOnTask = null;
    for (const response of shownResponses) {
      if (response.time_on_task != null) {
        responsesWithTimeOnTask++;
        totalTime += response.time_on_task;
      }
    }
    if (responsesWithTimeOnTask > 0) {
      averageTimeOnTask = Math.round(totalTime / responsesWithTimeOnTask);
    }
    return averageTimeOnTask;
  }, [shownResponses]);

  const averageRating = useMemo(() => {
    if (taskType === "rating_scale") {
      const total = shownResponses
        .map(({ response }) => response.rating)
        .reduce((a, b) => a + b, 0);
      const average = total / shownResponses.length;
      return Math.round(average * 10) / 10;
    } else {
      return null;
    }
  }, [shownResponses, taskType]);

  const taskResultContextValue = useMemo(
    () => ({
      testId,
      taskId,
      task: taskText,
      testHasUnfinishedDeliveries,
      options,
      shownResponses,
      countResponses: responses.length,
      countUpToDateResponses: upToDateResponses.length,
      stepNumber,
      averageTimeOnTask,
      averageRating,
      showUpToDateOnly,
      onChangeShowUpToDateOnly: handleChangeShowUpToDateOnly,
      testLanguage,
    }),
    [
      testId,
      taskId,
      taskText,
      testHasUnfinishedDeliveries,
      options,
      shownResponses,
      responses.length,
      upToDateResponses.length,
      stepNumber,
      averageTimeOnTask,
      averageRating,
      showUpToDateOnly,
      handleChangeShowUpToDateOnly,
      testLanguage,
    ],
  );

  let taskResults = null;

  switch (taskType) {
    case "task":
      taskResults = <TaskResultsTask />;
      break;
    case "single_choice":
      taskResults = <TaskResultsSingleChoice />;
      break;
    case "multiple_choice":
      taskResults = <TaskResultsMultipleChoice />;
      break;
    case "rating_scale":
      taskResults = <TaskResultsRatingScale />;
      break;
    case "redirect":
      taskResults = <TaskResultsRedirect />;
      break;
    case "written_response":
      taskResults = <TaskResultsWrittenResponse />;
      break;
    default:
      taskResults = <div>Task type not supported ({taskType}).</div>;
      break;
  }

  return (
    <TaskResultContext.Provider value={taskResultContextValue}>
      {taskResults}
    </TaskResultContext.Provider>
  );
}
