import { ReactNode, useEffect, useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";

import styles from "./styles.module.css";

export function ImageLoaderFallback({ className }: { className?: string }) {
  return (
    <FontAwesomeIcon
      className={cn(styles.fallbackIcon, className)}
      icon={regular("image-slash")}
    />
  );
}

/**
 * Image Loader with fallback component and loading state.
 * Always fills 100% of the parent container.
 */
export function ImageLoader({
  url,
  fallback,
  children,
  className,
  imageClassName,
}: {
  url: string;
  fallback?: ReactNode;
  children?: ReactNode;
  className?: string;
  imageClassName?: string;
}) {
  const [loadingState, setLoadingState] = useState<
    "success_network" | "success_cache" | "error" | "loading" | "cache"
  >("loading");

  useEffect(() => {
    var img = new Image();
    img.src = url;

    const isCached = img.complete;

    img.onload = () => {
      setLoadingState(isCached ? "success_cache" : "success_network");
    };

    img.onerror = () => {
      setLoadingState("error");
    };
  }, [url]);

  return (
    <div className={cn(styles.container, className)}>
      <div
        style={{ backgroundImage: `url(${url})` }}
        className={cn(
          styles.image,
          loadingState === "success_network" && styles.image_success_network,
          loadingState === "success_cache" && styles.image_success_cache,
          imageClassName,
        )}
      />
      {children && <div className={styles.childrenContainer}>{children}</div>}
      {fallback && (
        <div
          className={cn(
            styles.fallback,
            loadingState === "error" && styles.fallback_display,
          )}
        >
          {fallback}
        </div>
      )}
    </div>
  );
}
