import { TestInsightsApi } from "@/entities/test/insights";

import createGenericApiSlice from "./genericApiSlice";

type TestInsights = {
  testId: number;
  insights: TestInsightsApi;
  refreshHash: string;
};

const slice = createGenericApiSlice<TestInsights>("TEST_INSIGHTS", {
  storeRequestAction: true,
});

export const testInsightsReducer = slice.reducer;

export const TEST_INSIGHTS_REQUEST = slice.actionTypes.REQUEST;
export const TEST_INSIGHTS_SUCCESS = slice.actionTypes.SUCCESS;
export const TEST_INSIGHTS_FAILURE = slice.actionTypes.FAILURE;
export const TEST_INSIGHTS_SET = slice.actionTypes.SET;
export const TEST_INSIGHTS_RE_REQUEST = "TEST_INSIGHTS_RE_REQUEST";
