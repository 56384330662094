import React from "react";
import { concatClassNames } from "@system42/core";
import isFunction from "lodash/isFunction";
import uniqueId from "lodash/uniqueId";

import {
  addPropsToChildrenIfValueEq,
  extractContainerProps,
} from "../../helpers";
import RadioSelectItem from "../RadioSelectItem";

import styles from "./styles.module.css";

class RadioSelect extends React.Component {
  static defaultProps = { styles };

  constructor(props) {
    super(props);
    this.uniqueId = uniqueId("radioSelect");
    this.onChildChange = this.onChildChange.bind(this);
  }

  onChildChange(e) {
    if (isFunction(this.props.onChange)) {
      this.props.onChange(e);
    }
  }

  render() {
    const { children, className, fullWidth, styles, value } = this.props;

    const result = addPropsToChildrenIfValueEq(
      children,
      value,
      { checked: true },
      { onChange: this.onChildChange, checked: false },
    );
    const found = result.found;
    const childrenWithProps = result.children;

    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(
          className,
          styles.root,
          fullWidth && styles.fullWidth,
        )}
      >
        {childrenWithProps}
        {!found && value !== undefined && value !== null && (
          <RadioSelectItem value={value} checked>
            {value}
          </RadioSelectItem>
        )}
      </div>
    );
  }
}

export default RadioSelect;
