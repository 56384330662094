import React from "react";
import { concatClassNames } from "@system42/core";
import uniqueId from "lodash/uniqueId";

import {
  addPropsToChildrenIfValueEq,
  extractContainerProps,
  noop,
} from "../../helpers";
import ChoiceChip from "../ChoiceChip";

import styles from "./styles.module.css";

class ChoiceChips extends React.Component {
  static defaultProps = { styles, onChange: noop };

  constructor(props) {
    super(props);
    this.uniqueId = uniqueId("radioSelect");
  }

  onChildChange = (e) => {
    this.props.onChange({ target: { value: e.target.value } });
  };

  render() {
    const { children, className, fullWidth, styles, value } = this.props;

    const result = addPropsToChildrenIfValueEq(
      children,
      value,
      { selected: true },
      { onChange: this.onChildChange, selected: false },
    );

    const found = result.found;
    const childrenWithProps = result.children;

    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(
          className,
          styles.root,
          fullWidth && styles.fullWidth,
        )}
      >
        {childrenWithProps}
        {!found && (
          <ChoiceChip value={value} key={value} selected>
            {value}
          </ChoiceChip>
        )}
      </div>
    );
  }
}

export default ChoiceChips;
