import React from "react";

import { Modal2 } from "@/components/Modal";
import { noop } from "@/helpers";

import CheckoutModalContent from "./CheckoutModalContent";

export default function CheckoutModal({
  isActive,
  onClose,
  showContinueOrder,
  continueOrderLabel,
  onContinueOrder,
  onClickDone,
}) {
  return (
    <Modal2
      isActive={isActive}
      onClose={noop}
      maxWidth={"30rem"}
      content={
        <CheckoutModalContent
          onClose={onClose}
          showContinueOrder={showContinueOrder}
          continueOrderLabel={continueOrderLabel}
          onContinueOrder={onContinueOrder}
          onClickDone={onClickDone}
        />
      }
    />
  );
}
