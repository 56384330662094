import { useHotkeys } from "react-hotkeys-hook";

export default function useUserbrainPlayerHotkeys(
  playerApi,
  nextJumpPoint,
  previousJumpPoint,
) {
  // PLAY_PAUSE
  useHotkeys(
    "space",
    (e) => {
      if (playerApi) {
        const targetTag = e.target.tagName.toUpperCase();
        if (targetTag === "BODY" || targetTag === "VIDEO") {
          // This hotkey is only active when video or body is the target
          // otherwise it would also be triggered when the play/pause button
          // or other buttons are triggered by space
          if (playerApi.playing === false) {
            playerApi.play();
          } else {
            playerApi.pause();
          }
        }
      }
    },
    [playerApi],
  );

  // FORWARD
  useHotkeys(
    "ArrowRight",
    (e) => {
      e.stopImmediatePropagation(); // Otherwise react-slider will also be triggered
      if (playerApi) {
        playerApi.currentTime(playerApi.currentTime() + 5);
      }
    },
    [playerApi],
  );

  // BACK
  useHotkeys(
    "ArrowLeft",
    (e) => {
      e.stopImmediatePropagation(); // Otherwise react-slider will also be triggered
      if (playerApi) {
        playerApi.currentTime(playerApi.currentTime() - 5);
      }
    },
    [playerApi],
  );

  // END OF TASK
  useHotkeys(
    "shift+ArrowRight",
    () => {
      if (playerApi) {
        playerApi.currentTime(nextJumpPoint);
      }
    },
    [playerApi, nextJumpPoint],
  );

  // START OF TASK
  useHotkeys(
    "shift+ArrowLeft",
    () => {
      if (playerApi) {
        playerApi.currentTime(previousJumpPoint);
      }
    },
    [playerApi, previousJumpPoint],
  );

  // Toggle Fullscreen
  useHotkeys(
    "f",
    () => {
      if (playerApi) {
        if (!playerApi.isFullscreen()) {
          playerApi.requestFullscreen();
        } else {
          playerApi.exitFullscreen();
        }
      }
    },
    [playerApi],
  );

  // FORWARD: "ArrowRight",
  // BACK: "ArrowLeft",
  // NEXT_TASK: "shift+ArrowLeft",
  // PREVIOUS_TASK: "shift+ArrowRight"
}
