import { useEffect, useState } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import capitalize from "lodash/capitalize";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import ConfirmDeleteModal from "@/components/ConfirmDeleteModal";
import { useModal2Controller } from "@/components/Modal/hooks";
import ScreenerResponseModal from "@/components/ScreenerResponseModal";
import ReportAProblemModal from "@/containers/ReportAProblemModal";
import { ButtonLink } from "@/design-system";
import { getAdditionalTesterInfos, getDateName, usePrevious } from "@/helpers";
import { useFeatures } from "@/hooks/useFeatures";
import { selectIsUserOwnerOrAdmin } from "@/selectors/user";
import VideoRating from "../components/VideoRating";
import { RootState, Video } from "../types";
import { createDeviceDetailString } from "../utils/createDeviceDetailString";

import styles from "../styles.module.css";

export interface DetailsProps {
  video: Video;
  onBack: () => void;
}

/**
 *
 * Details
 */
export function Details({ video, onBack }: DetailsProps) {
  const features = useFeatures();
  const dispatch = useDispatch();
  const [problemReportSent, setProblemReportSent] = useState(false);
  const problemReported = video.problem_reported === true || problemReportSent;

  const isTranscriptIncludedInPlan = features?.transcripts;

  // XXX: This might not be needed anymore because tester should always be present
  // the shared video is now fetched with another reducer. This issue was I think because
  // the shared video was fetched with the video reducer and the tester was not present
  const additionalTesterInfos =
    video.tester && getAdditionalTesterInfos(video.tester);

  const deleteFetching = useSelector(
    (state: RootState) => state.video.deleteFetching,
  );
  const deleteSuccess = useSelector(
    (state: RootState) => state.video.deleteSuccess,
  );
  const prevDeleteFetching = usePrevious(deleteFetching);

  const isOwnerOrAdmin = useSelector(selectIsUserOwnerOrAdmin);

  const problemFetching = useSelector(
    (state: RootState) => state.video.problemFetching,
  );

  const prevProblemFetching = usePrevious(problemFetching);

  const deleteFieldFeedback = useSelector(
    (state: RootState) => state.video.deleteFieldFeedback,
  );

  const problemSuccess = useSelector(
    (state: RootState) => state.video.problemSuccess,
  );

  const rateFetching = useSelector(
    (state: RootState) => state.video.fetchingRate,
  );

  const baseCreateDetailStringProps = {
    videoDeviceDetails: video.device?.details,
    videoDeviceOs: video.device?.os,
  };

  const deviceDetailString = createDeviceDetailString(
    baseCreateDetailStringProps,
  );
  const fullDeviceDetailString = createDeviceDetailString({
    ...baseCreateDetailStringProps,
    isFull: true,
  });

  /*

    Handlers

  */
  function handleClickRateVideo(rating: number) {
    if (video) {
      dispatch({ type: "VIDEOS_RATE_REQUEST", id: video?.id, rating });
    }
  }

  function handleClickDeleteVideo() {
    openDeleteVideoModal();
  }

  function handleClickShowScreenerResponse() {
    openScreenerResponseModal();
  }

  function handleClickReportAProblem() {
    openReportAProblemModal();
  }

  function handleDeleteVideo(confirmation: any) {
    dispatch({ type: "VIDEO_DELETE_REQUEST", id: video?.id, confirmation });
  }

  /*

    Modals

  */

  const {
    isOpen: isReportAProblemModalOpen,
    open: openReportAProblemModal,
    close: closeReportAProblemModal,
  } = useModal2Controller();

  const {
    isOpen: isDeleteVideoModalOpen,
    open: openDeleteVideoModal,
    close: closeDeleteVideoModal,
  } = useModal2Controller();

  const {
    isOpen: isScreenerResponseModalOpen,
    open: openScreenerResponseModal,
    close: closeScreenerResponseModal,
  } = useModal2Controller();

  /*

    Effects

  */
  useEffect(() => {
    if (
      deleteFetching === false &&
      prevDeleteFetching === true &&
      deleteSuccess === true
    ) {
      onBack();
    }
  }, [deleteFetching, prevDeleteFetching, deleteSuccess, onBack]);

  useEffect(() => {
    if (
      problemFetching === false &&
      prevProblemFetching === true &&
      problemSuccess === true
    ) {
      setProblemReportSent(true);
    }
  }, [problemFetching, prevProblemFetching, problemSuccess]);

  /*

    ReactNode

  */
  return (
    <>
      <div className={styles.detailsSection}>
        <Link
          className={`${styles.detailsTitleLink}`}
          to={`/test/${video.test_id}`}
          title={video.title}
        >
          {video.title}
        </Link>
        <div className={`${styles.detailsUrl}`}>{video.test_url}</div>
      </div>

      <div className={styles.detailsSection}>
        <div className={styles.iconList}>
          <div className={styles.iconList_item}>
            <div className={styles.iconList_icon}>
              <FontAwesomeIcon icon={solid("user")} fixedWidth />
            </div>
            <div className={styles.iconList_content}>
              {video.tester.name}{" "}
              <span className={styles.detailsTesterCountry}>
                {additionalTesterInfos && `(${additionalTesterInfos})`}
              </span>
              {video.tester.email ? (
                <>
                  <br />
                  <a
                    href={`mailto:${video.tester.email}`}
                    className={styles.detailsEmail}
                  >
                    {video.tester.email}
                  </a>
                </>
              ) : (
                false
              )}
              {video.tester.type !== "invitation" && (
                <div>
                  <VideoRating
                    rating={rateFetching ? rateFetching : video.rating}
                    className={styles.detailsRating}
                    onClick={handleClickRateVideo}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.detailsSection}>
        <div className={styles.iconList}>
          {(deviceDetailString || video.device?.type) && (
            <div className={styles.iconList_item}>
              <div className={styles.iconList_icon}>
                {video.device?.type === "mobile" && (
                  <FontAwesomeIcon icon={solid("mobile-alt")} fixedWidth />
                )}
                {video.device?.type === "desktop" && (
                  <FontAwesomeIcon icon={solid("desktop")} fixedWidth />
                )}
                {video.device?.type === "tablet" && (
                  <FontAwesomeIcon icon={solid("tablet-alt")} fixedWidth />
                )}
              </div>
              <div className={styles.iconList_content}>
                <span>{capitalize(video.device?.type)}</span>
                {deviceDetailString && (
                  <>
                    {" "}
                    <span
                      className={styles.detailsDeviceDetails}
                      title={fullDeviceDetailString}
                    >
                      ({deviceDetailString})
                    </span>
                  </>
                )}
              </div>
            </div>
          )}

          <div className={styles.iconList_item}>
            <div className={styles.iconList_icon}>
              <FontAwesomeIcon icon={regular("calendar-alt")} fixedWidth />
            </div>
            <div className={styles.iconList_content}>
              {getDateName(video.created_at)}{" "}
              <span className={styles.detailsTime}>
                @ {dayjs(video.created_at).format("h:mma")}
              </span>
            </div>
          </div>

          {video.screener && (
            <div className={styles.iconList_item}>
              <div className={styles.iconList_icon}>
                <FontAwesomeIcon icon={regular("check-square")} fixedWidth />
              </div>
              <div className={styles.iconList_content}>
                <ButtonLink
                  className={styles.showScreenerResponseButton}
                  onClick={handleClickShowScreenerResponse}
                >
                  View screener response
                </ButtonLink>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={styles.detailsSection}>
        <div className={styles.iconList}>
          <div className={styles.iconList_item}>
            <div className={styles.iconList_icon}>
              {problemReported ? (
                <FontAwesomeIcon icon={solid("comment-alt-check")} fixedWidth />
              ) : (
                <FontAwesomeIcon
                  icon={solid("exclamation-triangle")}
                  fixedWidth
                />
              )}
            </div>
            <div className={styles.iconList_content}>
              {problemReported ? (
                <span>Problem reported, we will get back to you shortly.</span>
              ) : (
                <ButtonLink
                  onClick={handleClickReportAProblem}
                  className={styles.reportProblemButton}
                >
                  Report a problem
                </ButtonLink>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.detailsSection}>
        <div className={styles.iconList}>
          <div className={styles.iconList_item}>
            <div className={styles.iconList_icon}>
              <FontAwesomeIcon icon={solid("arrow-to-bottom")} fixedWidth />
            </div>
            <div className={styles.iconList_content}>
              <a href={video.download_video_url}>Download video</a>
            </div>
          </div>
          {isTranscriptIncludedInPlan && video.transcript && (
            <div className={styles.iconList_item}>
              <div className={styles.iconList_icon}>
                <FontAwesomeIcon icon={solid("arrow-to-bottom")} fixedWidth />
              </div>
              <div className={styles.iconList_content}>
                <a href={video.download_transcript_url}>
                  Download transcript (TXT)
                </a>
              </div>
            </div>
          )}
          {video.notes && video.notes.length > 0 && (
            <div className={styles.iconList_item}>
              <div className={styles.iconList_icon}>
                <FontAwesomeIcon icon={solid("arrow-to-bottom")} fixedWidth />
              </div>
              <div className={styles.iconList_content}>
                <a href={video.download_notes_pdf_url}>Download notes (PDF)</a>
              </div>
            </div>
          )}
          {video.notes && video.notes.length > 0 && (
            <div className={styles.iconList_item}>
              <div className={styles.iconList_icon}>
                <FontAwesomeIcon icon={solid("arrow-to-bottom")} fixedWidth />
              </div>
              <div className={styles.iconList_content}>
                <a href={video.download_notes_csv_url}>Download notes (CSV)</a>
              </div>
            </div>
          )}
        </div>
      </div>

      {isOwnerOrAdmin && (
        <div className={styles.detailsSection}>
          <div className={styles.iconList}>
            <div className={styles.iconList_item}>
              <div className={styles.iconList_icon}>
                <FontAwesomeIcon
                  icon={solid("trash")}
                  fixedWidth
                  className={styles.deleteVideoIcon}
                />
              </div>
              <div className={styles.iconList_content}>
                <ButtonLink
                  onClick={handleClickDeleteVideo}
                  className={styles.deleteVideoButton}
                >
                  Delete video
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
      )}

      <ScreenerResponseModal
        isActive={isScreenerResponseModalOpen}
        onClose={closeScreenerResponseModal}
        screener={video?.screener}
      />

      <ReportAProblemModal
        isActive={isReportAProblemModalOpen}
        onClose={closeReportAProblemModal}
        videoId={video?.id}
      />

      <ConfirmDeleteModal
        title="Are you sure you want to delete this video?"
        buttonLabel="Delete video"
        isActive={isDeleteVideoModalOpen}
        onClose={closeDeleteVideoModal}
        onSubmit={handleDeleteVideo}
        isFetching={deleteFetching}
        errorMessage={deleteFieldFeedback.confirmation || false}
      />
    </>
  );
}
