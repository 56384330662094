import { useCallback, useEffect, useState } from "react";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import startCase from "lodash/startCase";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useRevalidator,
  useSearchParams,
} from "react-router-dom";

import BillingInformationForm from "@/components/BillingInformationForm";
import ButtonGroup from "@/components/ButtonGroup";
import ModalHeader from "@/components/ModalHeader";
import Spinner from "@/components/Spinner";
import { CheckoutModalRedesign } from "@/containers/CheckoutModalRedesign";
import { Button, ButtonPrimary, ErrorMessage } from "@/design-system";
import {
  BILLING_CYCLE_ANNUAL,
  createCheckoutTexts,
  extractMessageFromApiError,
  formatPrice as formatPriceWithCurrency,
  usePrevious,
} from "@/helpers";
import { paymentCardFromBilling } from "@/helpers-ts";
import { usePricing } from "@/hooks/usePricing";
import { useSubscription } from "@/hooks/useSubscription";

import styles from "./styles.module.css";

const stepBillingInformation = 1;
const stepCreditCard = 2;
const stepThankYou = 3;

export default function CheckoutModalContent(props) {
  const { onClose, showContinueOrder, continueOrderLabel, onContinueOrder } =
    props;

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const setSearchParams = useSearchParams()[1];
  const location = useLocation();
  const revalidator = useRevalidator();

  const pathname = location.pathname;

  const [step, setStep] = useState(stepBillingInformation);
  const prevStep = usePrevious(step);
  const [billingInformation, setBillingInformation] = useState(null);
  const [payWithNewCard, setPayWithNewCard] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  // Thank you texts (This must be stored in state because it is displayed when the currentSubscription is already updated)
  const [thankYouHeading, setThankYouHeading] = useState("");
  const [thankYouText, setThankYouText] = useState("");
  const [thankYouSubline, setThankYouSubline] = useState("");
  const [skipLastStep, setSkipLastStep] = useState(false);

  // Card payment
  const [handleCardPaymentErrorMessage, setHandleCardPaymentErrorMessage] =
    useState(null);

  // Redux
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.user.user?.currency);
  const hasUserInvoices = useSelector((state) => state.user.user?.has_invoices);
  const pricing = usePricing();
  const netPricePerCredit = pricing?.payg / 100;

  const creditCount = useSelector((state) => state.checkout.creditCount);
  const checkoutPlan = useSelector((state) => state.checkout.plan);
  const isSubscription = checkoutPlan !== "payg";
  const redirectToBillingAfterCheckout = useSelector(
    (state) => state.checkout.redirectToBillingAfterCheckout,
  );
  const billingCycle = useSelector((state) => state.checkout.billingCycle);
  const deliveryRequest = useSelector(
    (state) => state.checkout.deliveryRequest,
  );
  // const billingInformationUpdateError = useSelector(state => state.billingInformation.updateError);
  const billingInformationUpdateErrorMessage = useSelector(
    (state) => state.billingInformation.updateErrorMessage,
  );
  const updateFieldFeedbackBillingInformation = useSelector(
    (state) => state.billingInformation.updateFieldFeedback,
  );
  const updateFetching = useSelector(
    (state) => state.billingInformation.updateFetching,
  );
  const availableCountries = useSelector(
    (state) => state.billingInformation.availableCountries,
  );
  const loadedBillingInformation = useSelector(
    (state) => state.billingInformation.billingInformation,
  );
  const loadedBillingInformationError = useSelector(
    (state) => state.billingInformation.error,
  );
  const loadedBillingInformationComplete = useSelector(
    (state) => state.billingInformation.complete,
  );
  const prevLoadedBillingInformationComplete = usePrevious(
    loadedBillingInformationComplete,
  );
  const vatCountries = useSelector(
    (state) => state.billingInformation.vatCountries,
  );
  const taxPercent = useSelector((state) => state.user.user?.tax_percent || 0);
  const hasSavedCard = useSelector(
    (state) => !!state.user.user?.billing?.card_last_four,
  );
  const billing = useSelector((state) => state.user.user?.billing);
  const subscription_owner = useSelector(
    (state) => state.user.user?.subscription_owner,
  );
  const isAdmin = useSelector((state) => state.user.user?.role === "admin");
  const isOwner = useSelector(
    (state) =>
      state.user.user?.role === "owner" ||
      state.user.user?.role === "superadmin",
  );

  const currentSubscription = useSubscription();

  const orderTestsFetching = useSelector((state) => state.orderTests.fetching);
  const prevOrderTestsFetching = usePrevious(orderTestsFetching);
  const orderTestsSuccess = useSelector((state) => state.orderTests.success);
  const orderTestsErrorMessage = useSelector(
    (state) => state.orderTests.errorMessage,
  );
  const orderTestsFieldFeedback = useSelector(
    (state) => state.orderTests.fieldFeedback,
  );

  const vat = (price) => price * (taxPercent / 100);

  const creditsPrice = netPricePerCredit * creditCount;
  const creditsVat = vat(creditsPrice);
  const monthlySubscriptionPrice = (pricing?.[checkoutPlan] || 0) / 100;
  const monthlySubscriptionVat = vat(monthlySubscriptionPrice);
  const annualSubscriptionPrice =
    (pricing?.[checkoutPlan + "_annual"] || 0) / 100;
  const annualSubscriptionVat = vat(annualSubscriptionPrice);

  const netPriceCharged = (() => {
    if (checkoutPlan === "payg") {
      return creditsPrice;
    } else {
      return billingCycle === BILLING_CYCLE_ANNUAL
        ? annualSubscriptionPrice
        : monthlySubscriptionPrice;
    }
  })();
  const vatCharged = vat(netPriceCharged);

  const netPriceChargedNext = netPriceCharged;
  const vatChargedNext = vat(netPriceChargedNext);

  const useSavedCard = hasSavedCard && !payWithNewCard;

  const {
    modalHeadingStepCreditCard,
    confirmButtonLabel,
    additionalInfoCharge,
    isContactSupportForChanges,
    thankYouHeading: thankYouHeadingWithCurrentData,
    thankYouText: thankYouTextWithCurrentData,
    thankYouSubline: thankYouSublineWithCurrentData,
    skipLastStep: skipLastStepWithCurrentData,
    creditsWordingPluralizer,
  } = createCheckoutTexts({
    formatPrice,

    vatCharged,
    netPriceCharged,

    vatChargedNext,
    netPriceChargedNext,

    creditCount,
    checkoutPlan,
    billingCycle,
    currentSubscription,
    deliveryRequest,
  });

  // navigate to billing if step changes to 3 and redirectToBillingAfterCheckout is true
  useEffect(() => {
    if (
      prevStep !== stepThankYou &&
      step === stepThankYou &&
      redirectToBillingAfterCheckout
    ) {
      navigate("/account/billing");
    }
  }, [step, redirectToBillingAfterCheckout, navigate, prevStep]);

  useEffect(() => {
    dispatch({ type: "BILLING_INFORMATION_REQUEST" });
  }, [dispatch]);

  useEffect(() => {
    setBillingInformation(loadedBillingInformation);
    if (
      loadedBillingInformationComplete &&
      !prevLoadedBillingInformationComplete
    ) {
      setStep(stepCreditCard);
    }
  }, [
    loadedBillingInformation,
    loadedBillingInformationComplete,
    prevLoadedBillingInformationComplete,
  ]);

  // Show step success if order was success
  useEffect(() => {
    if (
      orderTestsSuccess === true &&
      prevOrderTestsFetching === true &&
      orderTestsFetching === false
    ) {
      // Fetching did stop and success is true
      if (deliveryRequest) {
        const testId = deliveryRequest.testId;

        // ! Gleicher Code wie im OrderTestsModal !

        if (pathname.match(new RegExp(`^/test/${testId}(/.*)?$`, "i"))) {
          // If we are on any of the test detail views/tabs reload the test
          // this is needed because test loader does not revalidate the test by default
          dispatch({ type: "TEST_RE_REQUEST" });
        }

        if (pathname.match(new RegExp(`/test/${testId}/testers/?$`, "i"))) {
          // If we are on the correct testers page jump to the unfiltered first page
          setSearchParams({}); // will cause loader to refetch
        } else {
          navigate(`/test/${testId}/testers`);
        }
      } else {
        // Needed to reload insights or whatever after checkout a plan
        revalidator.revalidate();
      }
      if (skipLastStep === true) {
        onClose();
      } else {
        setStep(stepThankYou);
      }
    }
  }, [
    orderTestsFetching,
    deliveryRequest,
    orderTestsSuccess,
    prevOrderTestsFetching,
    navigate,
    location,
    dispatch,
    pathname,
    onClose,
    skipLastStep,
    setSearchParams,
    revalidator,
  ]);

  // Send order
  // This is used in useEffect thus the use of useCallback to not be a new function on every render
  const commitOrder = useCallback(
    (paymentMethodId) => {
      dispatch({
        type: "ORDER_TESTS_REQUEST",
        quantity: creditCount,
        plan: checkoutPlan,
        billingCycle,
        netPriceCharged,
        currency,
        deliveryRequest,
        paymentMethodId,
        isTermsAccepted,
      });
    },
    [
      creditCount,
      checkoutPlan,
      billingCycle,
      netPriceCharged,
      currency,
      deliveryRequest,
      isTermsAccepted,
      dispatch,
    ],
  );

  // Setup new card
  const paymentSetupClientSecretFetching = useSelector(
    (state) => state.payment.setupClientSecretFetching,
  );
  const prevPaymentCardSetupClientSecretFetching = usePrevious(
    paymentSetupClientSecretFetching,
  );
  const paymentSetupClientSecretError = useSelector(
    (state) => state.payment.setupClientSecretError,
  );
  const paymentSetupClientSecret = useSelector(
    (state) => state.payment.setupClientSecret,
  );
  const [handleCardSetupFetching, setHandleCardSetupFetching] = useState(false);
  const [handleCardSetupErrorMessage, setHandleCardSetupErrorMessage] =
    useState(null);

  useEffect(() => {
    if (
      prevPaymentCardSetupClientSecretFetching === true &&
      paymentSetupClientSecretFetching === false &&
      stripe !== null
    ) {
      // fetching true -> false
      setHandleCardSetupFetching(true);
      stripe
        .confirmCardSetup(paymentSetupClientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        })
        .then((result) => {
          setHandleCardSetupFetching(false);
          if (result.error) {
            setHandleCardSetupErrorMessage(result.error?.message);
          } else {
            commitOrder(result.setupIntent?.payment_method);
          }
        });
    }
  }, [
    paymentSetupClientSecret,
    paymentSetupClientSecretFetching,
    prevPaymentCardSetupClientSecretFetching,
    stripe,
    elements,
    commitOrder,
  ]);

  // Confirm Order Payment (eg. Verified by Visa)
  const orderTestsWaitingForConfirmation = useSelector(
    (state) => state.orderTests.waitingForConfirmation,
  );
  const orderTestsClientSecret = useSelector(
    (state) => state.orderTests.clientSecret,
  );
  useEffect(() => {
    if (orderTestsWaitingForConfirmation && stripe !== null) {
      stripe.confirmCardPayment(orderTestsClientSecret).then((result) => {
        if (result.error) {
          setHandleCardPaymentErrorMessage(result.error?.message);
          dispatch({ type: "ORDER_TESTS_CONFIRM_FAILED" });
        } else {
          dispatch({
            type: "ORDER_TESTS_CONFIRM",
            paymentIntentId: result.paymentIntent?.id,
            deliveryRequest,
          });
        }
      });
    }
  }, [
    orderTestsWaitingForConfirmation,
    orderTestsClientSecret,
    deliveryRequest,
    dispatch,
    stripe,
  ]);

  const setupFetching =
    paymentSetupClientSecretFetching || handleCardSetupFetching;
  const processingPayment =
    setupFetching || orderTestsFetching || orderTestsWaitingForConfirmation;

  function formatPrice(price) {
    return formatPriceWithCurrency(price, currency);
  }

  function handleChangeBillingInformation(billingInformation) {
    setBillingInformation(billingInformation);
  }

  function handleClickEditPaymentCard() {
    setPayWithNewCard(true);
  }

  function handleClickContinueToCreditCard() {
    const {
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    } = billingInformation;
    dispatch({
      type: "BILLING_INFORMATION_UPDATE_REQUEST",
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    });
  }

  function handleChangeBillingCycle(billingCycle) {
    dispatch({
      type: "CHECKOUT_SET_BILLING_CYCLE",
      billingCycle,
    });
  }

  function handleClickConfirmPurchase() {
    // Set the thank you texts in case it works
    setThankYouHeading(thankYouHeadingWithCurrentData);
    setThankYouText(thankYouTextWithCurrentData);
    setThankYouSubline(thankYouSublineWithCurrentData);
    setSkipLastStep(skipLastStepWithCurrentData);

    if (useSavedCard) {
      commitOrder();
    } else {
      dispatch({ type: "PAYMENT_SETUP_INTENT_REQUEST" });
    }
  }

  // Show spinner if not everything is ready
  if (
    (!billingInformation && !loadedBillingInformationError) ||
    !availableCountries
  ) {
    return <Spinner />;
  }

  if (step === stepBillingInformation) {
    if (isAdmin) {
      // Only the owner can add payment details
      return (
        <>
          <ModalHeader heading={"Billing address incomplete"} />
          <div className={styles.modalContent}>
            <p>The billing address for this account is not complete.</p>
            <p>Please contact the account owner to proceed.</p>
            <p>Account owner: {subscription_owner}</p>
          </div>
          <div className={styles.footer}>
            <ButtonGroup align={"right"}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </div>
        </>
      );
    }

    if (loadedBillingInformationError) {
      return (
        <>
          <ModalHeader heading={"Payment"} />
          <div className={styles.modalContent}>
            {loadedBillingInformationError && (
              <ErrorMessage
                message={extractMessageFromApiError(
                  loadedBillingInformationError,
                )}
              />
            )}
          </div>
          <div className={styles.footer}>
            <ButtonGroup align={"right"}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </div>
        </>
      );
    }

    const loadedBillingInformationErrorMessage = extractMessageFromApiError(
      loadedBillingInformationError,
    );

    return (
      <>
        <ModalHeader heading={"Billing Address"} />
        <div className={styles.modalContent}>
          <BillingInformationForm
            autoFocus
            fullWidth
            errorMessage={
              billingInformationUpdateErrorMessage ||
              loadedBillingInformationErrorMessage
            }
            fieldValues={billingInformation}
            fieldFeedback={updateFieldFeedbackBillingInformation}
            availableCountries={availableCountries}
            vatCountries={vatCountries}
            onChange={handleChangeBillingInformation}
          />
        </div>
        <div className={styles.footer}>
          <ButtonGroup align={"right"}>
            <ButtonPrimary
              onClick={handleClickContinueToCreditCard}
              disabled={updateFetching}
            >
              Continue to payment card
            </ButtonPrimary>
            <Button onClick={onClose}>Cancel</Button>
          </ButtonGroup>
        </div>
      </>
    );
  } else if (step === stepCreditCard) {
    if (!useSavedCard && !isOwner) {
      // Only the owner can add payment details
      return (
        <>
          <ModalHeader heading={"Missing payment card details"} />
          <div className={styles.modalContent}>
            <p>No payment card is stored for this account.</p>
            <p>Please contact the account owner to proceed.</p>
            <p>Account owner: {subscription_owner}</p>
          </div>
          <div className={styles.footer}>
            <ButtonGroup align={"right"}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </div>
        </>
      );
    }

    if (isContactSupportForChanges) {
      // This is, under normal circumstances, not accessible via the interface
      return (
        <>
          <ModalHeader
            heading={"Contact support"}
            additionalInfoText={
              <span className={styles.additionalInfoCharge}>
                Please{" "}
                <a href="mailto:support@userbrain.com">contact support</a> if
                you want to cancel or change your subscription.
              </span>
            }
          />
          <div className={styles.footer}>
            <ButtonGroup align={"right"}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </div>
        </>
      );
    }

    const paymentCardCheckout = paymentCardFromBilling(billing);

    const errors = [];

    if (paymentSetupClientSecretError) {
      errors.push(paymentSetupClientSecretError);
    }

    if (handleCardSetupErrorMessage) {
      errors.push(handleCardSetupErrorMessage);
    }

    if (handleCardPaymentErrorMessage) {
      errors.push(handleCardPaymentErrorMessage);
    }

    if (orderTestsErrorMessage) {
      errors.push(orderTestsErrorMessage);
    }

    if (orderTestsFieldFeedback.test_delivery) {
      errors.push(orderTestsFieldFeedback.test_delivery);
    }

    const isDisplayErrorMessage = errors.length > 0;

    return (
      <CheckoutModalRedesign
        currency={currency}
        paymentType={
          isSubscription
            ? !!currentSubscription
              ? "existingSubscription"
              : "newSubscription"
            : "credits"
        }
        paymentDescription={additionalInfoCharge}
        // CREDITS
        credits={{
          amount: creditCount,
          price: creditsPrice,
          vat: creditsVat,
        }}
        creditsWordingPluralizer={creditsWordingPluralizer}
        // SUBSCRIPTION
        subscriptionName={startCase(checkoutPlan + " plan")}
        activeSubscriptionCycle={billingCycle}
        monthlySubscription={{
          price: monthlySubscriptionPrice,
          vat: monthlySubscriptionVat,
        }}
        annualSubscription={{
          price: annualSubscriptionPrice,
          vat: annualSubscriptionVat,
        }}
        onClickSubscriptionCycle={handleChangeBillingCycle}
        // CARD
        card={useSavedCard ? paymentCardCheckout : null}
        isChangeCardAllowed={isOwner}
        onClickChangeCard={handleClickEditPaymentCard}
        // LEGAL
        onClickLegalAgree={() => setIsTermsAccepted(!isTermsAccepted)}
        isLegalAgree={isTermsAccepted}
        isLegalAgreementRequired={!hasUserInvoices}
        isDisplayLegalError={Boolean(orderTestsFieldFeedback.terms_accepted)} // XXX: Maybe this should be the text returned from the API
        // OTHER
        title={modalHeadingStepCreditCard}
        isConfirmButtonDisabled={processingPayment} // XXX: Maybe the buttons should be disabled when isLoading?
        isLoading={processingPayment}
        confirmButtonLabel={confirmButtonLabel}
        onClickConfirm={handleClickConfirmPurchase}
        onClickClose={onClose}
        errorMessage={isDisplayErrorMessage ? errors.join(" ") : null}
      />
    );
  } else if (step === stepThankYou) {
    function handleClickDone() {
      onClose();
    }

    return (
      <>
        <ModalHeader heading={thankYouHeading} />
        <div className={styles.modalContent}>
          {thankYouText && <p>{thankYouText}</p>}
          {thankYouSubline && (
            <p className={styles.thankYouSubline}>{thankYouSubline}</p>
          )}
        </div>
        <div className={styles.footerThankYou}>
          <ButtonGroup align={"right"}>
            {showContinueOrder ? (
              <ButtonPrimary onClick={onContinueOrder}>
                {continueOrderLabel}
              </ButtonPrimary>
            ) : (
              <ButtonPrimary onClick={handleClickDone}>Done</ButtonPrimary>
            )}
          </ButtonGroup>
        </div>
      </>
    );
  }
}
