import { InsightsSectionName } from "..";

import { ReactComponent as SvgSectionIconConfusion } from "./confusion.svg";
import { ReactComponent as SvgSectionIconHead } from "./head.svg";
import { ReactComponent as SvgSectionIconLightBulp } from "./light-bulp.svg";
import { ReactComponent as SvgSectionIconNegative } from "./negative.svg";
import { ReactComponent as SvgSectionIconPositive } from "./positive.svg";
import { ReactComponent as SvgSectionIconWarn } from "./warn.svg";

export { ReactComponent as SvgIconCaretDown } from "./icon-caret-down.svg";
export { ReactComponent as SvgIconClip } from "./icon-clip.svg";
export { ReactComponent as SvgIconNote } from "./icon-note.svg";

export function getSectionIcon(tag: InsightsSectionName) {
  switch (tag) {
    case "positive_feedback":
      return SvgSectionIconPositive;
    case "key_takeaway":
      return SvgSectionIconHead;
    case "usability_issue":
      return SvgSectionIconWarn;
    case "user_confusion":
      return SvgSectionIconConfusion;
    case "unexpected_interaction":
      return SvgSectionIconWarn;
    case "negative_feedback":
      return SvgSectionIconNegative;
    case "user_behavior":
      return SvgSectionIconHead;
    case "suggestion":
      return SvgSectionIconLightBulp;
    default:
      return SvgSectionIconWarn;
  }
}
