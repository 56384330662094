import React from "react";
import { concatClassNames as cn } from "@system42/core";

import styles from "./styles.module.css";

export function BarGraphRatingScale(props) {
  const { className, bars, labelStart, labelEnd, ...containerProps } = props;

  const max = bars.reduce((acc, cur) => (cur.value > acc ? cur.value : acc), 0);

  return (
    <div
      className={cn(className, styles.barGraphRatingScale)}
      {...containerProps}
    >
      <div className={styles.labels}>
        <div className={styles.labelStart}>{labelStart}</div>
        <div className={styles.labelEnd}>{labelEnd}</div>
      </div>
      <div className={styles.bars}>
        {bars.map((bar, index) => (
          <div className={styles.barCol} key={index}>
            <div className={styles.bar}>
              {bar.value > 0 && (
                <div
                  className={styles.barBar}
                  style={{ top: `${100 - (100 / max) * bar.value}%` }}
                />
              )}
            </div>
            <div className={styles.barLabel}>{bar.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
