export function filterOptions<T>(
  options: T[],
  searchBy: string,
  filterBy: string,
) {
  return options.filter(
    (option) =>
      (option[searchBy as keyof typeof option] as string)
        ?.toLowerCase()
        ?.includes(filterBy.toLowerCase()),
  );
}

export function toggleArrayItem<T>(array: T[] | null, item: T) {
  if (array === null) {
    return [item];
  }
  if (array.includes(item)) {
    return array.filter((i) => i !== item);
  }
  return [...array, item];
}

export function selectOptionsWithKeyboardArrows(
  e: KeyboardEvent,
  options?: HTMLCollection,
) {
  if (e.key === "ArrowDown" || e.key === "ArrowUp") {
    e.preventDefault();

    let optionFocusId = "";
    const activeOption = document.activeElement?.closest(
      '[for*="clip-filter-option"]',
    );

    if (activeOption) {
      if (e.key === "ArrowDown") {
        const nextSibling = activeOption.nextElementSibling as HTMLLabelElement;
        optionFocusId = nextSibling?.htmlFor;
      } else {
        const previousSibling =
          activeOption.previousElementSibling as HTMLLabelElement;
        optionFocusId = previousSibling?.htmlFor;
      }
    } else {
      if (e.key === "ArrowDown") {
        const firstChild = options?.[0] as HTMLLabelElement;
        optionFocusId = firstChild?.htmlFor;
      } else {
        const lastChild = options?.[options.length - 1] as HTMLLabelElement;
        optionFocusId = lastChild?.htmlFor;
      }
    }

    Boolean(optionFocusId) && document.getElementById(optionFocusId)?.focus();
  }
}
