const WELCOME_MESSAGE_SHOW = "WELCOME_MESSAGE_SHOW";
const WELCOME_MESSAGE_HIDE = "WELCOME_MESSAGE_HIDE";

const initialState = {
  active: false,
  type: null,
};

export const welcomeMessageReducer = function (state = initialState, action) {
  switch (action.type) {
    case WELCOME_MESSAGE_SHOW:
      return {
        ...state,
        active: true,
        type: action.welcomeMessageType || "freetrial",
      };
    case WELCOME_MESSAGE_HIDE:
      return { ...state, active: false };
    default:
      return state;
  }
};
