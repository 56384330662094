import {
  SvgIconAutoLarge,
  SvgIconAutoSmall,
  SvgIconScratchLarge,
  SvgIconScratchSmall,
  SvgIconTemplateLarge,
  SvgIconTemplateSmall,
} from "./icons";

export const URL = {
  app: ["play.google.com/store/apps", "apps.apple.com", "testflight.apple.com"],
  figmaKnowledgeBase:
    "https://help.userbrain.com/help/testing-your-figma-prototype-with-userbrain",
  adobeXdKnowledgeBase:
    "https://help.userbrain.com/help/userbrain-adobe-xd-integration",
  axureKnowledgeBase:
    "https://help.userbrain.com/help/how-to-test-axure-prototypes-with-userbrain",
};

// TODO [cleanup]: Reconcile & centralize types
export type TestType = "app" | "prototype" | "website";

export type TestAutoPrompt = {
  type: TestType;
  language: string;
  url: string;
  description: string;
};

export type CreationType = "scratch" | "auto" | "template";

export type CreationMode = {
  creationType: CreationType;
  cardTitle: string;
  cardDescription: string;
  bannerTitle: string;
  bannerDescription: string;
  iconSmall: React.FC;
  iconLarge: React.FC;
};

export const creationModeScratch: CreationMode = {
  creationType: "scratch",
  cardTitle: "Start from scratch",
  cardDescription: "Create your tasks from the ground up.",
  bannerTitle: "Start from Scratch",
  bannerDescription: "You’ve chosen to create your tasks from scratch.",
  iconSmall: SvgIconScratchSmall,
  iconLarge: SvgIconScratchLarge,
};

export const creationModeAuto: CreationMode = {
  creationType: "auto",
  cardTitle: "Auto-create with AI",
  cardDescription: "Let AI create your tasks – refine them if needed.",
  bannerTitle: "Auto-create with AI",
  bannerDescription: "You’ve chosen to auto-create your tasks with AI.",
  iconSmall: SvgIconAutoSmall,
  iconLarge: SvgIconAutoLarge,
};

export const creationModeTemplate: CreationMode = {
  creationType: "template",
  cardTitle: "Use template",
  cardDescription: "Customize your tasks based on a template.",
  bannerTitle: "Use Template",
  bannerDescription: "You’ve chosen to use our template to create your tasks.",
  iconSmall: SvgIconTemplateSmall,
  iconLarge: SvgIconTemplateLarge,
};

export const getCreationMode = (creationType: CreationType): CreationMode => {
  switch (creationType) {
    case "scratch":
      return creationModeScratch;
    case "auto":
      return creationModeAuto;
    case "template":
      return creationModeTemplate;
  }
};
