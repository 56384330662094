import React from "react";
import { concatClassNames } from "@system42/core";

import styles from "./styles.module.css";

class Button extends React.Component {
  static defaultProps = { styles, type: "button" };

  render() {
    const {
      children,
      styles,
      fullWidth,
      big,
      className,
      type,
      disabled,
      href,
      customTag,
      innerRef,
      ...containerProps
    } = this.props;
    const ComponentTag = customTag || (href ? "a" : "button");

    return (
      <ComponentTag
        {...containerProps}
        href={href}
        ref={innerRef}
        className={concatClassNames(
          className,
          styles.root,
          fullWidth && styles.fullWidth,
          big && styles.big,
        )}
        type={href ? undefined : type || "button"}
        disabled={disabled}
      >
        {children}
      </ComponentTag>
    );
  }
}

export default Button;
