import React from "react";
import { concatClassNames } from "@system42/core";
import isArray from "lodash/isArray";
import startsWith from "lodash/startsWith";
import uniqueId from "lodash/uniqueId";

import { extractContainerProps } from "../../helpers";
import Label from "../Label";

import styles from "./styles.module.css";

class FormField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueId: uniqueId("input"),
    };
  }

  static defaultProps = { styles };

  render() {
    const { className, errorMessage, styles, label, errorClassName } =
      this.props;

    // Find id of first input element (child) and set htmlFor accordingly
    let { htmlFor } = this.props;
    let childrenAsArray = React.Children.toArray(this.props.children);
    if (!htmlFor) {
      function calcInputProbability(child) {
        if (startsWith(child.type?.name, "Input")) {
          return 3;
        }
        if (child.type === "input" || child.type === "textarea") {
          return 2;
        }
        if (typeof child.props?.onChange === "function") {
          return 1;
        }
        return 0;
      }

      // Find out which of the children is most probably the element that the label should refer to
      let theInput = null; // First child with highest input probability
      let highestInputProbability = -1;
      for (const child of childrenAsArray) {
        const childInputProbability = calcInputProbability(child);
        if (childInputProbability > highestInputProbability) {
          theInput = child;
          highestInputProbability = childInputProbability;
        }
      }

      if (theInput !== null) {
        if (theInput.props.id != null) {
          htmlFor = theInput.props.id;
        } else {
          htmlFor = this.state.uniqueId;
          childrenAsArray.splice(
            childrenAsArray.indexOf(theInput),
            1,
            React.cloneElement(theInput, { id: htmlFor }),
          );
        }
      }
    }

    let errorMessageArray = errorMessage;
    if (!isArray(errorMessage)) {
      errorMessageArray = [];
      if (errorMessage) {
        errorMessageArray.push(errorMessage);
      }
    }

    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(className, styles.root)}
      >
        <Label htmlFor={htmlFor} className={styles.label}>
          {label}
        </Label>
        {childrenAsArray}
        {errorMessageArray.map((errorMessage, i) => (
          <div
            key={i}
            className={concatClassNames(styles.error, errorClassName)}
          >
            {errorMessage}
          </div>
        ))}
      </div>
    );
  }
}

export default FormField;
