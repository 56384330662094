import { LoaderFunctionArgs } from "react-router-dom";

import { SHARED_VIDEO_REQUEST } from "@/reducers/sharedVideo";
import { store } from "@/store";

export default async function loader({ params }: LoaderFunctionArgs) {
  store.dispatch({ type: SHARED_VIDEO_REQUEST, sharedHash: params.hash });

  const isSignedIn = store.getState().user.signedIn;
  if (isSignedIn) {
    store.dispatch({ type: "USER_REQUEST" });
  }

  return null;
}
