import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import {
  VIDEO_NOTE_CREATE_FAILURE,
  VIDEO_NOTE_CREATE_REQUEST,
  VIDEO_NOTE_CREATE_SUCCESS,
} from "@/reducers/videoNoteCreate";
import {
  VIDEO_NOTE_DELETE_FAILURE,
  VIDEO_NOTE_DELETE_REQUEST,
  VIDEO_NOTE_DELETE_SUCCESS,
} from "@/reducers/videoNoteDelete";
import {
  VIDEO_NOTE_UPDATE_FAILURE,
  VIDEO_NOTE_UPDATE_REQUEST,
  VIDEO_NOTE_UPDATE_SUCCESS,
} from "@/reducers/videoNoteUpdate";
import { API_URL } from ".";

export function* watchVideoNoteCreate() {
  yield takeLatest(VIDEO_NOTE_CREATE_REQUEST, callVideoNoteCreateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideoNoteCreateApi(action: any): SagaIterator {
  try {
    const { videoId, convertClipId, note, timestamp } = action;
    const response = yield call(
      postVideoNote,
      videoId,
      note,
      timestamp,
      convertClipId,
    );
    const noteData = response.data.data;
    yield put({
      type: VIDEO_NOTE_CREATE_SUCCESS,
      data: {
        videoId,
        convertClipId,
        note: noteData,
      },
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: VIDEO_NOTE_CREATE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function postVideoNote(
  videoId: number,
  note: string,
  timestamp: number,
  videoclip_id?: number,
) {
  return axios({
    method: "post",
    url: API_URL + "/video/" + videoId + "/note",
    data: {
      note,
      timestamp,
      videoclip_id,
    },
  });
}

export function* watchVideoNoteDelete() {
  yield takeLatest(VIDEO_NOTE_DELETE_REQUEST, callVideoNoteDeleteApi);
}

// worker saga: makes the api call when watcher saga sees the action
export function* callVideoNoteDeleteApi(action: any): SagaIterator {
  const { videoId, noteId } = action;
  try {
    yield call(deleteVideoNote, videoId, noteId);
    yield put({ type: VIDEO_NOTE_DELETE_SUCCESS, data: { videoId, noteId } });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: VIDEO_NOTE_DELETE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function deleteVideoNote(videoId: number, noteId: number) {
  return axios({
    method: "delete",
    url: API_URL + "/video/" + videoId + "/note/" + noteId,
  });
}

// VIDEO UPDATE NOTE
export function* watchVideoNoteUpdate() {
  yield takeLatest(VIDEO_NOTE_UPDATE_REQUEST, callVideoNoteUpdateApi);
}

// worker saga: makes the api call when watcher saga sees the action
export function* callVideoNoteUpdateApi(action: any): SagaIterator {
  const { videoId, noteId, note, timestamp } = action;
  try {
    const response = yield call(
      patchVideoNote,
      videoId,
      noteId,
      note,
      timestamp,
    );

    const noteData = response.data.data;

    yield put({
      type: VIDEO_NOTE_UPDATE_SUCCESS,
      data: {
        videoId,
        note: noteData,
      },
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: VIDEO_NOTE_UPDATE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function patchVideoNote(
  videoId: number,
  noteId: number,
  note: string,
  timestamp: number,
) {
  return axios({
    method: "patch",
    url: API_URL + "/video/" + videoId + "/note/" + noteId,
    data: {
      note,
      timestamp,
    },
  });
}
