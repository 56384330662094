import { z } from "zod";

import { TESTER_NAME_ANONYMOUS } from "@/helpers";

const TesterTypeSchema = z.union([z.literal("pool"), z.literal("invitation")]);

export const VideoClipTesterFilterItemApiSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  type: TesterTypeSchema,
  country_of_residence: z.string().nullable().optional(),
  age: z.number().nullable().optional(),
  gender: z.string().nullable().optional(),
  rating: z.number().min(0).max(5),
  count: z.number(),
});

export type VideoClipTesterFilterItemApi = z.infer<
  typeof VideoClipTesterFilterItemApiSchema
>;

export type VideoClipTesterFilterItem = {
  id: string;
  name: string;
  country?: string;
  gender?: string;
  age?: number;
  stars?: number;
  count: number;
};

export function parseApiTesterFilterItemToLocal(
  videoClipTesterFilterItemApi: VideoClipTesterFilterItemApi,
): VideoClipTesterFilterItem {
  const { id, count, name, country_of_residence, age, gender, rating } =
    VideoClipTesterFilterItemApiSchema.parse(videoClipTesterFilterItemApi);

  return {
    id: id?.toString() ?? "", // XXX: temporary solution because for invitation_testers the api returns undefined
    name: name ?? TESTER_NAME_ANONYMOUS,
    age: age ?? undefined,
    gender: gender ?? undefined,
    stars: rating > 0 ? rating : undefined,
    country: country_of_residence ?? undefined,
    count: count ?? 0,
  };
}
