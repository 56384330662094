import React, { useState } from "react";

import { ButtonPrimary, FormField, InputText } from "@/design-system";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from "./styles.module.css";

export default function ConfirmDeleteModal({
  isActive,
  onClose,
  ...restProps
}) {
  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      maxWidth={"24rem"}
      centered
      content={<ConfirmDeleteModalContent onClose={onClose} {...restProps} />}
    />
  );
}

function ConfirmDeleteModalContent({
  onClose,
  onSubmit,
  isFetching,
  errorMessage,
  title,
  buttonLabel,
}) {
  const [confirmText, setConfirmText] = useState("");

  function handleClickDelete() {
    onSubmit(confirmText);
  }

  function handleChangeConfirmText(e) {
    setConfirmText(e.target.value);
  }

  return (
    <>
      <ModalHeader heading={<>{title}</>} onClickCloseIcon={onClose} />
      <div className={styles.content}>
        <FormField
          label={`Type "DELETE" to confirm`}
          errorMessage={errorMessage}
        >
          <InputText
            onChange={handleChangeConfirmText}
            value={confirmText}
            fullWidth
            autoFocus
          />
        </FormField>

        <p>This action cannot be undone.</p>

        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          {isFetching ? (
            <ButtonPrimary disabled>Deleting…</ButtonPrimary>
          ) : (
            <ButtonPrimary onClick={handleClickDelete}>
              {buttonLabel ?? "Delete"}
            </ButtonPrimary>
          )}
        </ButtonGroup>
      </div>
    </>
  );
}
