import React from "react";
import { Link, LinkProps } from "react-router-dom";

type AutoLinkProps =
  | (React.HTMLProps<HTMLAnchorElement> & { to: undefined })
  | LinkProps;

export function AutoLink(props: AutoLinkProps) {
  const to = props.to;
  if (typeof to !== "undefined") {
    return <Link {...props} />;
  } else {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...props} />;
  }
}
