// CHECKOUT

export const CHECKOUT_INIT = "CHECKOUT_INIT";
export const CHECKOUT_SET_BILLING_CYCLE = "CHECKOUT_SET_BILLING_CYCLE";

const initialStateCheckout = {
  creditCount: 0,
  plan: "payg",
  billingCycle: "monthly",
  orderAttempt: false,
  iyopAttempt: false,
  deliveryRequest: null,
  redirectToBillingAfterCheckout: false,
};

export function checkoutReducer(state = initialStateCheckout, action) {
  switch (action.type) {
    case CHECKOUT_INIT: {
      const {
        creditCount,
        plan,
        billingCycle,
        orderAttempt,
        iyopAttempt,
        deliveryRequest,
        redirectToBillingAfterCheckout,
      } = action;
      return {
        ...state,
        creditCount,
        plan,
        billingCycle,
        orderAttempt,
        iyopAttempt,
        redirectToBillingAfterCheckout: redirectToBillingAfterCheckout ?? false,
        deliveryRequest: deliveryRequest ?? null,
      };
    }
    case CHECKOUT_SET_BILLING_CYCLE: {
      return {
        ...state,
        billingCycle: action.billingCycle,
      };
    }
    default:
      return state;
  }
}
