import React from "react";

import { ModalContent } from "../ModalContent";
import ModalHeader from "../ModalHeader";

import Popup from "./ChromeExtensionComponents/Popup/Popup";

import styles from "./styles.module.css";

export default function TaskPreview({ task, onClickClose, initialStep }) {
  return (
    <div className={styles.taskPreview}>
      <ModalHeader
        heading={"Preview tasks"}
        bigHeading
        onClickCloseIcon={onClickClose}
      />
      <ModalContent className={styles.modalContent}>
        {task && <Popup task={task} initialStep={initialStep} />}
      </ModalContent>
    </div>
  );
}
