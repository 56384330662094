import { ReactNode, useEffect, useId, useState } from "react";
import { concatClassNames as cn } from "@system42/core";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconExpand } from "./icon-expand.svg";

export function Details({
  initialIsOpen = false,
  onToggle,
  summary,
  children,
  className,
  summaryClassName,
}: {
  initialIsOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  summary: ReactNode;
  children: ReactNode;
  className?: string;
  summaryClassName?: string;
}) {
  const id = useId();
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  function handleToggle() {
    setIsOpen((prev) => !prev);
  }

  useEffect(() => {
    onToggle?.(isOpen);
  }, [isOpen, onToggle]);

  return (
    <div className={cn(styles.details, className)}>
      <button
        onClick={handleToggle}
        className={cn(
          styles.summary,
          isOpen && styles.summary_open,
          summaryClassName,
        )}
        aria-expanded={isOpen}
        aria-controls="expandable-content"
      >
        {summary}
        <SvgIconExpand />
      </button>
      <div
        id={"expandable-content-" + id}
        className={cn(styles.children, isOpen && styles.children_open)}
      >
        <div className={styles.childrenInner}>{children}</div>
      </div>
    </div>
  );
}
