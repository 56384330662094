import React from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import CheckoutModal from "@/containers/CheckoutModal";
import {
  ButtonPrimary,
  Card,
  ErrorMessage,
  FieldHelp,
  MainNavigation,
  MainNavigationContent,
  Stack,
  SubNavigation,
  SubNavigationContent,
} from "@/design-system";
import { useModal2 } from "@/hooks/useModal2";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

export default function AccountDev() {
  const dispatch = useDispatch();

  const featurePopupVersion = useSelector(
    (state) => state.user.user?.feature_popup_version,
  );
  const deleteSubscriptionFetching = useSelector(
    (state) => state.dog.deleteSubscriptionFetching,
  );
  const deleteCreditsFetching = useSelector(
    (state) => state.dog.deleteCreditsFetching,
  );
  const dogCustomerDelete = useSelector((state) => state.dogCustomerDelete);
  const confirmEmailResend = useSelector((state) => state.confirmEmailResend);

  const { showModal: showCheckoutModal, modal: checkoutModal } = useModal2(
    <CheckoutModal />,
  );

  function handleClickDeleteSubscription() {
    dispatch({ type: "DOG_DELETE_SUBSCRIPTION_REQUEST" });
  }

  function handleClickDeleteCredits() {
    dispatch({ type: "DOG_DELETE_CREDITS_REQUEST" });
  }

  function handleClickDeleteCustomer() {
    dispatch({ type: "DOG_CUSTOMER_DELETE_REQUEST" });
  }

  function handleClickBuyLegacyAnnual() {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "legacy",
      billingCycle: "annual",
    });
    showCheckoutModal();
  }

  function handleClickBuyLegacyMonthly() {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "legacy",
      billingCycle: "monthly",
    });
    showCheckoutModal();
  }

  function handleClickBuyLegacyProAnnual() {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "legacypro",
      billingCycle: "annual",
    });
    showCheckoutModal();
  }

  function handleClickBuyLegacyProMonthly() {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "legacypro",
      billingCycle: "monthly",
    });
    showCheckoutModal();
  }

  function handleClickResetFeaturePopupVersion() {
    dispatch({
      type: "FEATURE_POPUP_DISMISS_REQUEST",
      version: "0.0.0",
    });
  }

  function handleClickResetAccount() {
    if (window?.confirm("Are you sure?")) {
      dispatch({
        type: "DOG_ACCOUNT_RESET_REQUEST",
      });
    }
  }

  function handleClickConfirmEmailResend() {
    dispatch({ type: "CONFIRM_EMAIL_RESEND_REQUEST" });
  }

  return (
    <>
      <Helmet>
        <title>Account Dev | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <SubNavigation>
            <SubNavigationAccount currentNavItem={"account-dev"} />
            <SubNavigationContent>
              <div className={styles.content}>
                <Card className={styles.card}>
                  <Stack>
                    <div>
                      <ButtonPrimary onClick={handleClickDeleteSubscription}>
                        Delete Subscription
                        {deleteSubscriptionFetching && <>…</>}
                      </ButtonPrimary>
                    </div>
                    <Stack spacing={"xsmall"}>
                      <ButtonPrimary onClick={handleClickDeleteCredits}>
                        Delete Credits and running deliveries
                        {deleteCreditsFetching && <>…</>}
                      </ButtonPrimary>
                      <FieldHelp>
                        You may need to go to{" "}
                        <Link to={"/account"}>Account Details</Link> and save to
                        update the chache.
                      </FieldHelp>
                    </Stack>
                    <div>
                      <ButtonPrimary onClick={handleClickDeleteCustomer}>
                        Delete Customer{dogCustomerDelete.isFetching && <>…</>}
                      </ButtonPrimary>
                    </div>
                    <div>
                      <ButtonPrimary onClick={handleClickBuyLegacyAnnual}>
                        Buy a legacy plan (annual)
                      </ButtonPrimary>
                    </div>
                    <div>
                      <ButtonPrimary onClick={handleClickBuyLegacyMonthly}>
                        Buy a legacy plan (monthly)
                      </ButtonPrimary>
                    </div>
                    <div>
                      <ButtonPrimary onClick={handleClickBuyLegacyProAnnual}>
                        Buy a legacy PRO plan (annual)
                      </ButtonPrimary>
                    </div>
                    <div>
                      <ButtonPrimary onClick={handleClickBuyLegacyProMonthly}>
                        Buy a legacy PRO plan (monthly)
                      </ButtonPrimary>
                    </div>
                    <div>
                      <ButtonPrimary
                        onClick={handleClickResetFeaturePopupVersion}
                      >
                        Reset feature popup version (is: {featurePopupVersion})
                      </ButtonPrimary>
                    </div>
                    <div>
                      <ButtonPrimary onClick={handleClickResetAccount}>
                        Reset account
                      </ButtonPrimary>
                    </div>
                    <div>
                      <ButtonPrimary onClick={handleClickConfirmEmailResend}>
                        Confirm email resend
                        {confirmEmailResend.isFetching && <>…</>}
                      </ButtonPrimary>
                    </div>
                    {confirmEmailResend.isError && (
                      <ErrorMessage>
                        {confirmEmailResend.error.message}
                      </ErrorMessage>
                    )}
                    <div>
                      <a
                        rel="noreferrer"
                        href={`/confirm/${confirmEmailResend.data?.token}`}
                        target={"_blank"}
                      >
                        Confirm link
                      </a>
                    </div>
                  </Stack>
                </Card>
              </div>
            </SubNavigationContent>
          </SubNavigation>

          {checkoutModal}
        </MainNavigationContent>
      </MainNavigation>
    </>
  );
}
