import { ButtonPrimary } from "@/design-system";

import styles from "./styles.module.css";

import customerChoiceImg from "./images/choice-customer.png";
import testerChoiceImg from "./images/choice-tester.png";

/**


  Choose between user roles of either
  Tester or Customer.

*/
export default function CustomerTesterSwitch({
  onChooseTester,
  onChooseCustomer,
}) {
  return (
    <div className={styles.customerTesterSwitch}>
      <h1 className={styles.title}>I want to…</h1>
      <div className={styles.cardContainer}>
        <CustomerTesterSwitchCard
          image={
            <img
              src={customerChoiceImg}
              alt="Cursor hovering over a test's video and its transcripts"
            />
          }
          title="For customers"
          description="…try Userbrain for my company"
          buttonText="Start 14-day free trial"
          onButtonClick={onChooseCustomer}
        />
        <CustomerTesterSwitchCard
          image={
            <img
              src={testerChoiceImg}
              alt="Tester wearing a headset recording a test"
            />
          }
          title="For testers"
          description="Earn money online"
          buttonText="Get paid to test"
          onButtonClick={onChooseTester}
        />
      </div>
    </div>
  );
}

function CustomerTesterSwitchCard({
  image,
  title,
  buttonText,
  onButtonClick,
  description,
}) {
  return (
    <div className="card">
      <div className={styles.cardBody}>
        <h2 className={styles.cardTitle}>{title}</h2>
        {image}
        <div className={styles.cardDescription}>{description}</div>
        <ButtonPrimary onClick={onButtonClick} className={styles.cardButton}>
          {buttonText}
        </ButtonPrimary>
      </div>
    </div>
  );
}
