import React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import { Draggable, Droppable } from "react-beautiful-dnd";

import {
  Checkbox,
  DropDown,
  DropDownItem,
  ErrorMessage,
  InputText,
  TextArea,
  Tooltip,
} from "@/design-system";
import { testUrlFieldFeedbackFilter } from "@/utils/filters";
import { textCantChangeTaskType } from "../../../helpers";
import ChoiceAnswer from "../ChoiceAnswer";
import StepSeparator from "../StepSeparator";

import styles from "./styles.module.css";

export default React.memo(
  React.forwardRef(function TaskStep(props, ref) {
    const {
      step,
      originalStep,
      stepIndex,
      onChangeTaskType,
      onClickDuplicate,
      onClickDelete,
      hideDeleteButton,
      onChangeOption,
      onChangeTask,
      onClickDeleteAnswer,
      onClickAddStep,
      onClickPreview,
      classNameStepSeparator,
      fieldFeedback,
      hideDuplicateButton,
      hidePreviewButton,
      hideDragHandle,
      isDisabled,
    } = props;

    function handleChangeTask(e) {
      onChangeTask(e, stepIndex);
    }

    function handleBlurUrl(e, stepIndex) {
      let { value } = e.target;
      if (value && !/^https?:\/\//i.test(value)) {
        value = "http://" + value;
        onChangeOption({ target: { value } }, "url", stepIndex);
      }
    }

    const typeDropdown = (
      <DropDown
        value={step.type}
        className={cn(
          styles.typeDropDown,
          isDisabled && styles.typeDropDown_disabled,
        )}
        onChange={(e) => onChangeTaskType(e, stepIndex)}
        disabled={step.response_count > 0 || isDisabled}
      >
        <DropDownItem value={"task"}>
          <FontAwesomeIcon
            className={cn(
              styles.typeDropDownIcon,
              isDisabled && styles.typeDropDownIcon_disabled,
            )}
            fixedWidth
            icon={solid("align-left")}
          />
          Task
        </DropDownItem>
        <DropDownItem value={"rating_scale"}>
          <FontAwesomeIcon
            className={cn(
              styles.typeDropDownIcon,
              isDisabled && styles.typeDropDownIcon_disabled,
            )}
            fixedWidth
            icon={solid("star")}
          />
          Rating Scale
        </DropDownItem>
        <DropDownItem value={"multiple_choice"}>
          <FontAwesomeIcon
            className={cn(
              styles.typeDropDownIcon,
              isDisabled && styles.typeDropDownIcon_disabled,
            )}
            fixedWidth
            icon={solid("check-square")}
          />
          Multiple Choice
        </DropDownItem>
        <DropDownItem value={"single_choice"}>
          <FontAwesomeIcon
            className={cn(
              styles.typeDropDownIcon,
              isDisabled && styles.typeDropDownIcon_disabled,
            )}
            fixedWidth
            icon={solid("dot-circle")}
          />
          Single Choice
        </DropDownItem>
        <DropDownItem value={"written_response"}>
          <FontAwesomeIcon
            className={cn(
              styles.typeDropDownIcon,
              isDisabled && styles.typeDropDownIcon_disabled,
            )}
            fixedWidth
            icon={solid("text")}
          />
          Written Response
        </DropDownItem>
        <DropDownItem value={"redirect"}>
          <FontAwesomeIcon
            className={cn(
              styles.typeDropDownIcon,
              isDisabled && styles.typeDropDownIcon_disabled,
            )}
            fixedWidth
            icon={solid("share")}
          />
          Redirect
        </DropDownItem>
      </DropDown>
    );

    return (
      <Draggable
        key={typeof step.localId !== "undefined" ? step.localId : step.id}
        draggableId={
          typeof step.localId !== "undefined"
            ? step.localId.toString()
            : step.id.toString()
        }
        index={stepIndex}
      >
        {(provided, snapshot) => (
          <div
            className={cn(
              styles.stepWrapper,
              snapshot.isDragging && styles.stepWrapper_isDragging,
            )}
            {...provided.draggableProps}
            ref={(element) => {
              if (ref) {
                ref.current = element;
              }
              if (provided.innerRef) {
                provided.innerRef(element);
              }
            }}
            id={`task-${step.id}`}
          >
            <div className={cn(styles.step, "card")}>
              <div
                className={cn(
                  styles.stepHandle,
                  hideDragHandle && styles.stepHandle_disabled,
                )}
                {...provided.dragHandleProps}
              >
                <FontAwesomeIcon icon={solid("grip-horizontal")} />
              </div>
              {fieldFeedback[`task.${stepIndex}.type`]?.map(
                (errorMessage, index) => (
                  <ErrorMessage className={styles.errorMessage} key={index}>
                    {errorMessage}
                  </ErrorMessage>
                ),
              )}
              <div className={styles.taskHeader}>
                {step.response_count > 0 ? (
                  <Tooltip
                    content={textCantChangeTaskType}
                    width={"max-content"}
                    small
                  >
                    {typeDropdown}
                  </Tooltip>
                ) : (
                  typeDropdown
                )}

                <div className={styles.actionButtons}>
                  {!hidePreviewButton && (
                    <button
                      title="Preview"
                      className={styles.actionButton}
                      type="button"
                      onClick={() => onClickPreview(stepIndex)}
                    >
                      <FontAwesomeIcon icon={regular("eye")} />
                    </button>
                  )}
                  {!hideDuplicateButton && (
                    <button
                      title="Duplicate"
                      className={styles.actionButton}
                      type="button"
                      onClick={() => onClickDuplicate(stepIndex)}
                    >
                      <FontAwesomeIcon icon={regular("copy")} />
                    </button>
                  )}
                  {!hideDeleteButton && (
                    <button
                      title="Delete"
                      className={styles.actionButton}
                      type="button"
                      onClick={() => onClickDelete(stepIndex, step)}
                    >
                      <FontAwesomeIcon icon={regular("trash")} />
                    </button>
                  )}
                </div>
              </div>

              {/* TYPES */}

              {step.type === "task" && (
                <div>
                  <TextArea
                    error={!!fieldFeedback[`task.${stepIndex}.task`]}
                    className={styles.textArea}
                    autoFocus={step.localAddedRecently}
                    value={step.task}
                    placeholder={"Enter your task or questions here…"}
                    onChange={handleChangeTask}
                    autoSize
                    isDisabled={isDisabled}
                  />
                  {fieldFeedback[`task.${stepIndex}.task`]?.map(
                    (errorMessage, index) => (
                      <div className={styles.error} key={index}>
                        {errorMessage}
                      </div>
                    ),
                  )}
                  <Checkbox
                    disabled={isDisabled}
                    className={cn(
                      styles.checkboxAskCompleted,
                      isDisabled && styles.checkboxAskCompleted_disabled,
                    )}
                    checked={!!step.options?.ask_completed}
                    onChange={(e) =>
                      onChangeOption(e, "ask_completed", stepIndex)
                    }
                  >
                    Ask testers if they completed the task successfully
                  </Checkbox>
                </div>
              )}
              {step.type === "rating_scale" && (
                <div>
                  <TextArea
                    error={!!fieldFeedback[`task.${stepIndex}.task`]}
                    className={styles.textArea}
                    placeholder={"Enter your question here…"}
                    autoFocus={step.localAddedRecently}
                    value={step.task}
                    onChange={handleChangeTask}
                    autoSize
                    isDisabled={isDisabled}
                  />
                  {fieldFeedback[`task.${stepIndex}.task`]?.map(
                    (errorMessage, index) => (
                      <div className={styles.error} key={index}>
                        {errorMessage}
                      </div>
                    ),
                  )}
                  <div className={styles.ratingScaleStart}>
                    <label
                      className={cn(
                        styles.ratingScaleDropDownWithLabel,
                        isDisabled &&
                          styles.ratingScaleDropDownWithLabel_disabled,
                      )}
                    >
                      <span>Start at</span>
                      <DropDown
                        className={styles.ratingScaleDropDown}
                        value={step.options?.scale_start}
                        disabled={isDisabled}
                        onChange={(e) =>
                          onChangeOption(e, "scale_start", stepIndex)
                        }
                      >
                        <DropDownItem value={0}>0</DropDownItem>
                        <DropDownItem value={1}>1</DropDownItem>
                      </DropDown>
                    </label>
                    <InputText
                      className={cn(
                        styles.ratingScaleInput,
                        isDisabled && styles.ratingScaleInput_disabled,
                      )}
                      placeholder={"Start label"}
                      isDisabled={isDisabled}
                      error={
                        !!fieldFeedback[
                          `task.${stepIndex}.options.scale_start_label`
                        ]
                      }
                      value={step.options?.scale_start_label}
                      onChange={(e) =>
                        onChangeOption(e, "scale_start_label", stepIndex)
                      }
                    />
                  </div>
                  {fieldFeedback[
                    `task.${stepIndex}.options.scale_start_label`
                  ]?.map((errorMessage, index) => (
                    <div
                      className={cn(styles.error, styles.error_scaleLabel)}
                      key={index}
                    >
                      {errorMessage}
                    </div>
                  ))}
                  <div className={styles.ratingScaleEnd}>
                    <label
                      className={cn(
                        styles.ratingScaleDropDownWithLabel,
                        isDisabled &&
                          styles.ratingScaleDropDownWithLabel_disabled,
                      )}
                    >
                      <span>End at</span>
                      <DropDown
                        disabled={isDisabled}
                        className={styles.ratingScaleDropDown}
                        value={step.options?.scale_end}
                        onChange={(e) =>
                          onChangeOption(e, "scale_end", stepIndex)
                        }
                      >
                        <DropDownItem value={5}>5</DropDownItem>
                        <DropDownItem value={6}>6</DropDownItem>
                        <DropDownItem value={7}>7</DropDownItem>
                        <DropDownItem value={8}>8</DropDownItem>
                        <DropDownItem value={9}>9</DropDownItem>
                        <DropDownItem value={10}>10</DropDownItem>
                      </DropDown>
                    </label>
                    <InputText
                      isDisabled={isDisabled}
                      className={cn(
                        styles.ratingScaleInput,
                        isDisabled && styles.ratingScaleInput_disabled,
                      )}
                      placeholder={"End label"}
                      error={
                        !!fieldFeedback[
                          `task.${stepIndex}.options.scale_end_label`
                        ]
                      }
                      value={step.options?.scale_end_label}
                      onChange={(e) =>
                        onChangeOption(e, "scale_end_label", stepIndex)
                      }
                    />
                  </div>
                  {fieldFeedback[
                    `task.${stepIndex}.options.scale_end_label`
                  ]?.map((errorMessage, index) => (
                    <div
                      className={cn(styles.error, styles.error_scaleLabel)}
                      key={index}
                    >
                      {errorMessage}
                    </div>
                  ))}
                </div>
              )}
              {step.type === "multiple_choice" && (
                <div>
                  <TextArea
                    error={!!fieldFeedback[`task.${stepIndex}.task`]}
                    className={styles.textArea}
                    autoFocus={step.localAddedRecently}
                    value={step.task}
                    onChange={handleChangeTask}
                    autoSize
                    isDisabled={isDisabled}
                  />
                  {fieldFeedback[`task.${stepIndex}.task`]?.map(
                    (errorMessage, index) => (
                      <div className={styles.error} key={index}>
                        {errorMessage}
                      </div>
                    ),
                  )}
                  <Droppable
                    droppableId={`choice-answer-${stepIndex}`}
                    type={`ANSWER-${stepIndex}`}
                  >
                    {(provided) => (
                      <div
                        /*className={cn(styles.wrapper, snapshot.isDraggingOver && styles.wrapper_isDraggingOver)}*/
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {[
                          ...(step.options?.answers?.map(
                            (answer, answerIndex) => (
                              <ChoiceAnswer
                                className={cn(
                                  styles.multipleChoiceAnswer,
                                  isDisabled &&
                                    styles.multipleChoiceAnswer_disabled,
                                )}
                                value={answer}
                                key={answerIndex}
                                isDisabled={isDisabled}
                                index={answerIndex}
                                stepIndex={stepIndex}
                                choiceType={"multiple"}
                                hideDragHandle={isDisabled}
                                onClickDelete={() =>
                                  onClickDeleteAnswer(stepIndex, answerIndex)
                                }
                                onChange={(e) =>
                                  onChangeOption(
                                    e,
                                    "answers",
                                    stepIndex,
                                    answerIndex,
                                  )
                                }
                              />
                            ),
                          ) || []),
                          <ChoiceAnswer
                            className={cn(
                              styles.multipleChoiceAnswer,
                              isDisabled &&
                                styles.multipleChoiceAnswer_disabled,
                            )}
                            index={step.options?.answers?.length || 0}
                            key={step.options?.answers?.length || 0}
                            stepIndex={stepIndex}
                            isDisabled={isDisabled}
                            value={""}
                            hideDragHandle
                            error={
                              !!fieldFeedback[
                                `task.${stepIndex}.options.answers`
                              ]
                            }
                            placeholder={"Add answer"}
                            choiceType={"multiple"}
                            onChange={(e) =>
                              onChangeOption(
                                e,
                                "answers",
                                stepIndex,
                                step.options?.answers?.length || 0,
                              )
                            }
                          />,
                        ]}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {fieldFeedback[`task.${stepIndex}.options.answers`]?.map(
                    (errorMessage, index) => (
                      <div
                        className={cn(styles.error, styles.error_addAnswer)}
                        key={index}
                      >
                        {errorMessage}
                      </div>
                    ),
                  )}
                </div>
              )}
              {step.type === "single_choice" && (
                <div>
                  <TextArea
                    error={!!fieldFeedback[`task.${stepIndex}.task`]}
                    className={styles.textArea}
                    autoFocus={step.localAddedRecently}
                    value={step.task}
                    onChange={handleChangeTask}
                    autoSize
                    isDisabled={isDisabled}
                  />
                  {fieldFeedback[`task.${stepIndex}.task`]?.map(
                    (errorMessage, index) => (
                      <div className={styles.error} key={index}>
                        {errorMessage}
                      </div>
                    ),
                  )}
                  <Droppable
                    droppableId={`choice-answer-${stepIndex}`}
                    type={`ANSWER-${stepIndex}`}
                  >
                    {(provided) => (
                      <div
                        /*className={cn(styles.wrapper, snapshot.isDraggingOver && styles.wrapper_isDraggingOver)}*/
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {[
                          ...(step.options?.answers?.map(
                            (answer, answerIndex) => (
                              <ChoiceAnswer
                                isDisabled={isDisabled}
                                className={cn(
                                  styles.singleChoiceAnswer,
                                  isDisabled &&
                                    styles.singleChoiceAnswer_disabled,
                                )}
                                value={answer}
                                index={answerIndex}
                                key={answerIndex}
                                stepIndex={stepIndex}
                                hideDragHandle={isDisabled}
                                choiceType={"single"}
                                onClickDelete={() =>
                                  onClickDeleteAnswer(stepIndex, answerIndex)
                                }
                                onChange={(e) =>
                                  onChangeOption(
                                    e,
                                    "answers",
                                    stepIndex,
                                    answerIndex,
                                  )
                                }
                              />
                            ),
                          ) || []),
                          <ChoiceAnswer
                            isDisabled={isDisabled}
                            className={cn(
                              styles.singleChoiceAnswer,
                              isDisabled && styles.singleChoiceAnswer_disabled,
                            )}
                            index={step.options?.answers?.length || 0}
                            key={step.options?.answers?.length || 0}
                            stepIndex={stepIndex}
                            hideDragHandle
                            error={
                              !!fieldFeedback[
                                `task.${stepIndex}.options.answers`
                              ]
                            }
                            placeholder={"Add answer"}
                            choiceType={"single"}
                            value={""}
                            onChange={(e) =>
                              onChangeOption(
                                e,
                                "answers",
                                stepIndex,
                                step.options?.answers?.length || 0,
                              )
                            }
                          />,
                        ]}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                  {fieldFeedback[`task.${stepIndex}.options.answers`]?.map(
                    (errorMessage, index) => (
                      <div
                        className={cn(styles.error, styles.error_addAnswer)}
                        key={index}
                      >
                        {errorMessage}
                      </div>
                    ),
                  )}
                </div>
              )}
              {step.type === "written_response" && (
                <div>
                  <TextArea
                    error={!!fieldFeedback[`task.${stepIndex}.task`]}
                    className={styles.textArea}
                    autoFocus={step.localAddedRecently}
                    value={step.task}
                    onChange={handleChangeTask}
                    autoSize
                    isDisabled={isDisabled}
                  />
                  {fieldFeedback[`task.${stepIndex}.task`]?.map(
                    (errorMessage, index) => (
                      <div className={styles.error} key={index}>
                        {errorMessage}
                      </div>
                    ),
                  )}
                </div>
              )}
              {step.type === "redirect" && (
                <div>
                  <TextArea
                    error={!!fieldFeedback[`task.${stepIndex}.task`]}
                    className={styles.textArea}
                    value={step.task}
                    onChange={handleChangeTask}
                    autoSize
                    isDisabled={isDisabled}
                  />
                  {fieldFeedback[`task.${stepIndex}.task`]?.map(
                    (errorMessage, index) => (
                      <div className={styles.error} key={index}>
                        {errorMessage}
                      </div>
                    ),
                  )}
                  <InputText
                    className={cn(
                      styles.redirectInput,
                      isDisabled && styles.redirectInput_disabled,
                    )}
                    isDisabled={isDisabled}
                    autoFocus={step.localAddedRecently}
                    error={!!fieldFeedback[`task.${stepIndex}.options.url`]}
                    placeholder={"URL"}
                    onBlur={(e) => handleBlurUrl(e, stepIndex)}
                    value={step.options?.url}
                    onChange={(e) => onChangeOption(e, "url", stepIndex)}
                  />
                  {fieldFeedback[`task.${stepIndex}.options.url`]?.map(
                    (errorMessage, index) => (
                      <div className={styles.error} key={index}>
                        {testUrlFieldFeedbackFilter(
                          errorMessage,
                          step.options?.url,
                        )}
                      </div>
                    ),
                  )}
                </div>
              )}

              {/* END TYPES */}

              {originalStep?.type === step.type &&
                (step.response_count ?? 0) > 0 && (
                  <div className={styles.countResults}>
                    {step.response_count} Tester
                    {step.response_count !== 1 && "s"}
                  </div>
                )}
            </div>
            <StepSeparator
              onClick={() => onClickAddStep(stepIndex + 1)}
              className={cn(
                styles.stepSeparator,
                classNameStepSeparator,
                isDisabled && styles.stepSeparator_disabled,
              )}
            />
          </div>
        )}
      </Draggable>
    );
  }),
);
