import { useDispatch } from "react-redux";

import { IconTextMessageWithButton } from "@/components/IconTextMessageWithButton";

import { ReactComponent as SvgIconLocked } from "./lock.svg";

export default function UpgradeNowMessage({
  className,
  children,
  onClickUpgrade,
}: {
  className?: string;
  children: React.ReactNode;
  onClickUpgrade?: () => void;
}) {
  const dispatch = useDispatch();

  function handleClickUpgrade() {
    onClickUpgrade?.();
    dispatch({
      type: "GLOBAL_MODAL_OPEN",
      modal: "explorePlans",
      modalOptions: { redirectToBillingAfterCheckout: true },
    });
  }

  return (
    <IconTextMessageWithButton
      className={className}
      SvgIcon={SvgIconLocked}
      buttonText={`Upgrade now`}
      onClick={handleClickUpgrade}
    >
      {children}
    </IconTextMessageWithButton>
  );
}
