import { Reducer } from "@reduxjs/toolkit";

import { ApiError } from "./types";

// XXX: This is a temporary solution primarily for test clips, need to think about this in cooldown.

type GenericApiStateIdle = {
  isFetching: false;
  isError: false;
  error: null;
};

type GenericApiStateFetching = {
  isFetching: true;
  isError: false;
  error: null;
};

type GenericApiStateSuccess = {
  isFetching: false;
  isError: false;
  error: null;
};

type GenericApiStateError = {
  isFetching: false;
  isError: true;
  error: ApiError;
};

type GenericApiState =
  | GenericApiStateIdle
  | GenericApiStateFetching
  | GenericApiStateSuccess
  | GenericApiStateError;

export default function createGenericApiSliceWithoutData(
  actionIdentifier: string,
) {
  const REQUEST = `${actionIdentifier}_REQUEST`;
  const FAILURE = `${actionIdentifier}_FAILURE`;
  const SUCCESS = `${actionIdentifier}_SUCCESS`;

  const initialState: GenericApiStateIdle = {
    isFetching: false,
    isError: false,
    error: null,
  };

  const reducer: Reducer<GenericApiState> = function (
    state = initialState,
    action,
  ): GenericApiState {
    switch (action.type) {
      case REQUEST:
        return {
          ...initialState,
          isFetching: true,
        };
      case SUCCESS:
        return {
          isFetching: false,
          isError: false,
          error: null,
        };
      case FAILURE:
        const { error } = action;
        return {
          isFetching: false,
          isError: true,
          error,
        };
      default:
        return state;
    }
  };
  return {
    reducer,
    actionTypes: {
      REQUEST,
      SUCCESS,
      FAILURE,
    },
  };
}
