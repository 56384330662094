import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useIsFreeTrial } from "./freeTrialHooks";
import { useSubscription } from "./useSubscription";

/**
 * @typedef UserbrainFeatures
 * @type {object}
 * @property {bool} ai
 * @property {bool} automatedTesting
 * @property {bool} reports
 * @property {bool} transcripts
 * @property {bool} iyop
 * @property {bool} clips
 */

/**
 * Get the free trial
 * @returns {UserbrainFeatures|null} Returns the features
 */
export function useFeatures() {
  const subscription = useSubscription();
  const isFreeTrial = useIsFreeTrial();
  const isUser = useSelector(({ user }) => !!user.user);
  return useMemo(
    () =>
      isUser
        ? {
            ai: !!(subscription || isFreeTrial),
            automatedTesting: !!(subscription || isFreeTrial),
            reports: !!(subscription || isFreeTrial),
            clips: !!(subscription || isFreeTrial),
            transcripts: !!(subscription || isFreeTrial),
            iyop: !!(subscription || isFreeTrial),
          }
        : null,
    [subscription, isFreeTrial, isUser],
  );
}
