import { Component } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { connect } from "react-redux";

import {
  ButtonPrimary,
  ErrorMessage,
  FormField,
  Heading1,
  InputPassword,
  InputText,
  Stack,
  SuccessMessage,
} from "@/design-system";
import { LayoutCenterWithLogo } from "@/layouts/LayoutCenterWithLogo";
import { withRouter } from "../../helpers";

import styles from "./styles.module.css";

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      email: props.router.params.email ? props.router.params.email : "",
      newPassword: "",
      newPasswordConfirmation: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let hasUpdates = false;
    const stateUpdates = {};
    if (prevState.token !== nextProps.router.params.token) {
      hasUpdates = true;
      stateUpdates.token = nextProps.router.params.token;
    }
    if (hasUpdates) {
      return stateUpdates;
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.fetching === true &&
      this.props.fetching === false &&
      this.props.error === false
    ) {
      if (!this.props.signedIn) {
        this.props.router.navigate(
          "/login/" + encodeURIComponent(this.state.email),
        );
      }
    }
  }

  onNewPasswordFormSubmit = (e) => {
    e.preventDefault();
    const { email, newPassword, newPasswordConfirmation, token } = this.state;
    this.props.setNewPassword(
      email,
      newPassword,
      newPasswordConfirmation,
      token ? token : "",
    );
  };

  render() {
    const { newPassword, newPasswordConfirmation, email } = this.state;
    const { successMessage, fetching, error } = this.props;

    return (
      <LayoutCenterWithLogo>
        <div className={`${styles.form} card`}>
          <form onSubmit={this.onNewPasswordFormSubmit}>
            <Stack>
              <Heading1 className={styles["form-title"]}>
                Set a New Password
              </Heading1>
              {error?.message && (
                <ErrorMessage icon={regular("ban")} message={error.message} />
              )}
              {successMessage && (
                <SuccessMessage
                  icon={regular("check")}
                  message={successMessage}
                />
              )}
              <FormField
                label={"Email Address"}
                errorMessage={error?.fieldFeedback?.email}
              >
                <InputText
                  type={"email"}
                  placeholder={"yourname@company.com"}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={email}
                  autoFocus={!email}
                  fullWidth
                />
              </FormField>
              <FormField
                label={"Password"}
                errorMessage={error?.fieldFeedback?.password}
              >
                <InputPassword
                  autoComplete={"new-password"}
                  placeholder={"Enter a new password"}
                  autoFocus={!!email}
                  onChange={(e) =>
                    this.setState({ newPassword: e.target.value })
                  }
                  value={newPassword}
                  withShowPasswordToggle={true}
                  fullWidth
                />
              </FormField>

              <FormField
                label={"Confirm Password"}
                errorMessage={error?.fieldFeedback?.password_confirmation}
              >
                <InputPassword
                  autoComplete={"new-password"}
                  placeholder={"Repeat your new password"}
                  onChange={(e) =>
                    this.setState({
                      newPasswordConfirmation: e.target.value,
                    })
                  }
                  value={newPasswordConfirmation}
                  withShowPasswordToggle={true}
                  fullWidth
                />
              </FormField>

              <ButtonPrimary
                type={"submit"}
                fullWidth
                disabled={fetching}
                className={styles.buttonPromo}
              >
                {fetching ? "Saving…" : "Save new password"}
              </ButtonPrimary>
            </Stack>
          </form>
        </div>
      </LayoutCenterWithLogo>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: state.user.passwordNewFetching,
    error: state.user.passwordNewError,
    successMessage: state.user.passwordNewSuccessMessage,
    signedIn: state.user.signedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SIGN_OUT" }),
    setNewPassword: (email, password, passwordConfirmation, token) =>
      dispatch({
        type: "PASSWORD_NEW_REQUEST",
        email,
        password,
        passwordConfirmation,
        token,
      }),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewPassword),
);
