import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Modal2 } from "@/components/Modal";
import {
  ButtonPrimary,
  ErrorMessage,
  RadioSelect,
  RadioSelectItem,
  TextArea,
} from "@/design-system";
import ButtonGroup from "../../components/ButtonGroup";
import ModalHeader from "../../components/ModalHeader";
import {
  extractFirstFieldMessageFromApiError,
  extractMessageFromApiError,
  usePrevious,
} from "../../helpers";

import styles from "./styles.module.css";

const technicalProblems = [
  { value: "technical_audio", label: "Audio (low volume, excess noise)" },
  {
    value: "technical_video",
    label: "Video (blurry screen, recorded wrong screen)",
  },
  {
    value: "technical_website_access",
    label: "Problem with access to website, app, prototype, or survey",
  },
  { value: "technical_other", label: "None of the reasons listed" },
];

const testerProblems = [
  {
    value: "tester_not_following_task",
    label: "Instructions weren’t followed and/or rushed through",
  },
  {
    value: "tester_speech",
    label: "Speech was unclear or feedback was insufficient",
  },
  {
    value: "tester_wrong_demographics",
    label: "Demographics or screener requirements were not a match",
  },
  { value: "tester_other", label: "None of the reasons listed" },
];

export default function ReportAProblemModal({
  isActive,
  onClose,
  ...restProps
}) {
  function handleClose(reason) {
    if (reason !== "clickOutside") {
      onClose();
    }
  }

  return (
    <Modal2
      isActive={isActive}
      maxWidth={"30rem"}
      onClose={handleClose}
      content={<ReportAProblemModalContent onClose={onClose} {...restProps} />}
    />
  );
}

function ReportAProblemModalContent({ onClose, videoId }) {
  const [step, setStep] = useState(1);
  const [problemCategory, setProblemCategory] = useState(null);
  const [problemType, setProblemType] = useState(null);
  const prevProblemType = usePrevious(problemType);
  const [description, setDescription] = useState("");
  const [resolution, setResolution] = useState(null);
  const dispatch = useDispatch();
  const fetching = useSelector((state) => state.video.problemFetching);
  const prevFetching = usePrevious(fetching);
  const success = useSelector((state) => state.video.problemSuccess);
  const errorMessage = useSelector(
    (state) =>
      extractMessageFromApiError(state.video.problemError) ||
      extractFirstFieldMessageFromApiError(state.video.problemError),
  );

  const refTextareaDescription = useRef();

  const problemTypeHasDescription = true; //problemType === 'technical_other' || problemType === 'tester_other';

  useEffect(() => {
    if (prevFetching === true && fetching === false && success === true) {
      setStep(4);
    }
  }, [prevFetching, fetching, success]);

  const problemTypeOptions =
    problemCategory === "technical" ? technicalProblems : testerProblems;

  let title = "What is the main reason for this problem report?";
  if (step === 2 && problemCategory === "technical") {
    title = "Report a technical issue";
  }
  if (step === 2 && problemCategory === "tester") {
    title = "Report a problem with a tester";
  }
  if (step === 3) {
    title = "How can we resolve this problem?";
  }
  if (step === 4) {
    title = "Problem report sent successfully";
  }

  useEffect(() => {
    if (
      problemTypeHasDescription &&
      prevProblemType !== problemType &&
      !description
    ) {
      refTextareaDescription.current && refTextareaDescription.current.focus();
    }
  }, [problemTypeHasDescription, prevProblemType, problemType, description]);

  function handleClickSubmit() {
    dispatch({
      type: "VIDEO_PROBLEM_REQUEST",
      id: videoId,
      description: problemTypeHasDescription ? description : undefined,
      problemType,
      resolution,
    });
  }

  function handleChangeDescription(e) {
    setDescription(e.target.value);
  }

  function handleClickContinue1() {
    setStep(2);
  }

  function handleClickContinue2() {
    setStep(3);
  }

  function handleChangeProblemCategory(e) {
    setProblemCategory(e.target.value);
  }

  function handleChangeProblemType(e) {
    setProblemType(e.target.value);
  }

  function handleChangeResolution(e) {
    setResolution(e.target.value);
  }

  return (
    <>
      <ModalHeader heading={<>{title}</>} onClickCloseIcon={onClose} />
      <div className={styles.content}>
        {step === 1 && (
          <>
            <RadioSelect
              onChange={handleChangeProblemCategory}
              value={problemCategory}
            >
              <RadioSelectItem value={"technical"}>
                Technical Issue (Audio, Video or other)
              </RadioSelectItem>
              <RadioSelectItem value={"tester"}>Tester</RadioSelectItem>
            </RadioSelect>
            <ButtonGroup align={"right"} className={styles.buttonGroup}>
              <ButtonPrimary
                onClick={handleClickContinue1}
                disabled={problemCategory === null}
              >
                Continue
              </ButtonPrimary>
            </ButtonGroup>
          </>
        )}

        {step === 2 && (
          <>
            <RadioSelect onChange={handleChangeProblemType} value={problemType}>
              {problemTypeOptions.map((problemTypeOption) => (
                <RadioSelectItem
                  value={problemTypeOption.value}
                  key={problemTypeOption.value}
                >
                  {problemTypeOption.label}
                </RadioSelectItem>
              ))}
            </RadioSelect>
            {problemTypeHasDescription && (
              <TextArea
                fullWidth
                innerRef={refTextareaDescription}
                className={styles.textArea}
                value={description}
                onChange={handleChangeDescription}
                placeholder={"Describe your problem (required)…"}
              />
            )}
            <ButtonGroup align={"right"} className={styles.buttonGroup}>
              <ButtonPrimary
                onClick={handleClickContinue2}
                disabled={
                  (problemTypeHasDescription && !description) ||
                  problemType === null
                }
              >
                Continue
              </ButtonPrimary>
            </ButtonGroup>
          </>
        )}

        {step === 3 && (
          <>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <RadioSelect onChange={handleChangeResolution} value={resolution}>
              <RadioSelectItem value={"refund"}>
                Refund the session.
              </RadioSelectItem>
              <RadioSelectItem value={"report_only"}>
                Keep the session. Report the problem only.
              </RadioSelectItem>
            </RadioSelect>
            <ButtonGroup align={"right"} className={styles.buttonGroup}>
              <ButtonPrimary
                onClick={handleClickSubmit}
                disabled={resolution === null || fetching}
              >
                {fetching ? "Submitting…" : "Submit report"}
              </ButtonPrimary>
            </ButtonGroup>
          </>
        )}

        {step === 4 && (
          <>
            <p>
              Thank you for reporting this issue. We will get back to you
              shortly.
            </p>
            <ButtonGroup align={"right"} className={styles.buttonGroup}>
              <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
            </ButtonGroup>
          </>
        )}
      </div>
    </>
  );
}
