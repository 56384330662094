import { useEffect, useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

import { Modal2 } from "@/components/Modal";
import { ModalContent } from "@/components/ModalContent";
import ModalHeader from "@/components/ModalHeader";
import Spinner from "@/components/Spinner";
import { Avatar, AvatarGroup, PageSelector } from "@/design-system";
import {
  getTesterInitials,
  getWordingForScreenerChoice,
  pluralizer,
} from "@/helpers";

import styles from "./styles.module.css";

export default function ScreenersModal(props) {
  const { isActive, onClose, testId } = props;

  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      content={<ScreenersModalContent testId={testId} onClose={onClose} />}
    />
  );
}

function ScreenersModalContent({ onClose, testId }) {
  const dispatch = useDispatch();
  const {
    isFetching,
    isError: isApiError,
    data: screeners,
    error: apiError,
  } = useSelector((state) => state.testScreeners);
  const [currentScreenerPage, setCurrentScreenerPage] = useState(1);
  const currentScreenerIndex = currentScreenerPage - 1;
  const currentScreener = screeners?.[currentScreenerIndex];

  useEffect(() => {
    dispatch({ type: "TEST_SCREENERS_REQUEST", testId });
  }, [dispatch, testId]);

  if (isFetching) {
    return (
      <ModalContent>
        <Spinner />
      </ModalContent>
    );
  }

  return (
    <>
      {isApiError === true && apiError.message}
      {screeners?.length === 0 && <>No screeners</>}
      {screeners?.length > 0 && (
        <>
          <ModalHeader
            bigHeading
            heading={pluralizer("Screener")(screeners.length)}
            onClickCloseIcon={onClose}
          />
          <ModalContent withModalHeader={true}>
            {screeners.length > 1 && (
              <PageSelector
                className={styles.pageSelector}
                count={screeners.length}
                page={currentScreenerPage}
                onChange={setCurrentScreenerPage}
              />
            )}

            <div className={styles.questions}>
              {currentScreener.queries.map((query, index) => (
                <Query
                  key={index + query.question}
                  query={query}
                  queryId={index}
                  currentScreener={currentScreener}
                />
              ))}
            </div>
          </ModalContent>
        </>
      )}
    </>
  );
}

function getScreenerTesters(screener, queryId, answerId) {
  return screener.responses
    .filter((response) => response.query_id === queryId)
    .flatMap((response) =>
      response.answers
        .filter((answer) => answer.id === answerId)
        .map(() => ({
          id: response.tester.id,
          name: response.tester.name,
        })),
    );
}

function Query({ query, queryId, currentScreener }) {
  return (
    <>
      <h2 className={styles.questionsTitle}>{query.question}</h2>
      {query.answers.map((answer, answerId) => (
        <Answer
          key={answer.title + answerId}
          answer={answer}
          query={query}
          screener={currentScreener}
          queryId={queryId}
          answerId={answerId}
        />
      ))}
    </>
  );
}

const MAX_AVATAR_COUNT = 5;

function Answer({ answer, query, screener, queryId, answerId }) {
  const testers = getScreenerTesters(screener, queryId, answerId);

  return (
    <>
      <div className={styles.answer}>
        <FontAwesomeIcon
          className={styles.answerIcon}
          icon={
            query.type === "single_choice"
              ? regular("circle")
              : regular("square")
          }
          fixedWidth
        />
        <>{answer.title} </>
        <span className={styles.answerType}>
          ({getWordingForScreenerChoice(answer.type, query.type)})
        </span>
      </div>

      {testers.length > 0 && (
        <AvatarGroup className={styles.answerTesters}>
          {testers
            .slice(
              0,
              testers.length > MAX_AVATAR_COUNT
                ? MAX_AVATAR_COUNT - 1
                : testers.length,
            )
            .map((tester) => (
              <Avatar
                key={tester.id}
                initials={getTesterInitials(tester.name)}
                title={tester.name}
                colorSeed={tester.id}
              />
            ))}
          {testers.length > MAX_AVATAR_COUNT && (
            <Avatar
              initials={`+${testers.length - MAX_AVATAR_COUNT + 1}`}
              title={`+ ${testers.length - MAX_AVATAR_COUNT + 1} more`}
            />
          )}
        </AvatarGroup>
      )}
    </>
  );
}
