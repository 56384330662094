import { useContext, useEffect, useRef } from "react";

import { ModalContext } from "@/components/Modal/context";
import { DesignSystemContext } from "../../context";

import styles from "./styles.module.css";

export default function DesignSystemContainer({
  children,
  bodyBackground,
}: {
  children: React.ReactNode;
  bodyBackground?: string;
}) {
  const refModalContainer = useRef<HTMLDivElement>(null);

  const designSystemContext = useContext(DesignSystemContext);

  const designSystemContextValue = {
    ...designSystemContext,
  };

  const modalContext = useContext(ModalContext);

  const modalContextValue = {
    ...modalContext,
    refModalContainer,
  };

  useEffect(() => {
    if (bodyBackground) {
      document.body.style.background = bodyBackground;
    }
    return () => {
      document.body.style.background = "";
    };
  }, [bodyBackground]);

  return (
    <DesignSystemContext.Provider value={designSystemContextValue}>
      <ModalContext.Provider value={modalContextValue}>
        <div className={styles.designSystemContainer}>
          {children}
          <div ref={refModalContainer} id="modals" />
        </div>
      </ModalContext.Provider>
    </DesignSystemContext.Provider>
  );
}
