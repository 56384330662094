import {
  parseVideoClipOverviewApi,
  VideoClipOverview,
  VideoClipOverviewApi,
} from "@/entities/video/clip";
import {
  parseVideoClipTagFilterItemApi,
  VideoClipTagFilterItem,
  VideoClipTagFilterItemApi,
} from "@/entities/video/clipTagFilterItem";
import {
  parseVideoClipTaskFilterItemApi,
  VideoClipTaskFilterItem,
  VideoClipTaskFilterItemApi,
} from "@/entities/video/clipTaskFilterItem";
import {
  parseApiTesterFilterItemToLocal,
  VideoClipTesterFilterItem,
  VideoClipTesterFilterItemApi,
} from "@/entities/video/clipTesterFilterItem";

/** Non-exhaustive */
export type TestClipsApiResponseData = {
  data: VideoClipOverviewApi[];
  meta: {
    to: number;
    total: number;
    per_page: number;
    clip_count: number;
    filters: {
      teststeps: VideoClipTaskFilterItemApi[];
      testers: VideoClipTesterFilterItemApi[];
      tags: VideoClipTagFilterItemApi[];
    };
  };
};

export type TestClipsApiParsedData = {
  clips: VideoClipOverview[];
  testers: VideoClipTesterFilterItem[];
  tasks: VideoClipTaskFilterItem[];
  tags: VideoClipTagFilterItem[];
  clipsRemaining: number;
  total: number;
  unfilteredTotal: number;
  perPage: number;
  tagless: number;
};

export function parseTestClipsApi(
  responseData: TestClipsApiResponseData,
): TestClipsApiParsedData {
  const clipsRemaining = responseData.meta.total - responseData.meta.to;

  return {
    clips: responseData.data.map(parseVideoClipOverviewApi),
    testers: responseData.meta.filters.testers.map(
      parseApiTesterFilterItemToLocal,
    ),
    tasks: responseData.meta.filters.teststeps.map(
      parseVideoClipTaskFilterItemApi,
    ),
    tags: responseData.meta.filters.tags
      .filter((tag) => typeof tag.tag === "string")
      .map(parseVideoClipTagFilterItemApi)
      .sort((a, b) => a.value.localeCompare(b.value)),
    clipsRemaining: Math.max(clipsRemaining, 0),
    total: responseData.meta.total,
    unfilteredTotal: responseData.meta.clip_count,
    perPage: responseData.meta.per_page,
    tagless:
      responseData.meta.filters.tags.find(
        (tag) => (tag.tag as unknown) === false,
      )?.count || 0,
  };
}
