import { FunctionComponent, SVGProps } from "react";

import { PaymentCardBrand } from "../paymentCard";

import { ReactComponent as SvgIconAmericanExpress } from "./icon-american-express.svg";
import { ReactComponent as SvgIconDinersClub } from "./icon-diners-club.svg";
import { ReactComponent as SvgIconDiscover } from "./icon-discover.svg";
import { ReactComponent as SvgIconJcb } from "./icon-jcb.svg";
import { ReactComponent as SvgIconMasterCard } from "./icon-mastercard.svg";
import { ReactComponent as SvgIconUnionPay } from "./icon-unionpay.svg";
import { ReactComponent as SvgIconVisa } from "./icon-visa.svg";
export { ReactComponent as SvgIconUnknownPaymentCard } from "./icon-unknown-payment-card.svg";
export { ReactComponent as SvgIconLock } from "./icon-lock.svg";
export { ReactComponent as SvgIconTooltip } from "./icon-tooltip.svg";

export const svgIconsCardBrand: Record<
  PaymentCardBrand,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  visa: SvgIconVisa,
  mastercard: SvgIconMasterCard,
  amex: SvgIconAmericanExpress,
  unionpay: SvgIconUnionPay,
  discover: SvgIconDiscover,
  jcb: SvgIconJcb,
  diners: SvgIconDinersClub,
};
