import React from "react";

import { Button, ButtonPrimary } from "@/design-system";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";

import styles from "./styles.module.css";

export default function ConfirmModal({
  children,
  onClose,
  onConfirm,
  labelConfirm,
  isConfirmDisabled = false,
  isActive,
}) {
  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      maxWidth={"23rem"}
      centered
      loading={false}
      content={
        <ConfirmModalContent
          onClose={onClose}
          onClickConfirm={onConfirm}
          labelConfirm={labelConfirm}
          isConfirmDisabled={isConfirmDisabled}
        >
          {children}
        </ConfirmModalContent>
      }
    />
  );
}

function ConfirmModalContent({
  children,
  onClose,
  onClickConfirm,
  labelConfirm,
  isConfirmDisabled,
}) {
  return (
    <div className={styles.content}>
      {children}
      <ButtonGroup align={"right"} className={styles.buttonGroup}>
        <ButtonPrimary disabled={isConfirmDisabled} onClick={onClickConfirm}>
          {labelConfirm}
        </ButtonPrimary>
        <Button onClick={onClose}>Cancel</Button>
      </ButtonGroup>
    </div>
  );
}
