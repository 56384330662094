import { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";
import get from "lodash/get";
import { eventChannel } from "redux-saga";
import { all, call, put, race, take, takeEvery } from "redux-saga/effects";

import axios from "@/axios";
import {
  AUTH_LOCAL_STORAGE_KEY,
  authenticate,
  AuthToken,
  deleteAuthToken,
  parseAuthToken,
  readAuthToken,
  UserCredentials,
  writeAuthToken,
} from "@/axios/auth";
import { createReduxApiError } from "@/helpers-ts";
import { API_URL } from ".";

// AUTHENTICATION
// https://github.com/redux-saga/redux-saga/issues/14

export function* authentication(): any {
  yield all([authenticationLoop(), watchStorageEvent()]);
}

const storageEventChannel = eventChannel((emitter) => {
  window.addEventListener("storage", emitter);
  return () => window.removeEventListener("storage", emitter);
});

function* watchStorageEvent() {
  yield takeEvery(storageEventChannel, handleStorageEvent);
}

function handleStorageEvent(event: StorageEvent) {
  // Reload the page if the user has changed
  if (
    event.storageArea === window.localStorage &&
    event.key === AUTH_LOCAL_STORAGE_KEY
  ) {
    const { newValue, oldValue } = event;
    const newAuthToken = newValue !== null ? parseAuthToken(newValue) : null;
    const newAccessToken = newAuthToken?.access_token;
    const oldAuthToken = oldValue !== null ? parseAuthToken(oldValue) : null;
    const oldAccessToken = oldAuthToken?.access_token;

    let decodedNewAccessToken, decodedOldAccessToken;

    if (typeof newAccessToken !== "undefined") {
      // The new access token has a value…
      try {
        decodedNewAccessToken = jwtDecode(newAccessToken);
      } catch (e) {
        console.error("Error decoding access token (new): ", e);
      }
    }

    if (typeof oldAccessToken !== "undefined") {
      try {
        decodedOldAccessToken = jwtDecode(oldAccessToken);
      } catch (e) {
        console.error("Error decoding access token (old): ", e);
      }
    }

    if (decodedOldAccessToken?.sub !== decodedNewAccessToken?.sub) {
      window.location.reload();
    }
  }
}

function* authenticationLoop(): any {
  // Note: It is important that the first SIGN_IN action (and everything before that)
  // is synchronous, so that the user is "signed in" before the first render.
  // This is because the first render will trigger the loaders and the loaders
  // will make decisions based on the user being signed in or not.

  // At the beginning, we check if we have a token in local storage
  // and if we do, we dispatch a SIGNED_IN action
  if (readAuthToken() !== null) {
    yield put({ type: "SIGNED_IN" });
  }

  // After that, we wait for actions
  while (true) {
    const { signInAction, signOutAction, adminImpersonateSuccessAction } =
      yield race({
        signInAction: take("SIGN_IN"),
        signOutAction: take("SIGN_OUT"),
        adminImpersonateSuccessAction: take("ADMIN_IMPERSONATE_SUCCESS"),
      });

    if (signInAction) {
      const credentials: UserCredentials = {
        username:
          signInAction.credentials?.username.trim().length > 0
            ? signInAction.credentials.username
            : "invalidUsername",
        password:
          signInAction.credentials?.password.trim().length > 0
            ? signInAction.credentials.password
            : "invalidPassword",
      };
      yield call(signIn, credentials);
    }

    if (signOutAction) {
      yield call(signOut);
    }

    if (adminImpersonateSuccessAction) {
      yield put({ type: "SIGN_OUT" });
      yield call(signOut);
      let newToken: AuthToken = {
        access_token: adminImpersonateSuccessAction.accessToken,
        expires_in: 99999999999,
        refresh_token: "nono",
        token_type: "Bearer",
      };
      writeAuthToken(newToken);
      yield put({ type: "SIGNED_IN" });
    }
  }
}

function* signIn(credentials: UserCredentials): any {
  try {
    yield call(authenticate, credentials);
    yield put({ type: "SIGNED_IN" });
  } catch (error) {
    yield put({
      type: "SIGN_IN_FAILURE",
      error: createReduxApiError(error as AxiosError),
    });
    if (get(error, "response.data.error") === "invalid_role") {
      window.location.href =
        "https://tester.userbrain.com/auth/login?email=" +
        encodeURIComponent(credentials.username);
    }
  }
}

function* signOut(): any {
  try {
    yield call(fetchUserLogout);
  } catch (error) {
    // Logout failed due to invalid auth
  } finally {
    deleteAuthToken();
    yield put({ type: "SIGNED_OUT" });
  }
}

// function that makes the api request and returns a Promise for response
function fetchUserLogout() {
  return axios({
    method: "post",
    url: API_URL + "/user/logout",
  });
}
