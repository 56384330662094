import React, { useState } from "react";
import { accessibleOnClick, concatClassNames as cn } from "@system42/core";
import PropTypes from "prop-types";

import { ButtonPrimary } from "@/design-system";
import {
  formatNumber,
  formatPrice as formatPriceWithCurrency,
} from "../../helpers";

import styles from "./styles.module.css";

const BuyCreditsGrid = function (props) {
  const {
    currency,
    pricing,
    onClickBuyCredits,
    taxPercent,
    ...containerProps
  } = props;

  const [selectedQuantity, setSelectedQuantity] = useState(
    pricing.payg_packages[1].quantity,
  );

  function handleClickCreditsItem(quantity) {
    setSelectedQuantity(quantity);
  }

  function formatPrice(netPrice) {
    return formatPriceWithCurrency(netPrice, currency);
  }

  function isSelected(quantity) {
    return selectedQuantity === quantity;
  }

  const netPricePerCredit = pricing.payg / 100;

  return (
    <div {...containerProps}>
      <div className={styles.creditsGrid}>
        {pricing.payg_packages.map(({ quantity, bonus_credits }) => (
          <div
            key={quantity}
            className={cn(
              styles.creditsItem,
              isSelected(quantity) && styles.creditsItemSelected,
            )}
            {...accessibleOnClick(() => handleClickCreditsItem(quantity))}
          >
            <div className={styles.creditsItemLeft}>
              {quantity === bonus_credits && (
                <span className={styles.blackText}>
                  🎄 Holiday offer
                  <br />
                </span>
              )}
              <span className={styles.blackText}>
                {formatNumber(quantity)} credits
              </span>
              {bonus_credits > 0 && <span>+{bonus_credits} for free</span>}
              {bonus_credits === 0 && (
                <span>{formatPrice(netPricePerCredit)} per credit</span>
              )}
            </div>
            <div className={styles.creditsItemRight}>
              <span
                className={cn(
                  styles.boldBigText,
                  isSelected(quantity) && styles.blackText,
                )}
              >
                {formatPrice(netPricePerCredit * quantity)}
              </span>
              {bonus_credits > 0 && (
                <span>
                  Save {formatPrice(netPricePerCredit * bonus_credits)}
                </span>
              )}
              {bonus_credits === 0 && <span>–</span>}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.purchaseAndMoreCreditsContainer}>
        <span className={styles.needMoreCreditsText}>
          Need more? <a href={"mailto:sales@userbrain.com"}>Contact sales</a>
        </span>
        <ButtonPrimary
          type={"submit"}
          className={styles.purchaseCreditsButton}
          disabled={selectedQuantity === null}
          onClick={() => onClickBuyCredits(selectedQuantity)}
        >
          Purchase {selectedQuantity} credits
        </ButtonPrimary>
      </div>
    </div>
  );
};

BuyCreditsGrid.propTypes = {
  pricing: PropTypes.object.isRequired,
};

export default BuyCreditsGrid;
