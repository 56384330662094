import { useEffect } from "react";

import { usePrevious } from "@/helpers";
import { GenericApiState } from "@/reducers/genericApiSlice";
import { RootState, useAppSelector } from "@/store";

/**
 * Custom hook that triggers a callback function when a generic API request succeeds.
 * @param reducerName - The name of the reducer in the RootState.
 * @param onSuccess - The callback function to be executed on success.
 */
export function useGenericApiOnSuccess(
  reducerName: keyof RootState,
  onSuccess: () => void,
) {
  const { isFetching, isError } = useAppSelector(
    (state) => state[reducerName],
  ) as GenericApiState<any>;
  const prevIsFetching = usePrevious(isFetching);

  useEffect(() => {
    if (isFetching === false && prevIsFetching === true && !isError) {
      onSuccess();
    }
  }, [isFetching, prevIsFetching, isError, onSuccess]);
}
