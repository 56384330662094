import { IconTextMessageWithButton } from "@/components/IconTextMessageWithButton";
import { SvgIconLocked } from "@/containers/Video/icons";

export default function TranscriptPromotion({
  onClickUpgrade,
}: {
  onClickUpgrade: () => void;
}) {
  return (
    <IconTextMessageWithButton
      SvgIcon={SvgIconLocked}
      buttonText={`Upgrade now`}
      onClick={onClickUpgrade}
    >
      Automatic transcripts are available with one of our monthly & yearly
      plans.
    </IconTextMessageWithButton>
  );
}
