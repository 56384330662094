import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  ReactNode,
  useRef,
  useState,
} from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import isUndefined from "lodash/isUndefined";
import { useDispatch } from "react-redux";

import { HHMMSSToSeconds, toHHMMSS } from "@/helpers-ts";
import { SvgIconReplay } from "../icons";
import { NoteOrClipPrototypeProps } from "../types";
import { validateNotePrototypeTimestamps } from "../utils/validateNotePrototypeTimestamps";

import editTimestampStyles from "./editTimestamp.module.css";
import styles from "./styles.module.css";

export type NoteOrClipControlProps = {
  contentInputProps: Omit<ContentTextareaProps, "value" | "isClip">;
  refContentInput: React.Ref<HTMLTextAreaElement>;
  noteOrClip: NoteOrClipPrototypeProps;
  onChangeNoteOrClip: (note: NoteOrClipPrototypeProps) => void;
  videoDuration: number;
  currentVideoSecond: number;
  onClickReplay: TimestampsControlsProps["onClickReplay"];
  isShowReplayButton: boolean;
};

/**
 *
 * Note or Clip Control
 */
export function NoteOrClipControl({
  contentInputProps,
  refContentInput,
  noteOrClip,
  onChangeNoteOrClip,
  videoDuration,
  currentVideoSecond,
  onClickReplay,
  isShowReplayButton,
}: NoteOrClipControlProps) {
  const dispatch = useDispatch();

  const isClip = noteOrClip.type === "clip";
  const isTimestampStartDefined = !isUndefined(noteOrClip.timestampStart);
  const isTimestampEndDefined = !isUndefined(noteOrClip.timestampEnd);

  // Call onChange only if timestamps are valid.
  // If not valid, notify user by dispatching
  // the validation errorMessage to store snackbar.
  function callOnChangeIfValid(
    props: Partial<
      Pick<NoteOrClipPrototypeProps, "timestampStart" | "timestampEnd">
    >,
  ) {
    const validation = validateNotePrototypeTimestamps({
      videoDuration: videoDuration,
      timestampEnd: noteOrClip.timestampEnd,
      timestampStart: noteOrClip.timestampStart,
      ...props,
      // If timestampStart is being modified and there is an error,
      // the message's subject should be it, and vice-versa.
      errorMessageSubject: isUndefined(props.timestampStart) ? "end" : "start",
    });

    if (!validation.success) {
      // Not all validation failures have error messages
      // because some are deemed too obvious.
      validation.errorMessage &&
        dispatch({
          type: "SNACKBAR_ADD",
          notificationType: "error",
          content: validation.errorMessage,
        });
    } else {
      onChangeNoteOrClip({
        ...noteOrClip,
        ...props,
      });
    }
  }

  function handleClickClearTimestampStart() {
    onChangeNoteOrClip({
      ...noteOrClip,
      timestampStart: undefined,
    });
  }

  function handleChangeTimestampStart(timestampStart?: number) {
    callOnChangeIfValid({ timestampStart });
  }

  function handleClickTimestampStart() {
    if (noteOrClip.timestampStart === undefined) {
      const newTimestampStart =
        noteOrClip.timestampEnd !== undefined &&
        currentVideoSecond >= noteOrClip.timestampEnd
          ? noteOrClip.timestampEnd - 1
          : currentVideoSecond;

      callOnChangeIfValid({ timestampStart: newTimestampStart });
    }
  }

  function handleClickClearTimestampEnd() {
    onChangeNoteOrClip({
      ...noteOrClip,
      timestampEnd: undefined,
    });
  }

  function handleChangeTimestampEnd(timestampEnd?: number) {
    callOnChangeIfValid({ timestampEnd });
  }

  function handleClickTimestampEnd() {
    if (noteOrClip.timestampEnd === undefined) {
      const newTimestampEnd =
        noteOrClip.timestampStart !== undefined &&
        currentVideoSecond <= noteOrClip.timestampStart
          ? noteOrClip.timestampStart + 1
          : currentVideoSecond;

      callOnChangeIfValid({ timestampEnd: newTimestampEnd });
    }
  }

  return (
    <div className={styles.noteControl}>
      <ContentInput
        ref={refContentInput}
        isClip={isClip}
        value={noteOrClip.content}
        {...contentInputProps}
      />
      <TimestampsControls
        timestampStartProps={{
          labelProps: {
            value: "Start",
            title: "Set current time as start timestamp",
            isShow: isClip,
            onClick: handleClickTimestampStart,
            // Label (which is a button) is only shown
            // if the user needs to set a timestamp manually.
            isDisabled: isTimestampStartDefined,
          },
          inputProps: {
            isShow: true,
            isDisabled: !isTimestampStartDefined,
            value: noteOrClip.timestampStart ?? currentVideoSecond,
            onChange: handleChangeTimestampStart,
            onClick: handleClickTimestampStart,
          },
          clearButtonProps: {
            isShow: isTimestampStartDefined,
            onClick: handleClickClearTimestampStart,
          },
        }}
        isShowTimestampEnd={isClip}
        timestampEndProps={{
          labelProps: {
            value: "End",
            title: "Set current time as end timestamp",
            isShow: true,
            onClick: handleClickTimestampEnd,
            isDisabled: isTimestampEndDefined,
          },
          inputProps: {
            isShow: isTimestampEndDefined,
            isDisabled: !isTimestampEndDefined,
            value: noteOrClip.timestampEnd,
            onChange: handleChangeTimestampEnd,
            onClick: handleClickTimestampEnd,
          },
          clearButtonProps: {
            isShow: isTimestampEndDefined,
            onClick: handleClickClearTimestampEnd,
          },
        }}
        isShowReplayButton={isShowReplayButton && isTimestampStartDefined}
        onClickReplay={onClickReplay}
      />
    </div>
  );
}

/**
 *
 * Stack
 */
export function Stack({
  children,
  direction = "row",
  marginBottom,
  justify = "space-between",
  gap,
  fillWidth = true,
}: {
  children: ReactNode;
  direction?: "row" | "column";
  marginBottom?: string;
  justify?: "space-between" | "center";
  gap?: string;
  fillWidth?: boolean;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: direction,
        justifyContent: justify,
        gap: gap,
        marginBottom: marginBottom,
        width: fillWidth ? "100%" : "",
      }}
    >
      {children}
    </div>
  );
}

type PlayerControlsProps = {
  inputProps?: TimestampInputProps;
  baseControlProps?: {
    isPlaying: boolean;
    onClickTogglePlay: () => void;
    onClickGoToStart: () => void;
  };
  onClickClear?: () => void;
  onMouseLeave?: () => void;
};

/**
 *
 * Player Controls
 */
export function PlayerControls({
  inputProps,
  baseControlProps,
  onClickClear,
  onMouseLeave,
}: PlayerControlsProps) {
  return (
    <div className={styles.playerControlsWrapper} onMouseLeave={onMouseLeave}>
      <div className={styles.playerControls}>
        {inputProps && (
          <div onClick={inputProps.onClick}>
            <TimestampInput {...inputProps} />
          </div>
        )}
        {baseControlProps && inputProps && <VerticalDivider />}
        {baseControlProps && (
          <div className={styles.basePlayerControls}>
            <button onClick={baseControlProps.onClickTogglePlay}>
              <FontAwesomeIcon
                icon={
                  baseControlProps.isPlaying ? solid("pause") : solid("play")
                }
                fixedWidth
              />
            </button>
            <button onClick={baseControlProps.onClickGoToStart}>
              <FontAwesomeIcon icon={solid("step-backward")} fixedWidth />
            </button>
            {onClickClear && (
              <>
                <VerticalDivider />
                <button onClick={onClickClear}>
                  <FontAwesomeIcon icon={solid("clock")} fixedWidth />
                  Clear
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

/**
 *
 * Vertical Divider
 */
function VerticalDivider() {
  return <div className={styles.verticalDivider} />;
}

type ContentTextareaProps = {
  isDisabled: boolean;
  value: string;
  onChange: (value: string) => void;
  onEnter?: () => void;
  onEscape?: () => void;
  onFocus?: () => void;
  isClip: boolean;
};

/**
 *
 * Input Content
 */
const ContentInput = forwardRef<HTMLTextAreaElement, ContentTextareaProps>(
  (
    { value, onChange, onEnter, onEscape, isClip, isDisabled, onFocus },
    ref,
  ) => {
    function handleKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
      if (e.key === "Enter" && e.shiftKey === false) {
        e.preventDefault();
        onEnter?.();
      } else if (e.key === "Escape") {
        e.preventDefault();
        onEscape?.();
      }
    }

    return (
      <textarea
        disabled={isDisabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
        ref={ref}
        className={styles.inputContent}
        placeholder={`Type and use #tags (add blank ${
          isClip ? "clips" : "notes"
        } as markers)`}
        onKeyDown={handleKeyDown}
      />
    );
  },
);

type TimestampInputProps = {
  value?: number;
  onChange: (value: number) => void;
  isDisabled: boolean;
  onClick: () => void;
};

/**
 *
 * Input Timestamp
 */
function TimestampInput({
  value,
  onChange,
  onClick,
  isDisabled,
}: TimestampInputProps) {
  const [localValue, setLocalValue] = useState("");
  const refInput = useRef<HTMLInputElement>(null);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    if (/^[0-9:]*$/.test(value) && value.length <= 7) {
      setLocalValue(value);
    }
  }

  function handleBlur() {
    if (localValue) {
      onChange(HHMMSSToSeconds(localValue ?? ""));
      setLocalValue("");
    }
  }

  function arrowKeyAdjust({
    isUp,
    amount = 1,
  }: {
    isUp?: boolean;
    amount?: number;
  }) {
    const currentValue =
      !localValue && value ? value : HHMMSSToSeconds(localValue ?? "");
    const newValue = currentValue + amount * (isUp ? 1 : -1);
    if (newValue >= 0) {
      setLocalValue(toHHMMSS(newValue));
    }
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      refInput.current?.blur();
    } else if (e.key === "Escape") {
      e.preventDefault();
      refInput.current?.blur();
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      arrowKeyAdjust({
        isUp: e.key === "ArrowUp" ? true : false,
        amount: e.shiftKey ? 5 : 1,
      });
    }
  }

  const inputValue = localValue || toHHMMSS(value);

  return (
    <div onClick={onClick}>
      <input
        type="text"
        ref={refInput}
        className={styles.inputTimestamp}
        onChange={handleChange}
        onBlur={handleBlur}
        value={inputValue}
        size={inputValue.length}
        onKeyDown={handleKeyDown}
        disabled={isDisabled}
        autoFocus={!isDisabled}
      />
    </div>
  );
}

type LabeledTimestampInputAndClearButtonProps = {
  labelProps: {
    value: string;
    title: string;
    isShow: boolean;
    onClick: () => void;
    isDisabled: boolean;
  };
  clearButtonProps: {
    isShow: boolean;
    onClick: () => void;
  };
  inputProps: TimestampInputProps & {
    isShow: boolean;
  };
};

/**
 *
 * Edit Timestamp
 */
function LabeledTimestampInputAndClearButton({
  labelProps,
  clearButtonProps,
  inputProps,
}: LabeledTimestampInputAndClearButtonProps) {
  return (
    <div className={cn(editTimestampStyles.editTimestamp)}>
      {labelProps.isShow && (
        <button
          disabled={labelProps.isDisabled}
          className={editTimestampStyles.labelButton}
          onClick={labelProps.onClick}
          title={labelProps.title}
        >
          {labelProps.value}
        </button>
      )}
      {inputProps.isShow && <TimestampInput {...inputProps} />}
      {clearButtonProps.isShow && (
        <button
          className={editTimestampStyles.clearButton}
          onClick={clearButtonProps.onClick}
        >
          Clear
        </button>
      )}
    </div>
  );
}

type TimestampsControlsProps = {
  timestampStartProps: Omit<LabeledTimestampInputAndClearButtonProps, "label">;
  timestampEndProps: Omit<LabeledTimestampInputAndClearButtonProps, "label">;
  isShowTimestampEnd: boolean;
  isShowReplayButton: boolean;
  onClickReplay: () => void;
};

/**
 *
 * Timestamps Controls
 *
 * Combination of LabeledTimestampInputAndClearButton for start and end timestamps,
 * and a replay button.
 */
export function TimestampsControls({
  timestampStartProps,
  isShowTimestampEnd,
  timestampEndProps,
  isShowReplayButton,
  onClickReplay,
}: TimestampsControlsProps) {
  return (
    <div className={styles.timestampControls}>
      <LabeledTimestampInputAndClearButton {...timestampStartProps} />
      {isShowTimestampEnd && (
        <LabeledTimestampInputAndClearButton {...timestampEndProps} />
      )}
      {isShowReplayButton && (
        <button
          onClick={onClickReplay}
          className={styles.timestampControlsReplayButton}
        >
          <SvgIconReplay /> Replay
        </button>
      )}
    </div>
  );
}
