import { concatClassNames as cn } from "@system42/core";

import styles from "./styles.module.css";

export function CardBrandIcon({
  className,
  SvgIcon,
}: {
  className?: string;
  SvgIcon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}) {
  return (
    <div className={cn(styles.cardBrandIcon, className)}>
      <SvgIcon />
    </div>
  );
}
