import { Question } from "../types";

export function updateQuestionType(
  question: Question,
  newQuestionType: Question["type"],
) {
  const answers =
    question.answers &&
    question.answers.map((answer) => {
      // Convert answers
      const oldQuestionType = question.type;
      if (newQuestionType !== oldQuestionType) {
        if (newQuestionType === "single_choice") {
          // Apply reject
          if (answer.typeMultipleChoice === "reject") {
            return { ...answer, typeSingleChoice: "reject" };
          }
          // Apply accept
          if (
            answer.typeMultipleChoice === "may_select" ||
            answer.typeMultipleChoice === "must_select"
          ) {
            return { ...answer, typeSingleChoice: "accept" };
          }
        }
        if (newQuestionType === "multiple_choice") {
          // Apply reject
          if (answer.typeSingleChoice === "reject") {
            return { ...answer, typeMultipleChoice: "reject" };
          }
          // Apply accept
          if (
            answer.typeSingleChoice === "accept" &&
            answer.typeMultipleChoice === "reject"
          ) {
            return { ...answer, typeMultipleChoice: "may_select" };
          }
          // Apply accept
          if (
            answer.typeSingleChoice === "accept" &&
            answer.typeMultipleChoice === "accept"
          ) {
            return { ...answer, typeMultipleChoice: "may_select" };
          }
        }
      }
      return answer;
    });

  return {
    ...question,
    type: newQuestionType,
    answers,
  };
}
