import { concatClassNames as cn } from "@system42/core";

import { TestTabCardContentLocked } from "../TestTabCardContent/Locked";

import styles from "./styles.module.css"; // Styles are imported twice (here and in index.js)

import imgEmptyState from "./empty-state.svg";

export function TestResultsLocked(props) {
  const { className, onClickExplorePlans, ...containerProps } = props;

  return (
    <div className={cn(className, styles.testResults)} {...containerProps}>
      <div
        className={cn(
          styles.taskResultContainer,
          styles.taskResultContainer_withPadding,
          "card",
        )}
      >
        <TestTabCardContentLocked
          imageSrc={imgEmptyState}
          imageAlt={"Man speaking into microphone"}
          title={"Want test reports?"}
          text={
            <>
              See all your test results compiled and illustrated with beautiful
              graphs ready to share with your team&nbsp;and&nbsp;stakeholders.{" "}
              <a href="https://help.userbrain.com/help/how-userbrain-s-reporting-feature-works">
                Learn&nbsp;more
              </a>
            </>
          }
          onClickExplorePlans={onClickExplorePlans}
        />
      </div>
    </div>
  );
}
