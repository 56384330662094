import { z } from "zod";

import { transformVideoAwsUrls } from "@/helpers";
import { UrlOrFalseSchema } from "../common";

//
// Zod schemas
//

const VideoTypeSchema = z.union([
  z.literal("userbrain"),
  z.literal("wistia"), // Not really used anymore
]);

const TestStepSchema = z.object({
  id: z.number(),
  type: z.string(),
  task: z.string(),
  options: z.union([z.record(z.unknown()), z.array(z.unknown())]),
});

const ResponseSchema = z.object({
  teststep_id: z.number(),
  response: z.record(z.unknown()).nullable(),
  timestamp_completed: z.number(),
});

export const VideoSharedApiSchema = z.object({
  test_url: z.string(),
  title: z.string(),
  type: VideoTypeSchema,
  location: UrlOrFalseSchema,
  duration: z.number(),
  device: z.object({
    type: z.string(),
    details: z.string(),
    os: z.string(),
  }),
  task: z.array(TestStepSchema),
  responses: z.array(ResponseSchema),
  preview: z
    .object({
      vtt: z.string().nullable(),
      image: z.string().nullable(),
    })
    .optional(),
  poster: UrlOrFalseSchema.optional(),
  sources: z
    .array(
      z.object({
        resolution: z.number(),
        location: UrlOrFalseSchema,
      }),
    )
    .optional(),
  signature: z.object({
    policy: z.string().nullable(),
    signature: z.string().nullable(),
    key_pair_id: z.string().nullable(),
  }),
  transcript_vtt_url: z.string().url().optional(),
  transcript: z.union([
    z.array(
      z.object({
        start: z.number(),
        end: z.number(),
        transcript: z.string().nullable(),
      }),
    ),
    z.literal(false),
  ]),
});

export const VideoSharedAuthenticatedApiSchema = VideoSharedApiSchema.extend({
  id: z.number(),
});

//
// Types
//

// Api (parsed)
export type VideoSharedApi = z.infer<typeof VideoSharedApiSchema>;
export type VideoSharedAuthenticatedApi = z.infer<
  typeof VideoSharedAuthenticatedApiSchema
>;

// Local
export type VideoShared = {
  id?: number;
  testUrl: string;
  title: string;
  type: string;
  duration: number;
  location: string | null;
  preview?: {
    vtt: string;
    image: string;
  };
  poster: string | null;
  sources?: string[];
  transcriptVttUrl?: string;
  transcript: unknown[];
  task: unknown[];
  responses: unknown[];
};

//
// parsing and mapping functions
//

export function parseVideoSharedApi(
  apiResponseData: VideoSharedApi | VideoSharedAuthenticatedApi,
): VideoShared {
  // XXX: Hmm not sure if there's a better way to do this
  let parsedApiData;
  // If the id is present, it's an authenticated video
  // could be null or undefined
  if ((apiResponseData as VideoSharedAuthenticatedApi).id == null) {
    parsedApiData = VideoSharedApiSchema.parse(
      transformVideoAwsUrls(apiResponseData),
    );
  } else {
    parsedApiData = VideoSharedAuthenticatedApiSchema.parse(
      transformVideoAwsUrls(apiResponseData),
    );
  }

  const {
    test_url,
    title,
    type,
    duration,
    location,
    preview: previewApi,
    poster,
    transcript_vtt_url,
    transcript,
    task,
    responses,
  } = parsedApiData;
  const id = (apiResponseData as VideoSharedAuthenticatedApi).id as
    | number
    | undefined;

  let preview;
  if (previewApi?.image && previewApi?.vtt) {
    preview = {
      image: previewApi.image,
      vtt: previewApi.vtt,
    };
  }

  const video: VideoShared = {
    id,
    testUrl: test_url,
    title,
    type,
    duration,
    location: location || null,
    preview,
    poster: poster || null,
    transcriptVttUrl: transcript_vtt_url,
    transcript: transcript || [],
    task: task || [],
    responses: responses || [],
  };

  return video;
}
