import React, { Component } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import upperFirst from "lodash/upperFirst";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import {
  ButtonLink,
  ButtonPrimary,
  Card,
  DropDownDialog,
  Heading2,
  MainNavigation,
  MainNavigationContent,
  RadioGroup,
  RadioGroupItem,
  SubNavigation,
  SubNavigationContent,
  Tooltip,
} from "@/design-system";
import InviteTeamMemberModal from "../../components/InviteTeamMemberModal";
import RemoveTeamMemberModal from "../../components/RemoveTeamMemberModal";
import ResendInviteModal from "../../components/ResendInviteModal";
import RevokeInviteModal from "../../components/RevokeInviteModal";
import Spinner from "../../components/Spinner";
import { UsageBoxTeamMembers } from "../../components/UsageBoxTeamMembers";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

const initialInviteTeamMemberModalEmail = "";
const initialInviteTeamMemberModalRole = "collaborator";
const initialInviteTeamMemberModalMessage = "";

class AccountTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteTeamMemberModalActive: false,
      revokeInviteModalActive: false,
      revokeInviteModalMember: null,
      resendInviteModalActive: false,
      resendInviteModalMember: null,
      removeTeamMemberModalActive: false,
      removeTeamMemberModalMember: null,
      lastChangedRoleMemberId: null,
      inviteTeamMemberModalEmail: initialInviteTeamMemberModalEmail,
      inviteTeamMemberModalRole: initialInviteTeamMemberModalRole,
      inviteTeamMemberModalMessage: initialInviteTeamMemberModalMessage,
    };
    props.loadMembers();
  }

  handleChangeRole = (id, role) => {
    this.props.updateMember(id, role);
    this.setState({ lastChangedRoleMemberId: id });
  };

  handleClickAddTeamMember = () => {
    this.props.resetCreateMember();
    this.setState({ inviteTeamMemberModalActive: true });
  };

  handleClickRemoveTeamMember = (member) => {
    this.setState({
      removeTeamMemberModalActive: true,
      removeTeamMemberModalMember: member,
    });
  };

  handleClickSendInvitation = (email, role, message) => {
    this.props.createMember(email, role, message);
  };

  handleClickResendInvitation = (member) => {
    this.setState({
      resendInviteModalActive: true,
      resendInviteModalMember: member,
    });
  };

  handleClickRevokeInvitation = (member) => {
    this.setState({
      revokeInviteModalActive: true,
      revokeInviteModalMember: member,
    });
  };

  handleRevokeInvitation = (member) => {
    this.props.deleteMember(member.id);
  };

  handleRemoveTeamMember = (member) => {
    this.props.deleteMember(member.id);
  };

  handleResendInvitation = (member) => {
    this.props.resendMember(member.id);
  };

  handleCloseInviteTeamMemberModal = () => {
    this.setState({ inviteTeamMemberModalActive: false });
  };

  handleCloseRevokeInviteModal = () => {
    this.setState({ revokeInviteModalActive: false });
  };

  handleCloseResendInviteModal = () => {
    this.setState({ resendInviteModalActive: false });
  };

  handleCloseRemoveTeamMemberModal = () => {
    this.setState({ removeTeamMemberModalActive: false });
  };

  handleChangeInviteTeamMemberModalEmail = (e) => {
    this.setState({ inviteTeamMemberModalEmail: e.target.value });
  };

  handleChangeInviteTeamMemberModalRole = (e) => {
    this.setState({ inviteTeamMemberModalRole: e.target.value });
  };

  handleChangeInviteTeamMemberModalMessage = (e) => {
    this.setState({ inviteTeamMemberModalMessage: e.target.value });
  };

  handleInviteTeamMemberSuccess = () => {
    this.setState({
      inviteTeamMemberModalEmail: initialInviteTeamMemberModalEmail,
      inviteTeamMemberModalRole: initialInviteTeamMemberModalRole,
      inviteTeamMemberModalMessage: initialInviteTeamMemberModalMessage,
    });
  };

  render() {
    const {
      inviteTeamMemberModalActive,
      revokeInviteModalActive,
      revokeInviteModalMember,
      resendInviteModalActive,
      resendInviteModalMember,
      removeTeamMemberModalActive,
      removeTeamMemberModalMember,
      lastChangedRoleMemberId,
    } = this.state;

    const { membersUpdateFetching } = this.props;

    const {
      teamMembers,
      invitations,
      admins,
      collaborators,
      collaboratorInvitations,
      adminInvitations,
    } = {
      admins:
        this.props.members?.filter(
          (member) =>
            member.invitation_accepted === true && member.role === "admin",
        ) ?? [],
      collaborators:
        this.props.members?.filter(
          (member) =>
            member.invitation_accepted === true &&
            member.role === "collaborator",
        ) ?? [],
      teamMembers:
        this.props.members?.filter(
          (member) => member.invitation_accepted === true,
        ) ?? [],
      /*[
        { email: 'blubblub@gmail.com', role: 'admin' },
        { email: 'josef.harler@gmail.com', role: 'admin' },
        { email: 'neuland@hotmail.com', role: 'admin' }
      ],*/
      invitations:
        this.props.members?.filter(
          (member) => member.invitation_accepted !== true,
        ) ?? [],
      /*[
        { email: 'hannes@gmail.com', role: 'admin' },
        { email: 'supergau@gmail.com', role: 'admin' },
      ]*/
      collaboratorInvitations:
        this.props.members?.filter(
          (member) =>
            member.invitation_accepted !== true &&
            member.role === "collaborator",
        ) ?? [],
      adminInvitations:
        this.props.members?.filter(
          (member) =>
            member.invitation_accepted !== true && member.role === "admin",
        ) ?? [],
    };

    const maxCollaborators = this.props.user?.features.limit_collaborators;
    const usedCollaborators = Math.min(
      collaborators.length + collaboratorInvitations.length,
      maxCollaborators,
    );
    const maxAdmins = this.props.user?.features.limit_admins;
    const usedAdmins = Math.min(
      admins.length + adminInvitations.length,
      maxAdmins,
    );

    return (
      <>
        <Helmet>
          <title>Team Members | Userbrain</title>
        </Helmet>
        <MainNavigation>
          <Navigation />
          <MainNavigationContent>
            <Notifications />
            <SubNavigation>
              <SubNavigationAccount currentNavItem={"team"} />
              <SubNavigationContent>
                <div className={styles.content}>
                  <UsageBoxTeamMembers
                    usedCollaborators={usedCollaborators}
                    maxCollaborators={maxCollaborators}
                    usedAdmins={usedAdmins}
                    maxAdmins={maxAdmins}
                  />

                  {this.props.members === null || this.props.user === null ? (
                    <Spinner />
                  ) : (
                    <>
                      <Card>
                        <div className={styles.formContent}>
                          <Heading2 className={styles.cardHeading1}>
                            Team Members
                          </Heading2>

                          <div className={styles.teamMembers}>
                            <div className={styles.teamMember}>
                              <div
                                className={styles.teamMemberEmail}
                                title={`${this.props.user?.subscription_owner}`}
                              >
                                {this.props.user?.subscription_owner}
                              </div>
                              <div className={styles.accountOwnerTag}>
                                <span className={styles.accountOwnerLabel}>
                                  Account Owner
                                  {this.props.user?.email ===
                                    this.props.user?.subscription_owner && (
                                    <> (me)</>
                                  )}
                                  &nbsp;
                                </span>
                                <Tooltip
                                  content={
                                    <>
                                      Only the account owner can:
                                      <ul
                                        className={styles.accountOwnerCanList}
                                      >
                                        <li>
                                          Update billing information and payment
                                          card details
                                        </li>
                                        <li>Cancel current subscription</li>
                                      </ul>
                                    </>
                                  }
                                />
                              </div>
                            </div>

                            {teamMembers.map((teamMember) => (
                              <div
                                className={styles.teamMember}
                                key={teamMember.id}
                              >
                                <div
                                  className={styles.teamMemberEmail}
                                  title={teamMember.invitation_email}
                                >
                                  {teamMember.invitation_email}
                                </div>
                                {this.props.user?.email ===
                                teamMember.invitation_email ? (
                                  <div className={styles.accountOwnerLabel}>
                                    {upperFirst(teamMember.role)}
                                    {this.props.user?.email ===
                                      teamMember.invitation_email && <> (me)</>}
                                  </div>
                                ) : (
                                  <DropDownDialog
                                    className={styles.dropDownRole}
                                    label={
                                      lastChangedRoleMemberId ===
                                        teamMember.id &&
                                      membersUpdateFetching ? (
                                        <>Saving…</>
                                      ) : teamMember.active ? (
                                        upperFirst(teamMember.role)
                                      ) : (
                                        <span className={styles.overLimit}>
                                          Over limit
                                        </span>
                                      )
                                    }
                                    align={"left"}
                                    appearance={"select"}
                                    closeOnClick={true}
                                  >
                                    <div className={styles.dropDownRoleContent}>
                                      <RadioGroup
                                        className={styles.radioGroupRoles}
                                        value={teamMember.role}
                                        disabled={true}
                                        onChange={(e) =>
                                          this.handleChangeRole(
                                            teamMember.id,
                                            e.target.value,
                                          )
                                        }
                                      >
                                        <RadioGroupItem
                                          value={"collaborator"}
                                          disabled={
                                            usedCollaborators >=
                                            maxCollaborators
                                          }
                                        >
                                          Collaborator{" "}
                                          <span className={styles.usage}>
                                            ({usedCollaborators} of{" "}
                                            {maxCollaborators} used)
                                          </span>
                                          <div
                                            className={styles.roleDescription}
                                          >
                                            Can create tests, spend credits and
                                            watch, comment, share or export
                                            videos.
                                          </div>
                                        </RadioGroupItem>
                                        <RadioGroupItem
                                          value={"admin"}
                                          disabled={usedAdmins >= maxAdmins}
                                        >
                                          Admin{" "}
                                          <span className={styles.usage}>
                                            ({usedAdmins} of {maxAdmins} used)
                                          </span>
                                          <div
                                            className={styles.roleDescription}
                                          >
                                            Can also purchase credits and add
                                            team members or change roles.
                                          </div>
                                        </RadioGroupItem>
                                      </RadioGroup>
                                      <hr />
                                      <button
                                        className={styles.teamMemberRemove}
                                        onClick={() =>
                                          this.handleClickRemoveTeamMember(
                                            teamMember,
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          className={
                                            styles.teamMemberRemoveIcon
                                          }
                                          icon={solid("trash")}
                                          fixedWidth
                                        />{" "}
                                        Remove
                                      </button>
                                    </div>
                                  </DropDownDialog>
                                )}
                              </div>
                            ))}
                          </div>

                          <ButtonPrimary
                            onClick={this.handleClickAddTeamMember}
                            className={styles.addTeamMember}
                          >
                            Add Team Member
                          </ButtonPrimary>
                        </div>
                      </Card>

                      <Card>
                        <div className={styles.formContent}>
                          <Heading2 className={styles.headingInvites}>
                            Pending invites
                          </Heading2>
                          {invitations.length > 0 ? (
                            <div className={styles.invitations}>
                              {invitations.map((invitation) => (
                                <div
                                  className={styles.invitation}
                                  key={invitation.id}
                                >
                                  {invitation.invitation_email}{" "}
                                  <span className={styles.invitationRole}>
                                    {upperFirst(invitation.role)}
                                  </span>
                                  <div className={styles.invitationActions}>
                                    <ButtonLink
                                      onClick={() =>
                                        this.handleClickResendInvitation(
                                          invitation,
                                        )
                                      }
                                    >
                                      Resend invite
                                    </ButtonLink>
                                    <ButtonLink
                                      onClick={() =>
                                        this.handleClickRevokeInvitation(
                                          invitation,
                                        )
                                      }
                                    >
                                      Revoke invite
                                    </ButtonLink>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className={styles.invitationsEmptyState}>
                              No pending invitations.
                            </div>
                          )}
                        </div>
                      </Card>
                    </>
                  )}
                </div>
              </SubNavigationContent>
            </SubNavigation>
          </MainNavigationContent>
        </MainNavigation>
        <InviteTeamMemberModal
          isActive={inviteTeamMemberModalActive}
          onClose={this.handleCloseInviteTeamMemberModal}
          fetching={this.props.membersCreateFetching}
          error={this.props.membersCreateError}
          token={this.props.membersCreateToken}
          onSendInvitation={this.handleClickSendInvitation}
          onSuccess={this.handleInviteTeamMemberSuccess}
          errorMessage={this.props.membersCreateErrorMessage}
          fieldFeedback={this.props.membersCreateFieldFeedback}
          onChangeEmail={this.handleChangeInviteTeamMemberModalEmail}
          onChangeRole={this.handleChangeInviteTeamMemberModalRole}
          onChangeMessage={this.handleChangeInviteTeamMemberModalMessage}
          email={this.state.inviteTeamMemberModalEmail}
          role={this.state.inviteTeamMemberModalRole}
          message={this.state.inviteTeamMemberModalMessage}
          usedCollaborators={usedCollaborators}
          maxCollaborators={maxCollaborators}
          usedAdmins={usedAdmins}
          maxAdmins={maxAdmins}
        />
        <RevokeInviteModal
          isActive={revokeInviteModalActive}
          member={revokeInviteModalMember}
          onClose={this.handleCloseRevokeInviteModal}
          onRevoke={this.handleRevokeInvitation}
          fetching={this.props.membersDeleteFetching}
          error={this.props.membersDeleteError}
          errorMessage={this.props.membersDeleteErrorMessage}
        />
        <ResendInviteModal
          isActive={resendInviteModalActive}
          member={resendInviteModalMember}
          onClose={this.handleCloseResendInviteModal}
          onResend={this.handleResendInvitation}
          fetching={this.props.membersResendFetching}
          error={this.props.membersResendError}
          errorMessage={this.props.membersResendErrorMessage}
        />
        <RemoveTeamMemberModal
          isActive={removeTeamMemberModalActive}
          member={removeTeamMemberModalMember}
          onClose={this.handleCloseRemoveTeamMemberModal}
          onRemove={this.handleRemoveTeamMember}
          fetching={this.props.membersDeleteFetching}
          error={this.props.membersDeleteError}
          errorMessage={this.props.membersDeleteErrorMessage}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    members: state.members.members,
    membersFetching: state.members.fetching,
    membersCreateFetching: state.members.createFetching,
    membersCreateError: state.members.createError,
    membersCreateToken: state.members.createToken,
    membersCreateErrorMessage: state.members.createErrorMessage,
    membersCreateFieldFeedback: state.members.createFieldFeedback,
    membersDeleteFetching: state.members.deleteFetching,
    membersDeleteError: state.members.deleteError,
    membersDeleteErrorMessage: state.members.deleteErrorMessage,
    membersResendFetching: state.members.resendFetching,
    membersResendError: state.members.resendError,
    membersResendErrorMessage: state.members.resendErrorMessage,
    membersUpdateFetching: state.members.updateFetching,
    membersUpdateError: state.members.updateError,
    membersUpdateErrorMessage: state.members.updateErrorMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadMembers: () => dispatch({ type: "MEMBERS_REQUEST" }),
    resetCreateMember: () => dispatch({ type: "MEMBER_CREATE_RESET" }),
    createMember: (email, role, message) =>
      dispatch({ type: "MEMBER_CREATE_REQUEST", email, role, message }),
    resendMember: (id) => dispatch({ type: "MEMBER_RESEND_REQUEST", id }),
    deleteMember: (id) => dispatch({ type: "MEMBER_DELETE_REQUEST", id }),
    updateMember: (id, role) =>
      dispatch({ type: "MEMBER_UPDATE_REQUEST", id, role }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountTeam);
