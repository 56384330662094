import { MutableRefObject } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import { useDispatch, useSelector } from "react-redux";

import { InlineIcon, OverflowMenu, OverflowMenuItem } from "@/design-system";
import { SvgIconHeartEmpty, SvgIconHeartFull } from "../icons";
import { RootState, Video } from "../types";

import { SvgIconAi } from "./icons";

import styles from "../styles.module.css";

interface MenuProps {
  video: Video;
  automatedInsights: {
    count: number;
    isDisplay: boolean;
    onClickToggleDisplay: () => void;
  };
}

function eventStopPropagation(event: React.MouseEvent) {
  event.stopPropagation();
}

/**
 *
 * Menu
 */
export function Menu({ video, automatedInsights }: MenuProps) {
  const dispatch = useDispatch();

  const starFetching = useSelector(
    (state: RootState) => state.videos.fetchingStar.indexOf(video.id) !== -1,
  );
  const unstarFetching = useSelector(
    (state: RootState) => state.videos.fetchingUnstar.indexOf(video?.id) !== -1,
  );
  const isStarred = (video.starred || starFetching) && !unstarFetching;

  function handleClickStarVideo() {
    dispatch({ type: "VIDEOS_STAR_REQUEST", id: video?.id });
  }
  function handleClickUnStarVideo() {
    dispatch({ type: "VIDEOS_UNSTAR_REQUEST", id: video?.id });
  }

  return (
    <OverflowMenu
      className={styles.overflowMenu}
      customTriggerButton={({
        innerRef,
        onClick,
        isOpen,
      }: {
        innerRef: MutableRefObject<null>;
        onClick: () => void;
        isOpen: boolean;
      }) => (
        <button
          className={cn(
            styles.overflowMenuTriggerButton,
            isOpen && styles.overflowMenuTriggerButton_open,
          )}
          ref={innerRef}
          onClick={onClick}
        >
          <FontAwesomeIcon icon={solid("ellipsis-h")} />
        </button>
      )}
    >
      {/* Prevent onMouseUp from closing the overflow menu */}
      {isStarred && (
        <OverflowMenuItem
          onMouseUp={eventStopPropagation}
          onClick={handleClickUnStarVideo}
        >
          <InlineIcon Svg={SvgIconHeartFull} color={"var(--color-blue-600)"} />{" "}
          Remove from favorites
        </OverflowMenuItem>
      )}

      {/* Prevent onMouseUp from closing the overflow menu */}
      {!isStarred && (
        <OverflowMenuItem
          onMouseUp={eventStopPropagation}
          onClick={handleClickStarVideo}
        >
          <InlineIcon Svg={SvgIconHeartEmpty} color={"var(--color-blue-600)"} />{" "}
          Add to favorites
        </OverflowMenuItem>
      )}

      {video.ai_status === "complete" && automatedInsights.count > 0 && (
        <OverflowMenuItem onClick={automatedInsights.onClickToggleDisplay}>
          <InlineIcon Svg={SvgIconAi} color={"var(--color-blue-600)"} />{" "}
          {automatedInsights.isDisplay ? "Hide" : "Show"}{" "}
          {automatedInsights.count} Automated Insights
        </OverflowMenuItem>
      )}
    </OverflowMenu>
  );
}
