import React from "react";
import { concatClassNames } from "@system42/core";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class Heading5 extends React.Component {
  static defaultProps = { styles };

  render() {
    const { className, children, styles } = this.props;
    return (
      <h5
        {...extractContainerProps(this.props)}
        className={concatClassNames(className, styles.root)}
      >
        {children}
      </h5>
    );
  }
}

export default Heading5;
