import { cloneElement, ReactElement, useState } from "react";

// Todo: remove this hook and use somthing like const { isOpen, setIsOpen, open, close } = useModalHelper()
// useModal2Controller is already a draft for this in the Modal folder

export function useModal2(modal: ReactElement) {
  const [isActive, setIsActive] = useState(false);

  function handleCloseModal() {
    setIsActive(false);
  }
  return {
    isModalActive: isActive,
    showModal: () => setIsActive(true),
    hideModal: () => setIsActive(false),
    modal: cloneElement(modal, {
      isActive: isActive,
      onClose: handleCloseModal,
    }), // cloneElement is probably not the best solution here
  };
}
