import { useSelector } from "react-redux";

/**
 * @typedef UserbrainBillingInformation
 * @type {object}
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} organization
 * @property {string} street
 * @property {string} postcode
 * @property {string} city
 * @property {string} country
 * @property {string} vatId
 * @property {string} billingEmail
 * @property {number} taxPercent
 */

/**
 * Get billing object
 * @returns {UserbrainBillingInformation|null} Returns the billing object
 */
export function useBillingInformation() {
  return useSelector(({ billingInformation }) =>
    billingInformation.billingInformation
      ? billingInformation.billingInformation
      : null,
  );
}
