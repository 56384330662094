import { IconTextMessageWithButton } from "@/components/IconTextMessageWithButton";
import { SvgIconLocked } from "@/containers/Video/icons";

interface AiPromotionProps {
  onClickUpgrade: () => void;
  onClickHide: () => void;
}

/**
 *
 * AI Promotion
 */
export function AiPromotion({ onClickUpgrade, onClickHide }: AiPromotionProps) {
  return (
    <IconTextMessageWithButton
      SvgIcon={SvgIconLocked}
      title={"Unlock Automated Insights"}
      buttonText={`Upgrade now`}
      onClick={onClickUpgrade}
      onClickHide={onClickHide}
    >
      Efficiently analyze videos with AI-generated insights.{" "}
      <a
        href="https://help.userbrain.com/help/how-to-use-automated-insights"
        target="_blank"
        rel="noreferrer"
      >
        Learn more
      </a>
    </IconTextMessageWithButton>
  );
}
