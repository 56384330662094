import { useState } from "react";
import { concatClassNames as cn } from "@system42/core";
import upperFirst from "lodash/upperFirst";

import { SkeletonParagraph } from "@/components/Skeleton";
import { Avatar, AvatarGroup, Button } from "@/design-system";
import { TestInsightApi, TestInsightTesterApi } from "@/entities/test/insights";
import { getTesterInitials } from "@/helpers";
import { useLocationState } from "@/hooks/useLocationState";

import { getSectionIcon, SvgIconCaretDown } from "./icons";
import { NoteOrClipItem } from "./NoteOrClipItem";

import styles from "./styles.module.css";

export type InsightsSectionProps = {
  name: InsightsSectionName;
  /* If section is set to null then the component will render a skeleton */
  insights: TestInsightApi[] | null;
};

export type InsightsSectionName =
  | "positive_feedback"
  | "key_takeaway"
  | "usability_issue"
  | "user_confusion"
  | "unexpected_interaction"
  | "negative_feedback"
  | "user_behavior"
  | "suggestion";

const COUNT_SHOW_DEFAULT = 3;

export function InsightsSection({
  name,
  className,
  insights,
}: InsightsSectionProps & { className: string }) {
  const [showAll, setShowAll] = useState(false);
  const visibleInsights = showAll
    ? insights
    : insights?.slice(0, COUNT_SHOW_DEFAULT);

  const isDisplaySkeletons = insights === null;
  const title = upperFirst(name.replace(/_/g, " "));
  const SvgIcon = getSectionIcon(name);

  return (
    <section className={cn("card", className, styles.insightsSection)}>
      <div className={styles.header}>
        <SvgIcon className={styles.headerIcon} />
        <h2>{title}</h2>
      </div>

      {!isDisplaySkeletons &&
        visibleInsights?.map((insight) => (
          <AccordionItem
            key={insight.id}
            accordionGroupId={name}
            title={insight.summary}
            insight={insight}
            id={insight.id.toString()}
          />
        ))}

      {!isDisplaySkeletons &&
        !showAll &&
        insights.length > COUNT_SHOW_DEFAULT && (
          <Button
            className={styles.showMore}
            onClick={() => setShowAll((v) => !v)}
          >
            Show more
          </Button>
        )}

      {isDisplaySkeletons && <PlaceholderContent />}
    </section>
  );
}

const MAX_AVATAR_COUNT = 3;

function AccordionItem({
  title,
  insight,
  accordionGroupId,
  id,
}: {
  title: string;
  insight: TestInsightApi;
  id: string;
  accordionGroupId: string;
}) {
  const triggerId = `${accordionGroupId}-${id}`;
  const contentId = `${accordionGroupId}-${id}-content`;

  // This is saved in history state to preserve the state when the user navigates back
  const [isExpanded, setIsExpanded] = useLocationState<boolean>(
    `ai-insight-accordion-${id}`,
    false,
  );

  const notesAndClips: (
    | (typeof insight.notes)[0]
    | (typeof insight.clips)[0]
  )[] = [...insight.notes, ...insight.clips];
  notesAndClips.sort((a, b) => a.timestamp - b.timestamp);

  const testers: TestInsightTesterApi[] = [];

  // Get unique testers (tester.id is unique)
  notesAndClips.forEach((noteOrClip) => {
    const tester = noteOrClip.tester;
    if (tester && !testers.some((item) => item.id === tester.id)) {
      testers.push(tester);
    }
  });

  return (
    <div
      className={cn(
        styles.accordionItem,
        isExpanded && styles.accordionItem_expanded,
      )}
    >
      <h3 className={styles.accordionHeading}>
        <button
          type="button"
          aria-expanded={isExpanded}
          className={styles.accordionTrigger}
          aria-controls={contentId}
          id={triggerId}
          onClick={() => {
            if (window.getSelection()?.toString()) {
              return;
            }
            setIsExpanded((v) => !v);
          }}
        >
          <span className={styles.accordionTriggerTitle}>{title}</span>
          <span className={styles.accordionInitialsAndIcon}>
            <span className={styles.accordionInitials}>
              <AvatarGroup className={styles.avatarGroup}>
                {testers
                  .slice(
                    0,
                    testers.length > MAX_AVATAR_COUNT
                      ? MAX_AVATAR_COUNT - 1
                      : testers.length,
                  )
                  .map((tester) => (
                    <Avatar
                      key={tester.id}
                      initials={getTesterInitials(tester.name)}
                      title={tester.name}
                      colorSeed={tester.id}
                    />
                  ))}
                {testers.length > MAX_AVATAR_COUNT && (
                  <Avatar
                    initials={`+${testers.length - MAX_AVATAR_COUNT + 1}`}
                    title={`+ ${testers.length - MAX_AVATAR_COUNT + 1} more`}
                  />
                )}
              </AvatarGroup>
            </span>
            <SvgIconCaretDown className={styles.accordionIcon} />
          </span>
        </button>
      </h3>

      <div
        id={contentId}
        className={styles.accordionContent}
        aria-labelledby={triggerId}
        hidden={!isExpanded}
      >
        {notesAndClips.map((noteOrClip) => (
          <NoteOrClipItem
            key={noteOrClip.id}
            className={styles.noteOrClipItem}
            noteOrClip={noteOrClip}
          />
        ))}
      </div>
    </div>
  );
}

function PlaceholderContent() {
  const count = Math.floor(Math.random() * 3) + 1;

  const items = Array.from({ length: count }).map((_, i) => (
    <SkeletonParagraph key={i} className={styles.placeholderContent} />
  ));

  return <>{items}</>;
}
