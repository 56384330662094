import { memo } from "react";
import upperFirst from "lodash/upperFirst";

import { renderHashtags } from "../../helpers";

export const NoteWithHashtags = memo(
  ({ note, classNameHashtag }: { note: string; classNameHashtag: string }) => {
    const renderer = (hashtag: any, index: any) => (
      <span key={index} className={classNameHashtag}>
        {hashtag}
      </span>
    );
    return renderHashtags(upperFirst(note), renderer);
  },
);
