import { Action, Reducer } from "@reduxjs/toolkit";

import { ApiError } from "./types";

type GenericApiStateIdle = {
  isFetching: false;
  isError: false;
  data: null;
  error: null;
};

type GenericApiStateFetching<T> = {
  isFetching: true;
  isError: false;
  data: NonNullable<T> | null;
  error: null;
};

type GenericApiStateSuccess<T> = {
  isFetching: false;
  isError: false;
  data: NonNullable<T>;
  error: null;
};

type GenericApiStateError = {
  isFetching: false;
  isError: true;
  data: null;
  error: ApiError;
};

export type GenericApiState<T> = (
  | GenericApiStateIdle
  | GenericApiStateFetching<T>
  | GenericApiStateSuccess<T>
  | GenericApiStateError
) & {
  requestAction?: Action;
};

type GenericApiSliceOptions = {
  storeRequestAction?: boolean; // This can be used to refresh the data by dispatching stored action again
};

export default function createGenericApiSlice<T = any>(
  /**
   * Creates a generic API slice for Redux.
   *
   * @template T - The type of data returned to be stored in the state.
   * @param {string} actionIdentifier - A prefix for the actions.
   * @param {GenericApiSliceOptions} [options={}] - Additional options.
   * @returns {GenericApiSlice<T>} - An object containing the recucer and the action names.
   */
  actionIdentifier: string,
  options: GenericApiSliceOptions = {},
) {
  const { storeRequestAction = false } = options;

  const REQUEST = `${actionIdentifier}_REQUEST`;
  const FAILURE = `${actionIdentifier}_FAILURE`;
  const SUCCESS = `${actionIdentifier}_SUCCESS`;
  const SET = `${actionIdentifier}_SET`;

  const initialState: GenericApiStateIdle = {
    isFetching: false,
    isError: false,
    data: null,
    error: null,
  };

  const reducer: Reducer<GenericApiState<T>> = function (
    state = initialState,
    action,
  ): GenericApiState<T> {
    switch (action.type) {
      case REQUEST:
        return {
          ...initialState,
          data: action.isKeepData ? state.data : null,
          isFetching: true,
          requestAction: storeRequestAction ? action : undefined,
        } as GenericApiStateFetching<T>;
      case SET: {
        return {
          ...state,
          data: action.data,
        } as GenericApiStateSuccess<T>;
      }
      case SUCCESS: {
        // This line is necessary to prevent the data from being set to `null`
        // `null` should only be used to indicate that data is not yet fetched.
        const data = action.data === null ? undefined : action.data;

        return {
          isFetching: false,
          isError: false,
          data,
          error: null,
          requestAction: state.requestAction,
        } as GenericApiStateSuccess<T>;
      }
      case FAILURE:
        return {
          isFetching: false,
          isError: true,
          data: null,
          error: action.error,
          requestAction: state.requestAction,
        } as GenericApiStateError;
      default:
        return state;
    }
  };
  return {
    reducer,
    actionTypes: {
      REQUEST,
      SUCCESS,
      FAILURE,
      SET,
    },
  };
}
