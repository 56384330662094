import { LoaderFunctionArgs, ShouldRevalidateFunction } from "react-router-dom";

import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";

async function loader({ params, request }: LoaderFunctionArgs) {
  // If the currently loaded test is the same as the one we're trying to load, we can load the new
  // test silently.
  const loadedTestId = store.getState().test.test?.id;
  const isRequestedTestLoaded = loadedTestId === Number(params.testId);
  store.dispatch({
    type: "TEST_REQUEST",
    id: params.testId,
    silent: isRequestedTestLoaded,
  });
  return null;
}

export const shouldRevalidate: ShouldRevalidateFunction = ({
  actionResult,
  currentParams,
  currentUrl,
  defaultShouldRevalidate,
  formAction,
  formData,
  formEncType,
  formMethod,
  nextParams,
  nextUrl,
}) => {
  return false;
};

export default authRequiredWrapper(loader);
