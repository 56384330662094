import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import {
  VIDEO_CLIP_CREATE_FAILURE,
  VIDEO_CLIP_CREATE_REQUEST,
  VIDEO_CLIP_CREATE_SUCCESS,
} from "@/reducers/videoClipCreate";
import {
  VIDEO_CLIP_DELETE_FAILURE,
  VIDEO_CLIP_DELETE_REQUEST,
  VIDEO_CLIP_DELETE_SUCCESS,
} from "@/reducers/videoClipDelete";
import {
  VIDEO_CLIP_SHARE_FAILURE,
  VIDEO_CLIP_SHARE_REQUEST,
  VIDEO_CLIP_SHARE_SUCCESS,
} from "@/reducers/videoClipShare";
import {
  VIDEO_CLIP_UPDATE_FAILURE,
  VIDEO_CLIP_UPDATE_REQUEST,
  VIDEO_CLIP_UPDATE_SUCCESS,
} from "@/reducers/videoClipUpdate";
import { API_URL } from ".";

export function* watchVideoClipCreate() {
  yield takeLatest(VIDEO_CLIP_CREATE_REQUEST, callVideoClipCreateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideoClipCreateApi(action: any): SagaIterator {
  try {
    const { videoId, convertNoteId, note, timestamp, timestamp_end } = action;
    const response = yield call(
      postVideoClip,
      videoId,
      note,
      timestamp,
      timestamp_end,
      convertNoteId,
    );
    const clip = response.data.data;
    yield put({
      type: VIDEO_CLIP_CREATE_SUCCESS,
      data: {
        videoId,
        convertNoteId,
        clip,
      },
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: VIDEO_CLIP_CREATE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function postVideoClip(
  videoId: number,
  note: string,
  timestamp: number,
  timestamp_end: number,
  videonote_id?: number,
) {
  return axios({
    method: "post",
    url: API_URL + "/video/" + videoId + "/clip",
    data: {
      note,
      timestamp,
      timestamp_end,
      videonote_id,
    },
  });
}

export function* watchVideoClipDelete() {
  yield takeLatest(VIDEO_CLIP_DELETE_REQUEST, callVideoClipDeleteApi);
}

// worker saga: makes the api call when watcher saga sees the action
export function* callVideoClipDeleteApi(action: any): SagaIterator {
  const { videoId, clipId } = action;
  try {
    yield call(deleteVideoClip, videoId, clipId);
    yield put({ type: VIDEO_CLIP_DELETE_SUCCESS, data: { videoId, clipId } });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: VIDEO_CLIP_DELETE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function deleteVideoClip(videoId: number, clipId: number) {
  return axios({
    method: "delete",
    url: API_URL + "/video/" + videoId + "/clip/" + clipId,
  });
}

// VIDEO UPDATE NOTE
export function* watchVideoClipUpdate() {
  yield takeLatest(VIDEO_CLIP_UPDATE_REQUEST, callVideoClipUpdateApi);
}

// worker saga: makes the api call when watcher saga sees the action
export function* callVideoClipUpdateApi(action: any): SagaIterator {
  const { videoId, clipId, note, timestamp, timestamp_end } = action;
  try {
    const response = yield call(
      patchVideoClip,
      videoId,
      clipId,
      note,
      timestamp,
      timestamp_end,
    );

    const clip = response.data.data;

    yield put({
      type: VIDEO_CLIP_UPDATE_SUCCESS,
      data: {
        videoId,
        clip,
      },
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: VIDEO_CLIP_UPDATE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function patchVideoClip(
  videoId: number,
  clipId: number,
  note: string,
  timestamp: number,
  timestamp_end: number,
) {
  return axios({
    method: "patch",
    url: API_URL + "/video/" + videoId + "/clip/" + clipId,
    data: {
      note,
      timestamp,
      timestamp_end,
    },
  });
}

// VIDEO SET SHARE NOTE
export function* watchVideoClipShare() {
  yield takeLatest(VIDEO_CLIP_SHARE_REQUEST, callVideoClipShareApi);
}

// worker saga: makes the api call when watcher saga sees the action
export function* callVideoClipShareApi(action: any): SagaIterator {
  const { videoId, clipId, isShared } = action;
  try {
    const response = yield call(postVideoClipShare, videoId, clipId, isShared);

    const clip = response.data.data;

    yield put({
      type: VIDEO_CLIP_SHARE_SUCCESS,
      data: {
        videoId,
        clip,
      },
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: VIDEO_CLIP_SHARE_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function postVideoClipShare(
  videoId: number,
  clipId: number,
  isShared: boolean,
) {
  return axios({
    method: "post",
    url: API_URL + "/video/" + videoId + "/clip/" + clipId + "/share",
    data: {
      shared: isShared,
    },
  });
}
