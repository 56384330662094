import React from "react";
import { concatClassNames } from "@system42/core";

import styles from "./styles.module.css";

class InputText extends React.Component {
  static defaultProps = { styles };

  render() {
    const { className, error, styles, fullWidth, min, ...containerProps } =
      this.props;

    let classNameInput = concatClassNames(
      className,
      styles.input,
      fullWidth && styles.fullWidth,
      error && styles.error,
    );

    return (
      <input
        type={"number"}
        {...containerProps}
        min={min}
        className={classNameInput}
      />
    );
  }
}

export default InputText;
