// Global modal
const GLOBAL_MODAL_OPEN = "GLOBAL_MODAL_OPEN";
const GLOBAL_MODAL_CLOSE = "GLOBAL_MODAL_CLOSE";

const initialStateGlobalModal = {
  modal: null,
  modalOptions: null,
};

export function globalModalReducer(state = initialStateGlobalModal, action) {
  switch (action.type) {
    case GLOBAL_MODAL_OPEN:
      return {
        ...state,
        modal: action.modal,
        modalOptions: action.modalOptions ?? null,
      };
    case GLOBAL_MODAL_CLOSE:
      return { ...state, modal: null, modalOptions: null };
    default:
      return state;
  }
}
