import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import { useDispatch } from "react-redux";

import ConfirmModal from "@/components/ConfirmModal";
import { ImageLoader } from "@/components/ImageLoader";
import { Checkbox, DropDownDialog, InlineIcon } from "@/design-system";
import { pluralizer } from "@/helpers";
import { HHMMSSToSeconds, toHHMMSS, usePrevious } from "@/helpers-ts";
import { useBaseUrl } from "@/hooks/useBaseUrl";
import { useModal2 } from "@/hooks/useModal2";
import { VIDEO_SHARE_REQUEST } from "@/reducers/videoShare";
import { useAppSelector } from "@/store";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconClear } from "./icons/icon-clear.svg";
import { ReactComponent as SvgIconClip } from "./icons/icon-clip.svg";
import { ReactComponent as SvgIconDropdown } from "./icons/icon-dropdown.svg";
import { ReactComponent as SvgIconLink } from "./icons/icon-link.svg";
import { ReactComponent as SvgIconPlay } from "./icons/icon-play.svg";
import { ReactComponent as SvgIconPrivate } from "./icons/icon-private.svg";
import { ReactComponent as SvgIconPublic } from "./icons/icon-public.svg";

type ShareVideoProps = Omit<VideoProps, "shareUrl"> & {
  onClickClose: () => void;
  shareHash: string;
  currentVideoSecond: number;
  isShared: boolean;
  onToggleIsShared: () => void;
  publicClipsCount: number;
  videoId: number;
  isFetching: boolean;
};

export function ShareVideo({
  onClickClose,
  posterUrl,
  videoDuration,
  shareHash,
  currentVideoSecond,
  isShared,
  onToggleIsShared,
  publicClipsCount,
  videoId,
  isFetching,
}: ShareVideoProps) {
  const baseUrl = useBaseUrl();
  const [isCopied, setIsCopied] = useState(false);
  const [isStartAt, setIsStartAt] = useState(false);
  const [startAt, setStartAt] = useState(currentVideoSecond);

  const shareUrl = `${baseUrl}/shared/${shareHash}${
    isStartAt ? "?t=" + startAt : ""
  }`;

  const handleToggleIsStartAt = () => setIsStartAt((prev) => !prev);

  function handleCopyLink() {
    navigator.clipboard.writeText(shareUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  }

  useEffect(() => {
    setIsCopied(false);
  }, [isShared]);

  return (
    <div className={styles.shareClip}>
      <Header onClickClose={onClickClose} />
      <div className={styles.body}>
        <Video
          posterUrl={posterUrl}
          videoDuration={videoDuration}
          shareUrl={shareUrl}
        />
      </div>
      <div className={styles.startAtPreviewLinkContainer}>
        <StartAt
          isChecked={isStartAt}
          value={startAt}
          upperBounds={videoDuration}
          onChangeValue={setStartAt}
          onToggleIsStartAt={handleToggleIsStartAt}
        />
        <a
          className={styles.previewLink}
          rel="noreferrer"
          target="_blank"
          href={shareUrl}
        >
          Preview in new tab
        </a>
      </div>
      <div className={styles.footer}>
        <div className={styles.selectAccessCopyLinkButtonContainer}>
          <SelectAccess
            isFetching={isFetching}
            isShared={isShared}
            onToggleIsShared={onToggleIsShared}
          />
          <button onClick={handleCopyLink} className={styles.copyLinkButton}>
            <SvgIconLink /> {isCopied ? "Copied!" : "Copy Link"}
          </button>
        </div>
        {Boolean(publicClipsCount) && !isShared && (
          <div className={styles.footerSubsection}>
            <DisablePublicLinks count={publicClipsCount} videoId={videoId} />
          </div>
        )}
      </div>
    </div>
  );
}

/**
 * Header
 */
function Header({ onClickClose }: { onClickClose: () => void }) {
  return (
    <div className={styles.header}>
      <h4>Share this video</h4>
      <button onClick={onClickClose}>
        <SvgIconClear />
      </button>
    </div>
  );
}

type VideoProps = {
  posterUrl: string;
  videoDuration: number;
  shareUrl: string;
};

/**
 * Video
 */
function Video({ posterUrl, videoDuration, shareUrl }: VideoProps) {
  return (
    <a
      className={styles.video}
      rel="noreferrer"
      target="_blank"
      href={shareUrl}
    >
      <SvgIconPlay className={styles.videoPlayIcon} />
      <ImageLoader className={styles.videoPoster} url={posterUrl} />
      <div className={styles.videoDuration}>{toHHMMSS(videoDuration)}</div>
    </a>
  );
}

type StartAtProps = {
  isChecked: boolean;
  value: number;
  upperBounds: number;
  onChangeValue: (value: number) => void;
  onToggleIsStartAt: () => void;
};

/**
 * Start At
 */
function StartAt({
  isChecked,
  value,
  onChangeValue,
  onToggleIsStartAt,
  upperBounds,
}: StartAtProps) {
  const [localStartAtState, setLocalStartAtState] = useState(toHHMMSS(value));

  function isInBounds(seconds: number) {
    return seconds >= 0 && seconds <= upperBounds;
  }

  function handleBlur() {
    const isSeconds = !localStartAtState.includes(":");
    const sanitized = isSeconds
      ? Number(localStartAtState)
      : HHMMSSToSeconds(localStartAtState);
    const isValid = sanitized !== 0 && isInBounds(sanitized);

    isValid && onChangeValue(sanitized);
    setLocalStartAtState(toHHMMSS(isValid ? sanitized : value));
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    /^[0-9:]+$/.test(e.target.value) && setLocalStartAtState(e.target.value);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  }

  return (
    <Checkbox checked={isChecked} onChange={onToggleIsStartAt}>
      Start at{" "}
      <input
        className={styles.startAtInput}
        name="start-at"
        value={localStartAtState}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
    </Checkbox>
  );
}

/**
 * Select isShared Option
 */
function SelectAccessOption({
  onClick,
  isActive,
  children,
}: {
  onClick: () => void;
  isActive: boolean;
  children: ReactNode;
}) {
  return (
    <div
      className={cn(
        styles.selectAccessOption,
        isActive && styles.selectAccessOption_active,
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

type SelectAccessProps = {
  isShared: boolean;
  onToggleIsShared: () => void;
  isFetching: boolean;
};

/**
 * Select if video is public or private.
 */
function SelectAccess({
  isShared,
  onToggleIsShared,
  isFetching,
}: SelectAccessProps) {
  const labelElement = (
    <div className={styles.selectAccessIconLabelContainer}>
      {isShared ? "Access: Anyone" : "Access: Restricted"} <SvgIconDropdown />
    </div>
  );

  function handleClickOption(newIsShared: boolean) {
    if (newIsShared !== isShared) {
      onToggleIsShared();
    }
  }

  return (
    <div className={styles.selectAccess}>
      <div
        className={cn(
          styles.selectAccessIconContainer,
          isShared && styles.selectAccessIconContainer_public,
        )}
      >
        {!isFetching && (isShared ? <SvgIconPublic /> : <SvgIconPrivate />)}
        {isFetching && (
          <FontAwesomeIcon size="lg" icon={solid("spinner")} spin />
        )}
      </div>
      <div>
        <DropDownDialog
          align={"left"}
          label={labelElement}
          closeOnClick
          triggerButtonClassName={styles.selectAccessDropdownTrigger}
        >
          <div className={styles.selectAccessOptionsContainer}>
            <SelectAccessOption
              isActive={isShared}
              onClick={() => handleClickOption(true)}
            >
              Anyone
            </SelectAccessOption>
            <SelectAccessOption
              isActive={!isShared}
              onClick={() => handleClickOption(false)}
            >
              Restricted
            </SelectAccessOption>
          </div>
        </DropDownDialog>
        <div className={styles.selectAccessDescription}>
          {isShared && "Anyone with the link can view"}
          {!isShared && "Only people in your team can view"}
        </div>
      </div>
    </div>
  );
}

/**
 * Disable Public Links
 */
function DisablePublicLinks({
  count,
  videoId,
}: {
  count: number;
  videoId: number;
}) {
  const dispatch = useDispatch();
  const isError = useAppSelector((state) => state.videoShare.isError);
  const isFetching = useAppSelector((state) => state.videoShare.isFetching);
  const restrictAccessFailed = !isFetching && isError;
  const previousRestrictAccessFailed = usePrevious(restrictAccessFailed);

  function handleDisableAllPublicLinks() {
    dispatch({
      type: VIDEO_SHARE_REQUEST,
      videoId,
      unshare_clips: true,
    });
  }

  const {
    showModal: showConfirmSwitchScreenerModal,
    modal: confirmSwitchScreenerModal,
  } = useModal2(
    <ConfirmModal
      isActive
      labelConfirm={isFetching ? "Loading…" : "Disable links"}
      onConfirm={handleDisableAllPublicLinks}
      onClose={() => {}} // Why is this needed?
    >
      Are you sure you want to restrict access for all clips?
    </ConfirmModal>,
  );

  useEffect(() => {
    if (restrictAccessFailed && !previousRestrictAccessFailed) {
      dispatch({
        type: "SNACKBAR_ADD",
        notificationType: "error",
        content: "Failed to disable link.",
      });
    }
  }, [dispatch, restrictAccessFailed, previousRestrictAccessFailed]);

  return (
    <>
      {confirmSwitchScreenerModal}
      <div className={styles.disablePublicLinks}>
        <span>
          <InlineIcon
            Svg={SvgIconClip}
            className={styles.disablePublicLinksIcon}
          />{" "}
          {pluralizer("clip")(count, true)} from this video{" "}
          {count > 1 ? "are" : "is"} available to anyone via link.
        </span>
        <button
          onClick={showConfirmSwitchScreenerModal}
          className={styles.disablePublicLinksButton}
        >
          Restrict access for all clips to your team only
        </button>
      </div>
    </>
  );
}
