import { useContext, useEffect } from "react";
import { RemoveScrollBar } from "react-remove-scroll-bar";
import { CSSTransition, TransitionGroup } from "react-transition-group"; // Todo: make part of Design System

import { ModalContext } from "../context";
import { hasActiveSubmodal } from "../helpers";
import { ModalContainer } from "../ModalContainer";

import styles from "./styles.module.css";

interface FullscreenModalModalProps {
  onClose?: (reason: "clickClose" | "esc") => void;
  content: JSX.Element;
}

interface FullscreenModalProps extends FullscreenModalModalProps {
  isActive: boolean;
}

export function FullscreenModal2({
  isActive,
  ...restProps
}: FullscreenModalProps) {
  return (
    <ModalContainer
      isActive={isActive}
      element={
        <TransitionGroup>
          {isActive && (
            <CSSTransition timeout={200} classNames={{ ...styles }}>
              <Modal {...restProps} />
            </CSSTransition>
          )}
        </TransitionGroup>
      }
    />
  );
}

function Modal({ onClose, content }: FullscreenModalModalProps) {
  const { refSubmodals } = useContext(ModalContext);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (!hasActiveSubmodal(refSubmodals.current) && event.key === "Escape") {
        onClose?.("esc");
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose, refSubmodals]);

  function handleClickClose() {
    onClose?.("clickClose");
  }

  return (
    <div className={styles.fullscreenModal}>
      <RemoveScrollBar />
      <button onClick={handleClickClose} className={styles.close}>
        Close
      </button>
      {content}
    </div>
  );
}
