import { Reducer } from "@reduxjs/toolkit";
import produce from "immer";

export const VIDEO_SECOND_WATCHED = "VIDEO_SECOND_WATCHED";
export const VIDEO_STATS_SENT = "VIDEO_STATS_SENT";
export const VIDEO_PAUSED = "VIDEO_PAUSED";
export const VIDEO_ENDED = "VIDEO_ENDED";

export enum VideoStatsType {
  Video,
  SharedVideo,
  SharedClip,
}

type VideoStatsItem = {
  type: VideoStatsType;
  id: number | string;
  timestamps: Array<number>;
};

type VideoStatsState = {
  items: Array<VideoStatsItem>;
};

const initialStateVideoStats = {
  items: [],
};

export const videoStatsReducer: Reducer<VideoStatsState> = function (
  state = initialStateVideoStats,
  action,
) {
  switch (action.type) {
    case VIDEO_SECOND_WATCHED: {
      const { videoStatsType, id, timestamp } = action;
      return produce(state, (draft) => {
        const videoStatsItem = draft.items.find(
          (item) => item.type === videoStatsType && item.id === id,
        );
        if (videoStatsItem) {
          videoStatsItem.timestamps.push(timestamp);
        } else {
          draft.items.push({
            type: videoStatsType,
            id,
            timestamps: [timestamp],
          });
        }
      });
    }
    case VIDEO_STATS_SENT: {
      const { sentItem } = action;
      return produce(state, (draft) => {
        // remove all sent timestamps, timestamps could can be included multiple times
        const videoStatsItem = draft.items.find(
          (item) => item.type === sentItem.type && item.id === sentItem.id,
        );
        if (videoStatsItem) {
          for (const sentTimestamp of sentItem.timestamps) {
            const indexOfTimestamp =
              videoStatsItem.timestamps.indexOf(sentTimestamp);
            if (indexOfTimestamp !== -1) {
              videoStatsItem.timestamps.splice(indexOfTimestamp, 1);
            }
            // remove item if no timestamps are left
            if (videoStatsItem.timestamps.length === 0) {
              const indexOfItem = draft.items.indexOf(videoStatsItem);
              draft.items.splice(indexOfItem, 1);
            }
          }
        }
      });
    }
  }
  return state;
};
