import { useEffect, useState } from "react";
import screenfull from "screenfull";

export function useScreenfullIsFullscreen() {
  const [isFullscreen, setIsFullscreen] = useState(!!screenfull.isFullscreen);

  useEffect(() => {
    if (screenfull.isEnabled) {
      function handleScreenfullChange() {
        setIsFullscreen(screenfull.isFullscreen);
      }
      screenfull.on("change", handleScreenfullChange);
      return () => screenfull.off("change", handleScreenfullChange);
    }
  }, []);

  return isFullscreen;
}
