import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { usePageVisibility } from "react-page-visibility";
import { useParams } from "react-router-dom";

import { VideoPlayerWithSidebar } from "@/components/VideoPlayerWithSidebar";
import { HeaderWithLogo } from "@/components/VideoPlayerWithSidebar/HeaderWithLogo";
import {
  VideoPlayerContext,
  VideoPlayerContextType,
} from "@/components/VideoPlayerWithSidebar/videoPlayerContext";
import { usePrevious } from "@/helpers-ts";
import { useSendVideoStats } from "@/hooks/useSendVideoStats";
import { SHARED_CLIP_RE_REQUEST } from "@/reducers/sharedClip";
import {
  VIDEO_ENDED,
  VIDEO_PAUSED,
  VideoStatsType,
} from "@/reducers/videoStatsReducer";
import { useAppDispatch, useAppSelector } from "@/store";
import { VideoClipSharedAuthenticated } from "../../entities/video/clip";
import Loading from "../Loading";
import { UserbrainVideoPlayerApi } from "../Video/components/VideoPlayer/types";

import { Sidebar } from "./Sidebar";

import contextStyles from "./context.module.css";
import styles from "./styles.module.css";

export const SharedClipContextStyles = createContext(contextStyles);

export function SharedClip() {
  const dispatch = useAppDispatch();
  const isPageVisible = usePageVisibility();
  const prevIsPageVisible = usePrevious(isPageVisible);

  const refPlayer = useRef<UserbrainVideoPlayerApi>(null);
  const [currentSecond, setCurrentSecond] = useState<number>();

  // we know better *fingers crossed* than tsc and assert the type
  const { hash: sharedHash } = useParams() as { hash: string };

  const { user } = useAppSelector((state) => state.user);
  const {
    isError,
    error,
    data: clip,
  } = useAppSelector((state) => state.sharedClip);
  const isLoggedIn = Boolean(user?.email);

  const handleSecondChange = useCallback((second: number) => {
    setCurrentSecond(second);
  }, []);

  const handleVideoPause = useCallback(() => {
    dispatch({
      type: VIDEO_PAUSED,
    });
  }, [dispatch]);

  const handleVideoEnded = useCallback(() => {
    dispatch({
      type: VIDEO_ENDED,
    });
  }, [dispatch]);

  const videoPlayerContextValue = useMemoizedVideoPlayerContextValue(
    refPlayer,
    handleSecondChange,
    handleVideoPause,
    handleVideoEnded,
  );

  useSendVideoStats(
    sharedHash,
    VideoStatsType.SharedClip,
    currentSecond,
    refPlayer,
  );

  const clipId = useAppSelector(
    (state) =>
      (state.sharedClip.data as VideoClipSharedAuthenticated | null)?.id,
  );

  const videoId = useAppSelector(
    (state) =>
      (state.sharedClip.data as VideoClipSharedAuthenticated | null)?.videoId,
  );

  const hasFullAccess =
    typeof clipId !== "undefined" && typeof videoId !== "undefined";

  useEffect(() => {
    function reload() {
      dispatch({ type: SHARED_CLIP_RE_REQUEST });
    }
    if (isPageVisible && clip?.isVideoReady === false) {
      // If page was invisible before, reload immediately
      if (prevIsPageVisible === false) {
        reload();
      }
      const reloadInterval = setInterval(
        reload,
        Number(process.env.REACT_APP_REFRESH_INTERVAL),
      );
      return () => {
        clearInterval(reloadInterval);
      };
    }
  }, [clip?.isVideoReady, isPageVisible, prevIsPageVisible, dispatch]);

  if (clip === null || isError) {
    return <Loading error={error}>Loading clip</Loading>;
  }

  return (
    <VideoPlayerContext.Provider value={videoPlayerContextValue}>
      <Helmet>
        <title>{`Shared Clip | Userbrain`}</title>
      </Helmet>
      <VideoPlayerWithSidebar
        className={styles.sharedClip}
        headerContent={<HeaderWithLogo isLoggedIn={isLoggedIn} />}
        sidebarContent={
          <Sidebar
            hasFullAccess={hasFullAccess}
            isLoggedIn={isLoggedIn}
            videoId={videoId}
            clipId={clipId}
          />
        }
      />
    </VideoPlayerContext.Provider>
  );
}

function useMemoizedVideoPlayerContextValue(
  refPlayer: React.MutableRefObject<UserbrainVideoPlayerApi | null>,
  handleSecondChange: (second: number) => void,
  handleVideoPause: () => void,
  handleVideoEnded: () => void,
) {
  const sharedClip = useAppSelector((state) => state.sharedClip.data);

  const context = useMemo<VideoPlayerContextType>(() => {
    if (sharedClip === null) {
      return null;
    } else {
      return {
        type: "userbrain",
        autoplay: false,
        initialTime: null,
        location: sharedClip.location,
        isVideoProcessing: sharedClip.isVideoReady === false,
        sources: undefined,
        vtt: sharedClip.previewVtt ?? undefined,
        poster: sharedClip.videoPosterUrl ?? undefined,
        transcriptVttUrl: undefined,
        transcriptAvailable: false,
        thumbnailSprite: sharedClip.previewImage ?? undefined,
        nextJumpPoint: undefined,
        previousJumpPoint: undefined,
        onSecondChange: handleSecondChange,
        onPause: handleVideoPause,
        onEnded: handleVideoEnded,
        resumeKey: null,
        refPlayer,
      };
    }
  }, [
    sharedClip,
    refPlayer,
    handleSecondChange,
    handleVideoPause,
    handleVideoEnded,
  ]);
  return context;
}
