export function parseStringNumber(str: string | null): number | null {
  return str === null ? null : parseInt(str);
}

export function parseCommaStrings(str: string | null): string[] | null {
  return str === null ? null : str.split(",");
}

export function parseCommaNumberStrings(str: string | null): number[] | null {
  return str === null
    ? null
    : (str.split(",").map(parseStringNumber) as number[]);
}

export function parseStringBoolean(str: string | null): boolean | null {
  return str === null ? null : str === "true";
}
