import { useEffect, useState } from "react";

type Primitive = string | number | boolean | null | undefined | symbol | bigint;

const STATE_KEY = "useLocationState";

export function useLocationState<T extends Primitive>(
  historyStateKey: string,
  initialValue: T,
) {
  const useStateResult = useState<T>(() => {
    if (
      typeof window?.history.state?.[STATE_KEY]?.[historyStateKey] ===
      "undefined"
    ) {
      return initialValue;
    }
    return window?.history.state?.[STATE_KEY]?.[historyStateKey] as T;
  });

  const stateValue = useStateResult[0];

  useEffect(() => {
    if (window?.history.state?.[STATE_KEY]?.[historyStateKey] !== stateValue) {
      // github.com/remix-run/react-router/discussions/11415
      window?.history.replaceState(
        {
          ...window?.history.state,
          [STATE_KEY]: {
            ...window?.history.state?.[STATE_KEY],
            [historyStateKey]: stateValue,
          },
        },
        "",
      );
    }
  }, [stateValue, historyStateKey]);

  return useStateResult;
}
