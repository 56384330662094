import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Button from "../Button";

import styles from "./styles.module.css";

export function ButtonIcon(props) {
  const { className, icon, ...containerProps } = props;

  return (
    <Button styles={styles} {...containerProps}>
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
}
