import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { parseVideoSharedApi } from "@/entities/video/shared";
import { createReduxApiError } from "@/helpers-ts";
import {
  SHARED_VIDEO_FAILURE,
  SHARED_VIDEO_REQUEST,
  SHARED_VIDEO_SUCCESS,
} from "@/reducers/sharedVideo";
import { API_URL } from ".";

export function* watchSharedVideo() {
  yield takeLatest(SHARED_VIDEO_REQUEST, callSharedVideoApi);
}

function* callSharedVideoApi(action: any): SagaIterator {
  const { sharedHash } = action;
  try {
    const response = yield call(fetchSharedVideo, sharedHash);

    const video = parseVideoSharedApi(response.data.data);

    yield put({
      type: SHARED_VIDEO_SUCCESS,
      data: video,
    });
  } catch (error) {
    yield put({
      type: SHARED_VIDEO_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function fetchSharedVideo(shared_hash: string) {
  return axios({
    method: "get",
    url: `${API_URL}/shared/${shared_hash}`,
  });
}
