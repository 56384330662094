import { useMemo } from "react";
import { useSelector } from "react-redux";

/**
 * @typedef UserbrainSessions
 * @type {object}
 * @property {number} available
 * @property {number} included
 * @property {number} included_max
 * @property {number} used_this_month
 */

/**
 * Get IYOP sessions
 * @returns {UserbrainSessions|false|null} Returns the tester sessions object
 */
export function useSessions() {
  const user = useSelector(({ user }) => user.user);
  return useMemo(() => {
    if (!user) {
      return null;
    } else {
      const sessions = user.sessions || false;
      return sessions;
    }
  }, [user]);
}
