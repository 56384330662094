const APPUPDATE_REQUIRED = "APPUPDATE_REQUIRED";
const APPUPDATE_REQUIRED_DISMISS = "APPUPDATE_REQUIRED_DISMISS";

const initialState = {
  updateRequiredDismissed: false,
  updateRequired: false,
};

export const appUpdateReducer = function (state = initialState, action) {
  switch (action.type) {
    case APPUPDATE_REQUIRED:
      return { ...state, updateRequired: true, updateRequiredDismissed: false };
    case APPUPDATE_REQUIRED_DISMISS:
      return { ...state, updateRequiredDismissed: true };
    default:
      return state;
  }
};
