import React, { useEffect, useState } from "react";

import { getCues } from "@/helpers";

export const VideoSubtitles = (props) => {
  const { time, vttUrl, ...restProps } = props;

  const [cues, setCues] = useState(null);
  const [currentCue, setCurrentCue] = useState(null);

  useEffect(() => {
    if (!vttUrl) return;
    getCues(vttUrl).then(setCues).catch(console.error);
  }, [vttUrl]);

  useEffect(() => {
    if (!cues) return;
    const cue = cues?.find(
      (cue) => cue.startTime <= time && cue.endTime >= time,
    );
    setCurrentCue(cue);
  }, [cues, time]);

  const cueText = currentCue?.text;

  if (!cueText) return null;

  return <div {...restProps}>{cueText}</div>;
};
