import isUndefined from "lodash/isUndefined";

import { NoteOrClipPrototypeProps } from "../types";

const CLIP_MIN_DURATION_IN_SECONDS = 1;

/**
 *
 * Tests if the provided timestamps are valid.
 * @returns success boolean and optional error message.
 */
export function validateNotePrototypeTimestamps({
  videoDuration,
  timestampStart,
  timestampEnd,
  // Used to modify error message so that the newly modified
  // timestamp is described.
  errorMessageSubject,
}: {
  videoDuration: number;
  timestampStart: NoteOrClipPrototypeProps["timestampStart"];
  timestampEnd: NoteOrClipPrototypeProps["timestampEnd"];
  errorMessageSubject: "start" | "end";
}): { success: true } | { success: false; errorMessage?: string } {
  const isTimestampStartDefined = !isUndefined(timestampStart);
  const isTimestampEndDefined = !isUndefined(timestampEnd);

  // XXX: This is called when timestamp_end === null
  // Happened to me with editing old "notes" that havea timestamp_end of null
  if (isTimestampStartDefined && isTimestampEndDefined) {
    if (timestampStart >= timestampEnd) {
      return {
        success: false,
        errorMessage:
          errorMessageSubject === "start"
            ? "The starting time needs to come before the ending time."
            : "The ending time needs to come after the starting time.",
      };
    }
    if (timestampEnd - timestampStart < CLIP_MIN_DURATION_IN_SECONDS) {
      return {
        success: false,
        errorMessage: "A clip needs to be at least a second long.",
      };
    }
  }

  if (isTimestampStartDefined) {
    if (timestampStart < 0) {
      // Some error messages like this one are ommited because
      // it's perhaps too obvious to display a user notification.
      return {
        success: false,
      };
    }
    if (timestampStart > videoDuration) {
      return {
        success: false,
        errorMessage:
          "The starting time can't be greater than the video duration.",
      };
    }
  }

  if (isTimestampEndDefined) {
    if (timestampEnd > videoDuration) {
      return {
        success: false,
        errorMessage:
          "The ending time can't be greater than the video duration.",
      };
    }
    if (timestampEnd === 0) {
      return {
        success: false,
      };
    }
    if (!(timestampEnd - CLIP_MIN_DURATION_IN_SECONDS >= 0)) {
      return {
        success: false,
        errorMessage: "A clip needs to be at least a second long.",
      };
    }
  }

  return {
    success: true,
  };
}
