import { z } from "zod";

export const VideoClipTagFilterItemApiSchema = z.object({
  count: z.number(),
  tag: z.string(),
});
export type VideoClipTagFilterItemApi = z.infer<
  typeof VideoClipTagFilterItemApiSchema
>;

export type VideoClipTagFilterItem = {
  id: string;
  count: number;
  value: string;
};

export function parseVideoClipTagFilterItemApi(
  tagFilterItemApi: VideoClipTagFilterItemApi,
): VideoClipTagFilterItem {
  const { tag, count } =
    VideoClipTagFilterItemApiSchema.parse(tagFilterItemApi);

  return {
    id: tag,
    value: tag,
    count: count,
  };
}
