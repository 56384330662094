import { concatClassNames as cn } from "@system42/core";

import { TaskStep } from "@/containers/Video/types";
import { TimelineItem, TimelineItemProps } from "../TimelineItem";

import styles from "./styles.module.css";

export interface TaskTimelineProps
  extends Omit<TimelineItemProps, "step" | "prevStep"> {
  className?: string;
  taskStepsWithResponse: TaskStep[];
}

export default function TaskTimeline({
  taskStepsWithResponse,
  isLegacyTask,
  currentVideoTime,
  videoDuration,
  onClickTime,
  onClickDownloadVideoClip,
  className,
  scrollerRef,
  onChangeCurrentTimelineItemVisible,
  preventScrollIntoView,
}: TaskTimelineProps) {
  return (
    <div className={cn(styles.timeline, className)}>
      {taskStepsWithResponse.map((taskStepWithResponse, index) => (
        <TimelineItem
          key={taskStepWithResponse.id}
          step={taskStepWithResponse}
          isLegacyTask={isLegacyTask}
          prevStep={taskStepsWithResponse[index - 1]}
          currentVideoTime={currentVideoTime}
          videoDuration={videoDuration}
          onClickTime={onClickTime}
          onClickDownloadVideoClip={onClickDownloadVideoClip}
          scrollerRef={scrollerRef}
          preventScrollIntoView={preventScrollIntoView}
          onChangeCurrentTimelineItemVisible={
            onChangeCurrentTimelineItemVisible
          }
        />
      ))}
    </div>
  );
}
