import React from "react";
import { concatClassNames } from "@system42/core";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class MainNavigation extends React.Component {
  static defaultProps = { styles };

  componentDidMount() {
    if (document) {
      const htmlElement = document.documentElement;
      htmlElement.classList.add(styles.html);
    }
  }

  componentWillUnmount() {
    if (document) {
      const htmlElement = document.documentElement;
      htmlElement.classList.remove(styles.html);
    }
  }

  render() {
    const { children, className, styles } = this.props;
    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(className, styles.root)}
      >
        {children}
      </div>
    );
  }
}

export default MainNavigation;
