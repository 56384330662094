import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Spinner from "@/components/Spinner";
import { selectIsUserOwner } from "@/selectors/user";
import { FullscreenModal2 } from "../../components/Modal";
import {
  PLAN_AGENCY,
  PLAN_LEGACY,
  PLAN_PRO,
  PLAN_STARTER,
  PlanInfoBox,
} from "../../components/PlanInfoBox";
import { UserbrainPlans } from "../../components/UserbrainPlans";
import { BILLING_CYCLE_ANNUAL } from "../../helpers";
import { useCurrency } from "../../hooks/useCurrency";
import { usePricing } from "../../hooks/usePricing";
import { useSubscription } from "../../hooks/useSubscription";

import styles from "./styles.module.css";

export function EditSubscriptionModal(props) {
  const { isActive, onClose, ...restProps } = props;

  return (
    <FullscreenModal2
      isActive={isActive}
      onClose={onClose}
      content={<EditSubscriptionModalContent {...restProps} />}
    />
  );
}

function EditSubscriptionModalContent({
  onClickChooseStarter,
  onClickChoosePro,
  onClickChooseAgency,
  onClickCancel,
  showCancel,
}) {
  const [billingCycle, setBillingCycle] = useState(BILLING_CYCLE_ANNUAL); // This is overridden by the active subscription

  function handleChangeBillingCycle(e) {
    setBillingCycle(e.target.value);
  }

  const location = useLocation();
  const navigate = useNavigate();
  const pricing = usePricing();
  const currency = useCurrency();
  const subscription = useSubscription();

  const isOwner = useSelector(selectIsUserOwner);

  useEffect(() => {
    if (subscription) {
      setBillingCycle(subscription.billing_cycle);
    }
  }, [subscription]);

  useEffect(() => {
    if (location.hash === "#upgrade-yearly") {
      setBillingCycle(BILLING_CYCLE_ANNUAL);
      navigate(location.pathname + location.search, { replace: true });
    }
  }, [location, navigate]);

  function handleClickChooseStarter() {
    onClickChooseStarter(billingCycle);
  }

  function handleClickChoosePro() {
    onClickChoosePro(billingCycle);
  }

  function handleClickChooseAgency() {
    onClickChooseAgency(billingCycle);
  }

  const isLoading =
    pricing === null || currency === null || subscription === null;

  const isLegacyPlan = subscription?.plan === "legacy";

  // Starter
  let buttonLabelStarter = "Choose Starter";
  let buttonDisabledStarter = false;
  let showCancelStarter = false;
  if (subscription?.plan === "starter") {
    if (billingCycle === subscription.billing_cycle) {
      buttonLabelStarter = "Your current plan";
      buttonDisabledStarter = true;
      showCancelStarter = showCancel;
    } else {
      buttonLabelStarter = "Update to yearly billing";
    }
  } else if (billingCycle === "annual") {
    buttonLabelStarter = "Update to Starter yearly";
  } else if (subscription?.plan === "agency" || subscription?.plan === "pro") {
    buttonLabelStarter = "Downgrade to Starter";
  }

  // Pro
  let buttonLabelPro = "Choose Pro";
  let buttonDisabledPro = false;
  let showCancelPro = false;
  if (subscription?.plan === "pro") {
    if (billingCycle === subscription.billing_cycle) {
      buttonLabelPro = "Your current plan";
      buttonDisabledPro = true;
      showCancelPro = showCancel;
    } else {
      buttonLabelPro = "Update to yearly billing";
    }
  } else if (billingCycle === "annual") {
    buttonLabelPro = "Update to Pro yearly";
  } else if (subscription?.plan === "agency") {
    buttonLabelPro = "Downgrade to Pro";
  } else if (subscription?.plan === "starter") {
    buttonLabelPro = "Upgrade to Pro";
  }

  // Agency
  let buttonLabelAgency = "Choose Agency";
  let buttonDisabledAgency = false;
  let showCancelAgency = false;
  if (subscription?.plan === "agency") {
    if (billingCycle === subscription.billing_cycle) {
      buttonLabelAgency = "Your current plan";
      buttonDisabledAgency = true;
      showCancelAgency = showCancel;
    } else {
      buttonLabelAgency = "Update to yearly billing";
    }
  } else if (billingCycle === "annual") {
    buttonLabelAgency = "Update to Agency yearly";
  } else if (subscription?.plan === "pro" || subscription?.plan === "starter") {
    buttonLabelAgency = "Upgrade to Agency";
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className={styles.content}>
      <UserbrainPlans
        title={"Edit your subscription"}
        onChangeBillingCycle={handleChangeBillingCycle}
        billingCycle={billingCycle}
      >
        {isLegacyPlan && (
          <PlanInfoBox
            plan={PLAN_LEGACY}
            buttonLabel={"Your current plan"}
            buttonDisabled={true}
            subscriptionQuantity={subscription.quantity}
            monthlyPrice={
              subscription.billing_cycle === "monthly"
                ? subscription.plan_price / 100
                : null
            }
            annualPrice={
              subscription.billing_cycle === "annual"
                ? subscription.plan_price / 100
                : null
            }
            currency={subscription.plan_currency}
            billingCycle={subscription.billing_cycle}
            showCancel={showCancel}
            canCancel={isOwner}
            onClickCancel={onClickCancel}
          />
        )}
        <PlanInfoBox
          plan={PLAN_STARTER}
          monthlyPrice={pricing.starter / 100}
          annualPrice={pricing.starter_annual / 100}
          currency={currency}
          billingCycle={billingCycle}
          onClickButton={handleClickChooseStarter}
          buttonLabel={buttonLabelStarter}
          buttonDisabled={buttonDisabledStarter}
          showCancel={showCancelStarter}
          canCancel={isOwner}
          onClickCancel={onClickCancel}
        />
        <PlanInfoBox
          plan={PLAN_PRO}
          monthlyPrice={pricing.pro / 100}
          annualPrice={pricing.pro_annual / 100}
          currency={currency}
          billingCycle={billingCycle}
          onClickButton={handleClickChoosePro}
          buttonLabel={buttonLabelPro}
          buttonDisabled={buttonDisabledPro}
          showCancel={showCancelPro}
          canCancel={isOwner}
          onClickCancel={onClickCancel}
        />
        <PlanInfoBox
          plan={PLAN_AGENCY}
          monthlyPrice={pricing.agency / 100}
          annualPrice={pricing.agency_annual / 100}
          currency={currency}
          billingCycle={billingCycle}
          onClickButton={handleClickChooseAgency}
          buttonLabel={buttonLabelAgency}
          buttonDisabled={buttonDisabledAgency}
          showCancel={showCancelAgency}
          canCancel={isOwner}
          onClickCancel={onClickCancel}
        />
      </UserbrainPlans>
    </div>
  );
}
