import { ReactNode } from "react";

import styles from "./styles.module.css";

type TestTabCardContentInfoContentProps = {
  title: ReactNode;
  content?: ReactNode;
  imageSrc: string;
  imageAlt: string;
};

export function TestTabCardContentInfoContent(
  props: TestTabCardContentInfoContentProps,
) {
  const { title, content, imageSrc, imageAlt } = props;

  return (
    <div className={styles.testTabCardContentInfoContent}>
      <img alt={imageAlt} className={styles.image} src={imageSrc} />
      <div className={styles.title}>{title}</div>
      {content && <div className={styles.content}>{content}</div>}
    </div>
  );
}
