import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "@/containers/Loading";
import { useAppSelector } from "@/store";

export function ConfirmEmail() {
  const navigate = useNavigate();
  const confirmEmailData = useAppSelector((state) => state.confirmEmail.data);
  const isError = useAppSelector((state) => state.confirmEmail.isError);

  useEffect(() => {
    if (confirmEmailData !== null) {
      navigate("/login", { state: { confirmEmailSuccess: true } });
    }
  }, [navigate, confirmEmailData]);

  return (
    <Loading
      error={isError}
      errorMessage={
        isError &&
        "This verification link is expired or you have already verified your email."
      }
    >
      Confirming your email
    </Loading>
  );
}
