import React from "react";
import { concatClassNames } from "@system42/core";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class MainNavigationItem extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, className, styles, active } = this.props;
    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(
          className,
          styles.root,
          active && styles["root--active"],
        )}
      >
        {children}
      </div>
    );
  }
}

export default MainNavigationItem;
