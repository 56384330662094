import { ReactNode } from "react";
import isString from "lodash/isString";

import { ButtonLink } from "@/design-system";
import {
  hrefHelpFigmaPrototype,
  textFigmaShareLinkInaccessible,
} from "@/helpers";

export function testUrlErrorStringFilter(
  errorString: string | null | undefined,
): ReactNode {
  // startsWith because errorString can be "site_blocked (and 2 more errors)" >_<
  if (errorString?.startsWith("site_blocked")) {
    return (
      <>
        This site has been blocked.{" "}
        <a
          href={
            "https://help.userbrain.com/help/why-has-the-site-i-d-like-to-test-been-blocked"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn&nbsp;more…
        </a>
      </>
    );
  } else {
    return errorString;
  }
}

export function testUrlFieldFeedbackFilter(
  errorMessage: string | null | undefined,
  currentFieldValue: any,
  onClickProceedAnyway: () => void | undefined,
): ReactNode {
  if (!isString(errorMessage) || !isString(currentFieldValue)) {
    return errorMessage;
  }

  if (
    errorMessage.includes("403 Forbidden") &&
    currentFieldValue.startsWith("https://www.figma.com/proto")
  ) {
    return (
      <div>
        {textFigmaShareLinkInaccessible}{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={hrefHelpFigmaPrototype}
        >
          Learn&nbsp;more…
        </a>
        {onClickProceedAnyway && (
          <>
            {" "}
            or{" "}
            <ButtonLink onClick={onClickProceedAnyway}>
              Proceed anyway…
            </ButtonLink>
          </>
        )}
      </div>
    );
  } else if (errorMessage.includes("offline") && onClickProceedAnyway) {
    return (
      <>
        {errorMessage}{" "}
        <ButtonLink onClick={onClickProceedAnyway}>Proceed anyway…</ButtonLink>
      </>
    );
  }

  return testUrlErrorStringFilter(errorMessage);
}
