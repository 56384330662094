import { Question } from "../types";

export type ValidatedQuestionResults = string[];

/**

  Is the UI responsible for validating a screener object?

  a.) Yes, the validation is a feature of the UI (defined eg. in helpers.js) 
    or this component (definded here).
  b.) No, screenser validation is a userbrain business rule, could be 
    part of a shared library or antother local place.
  c.) No, the backend should responsible for validating the screener

*/
export function validateQuestion(question: Question): ValidatedQuestionResults {
  const result = [];

  if (!question) {
    result.push("Please enter a question text.");
  }

  if (question.type === "single_choice") {
    if (
      typeof question.answers?.find(
        (answer) => answer.typeSingleChoice === "accept",
      ) === "undefined"
    ) {
      result.push("This question will reject all testers.");
    }
  } else if (question.type === "multiple_choice") {
    if (
      typeof question.answers?.find(
        (answer) =>
          answer.typeMultipleChoice === "must_select" ||
          answer.typeMultipleChoice === "may_select",
      ) === "undefined"
    ) {
      result.push("This question will reject all testers.");
    }
  }

  if (!question.answers || question.answers.length < 2) {
    result.push(
      "Please add at least one more answer for an effective screener.",
    );
  }

  return result;
}
