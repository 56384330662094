import { LoaderFunctionArgs } from "react-router-dom";

import { TEST_CLIPS_API_REQUEST } from "@/reducers/testClipsApi";
import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";
import {
  parseCommaStrings,
  parseStringBoolean,
  parseStringNumber,
} from "./utils";

export const TEST_CLIPS_PARAM_NAME = {
  PAGE: "page",
  TESTSTEP_IDS: "teststep_ids",
  TESTER_IDS: "tester_ids",
  TAGS: "tags",
  UNTAGGED: "untagged",
};

async function loader({ params, request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;

  const page = searchParams.get(TEST_CLIPS_PARAM_NAME.PAGE);
  const testerIds = searchParams.get(TEST_CLIPS_PARAM_NAME.TESTER_IDS);
  const taskIds = searchParams.get(TEST_CLIPS_PARAM_NAME.TESTSTEP_IDS);
  const selectedTags = searchParams.get(TEST_CLIPS_PARAM_NAME.TAGS);
  const isUntagged = searchParams.get(TEST_CLIPS_PARAM_NAME.UNTAGGED);

  store.dispatch({
    type: TEST_CLIPS_API_REQUEST,
    testId: params.testId,
    page: parseStringNumber(page),
    selectedTesterIds: parseCommaStrings(testerIds),
    selectedTaskIds: parseCommaStrings(taskIds),
    selectedTags: parseCommaStrings(selectedTags),
    isUntaggedSelected: parseStringBoolean(isUntagged),
  });
  return null;
}

export default authRequiredWrapper(loader);
