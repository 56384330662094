import { VideoClip, VideoNote } from "@/containers/Video/types";
import { NoteType } from "../types";

export type NotesAndClipsItem =
  | (VideoNote & { type: "note" })
  | (VideoClip & { type: "clip" });

export function filterAndCountNotesAndClipsByType({
  type,
  notes,
  clips,
}: {
  type: NoteType | null;
  notes?: VideoNote[];
  clips?: VideoClip[];
}) {
  const filtered: NotesAndClipsItem[] = [];
  const count: Record<NoteType, number> = {
    note: 0,
    clip: 0,
  };

  notes?.forEach((note) => {
    if (type === null || type === "note") {
      filtered.push({ ...note, type: "note" });
    }
    count.note++;
  });

  clips?.forEach((clip) => {
    if (type === null || type === "clip") {
      filtered.push({ ...clip, type: "clip" });
    }
    count.clip++;
  });

  const sorted = filtered.sort((a, b) => a.timestamp - b.timestamp);

  return {
    notesAndClips: sorted,
    count: count,
  };
}
