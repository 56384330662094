import React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  ButtonLink,
  MainNavigationAccount,
  MainNavigationAccountItem,
  MainNavigationAccountItemLink,
  MainNavigationItem,
  MainNavigationNav,
} from "@/design-system";
import { selectIsUserOwnerOrAdmin } from "@/selectors/user";
import { getWordingForTestType, pluralizer } from "../../helpers";
import { useFreeTrial } from "../../hooks/freeTrialHooks";
import { useCreditsAvailable } from "../../hooks/useCreditsAvailable";
import { useSubscription } from "../../hooks/useSubscription";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconApp } from "./icon-app.svg";
import { ReactComponent as SvgIconPrototype } from "./icon-prototype.svg";
import { ReactComponent as SvgIconWebsite } from "./icon-website.svg";
import logo from "./logo.svg";
import { ReactComponent as SvgSeparator } from "./separator.svg";

export default function Navigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();

  const user = useSelector((state) => state.user.user);
  const test = useSelector((state) => state.test.test);
  const subscription = useSubscription();
  const testSetupLoadedTest = useSelector(
    (state) => state.testSetup.loadedTest,
  );
  const testSetupLoadedDuplicateType = useSelector(
    (state) => state.testSetup.loadedDuplicate?.type,
  );
  const userLoginEmail = useSelector((state) => state.user.userLoginEmail);
  const isOwnerOrAdmin = useSelector(selectIsUserOwnerOrAdmin);
  const creditsAvailable = useCreditsAvailable();
  const freeTrial = useFreeTrial();

  const userEmail = user?.email || userLoginEmail || "";
  const hasNewVideos = !!user?.new_video_count;

  function handleClickLogout() {
    navigate("/");
    dispatch({ type: "SIGN_OUT" });
  }

  const accountUsername = (
    <div className={styles.account} title={userEmail}>
      <FontAwesomeIcon
        icon={solid("user-circle")}
        className={styles.userIcon}
      />
    </div>
  );

  let headerInfo = null;

  function handleClickUpgradeNow() {
    dispatch({
      type: "GLOBAL_MODAL_OPEN",
      modal: "explorePlans",
      modalOptions: { redirectToBillingAfterCheckout: true },
    });
  }

  function handleClickUpgradePlan() {
    if (subscription === false) {
      dispatch({
        type: "GLOBAL_MODAL_OPEN",
        modal: "explorePlans",
        modalOptions: { redirectToBillingAfterCheckout: true },
      });
    } else {
      if (subscription.contactSupportToEdit === true) {
        navigate("/account/billing");
      } else {
        navigate("/account/billing#show-plans-modal");
      }
    }
  }

  if (freeTrial?.isActive && !subscription) {
    headerInfo = (
      <div className={styles.headerInfo}>
        <strong>{pluralizer("day")(freeTrial.daysLeft, true)} </strong>left in
        your free trial
        <> &nbsp;</>
        <ButtonLink className={styles.buyLink} onClick={handleClickUpgradeNow}>
          Choose a plan
        </ButtonLink>
      </div>
    );
  } else if (
    freeTrial?.isExpired &&
    user?.has_invoices === false &&
    !subscription
  ) {
    headerInfo = (
      <div className={styles.headerInfo}>
        Your trial has ended<> &nbsp;</>
        <ButtonLink className={styles.buyLink} onClick={handleClickUpgradeNow}>
          Choose a plan
        </ButtonLink>
      </div>
    );
  } else if (
    isOwnerOrAdmin && // Only admins can buy
    !subscription && // Has no subscription (or free trial)
    user?.confirmed === true && // Is confirmed
    creditsAvailable < 10 // Low credits
  ) {
    headerInfo = (
      <div className={styles.headerInfo}>
        {pluralizer("credit")(creditsAvailable, true)} left
        <> </>
        <Link to={"/account/buy-credits"} className={styles.buyLink}>
          Buy more
        </Link>
      </div>
    );
  }

  const navigationItemVideoActive =
    !!location.pathname.match(/^\/sessions\/?$/i);
  const navigationItemDashboardActive = !!(
    location.pathname === "/" ||
    location.pathname.match(/^\/dashboard\/?$/i) ||
    location.pathname.match(/^\/test\/$/)
  );

  const matchPathResultCreateTest = matchPath(
    "/test/create/:type",
    location.pathname,
  );

  const matchPathResultCreateTestFromTemplate = matchPath(
    "/test/create/:type/template/:templateId",
    location.pathname,
  );

  const matchPathResultDuplicateTest = matchPath(
    "/test/duplicate/:duplicateId",
    location.pathname,
  );

  const isTestDuplicateView = !!matchPathResultDuplicateTest;

  const isCreateTestView =
    matchPathResultCreateTest !== null ||
    matchPathResultCreateTestFromTemplate !== null;

  const testTypeForBreadcrumbs =
    matchPathResultCreateTest?.params.type ||
    matchPathResultCreateTestFromTemplate?.params.type ||
    testSetupLoadedDuplicateType;

  const isTestView = !!location.pathname.match(
    /\/test\/[0-9]+(\/report|\/reels|\/clips|\/insights|\/testers)?\/?$/i,
  );
  const isTestEditView = !!location.pathname.match(
    /\/test\/[0-9]*\/setup\/?$/i,
  );

  return (
    <MainNavigationNav logo={logo}>
      {isTestView ||
      isTestEditView ||
      isCreateTestView ||
      isTestDuplicateView ? (
        <div className={styles.mainNavigationBreadcrumbs}>
          <div className={styles.mainNavigationBreadcrumbsItem}>
            <Link to={"/dashboard"}>Dashboard</Link>
          </div>
          <div className={styles.mainNavigationBreadcrumbsSeparator}>
            <SvgSeparator
              className={styles.mainNavigationBreadcrumbsSeparatorIcon}
            />
          </div>

          {isTestView && (
            <div className={styles.mainNavigationBreadcrumbsItem}>
              {test && <TestTypeIconBreadcrumb testType={test?.type} />}
              {test?.title || "Loading…"}
            </div>
          )}

          {isTestEditView && (
            <>
              <div className={styles.mainNavigationBreadcrumbsItem}>
                <TestTypeIconBreadcrumb testType={testSetupLoadedTest?.type} />
                <Link to={"/test/" + params.id}>
                  {testSetupLoadedTest?.title || "Loading…"}
                </Link>
              </div>
              <div className={styles.mainNavigationBreadcrumbsSeparator}>
                <SvgSeparator
                  className={styles.mainNavigationBreadcrumbsSeparatorIcon}
                />
              </div>
              <div className={styles.mainNavigationBreadcrumbsItem}>Edit</div>
            </>
          )}

          {(isCreateTestView || isTestDuplicateView) && (
            <>
              <div className={styles.mainNavigationBreadcrumbsItem}>
                <TestTypeIconBreadcrumb testType={testTypeForBreadcrumbs} />
                Create new {getWordingForTestType(testTypeForBreadcrumbs)} test
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <MainNavigationItem active={navigationItemDashboardActive}>
            <Link to={"/dashboard"}>Dashboard</Link>
          </MainNavigationItem>
          <MainNavigationItem active={navigationItemVideoActive}>
            <Link
              to={"/sessions"}
              className={hasNewVideos ? styles.hasNews : ""}
            >
              All sessions
            </Link>
          </MainNavigationItem>

          {headerInfo}
        </>
      )}

      <MainNavigationAccount
        username={accountUsername}
        className={styles.account}
      >
        <MainNavigationAccountItemLink to={"/account"}>
          Account Details
        </MainNavigationAccountItemLink>
        {isOwnerOrAdmin && (
          <MainNavigationAccountItemLink to={"/account/buy-credits"}>
            Buy credits
          </MainNavigationAccountItemLink>
        )}
        {isOwnerOrAdmin && (
          <MainNavigationAccountItemLink to={"/account/billing"}>
            Usage &amp; Billing
          </MainNavigationAccountItemLink>
        )}
        {isOwnerOrAdmin && (
          <MainNavigationAccountItemLink to={"/account/team"}>
            Team
          </MainNavigationAccountItemLink>
        )}
        <hr className={styles.accountItemsDivider} />

        {isOwnerOrAdmin && (
          <MainNavigationAccountItem onClick={handleClickUpgradePlan}>
            <FontAwesomeIcon icon={solid("star")} /> Upgrade Plan
          </MainNavigationAccountItem>
        )}

        <MainNavigationAccountItemLink
          className={styles.itemAlt}
          href={"https://help.userbrain.com/"}
          target={"_blank"}
        >
          <FontAwesomeIcon icon={regular("life-ring")} /> Help &amp; Support
        </MainNavigationAccountItemLink>
        <MainNavigationAccountItem
          className={styles.itemAlt}
          onClick={handleClickLogout}
        >
          <FontAwesomeIcon icon={solid("sign-out")} /> Log Out
        </MainNavigationAccountItem>
        <hr className={styles.accountItemsDivider} />
        <div className={styles.releaseInfo}>
          v{process.env.REACT_APP_VERSION}{" "}
          <Link to={"/release-notes"} className={styles.releaseInfoLink}>
            Release notes
          </Link>
        </div>
      </MainNavigationAccount>
    </MainNavigationNav>
  );
}

function TestTypeIconBreadcrumb({ testType }) {
  if (testType === "prototype") {
    return (
      <SvgIconPrototype className={styles.mainNavigationBreadcrumbsItemIcon} />
    );
  } else if (testType === "app") {
    return <SvgIconApp className={styles.mainNavigationBreadcrumbsItemIcon} />;
  } else {
    // testType === "website" or else;
    return (
      <SvgIconWebsite className={styles.mainNavigationBreadcrumbsItemIcon} />
    );
  }
}
