import { ChangeEvent } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Droppable } from "react-beautiful-dnd";

import {
  ButtonLink,
  DropDown,
  DropDownItem,
  ErrorMessage,
  Label,
  Stack,
  TextArea,
} from "@/design-system";
import { FeedbackBox } from "../FeedbackBox";
import ScreenerChoiceAnswer from "../ScreenerChoiceAnswer";
import { Answer, Question } from "../types";

import { getQuestionAdvice } from "./getQuestionAdvice";

import styles from "./styles.module.css";

interface ScreenerQuestionProps {
  className?: string;
  showRemoveButton: boolean;
  questionIndex: number;
  question: Question;
  onRemoveQuestion: HeaderProps["onRemoveQuestion"];
  errors?: string[];
  onChangeQuestionType: TypeSelectorProps["onChangeQuestionType"];
  onChangeQuestionText: QuestionInputProps["onChangeQuestionText"];
  onDeleteAnswer: AnswersProps["onDeleteAnswer"];
  onChangeAnswer: AnswersProps["onChangeAnswer"];
  onAddAnswer: AnswersProps["onAddAnswer"];
}

export function ScreenerQuestion({
  className,
  questionIndex,
  question,
  showRemoveButton,
  onRemoveQuestion,
  errors,
  onChangeQuestionType,
  onChangeQuestionText,
  onDeleteAnswer,
  onChangeAnswer,
  onAddAnswer,
}: ScreenerQuestionProps) {
  return (
    <div className={className}>
      <Stack spacing="small">
        <Header
          questionIndex={questionIndex}
          showRemoveButton={showRemoveButton}
          onRemoveQuestion={onRemoveQuestion}
        />
        {errors?.map((error, i) => (
          <ErrorMessage key={i}>{error}</ErrorMessage>
        ))}
        <TypeSelector
          question={question}
          onChangeQuestionType={onChangeQuestionType}
        />
        <QuestionInput
          question={question}
          onChangeQuestionText={onChangeQuestionText}
        />
        <div className={styles.headingAnswers}>Answers</div>
        <p className={styles.answerInfo}>
          Testers need to{" "}
          <strong>
            select{" "}
            {question.type === "single_choice" ? "one answer" : "all answers"}{" "}
            marked 'Approve'
          </strong>{" "}
          to pass.
        </p>
        <Advice question={question} />
        <Answers
          questionIndex={questionIndex}
          question={question}
          onDeleteAnswer={onDeleteAnswer}
          onChangeAnswer={onChangeAnswer}
          onAddAnswer={onAddAnswer}
        />
      </Stack>
    </div>
  );
}

/*


  ANSWERS


*/
interface AnswersProps {
  questionIndex: number;
  question: Question;
  onDeleteAnswer: (answerIndex: number) => void;
  onChangeAnswer: (
    originalAnswer: Answer,
    updatedAnswer: Answer,
    answerIndex: number,
  ) => void;
  onAddAnswer: (answer: Answer) => void;
}

function Answers({
  questionIndex,
  question,
  onDeleteAnswer,
  onChangeAnswer,
  onAddAnswer,
}: AnswersProps) {
  return (
    <Droppable
      droppableId={`question-${questionIndex}`}
      type={`QUESTION-${questionIndex}`}
    >
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {[...question.answers, undefined].map((answer, answerIndex) => {
            const isLast = answer === undefined;
            return (
              <ScreenerChoiceAnswer
                className={styles.answer}
                answer={answer}
                index={answerIndex}
                key={answerIndex}
                questionIndex={questionIndex}
                choiceType={question.type}
                isAddAnswer={isLast}
                placeholder={isLast ? "Add another answer…" : undefined}
                onClickDelete={
                  isLast ? undefined : () => onDeleteAnswer(answerIndex)
                }
                onChange={
                  isLast
                    ? (newAnswer: Answer) => onAddAnswer(newAnswer)
                    : (updatedAnswer: Answer) =>
                        onChangeAnswer(answer, updatedAnswer, answerIndex)
                }
              />
            );
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

/*


  ADVICE


*/
interface AdviceProps {
  question: Question;
}

function Advice({ question }: AdviceProps) {
  const questionAdvice = getQuestionAdvice(question);

  const DEFAULT_LINK_TEXT = "How to write great screeners";
  const LINK_HREF_DOMAIN = "https://help.userbrain.com/help/";
  const DEFAULT_LINK_HREF =
    LINK_HREF_DOMAIN + "use-screening-questions-to-filter-testers";
  const ALLPASS_LINK_HREF =
    LINK_HREF_DOMAIN + "how-to-use-single-and-multiple-choice-questions";

  if (questionAdvice) {
    const typeProps = {
      yesno: {
        message: `Yes/No questions are biased towards “Yes”. 
        Consider rephrasing it.`,
        linkHref: `${DEFAULT_LINK_HREF}#yes-no`,
        linkText: DEFAULT_LINK_TEXT,
      },
      morethan6: {
        message: `Please note that too many answers can overwhelm testers 
        and may lead to lower acceptance rates. Consider removing some.`,
        linkHref: `${DEFAULT_LINK_HREF}#limit-answers`,
        linkText: DEFAULT_LINK_TEXT,
      },
      allpass: {
        message: `All testers will pass this question. 
        Instead of using screening questions, 
        you can add a survey as part of your test.`,
        linkHref: ALLPASS_LINK_HREF,
        linkText: "More on task types",
      },
    }[questionAdvice];

    return (
      <FeedbackBox>
        {typeProps.message}{" "}
        <a href={typeProps.linkHref} target="_blank" rel="noopener noreferrer">
          {typeProps.linkText}
        </a>
      </FeedbackBox>
    );
  } else {
    return null;
  }
}

/*


  TYPE SELECTOR


*/
interface TypeSelectorProps {
  question: Question;
  onChangeQuestionType: (newType: Question["type"]) => void;
}

function TypeSelector({ question, onChangeQuestionType }: TypeSelectorProps) {
  return (
    <DropDown
      value={question.type}
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        onChangeQuestionType(e.target.value as Question["type"])
      }
      className={styles.typeSelector}
    >
      <DropDownItem value={"single_choice"}>
        <FontAwesomeIcon fixedWidth icon={solid("dot-circle")} /> Single Select
      </DropDownItem>
      <DropDownItem value={"multiple_choice"}>
        <FontAwesomeIcon fixedWidth icon={solid("check-square")} /> Multiple
        Select
      </DropDownItem>
    </DropDown>
  );
}

/*


  QUESTION INPUT


*/
interface QuestionInputProps {
  question: Question;
  onChangeQuestionText: (newText: string) => void;
}

function QuestionInput({ question, onChangeQuestionText }: QuestionInputProps) {
  return (
    <TextArea
      value={question.text}
      placeholder={
        question.type === "single_choice"
          ? "e.g. ‘How often do you shop online?’"
          : "e.g. ‘Which of these pets do you own?’"
      }
      className={styles.questionTextArea}
      fullWidth
      autoSize
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        onChangeQuestionText(e.target.value)
      }
    />
  );
}

/*


  HEADER


*/
interface HeaderProps {
  questionIndex: number;
  showRemoveButton: boolean;
  onRemoveQuestion: () => void;
}

function Header({
  questionIndex,
  showRemoveButton,
  onRemoveQuestion,
}: HeaderProps) {
  return (
    <div className={styles.questionHeader}>
      <Label>{`Question #${questionIndex + 1}`}</Label>
      {showRemoveButton && (
        <ButtonLink
          className={styles.removeQuestion}
          onClick={onRemoveQuestion}
        >
          Remove
        </ButtonLink>
      )}
    </div>
  );
}
