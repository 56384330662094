import { StripeCardNumberElementChangeEvent } from "@stripe/stripe-js";

import { svgIconsCardBrand, SvgIconUnknownPaymentCard } from "./icons";

export type PaymentCardBrand = Exclude<
  StripeCardNumberElementChangeEvent["brand"],
  "unknown"
>;

export type PaymentCard = {
  brand: PaymentCardBrand;
  lastFourDigits: string;
  expiryMonth: number;
  expiryYear: number;
  isExpired?: boolean;
};

const displayNames: Record<PaymentCardBrand, string> = {
  visa: "Visa",
  mastercard: "Mastercard",
  amex: "American Express",
  discover: "Discover",
  diners: "Diners Club",
  jcb: "JCB",
  unionpay: "UnionPay",
};

const UNKNOWN_CARD_DISPLAY_NAME = "Payment Card";
const UNKNOWN_CARD_SVG_ICON = SvgIconUnknownPaymentCard;

export const paymentCardBrandUtils = {
  getIcon: (key: PaymentCardBrand) =>
    svgIconsCardBrand[key] ?? UNKNOWN_CARD_SVG_ICON,
  allIcons: () => Object.values(svgIconsCardBrand),
  allNames: () => Object.keys(svgIconsCardBrand) as PaymentCardBrand[],
  getDisplayName: (key: PaymentCardBrand) =>
    displayNames[key] ?? UNKNOWN_CARD_DISPLAY_NAME,
};
