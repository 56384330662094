import React, { Component } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { ButtonText } from "@/design-system";
import { withRouter } from "../../helpers";
import UpdateModal from "../UpdateModal";

import styles from "./styles.module.css";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.snackbarRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleCloseUpdateModal = () => {
    this.props.dismissUpdateRequired();
  };

  handleClickOutside(event) {
    if (
      this.snackbarRef.current &&
      !this.snackbarRef.current.contains(event.target)
    ) {
      // Disabled for now
      // this.props.dismissSnackbar();
    }
  }

  handleMouseEnterSnackbar = () => {
    this.props.pauseSnackbarTimer();
  };

  handleMouseLeaveSnackbar = () => {
    this.props.resumeSnackbarTimer();
  };

  render() {
    const {
      notificationQueue,
      snackbarQueue,
      updateRequired,
      updateRequiredDismissed,
      hideNotifications /* Hide notification on top of page, eg. on video detail */,
    } = this.props;

    let notification = null;
    let snackbar = null;

    if (notificationQueue.length > 0) {
      notification = notificationQueue[notificationQueue.length - 1];
    }

    if (snackbarQueue.length > 0) {
      snackbar = snackbarQueue[snackbarQueue.length - 1];
    }

    return (
      <>
        <UpdateModal
          isActive={updateRequired && !updateRequiredDismissed}
          onClose={this.handleCloseUpdateModal}
          onClickReload={() => window.location.reload()}
        />
        <TransitionGroup>
          {notification && hideNotifications !== true && (
            <CSSTransition
              classNames={{
                enter: styles["notification--enter"],
                enterActive: styles["notification--enter-active"],
                exit: styles["notification--exit"],
                exitActive: styles["notification--exit-active"],
              }}
              timeout={{ enter: 225, exit: 225 }}
            >
              <div className={styles.notificationWrapper}>
                <div className={styles.notificationFixed}>
                  <div
                    className={`${styles.notification}${
                      typeof styles["notification--" + notification.type] !==
                      "undefined"
                        ? " " + styles["notification--" + notification.type]
                        : ""
                    }`}
                    key={notification.id}
                  >
                    <div className={styles.notification___inner}>
                      <div className={styles.notification__content}>
                        {notification.type === "error" && (
                          <>
                            <FontAwesomeIcon
                              icon={solid("exclamation-triangle")}
                              fixedWidth
                            />
                            &nbsp;&nbsp;
                          </>
                        )}
                        {notification.content}
                      </div>
                      <div className={styles.notification__action}>
                        <ButtonText onClick={this.props.dismissNotification}>
                          Dismiss
                        </ButtonText>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
        <TransitionGroup>
          {snackbar && (
            <CSSTransition
              classNames={{
                enter: styles["snackbar--enter"],
                enterActive: styles["snackbar--enter-active"],
                exit: styles["snackbar--exit"],
                exitActive: styles["snackbar--exit-active"],
              }}
              timeout={{ enter: 225, exit: 225 }}
              key={snackbar.id}
            >
              <div className={styles.snackbar}>
                <div
                  className={styles.snackbar__snackbar}
                  role={"alertdialog"}
                  onMouseEnter={this.handleMouseEnterSnackbar}
                  onMouseLeave={this.handleMouseLeaveSnackbar}
                  ref={this.snackbarRef}
                >
                  <div className={styles.snackbar__content}>
                    {snackbar.content}
                  </div>
                  <div className={styles.snackbar__action}>
                    <ButtonText
                      onClick={this.props.dismissSnackbar}
                      className={styles.snackbar__dismiss}
                    >
                      Dismiss
                    </ButtonText>
                  </div>
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificationQueue: state.notifications.queue,
    snackbarQueue: state.notifications.snackbarQueue,
    updateRequired: state.appUpdate.updateRequired,
    updateRequiredDismissed: state.appUpdate.updateRequiredDismissed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dismissNotification: () => dispatch({ type: "NOTIFICATION_DISMISS" }),
    dismissSnackbar: () => dispatch({ type: "SNACKBAR_DISMISS" }),
    dismissUpdateRequired: () =>
      dispatch({ type: "APPUPDATE_REQUIRED_DISMISS" }),
    pauseSnackbarTimer: () => dispatch({ type: "SNACKBAR_TIMER_PAUSE" }),
    resumeSnackbarTimer: () => dispatch({ type: "SNACKBAR_TIMER_RESUME" }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Notifications));
