import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import {
  TEST_INSIGHTS_FAILURE,
  TEST_INSIGHTS_RE_REQUEST,
  TEST_INSIGHTS_REQUEST,
  TEST_INSIGHTS_SUCCESS,
} from "@/reducers/testInsights";
import { RootState } from "@/store";
import { API_URL } from ".";

export function* watchTestInsights() {
  yield all([
    takeLatest(TEST_INSIGHTS_REQUEST, callTestInsightsApi),
    takeLatest(TEST_INSIGHTS_RE_REQUEST, testInsightsReRequest),
  ]);
}

function* testInsightsReRequest() {
  const testInsights: RootState["testInsights"] = yield select(
    (state) => state.testInsights,
  );

  const { requestAction, isFetching, data } = testInsights;

  const refreshHash = data?.refreshHash;

  if (requestAction && !isFetching) {
    const actionToDispatch = {
      ...requestAction,
      refreshHash,
      isKeepData: true,
    };
    // Dispatch the same action with the refreshHash and isKeepData set to true
    yield put(actionToDispatch);
  }
}

function* callTestInsightsApi(action: any): SagaIterator {
  const { testId, refreshHash } = action;
  try {
    const response = yield call(fetchTestInsights, testId, refreshHash);

    let data: RootState["testInsights"]["data"];
    if (typeof response.data.data === "undefined") {
      // The success action just returns the original data if the response
      // is empty (the API does this when refreshHash was used and nothing has changed)
      data = yield select((state) => state.testInsights.data);
    } else {
      data = {
        insights: response.data.data,
        refreshHash: response.data.meta.refresh_hash,
        testId,
      };
    }

    yield put({
      type: TEST_INSIGHTS_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({
      type: TEST_INSIGHTS_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function fetchTestInsights(testId: number, refreshHash?: string | null) {
  return axios({
    method: "get",
    url: `${API_URL}/test/${testId}/insights${
      refreshHash ? "?refresh_hash=" + refreshHash : ""
    }`,
  });
}
