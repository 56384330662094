import React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function TaskTypeIcon({ taskType, askCompleted, title }) {
  switch (taskType) {
    case "task":
      if (askCompleted) {
        return (
          <FontAwesomeIcon
            fixedWidth
            icon={solid("file-check")}
            title={title}
          />
        );
      } else {
        return (
          <FontAwesomeIcon
            fixedWidth
            icon={solid("info-circle")}
            title={title}
          />
        );
      }
    case "single_choice":
      return (
        <FontAwesomeIcon fixedWidth icon={solid("list-radio")} title={title} />
      );
    case "multiple_choice":
      return (
        <FontAwesomeIcon fixedWidth icon={solid("list-check")} title={title} />
      );
    case "rating_scale":
      return (
        <FontAwesomeIcon
          fixedWidth
          icon={solid("gauge-simple-high")}
          title={title}
        />
      );
    case "redirect":
      return (
        <FontAwesomeIcon fixedWidth icon={regular("share")} title={title} />
      );
    case "written_response":
      return <FontAwesomeIcon fixedWidth icon={solid("text")} title={title} />;
    default:
      return (
        <FontAwesomeIcon fixedWidth icon={solid("info-circle")} title={title} />
      );
  }
}
