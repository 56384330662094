import { useState } from "react";
import CookieDisclaimer, {
  initCookieDisclaimer,
} from "@userbrain/cookie-disclaimer";
import isFunction from "lodash/isFunction";
import { useSearchParams } from "react-router-dom";
import Cookies from "universal-cookie";

import { LogoBanner } from "@/components/LogoBanner";
import Notifications from "@/containers/Notifications";
import { ButtonPrimary, ButtonText } from "@/design-system";

import styles from "./styles.module.css";

const cookies = new Cookies();

initCookieDisclaimer(process.env.REACT_APP_DEFAULT_COOKIE_DOMAIN);

export function LayoutCenterWithLogo({
  children,
  endComponent,
}: {
  children: React.ReactNode;
  endComponent?: React.ReactNode;
}) {
  const [searchParams] = useSearchParams();
  const [initialSearchParams] = useState(searchParams);

  function handleConsentGiven() {
    // Activate tracking scripts
    if (isFunction(window.enableTracking)) {
      window.enableTracking();
    }

    const utmSourceFromInitialSearchParams =
      initialSearchParams.get("utm_source");
    const utmSourceFromInitialGclid = initialSearchParams.get("gclid");

    // add more specific tracking source
    if (!utmSourceFromInitialSearchParams && utmSourceFromInitialGclid) {
      setTrafficSource("adwords:" + utmSourceFromInitialGclid);
    }
  }

  return (
    <>
      <Notifications />
      <div className={styles.centerContainer}>
        <LogoBanner endComponent={endComponent} />
        {children}
      </div>
      {/* Todo: The Cookie disclaimer should sit in App and not here. But I need to merge the other PR first. */}
      <CookieDisclaimer
        onConsentGiven={handleConsentGiven}
        classNameButtonAgree={ButtonPrimary.defaultProps.styles.root}
        classNameButtonDecline={ButtonText.defaultProps.styles.root}
      />
    </>
  );
}

function setTrafficSource(cvalue: string) {
  const exdays = 365;
  const expires = new Date();
  expires.setTime(expires.getTime() + exdays * 24 * 60 * 60 * 1000);
  cookies.set("ub_src", cvalue, {
    path: "/",
    domain: process.env.REACT_APP_DEFAULT_COOKIE_DOMAIN,
    expires,
  });
}
