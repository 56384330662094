import { useState } from "react";
import get from "lodash/get";
import omit from "lodash/omit";
import { useLocation } from "react-router-dom";

/**
 * Retrieve and clear a value by key from
 * the browser history state object.
 */
export function useHistoryStateAndClear(key: string) {
  const location = useLocation();
  const [valueState] = useState(() => {
    const value = get(location.state, key);
    if (value !== undefined) {
      window.history.replaceState(
        omit(location.state as object, key),
        "",
        location.pathname + location.search,
      );
    }
    return value;
  });

  return valueState;
}
