import React from "react";
import { concatClassNames as cn } from "@system42/core";

import { Button, ButtonPrimary } from "@/design-system";
import ButtonGroup from "../ButtonGroup";

import styles from "./styles.module.css";

export function ModalForm(props) {
  const {
    className,
    children,
    title,
    buttonLabelSubmit,
    buttonLabelSubmitFetching,
    fetching,
    onClickCancel,
    ...containerProps
  } = props;

  return (
    <form className={cn(className, styles.modalForm)} {...containerProps}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {children}
      <ButtonGroup align={"right"} className={styles.buttonGroup}>
        <ButtonPrimary disabled={fetching} type={"submit"}>
          {fetching ? buttonLabelSubmitFetching : buttonLabelSubmit}
        </ButtonPrimary>
        <Button onClick={onClickCancel}>Cancel</Button>
      </ButtonGroup>
    </form>
  );
}
