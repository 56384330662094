import { VideoShared } from "@/entities/video/shared";

import createGenericApiSlice from "./genericApiSlice";

const slice = createGenericApiSlice<VideoShared>("SHARED_VIDEO");

export const sharedVideoReducer = slice.reducer;

export const SHARED_VIDEO_REQUEST = slice.actionTypes.REQUEST;
export const SHARED_VIDEO_SUCCESS = slice.actionTypes.SUCCESS;
export const SHARED_VIDEO_FAILURE = slice.actionTypes.FAILURE;
