import { concatClassNames as cn } from "@system42/core";

import styles from "./styles.module.css";

export function InlineIcon({
  Svg,
  ...svgProps
}: {
  Svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} & React.SVGProps<SVGSVGElement>) {
  return (
    <Svg {...svgProps} className={cn(styles.inlineIcon, svgProps?.className)} />
  );
}

export function InlineIconImg(
  props: React.ImgHTMLAttributes<HTMLImageElement>,
) {
  return (
    <img
      {...props}
      alt={props.alt}
      className={cn(styles.inlineIcon, props?.className)}
    />
  );
}
