import React from "react";

import { ButtonPrimary } from "@/design-system";
import ButtonGroup from "../../components/ButtonGroup";
import { Modal2 } from "../../components/Modal";

import styles from "./styles.module.css";

export default function UpdateModal(props) {
  const { isActive, onClose, onClickReload } = props;

  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      maxWidth={"30rem"}
      content={
        <UpdateModalContent onClickReload={onClickReload} onClose={onClose} />
      }
    />
  );
}

function UpdateModalContent({ onClickReload, onClose }) {
  function handleClickClose() {
    onClose();
  }
  return (
    <div className={styles.content}>
      <button className={styles.closeButton} onClick={handleClickClose}>
        &times;
      </button>
      <h1 className={styles.title}>Update Required</h1>
      <p>Please reload to update to the latest version.</p>
      <ButtonGroup align={"right"} className={styles.buttonGroup}>
        <ButtonPrimary onClick={onClickReload}>Reload now</ButtonPrimary>
      </ButtonGroup>
    </div>
  );
}
