import axiosLib, { AxiosRequestConfig } from "axios";
import set from "lodash/set";

import { API_URL } from "@/sagas";
import { store } from "@/store";

import { authRefresh, readAuthToken, refreshAccessToken } from "./auth";

const axios = axiosLib.create();

axios.defaults.headers.common["X-USERBRAIN-App-Version"] =
  process.env.REACT_APP_VERSION ?? "0.0.0";

const HTTP_UNAUTHORIZED = 401;
const HTTP_RETRY_WITH = 449;

axios.interceptors.request.use(async (config) => {
  if (config.url?.startsWith(API_URL as string) === false) {
    throw new Error("This axios instance is for internal API requests only.");
  }

  // Wait for any ongoing refresh token promise to complete before making the request
  // This is to avoid the request being retried unnecessarily.
  await authRefresh.promise;

  const token = readAuthToken();
  if (token !== null) {
    set(config, "headers.Authorization", "Bearer " + token.access_token);
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === HTTP_UNAUTHORIZED) {
      await authRefresh.promise;

      const token = readAuthToken();
      const accessToken = token?.access_token;
      const accessTokenOriginalRequest = extractBearerTokenFromConfig(
        error.config,
      );

      // If the current access token is the one that was also used in the original request
      // then we try to refresh the access token
      if (accessTokenOriginalRequest === accessToken) {
        try {
          await refreshAccessToken();
        } catch (refreshTokenError) {
          // XXX: This could be due to a network error as well
          onAuthError();
          return Promise.reject(refreshTokenError);
        }
      }

      // Retry the original request
      return axios.request(error.config);
    }
    return Promise.reject(error);
  },
);

function onAuthError() {
  store.dispatch({ type: "SIGN_OUT" });
}

function extractBearerTokenFromConfig(config: AxiosRequestConfig) {
  return config.headers?.["Authorization"]?.toString().replace("Bearer ", "");
}

// Show app update required when the server responds with HTTP_RETRY
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response?.status === HTTP_RETRY_WITH) {
      store.dispatch({ type: "APPUPDATE_REQUIRED" });
    }
    return Promise.reject(error);
  },
);

export default axios;
