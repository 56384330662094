import { useEffect, useMemo, useState } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";

import ButtonGroup from "@/components/ButtonGroup";
import Spinner from "@/components/Spinner";
import {
  Button,
  ButtonPrimary,
  ErrorMessage,
  ExternalLink,
  Heading2,
  Tooltip,
} from "@/design-system";
import { urlInsightsKnowledgeBase, useUrlTypeformInsights } from "@/helpers-ts";
import { useFeatures } from "@/hooks/useFeatures";
import { usePersistentState } from "@/hooks/usePersistentState";
import { TEST_INSIGHTS_RE_REQUEST } from "@/reducers/testInsights";
import { TEST_INSIGHTS_REFRESH_REQUEST } from "@/reducers/testInsightsRefresh";
import { useAppDispatch, useAppSelector } from "@/store";
import { TestTabCardContentEmptyState } from "../TestTabCardContent/EmptyState";
import { TestTabCardContentLocked } from "../TestTabCardContent/Locked";

import { KeyMetrics } from "./KeyMetrics";
import {
  InsightsSection,
  InsightsSectionName,
  InsightsSectionProps,
} from "./Section";
import { TesterOverview } from "./TesterOverview";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconAiInsights } from "./icon-ai-insights.svg";
import srcIconAiInsightsCard from "./icon-ai-insights-card.svg";
import { ReactComponent as SvgIconClose } from "./icon-close.svg";
import { ReactComponent as SvgIconSpinner } from "./icon-spinner.svg";
import { ReactComponent as SvgIconTesterOverviewPlaceholder } from "./icon-tester-overview-placeholder.svg";
import { ReactComponent as SvgIconUpdateNow } from "./icon-update-now.svg";

const PLACEHOLDER_SECTIONS: InsightsSectionProps[] = [
  {
    name: "usability_issue",
    insights: null,
  },
  {
    name: "user_confusion",
    insights: null,
  },
  {
    name: "positive_feedback",
    insights: null,
  },
  {
    name: "user_behavior",
    insights: null,
  },
  {
    name: "suggestion",
    insights: null,
  },
];

export function Insights({
  testHasReport,
  testId,
  screenerCount,
  onClickGoToTesters,
  onClickShowScreeners,
  onClickGetTesters,
  isSample,
}: {
  testHasReport: boolean;
  testId: number;
  screenerCount: number;
  onClickGoToTesters: () => void;
  onClickShowScreeners: () => void;
  onClickGetTesters: () => void;
  isSample: boolean;
}) {
  const insights = useAppSelector((state) => state.testInsights.data?.insights);
  const isSummaryExist = Boolean(insights?.summary);
  const isFetching = useAppSelector((state) => state.testInsights.isFetching);
  const isError = useAppSelector((state) => state.testInsights.isError);
  const error = useAppSelector((state) => state.testInsights.error);
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(
    isSummaryExist ? "summary" : "testerOverview",
  );

  const features = useFeatures();

  const urlTypeformInsights = useUrlTypeformInsights();

  const isUserSubscribedToFeature = features?.ai;
  const isRegenerateable = insights?.refreshable === true;
  const isRegenerating = insights?.insights_status === "processing";

  const parsedSections = useMemo(() => {
    const result: InsightsSectionProps[] = [];
    const insightSections = insights?.insights ?? [];
    insightSections.forEach((insightSection) => {
      const section = result.find((s) => s.name === insightSection.tag);

      if (section?.insights) {
        section.insights.push(insightSection);
      } else {
        result.push({
          name: insightSection.tag as InsightsSectionName,
          insights: [insightSection],
        });
      }
    });
    return result;
  }, [insights]);

  const sections = isRegenerating ? PLACEHOLDER_SECTIONS : parsedSections;

  // Poll for insights if they are still processing
  useEffect(() => {
    function reRequestInsights() {
      dispatch({ type: TEST_INSIGHTS_RE_REQUEST });
    }
    if (isRegenerating) {
      const reloadTestsInsights = setInterval(
        reRequestInsights,
        Number(process.env.REACT_APP_REFRESH_INTERVAL_INSIGHTS ?? 2000),
      );
      return () => {
        clearInterval(reloadTestsInsights);
      };
    }
  }, [dispatch, isRegenerating]);

  function handleClickRegenerate() {
    dispatch({
      type: TEST_INSIGHTS_REFRESH_REQUEST,
      testId,
    });
  }

  if (!insights) {
    return (
      <div className={styles.insights}>
        <div className={cn("card", styles.summary)}>
          {!isFetching && isError && (
            <ErrorMessage>
              {error?.message || "Failed to load AI insights"}
            </ErrorMessage>
          )}
          {isFetching && <Spinner />}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.insights}>
      {isRegenerating && <Regenerating />}
      {!isRegenerating && isRegenerateable && (
        <RegeneratePrompt onClickRequest={handleClickRegenerate} />
      )}

      {isUserSubscribedToFeature && <WelcomeMessage isSample={isSample} />}
      <div className={cn("card", styles.summary)}>
        <div
          className={styles.tabs}
          role="tablist"
          aria-label="AI Insights summary tabs"
        >
          {isSummaryExist && (
            <TabButton
              isActive={activeTab === "summary"}
              id="summaryTab"
              panelId="summaryPanel"
              onClick={() => setActiveTab("summary")}
            >
              Summary
            </TabButton>
          )}
          <TabButton
            isActive={activeTab === "testerOverview"}
            id="testerOverviewTab"
            panelId="testerOverviewPanel"
            onClick={() => setActiveTab("testerOverview")}
            count={insights?.testers?.count}
          >
            Tester overview
          </TabButton>
          <TabButton
            isActive={activeTab === "keyMetrics"}
            id="keyMetricsTab"
            panelId="keyMetricsPanel"
            onClick={() => setActiveTab("keyMetrics")}
          >
            Key metrics
          </TabButton>
        </div>
        {isSummaryExist && (
          <div
            className={cn(
              styles.tab,
              activeTab === "summary" && styles.tab_active,
            )}
            role="tabpanel"
            aria-labelledby="summaryTab"
            id="summaryPanel"
          >
            {insights?.summary}
          </div>
        )}
        <div
          className={cn(
            styles.tab,
            activeTab === "testerOverview" && styles.tab_active,
          )}
          role="tabpanel"
          aria-labelledby="testerOverviewTab"
          id="testerOverviewPanel"
        >
          {insights?.testers && (
            <TesterOverview
              testers={insights.testers}
              onClickGoToTesters={onClickGoToTesters}
              onClickShowScreeners={onClickShowScreeners}
              screenerCount={screenerCount}
            />
          )}
          {!insights?.testers && (
            <>
              <div className={styles.testerOverviewEmptyState}>
                <SvgIconTesterOverviewPlaceholder />
                No testers yet
              </div>
              <ButtonPrimary
                className={styles.testerOverviewEmptyStateButton}
                onClick={onClickGetTesters}
                fullWidth
              >
                <FontAwesomeIcon icon={regular("user-plus")} /> Get testers
              </ButtonPrimary>
            </>
          )}
        </div>
        <div
          className={cn(
            styles.tab,
            activeTab === "keyMetrics" && styles.tab_active,
          )}
          role="tabpanel"
          aria-labelledby="keyMetricsTab"
          id="keyMetricsPanel"
        >
          <KeyMetrics
            metrics={insights.metrics}
            testHasReport={testHasReport}
            testId={testId}
          />
        </div>
      </div>
      {!isUserSubscribedToFeature && <PayWall />}
      {isUserSubscribedToFeature && (
        <>
          {sections.map(({ name, insights }) => (
            <InsightsSection
              key={name}
              className={styles.insightsSection}
              name={name}
              insights={insights}
            />
          ))}
          {sections.length === 0 && (
            <div className={cn("card", styles.emptyState)}>
              <TestTabCardContentEmptyState
                title="Here you will find all AI insights created for your user tests."
                imageSrc={srcIconAiInsightsCard}
                imageAlt="No AI insights have been generated yet."
                content={
                  !isRegenerating &&
                  isRegenerateable && (
                    <Button
                      className={styles.emptyStateButton}
                      onClick={handleClickRegenerate}
                    >
                      Regenerate
                    </Button>
                  )
                }
              />
            </div>
          )}
          {!isSample && (
            <div className={styles.footerLinkContainer}>
              <ExternalLink
                target="_blank"
                rel="noreferrer"
                href={urlTypeformInsights}
              >
                Share your feedback
              </ExternalLink>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function TabButton({
  isActive,
  id,
  panelId,
  onClick,
  children,
  count,
}: {
  isActive: boolean;
  id: string;
  panelId: string;
  onClick: () => void;
  children: React.ReactNode;
  count?: number;
}) {
  return (
    <button
      id={id}
      aria-controls={panelId}
      role="tab"
      type="button"
      aria-selected={isActive ? "true" : "false"}
      className={cn(styles.tabsTab, isActive && styles.tabsTab_active)}
      onClick={onClick}
      tabIndex={isActive ? 0 : -1}
    >
      {children}
      {typeof count === "number" && count > 0 && (
        <div className={styles.tabsTabCount}>{count}</div>
      )}
    </button>
  );
}

function WelcomeMessage({ isSample }: { isSample: boolean }) {
  const [isDismissed, setIsDismissed] = usePersistentState(
    "is-ai-insights-welcome-message-dismissed",
    false,
  );

  const urlTypeformInsights = useUrlTypeformInsights();

  const handleClickDismiss = () => setIsDismissed(true);

  return isDismissed ? null : (
    <div className={styles.welcomeMessage}>
      <div className={styles.welcomeMessageHeader}>
        <SvgIconAiInsights />
        <Heading2 className={styles.welcomeMessageHeading}>
          Welcome to AI Insights!
        </Heading2>
      </div>
      <div className={styles.welcomeMessageText}>
        The insights below are generated by AI. This is the first version of
        what we’re planning to do with AI Insights. We hope you find this useful
        and would love to hear your feedback.
      </div>
      <ButtonGroup align={"left"} className={styles.welcomeMessageButtons}>
        {!isSample && (
          <ButtonPrimary
            as="a"
            target="_blank"
            rel="noreferrer"
            href={urlTypeformInsights}
          >
            Share your feedback
          </ButtonPrimary>
        )}
        {!isSample && (
          <Button
            as="a"
            href={urlInsightsKnowledgeBase}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </Button>
        )}
        {isSample && (
          <ButtonPrimary
            as="a"
            href={urlInsightsKnowledgeBase}
            target="_blank"
            rel="noreferrer"
          >
            Learn more
          </ButtonPrimary>
        )}
      </ButtonGroup>
      <button
        onClick={handleClickDismiss}
        className={styles.welcomeMessageCloseButton}
      >
        <SvgIconClose />
      </button>
    </div>
  );
}

function PayWall() {
  const dispatch = useAppDispatch();

  function handleClickExplorePlans() {
    dispatch({
      type: "GLOBAL_MODAL_OPEN",
      modal: "explorePlans",
    });
  }

  return (
    <div className={cn("card", styles.payWall)}>
      <TestTabCardContentLocked
        imageSrc={srcIconAiInsightsCard}
        imageAlt={"AI stars"}
        textWidth={"24em"}
        title={"Unlock AI Insights"}
        text={
          <>
            Uncover the most important themes and patterns across your user
            tests instantly.{" "}
            <a
              style={{ color: "inherit" }}
              href={urlInsightsKnowledgeBase}
              target="_blank"
              rel="noreferrer"
            >
              Learn&nbsp;more
            </a>
          </>
        }
        onClickExplorePlans={handleClickExplorePlans}
      />
    </div>
  );
}

function RegeneratePrompt({ onClickRequest }: { onClickRequest: () => void }) {
  return (
    <div aria-busy={false} className={styles.regeneratePrompt}>
      <SvgIconUpdateNow className={styles.regeneratePromptIcon} />
      <div>
        There might be new AI Insights based on your notes and clips&nbsp;
        <Tooltip
          content={
            "Only notes and clips using the same tags as our AI assistant (#usability_issue, #positive_feedback, etc.) will be considered."
          }
        />
      </div>
      <Button
        className={styles.regeneratePromptButton}
        onClick={onClickRequest}
      >
        Regenerate
      </Button>
    </div>
  );
}

function Regenerating() {
  return (
    <div aria-busy={true} className={styles.regeneratePrompt}>
      <SvgIconUpdateNow className={styles.regeneratePromptIcon} />
      <div>Regenerating AI Insights…</div>

      <SvgIconSpinner
        aria-label="Loading..."
        className={styles.regeneratePromptSpinner}
      />
    </div>
  );
}
