import React, { Component } from "react";
import { CardNumberElement, ElementsConsumer } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PaymentForm } from "@/components/PaymentForm";
import { Button, ButtonPrimary, ErrorMessage, Stack } from "@/design-system";
import ButtonGroup from "../../components/ButtonGroup";
import { noop } from "../../helpers";

import styles from "./styles.module.css";

class UpdateCreditCardForm extends Component {
  static defaultProps = {
    onClickCancel: noop,
    showCancelButton: false,
  };

  static propTypes = {
    onClickCancel: PropTypes.func.isRequired,
    showCancelButton: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      handleCardSetupFetching: false,
      handleCardSetupErrorMessage: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.setupCardFetching === true &&
      this.props.setupCardFetching === false &&
      this.props.setupCardError === false
    ) {
      if (typeof this.props.onSuccess === "function") {
        this.props.onSuccess();
      }
    }

    if (
      prevProps.setupClientSecretFetching === true &&
      this.props.setupClientSecretFetching === false
    ) {
      if (this.props.setupClientSecretError === false) {
        const { setupClientSecret } = this.props;

        this.setState({ handleCardSetupFetching: true });
        this.props.stripe
          .confirmCardSetup(setupClientSecret, {
            payment_method: {
              card: this.props.elements.getElement(CardNumberElement),
            },
          })
          .then((result) => {
            this.setState({ handleCardSetupFetching: false });
            if (result.error) {
              const { error } = result;
              this.setState({ handleCardSetupErrorMessage: error.message });
            } else {
              const { setupIntent } = result;
              this.props.paymentSetupCard(setupIntent.payment_method);
            }
          });
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ handleCardSetupErrorMessage: false });
    this.props.paymentSetupIntent();
  };

  render() {
    const {
      setupClientSecretError,
      setupClientSecretFetching,
      setupCardFetching,
      setupCardError,
      showCancelButton,
      onClickCancel,
    } = this.props;
    const { handleCardSetupFetching, handleCardSetupErrorMessage } = this.state;

    const cardFetching =
      setupClientSecretFetching || setupCardFetching || handleCardSetupFetching;

    return (
      <form onSubmit={this.onSubmit}>
        <Stack>
          {setupCardError && <ErrorMessage message={setupCardError} />}
          {setupClientSecretError && (
            <ErrorMessage message={setupClientSecretError} />
          )}
          {handleCardSetupErrorMessage && (
            <ErrorMessage message={handleCardSetupErrorMessage} />
          )}
          <PaymentForm />
        </Stack>
        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          <ButtonPrimary type={"submit"} disabled={cardFetching}>
            {/*setupClientSecretFetching && 'Retrieving client secret'}
                {handleCardSetupFetching && 'Confirming card identity'}
                {setupCardFetching && 'Setting up card'*/}
            {cardFetching ? "Saving card…" : "Save card"}
          </ButtonPrimary>

          {showCancelButton && (
            <Button disabled={cardFetching} onClick={onClickCancel}>
              Cancel
            </Button>
          )}
        </ButtonGroup>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    setupClientSecretFetching: state.payment.setupClientSecretFetching,
    setupClientSecretError: state.payment.setupClientSecretError,
    setupClientSecret: state.payment.setupClientSecret,
    setupCardFetching: state.payment.setupCardFetching,
    setupCardError: state.payment.setupCardError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paymentSetupIntent: () =>
      dispatch({ type: "PAYMENT_SETUP_INTENT_REQUEST" }),
    paymentSetupCard: (paymentMethod) =>
      dispatch({ type: "PAYMENT_SETUP_CARD_REQUEST", paymentMethod }),
  };
};

const InjectedUpdateCreditCardForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <UpdateCreditCardForm stripe={stripe} elements={elements} {...props} />
    )}
  </ElementsConsumer>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InjectedUpdateCreditCardForm);
