import { Answer, Question } from "../types";

export function isUpdatedAnswerVirgin(
  originalQuestion: Question,
  originalAnswer: Answer,
  updatedAnswer: Answer,
): boolean {
  let answerTypeVirgin = !!originalQuestion.answerTypeVirgin;

  if (
    answerTypeVirgin &&
    (originalAnswer.typeMultipleChoice !== updatedAnswer.typeMultipleChoice ||
      originalAnswer.typeSingleChoice !== updatedAnswer.typeSingleChoice)
  ) {
    answerTypeVirgin = false;
  }

  return answerTypeVirgin;
}
