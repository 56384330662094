import React, { Component } from "react";
import { connect } from "react-redux";

import { MainNavigation, MainNavigationContent } from "@/design-system";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
// import styles from './styles.module.css';

class Discover extends Component {
  render() {
    return (
      <>
        <MainNavigation>
          <Navigation />
          <MainNavigationContent>
            <Notifications />
            Discover
            {/*<DropDown>
              <DropDownItem />
              <DropDownItem />
              <DropDownItem />
            </DropDown>
            <Searchbox placeholder={'Search for Notes, URL or Task'} />
            <Link><strong><FontAwesomeIcon icon={regular('filter')} /> Filter</strong></Link>
            <Table>
              <tbody>
              {this.state.videos.map(e => (<tr key={e.id}>
                <td>Thumbnail</td>
                <td>{e.title}<br />Tags</td>
                <td>Testername</td>
                <td>Jul 17</td>
                <td>12:00 min</td>
                <td>Windows 10</td>
              </tr>))}
              </tbody>
            </Table>*/}
          </MainNavigationContent>
        </MainNavigation>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Discover);
