import { concatClassNames as cn } from "@system42/core";
import { useDispatch, useSelector } from "react-redux";

import { ButtonLink } from "@/design-system";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconMail } from "./icon-mail.svg";

export function Card() {
  const userEmailAddress = useSelector(({ user }) => user.user?.email);

  return (
    <CardContainer>
      <CardContents userEmailAddress={userEmailAddress} />
      <Footer />
    </CardContainer>
  );
}

export function CardContainer({ children }) {
  return <div className={cn("card", styles.card)}>{children}</div>;
}

export function CardContents({ userEmailAddress }) {
  return (
    <>
      <SvgIconMail className={styles.svgIconMail} />
      <h1 className={styles.title}>Please verify your email address</h1>
      <div className={styles.subtitle}>
        We have sent a verification email to {userEmailAddress}.
      </div>
    </>
  );
}

function Footer() {
  const dispatch = useDispatch();

  function handleResendEmail() {
    dispatch({ type: "CONFIRM_EMAIL_RESEND_REQUEST" });
  }

  return (
    <div className={styles.cardFooter}>
      <div className={styles.smallPrint}>
        <ButtonLink onClick={handleResendEmail}>Resend email</ButtonLink> or
        check your spam/junk folder if you didn't receive an email.
      </div>
      <div className={styles.smallPrint}>
        <a href="mailto:support@userbrain.com">Contact support</a> or check out
        our{" "}
        <a
          href="https://help.userbrain.com/help/account"
          target="_blank"
          rel="noreferrer"
        >
          knowledge base
        </a>{" "}
        if you have any questions.
      </div>
    </div>
  );
}
