import { Question } from "../types";

export function getQuestionAdvice(question: Question) {
  // No reject
  if (
    question.answers.length > 1 &&
    ((question.type === "single_choice" &&
      question.answers.filter((answer) => answer.typeSingleChoice === "reject")
        .length === 0) ||
      (question.type === "multiple_choice" &&
        question.answers.filter(
          (answer) => answer.typeMultipleChoice === "reject",
        ).length === 0 &&
        question.answers.filter(
          (answer) => answer.typeMultipleChoice === "must_select",
        ).length === 0)) &&
    question.answerTypeVirgin !== true
  ) {
    return "allpass";
  }

  // Yes/No
  if (
    question.answers.length === 2 &&
    question.answers.find((answer) =>
      ["SÍ", "SI", "YES", "JA"].includes(answer?.text.trim().toUpperCase()),
    ) &&
    question.answers.find((answer) =>
      ["NO", "NEIN"].includes(answer?.text.trim().toUpperCase()),
    )
  ) {
    return "yesno";
  }

  // More than 6 answers
  if (question.answers.length > 6) {
    return "morethan6";
  }
}
