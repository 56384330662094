import { createContext, ReactNode, useContext } from "react";
import { useLocation } from "react-router-dom";

import { usePrevious } from "../helpers";

export const PreviousLocationContext = createContext(
  undefined as undefined | Location,
);

export function PreviousLocationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const previousLocation = usePrevious(useLocation());
  return (
    <PreviousLocationContext.Provider value={previousLocation}>
      {children}
    </PreviousLocationContext.Provider>
  );
}

export const usePreviousLocation = () => useContext(PreviousLocationContext);
