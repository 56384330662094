import { useFreeTrial } from "./freeTrialHooks";
import { useCreditsAvailable } from "./useCreditsAvailable";
import { useSubscription } from "./useSubscription";

export function useDefaultOrderQuantity() {
  const freeTrial = useFreeTrial();
  const subscription = useSubscription();
  const creditsAvailable = useCreditsAvailable() ?? 0;
  if (freeTrial?.isActive && !subscription && creditsAvailable < 3) {
    return 2;
  }
  return 5;
}
