import React from "react";
import { concatClassNames as cn } from "@system42/core";

import { Card } from "@/design-system";
import { UsageBoxItem } from "../UsageBoxItem";

// import { } from '../../system42'
import styles from "./styles.module.css";

export function UsageBoxTeamMembers(props) {
  const {
    className,
    title,
    usedCollaborators,
    maxCollaborators,
    usedAdmins,
    maxAdmins,
    ...containerProps
  } = props;

  return (
    <Card className={cn(className, styles.usageBox)} {...containerProps}>
      <div className={styles.header}>
        <h1>Your usage</h1>
      </div>
      <div className={styles.usageItems}>
        <UsageBoxItem
          className={styles.usageBoxItem}
          title={"Admins:"}
          amountUsed={usedAdmins}
          amountAvailable={maxAdmins}
          meterTitle={"Admins available"}
        />
        <UsageBoxItem
          className={styles.usageBoxItem}
          title={"Collaborators:"}
          amountUsed={usedCollaborators}
          amountAvailable={maxCollaborators}
          meterTitle={"Collaborators available"}
        />
      </div>
    </Card>
  );
}
