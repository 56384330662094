import React from "react";
import { concatClassNames as cn } from "@system42/core";

import styles from "./styles.module.css";

export default function FeatureList({
  children,
  className,
  ...containerProps
}) {
  return (
    <li className={cn(className, styles.featureListItem)} {...containerProps}>
      {children}
    </li>
  );
}
