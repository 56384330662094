import React from "react";
import { concatClassNames as cn } from "@system42/core";

import { ButtonPrimary } from "@/design-system";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconLocked } from "./icon-lock.svg";

export function FeatureGateMessage(props) {
  const {
    className,
    title,
    onClickUpgrade,
    showLockIcon,
    children,
    ...restProps
  } = props;

  return (
    <section
      className={cn(className, styles.featureGateMessage)}
      {...restProps}
    >
      {showLockIcon && (
        <div className={styles.icon}>
          <SvgIconLocked />
        </div>
      )}
      <div className={styles.content}>
        <div>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.text}>{children}</div>
        </div>
        <ButtonPrimary className={styles.button} onClick={onClickUpgrade}>
          Upgrade plan
        </ButtonPrimary>
      </div>
    </section>
  );
}
