import React from "react";
import { concatClassNames } from "@system42/core";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class MainNavigationAccount extends React.Component {
  static defaultProps = { styles };

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.close();
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 27 /* ESC */) {
      this.close();
    }
  };

  close = () => {
    this.setState({ menuOpen: false });
  };

  onClickMenuTrigger = () => {
    this.setState((prevState) => ({ menuOpen: !prevState.menuOpen }));
  };

  render() {
    const { children, className, styles, username } = this.props;
    const { menuOpen } = this.state;

    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(
          className,
          styles.root,
          menuOpen && styles.menuOpen,
        )}
        ref={this.wrapperRef}
      >
        <div className={styles.menuTrigger} onClick={this.onClickMenuTrigger}>
          {username}
        </div>
        {menuOpen && <div className={styles.menu}>{children}</div>}
      </div>
    );
  }
}

export default MainNavigationAccount;
