import axiosLib from "axios";
const authAxios = axiosLib.create();

authAxios.defaults.headers.common["X-USERBRAIN-App-Version"] =
  process.env.REACT_APP_VERSION ?? "0.0.0";

export type AuthToken = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  token_type: string;
};

export type UserCredentials = {
  username: string;
  password: string;
};

export const AUTH_LOCAL_STORAGE_KEY = "authToken";
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL;
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export function readAuthToken(): AuthToken | null {
  const authTokenStorageValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);

  if (authTokenStorageValue === null) {
    return null;
  }

  return parseAuthToken(authTokenStorageValue);
}

export function writeAuthToken(token: AuthToken) {
  localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, JSON.stringify(token));
}

export function deleteAuthToken() {
  localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
}

export function parseAuthToken(stringifiedAuthToken: string): AuthToken | null {
  try {
    // TODO: Validate data (could be anything, not only AuthToken)
    return JSON.parse(stringifiedAuthToken) as AuthToken;
  } catch (e) {
    console.error("Error parsing auth token: ", e);
    return null;
  }
}

export async function authenticate(credentials: UserCredentials) {
  const { username, password } = credentials;
  const response = await authAxios({
    method: "post",
    url: OAUTH_URL,
    data: {
      grant_type: "password",
      client_id: 2,
      client_secret: CLIENT_SECRET,
      username,
      password,
      scope: "*",
    },
  });
  writeAuthToken(response.data);
}

export const authRefresh = {
  promise: Promise.resolve(),
  isRefreshing: false,
};

/**
 * Refreshes the access token in storage.
 * @param refreshToken - The refresh token used to obtain a new access token.
 */
export async function refreshAccessToken() {
  async function startNewRefresh() {
    const token = readAuthToken();
    const refreshToken = token?.refresh_token;

    if (!refreshToken) {
      throw new Error("No refresh token found in storage.");
    }

    const response = await authAxios({
      method: "post",
      url: OAUTH_URL,
      data: {
        grant_type: "refresh_token",
        client_id: 2,
        client_secret: CLIENT_SECRET,
        refresh_token: refreshToken,
        scope: "*",
      },
    });
    writeAuthToken(response.data);
  }

  if (authRefresh.isRefreshing === true) {
    throw new Error(
      "A new refresh token request can not be started while another one is in progress.",
    );
  }

  authRefresh.isRefreshing = true;
  authRefresh.promise = startNewRefresh();
  try {
    await authRefresh.promise;
  } finally {
    authRefresh.isRefreshing = false;
    authRefresh.promise = Promise.resolve();
  }
}
