import { useContext, useRef } from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import { Link } from "react-router-dom";

import { Avatar } from "@/design-system";
import { ReportContext } from "../../../containers/Test";
import {
  formatDateWithoutTime,
  getTesterInitials,
  getTesterNameFromTester,
  toHMSText,
} from "../../../helpers";
import { NoteWithHashtags } from "../../NoteWithHashtags";
import { DifferingTestStepInfo } from "../DifferingTestStepInfo";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconClip } from "./icon-clip.svg";
import { ReactComponent as SvgIconNote } from "./icon-note.svg";

export function TaskResponseItem(props) {
  const {
    className,
    video,
    timeOnTask,
    timestampCompleted,
    testId,
    children,
    notes,
    clips,
    differingTestStepTooltipContent,
    ...containerProps
  } = props;

  const { showAutomatedInsights } = useContext(ReportContext);

  const linkTo = `/video/${video.id}?t=${
    timestampCompleted - timeOnTask
  }&autoplay=1`;

  const refSelectionTextMouseDown = useRef(() =>
    document.getSelection().toString(),
  );

  const testerName = getTesterNameFromTester(video.tester);

  function handleMouseDown() {
    refSelectionTextMouseDown.current = document.getSelection().toString();
  }

  function handleClick(e) {
    // prevent the click if the user selects text
    // do allow the click if the user selected the same text, this is done because otherwise
    // a cmd click would not fire while text is selected somewhere on the page…
    const selection = document.getSelection();
    if (
      selection.type === "Range" &&
      document.getSelection().toString() !== refSelectionTextMouseDown.current
    ) {
      e.preventDefault();
    }
  }

  function handleClickNote(e) {
    // prevent the click if the user selects text
    // do allow the click if the user selected the same text, this is done because otherwise
    // a cmd click would not fire while text is selected somewhere on the page…
    const selection = document.getSelection();
    if (
      selection.type === "Range" &&
      document.getSelection().toString() !== refSelectionTextMouseDown.current
    ) {
      e.preventDefault();
    }
  }

  const notesAndClips = [
    ...notes.map((note) => ({ ...note, isClip: false })),
    ...clips.map((clip) => ({ ...clip, isClip: true })),
  ].sort((a, b) => a.timestamp - b.timestamp);

  return (
    <div
      className={cn(
        className,
        styles.taskResponseItem,
        video.new && styles.taskResponseItem_new,
      )}
      {...containerProps}
      onMouseDown={handleMouseDown}
    >
      <Link
        className={styles.responseInfo}
        to={linkTo}
        draggable={false}
        onClick={handleClick}
      >
        <Avatar
          className={styles.avatar}
          initials={getTesterInitials(testerName)}
          colorSeed={video.tester.id}
          starred={video.starred}
          title={testerName}
        />
        <div className={styles.responseContent}>
          {children}

          <div className={styles.details}>
            <div className={styles.playVideo}>
              <div>
                <FontAwesomeIcon icon={regular("circle-play")} />
              </div>
              <div className={styles.playVideoLabel} title={"Time on task"}>
                {toHMSText(timeOnTask)}
              </div>
              <div
                className={styles.playVideoLabelHover}
                title={"Time on task"}
              >
                {toHMSText(timeOnTask)}
              </div>
            </div>
            {differingTestStepTooltipContent && (
              <DifferingTestStepInfo>
                {differingTestStepTooltipContent}
              </DifferingTestStepInfo>
            )}
          </div>
        </div>
      </Link>
      <div>
        {notesAndClips
          .filter(
            (noteOrClip) =>
              showAutomatedInsights === true ||
              noteOrClip.automated_insight === false,
          )
          .map((noteOrClip) => (
            <Link
              className={styles.comment}
              to={`/video/${video.id}?t=${noteOrClip.timestamp}&autoplay=1&${
                noteOrClip.isClip ? "clip" : "note"
              }=${noteOrClip.id}`}
              draggable={false}
              key={noteOrClip.id}
              onClick={handleClickNote}
            >
              <div className={styles.commentPlayVideo} title={"Play video"}>
                {noteOrClip.isClip && (
                  <SvgIconClip className={styles.commentIconItem} />
                )}
                {!noteOrClip.isClip && (
                  <SvgIconNote className={styles.commentIconItem} />
                )}
                <FontAwesomeIcon
                  icon={regular("circle-play")}
                  className={styles.commentIconPlay}
                />
              </div>

              <div className={styles.commentText}>
                {noteOrClip.automated_insight && (
                  <span className={styles.commentAutomatedInsightLabel}>
                    AI:{" "}
                  </span>
                )}
                <NoteWithHashtags
                  note={noteOrClip.note}
                  classNameHashtag={styles.noteOrClipHashtag}
                />
                {noteOrClip.automated_insight &&
                  noteOrClip.edited_at != null && (
                    <div className={styles.noteOrClipAuthor}>
                      Edited by {noteOrClip.user} on{" "}
                      {formatDateWithoutTime(new Date(noteOrClip.edited_at))}
                    </div>
                  )}
                {!noteOrClip.automated_insight && (
                  <div
                    className={styles.noteOrClipAuthor}
                    title={noteOrClip.user}
                  >
                    by {noteOrClip.is_mine ? "me" : noteOrClip.user}
                  </div>
                )}
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}
