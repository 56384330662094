/**
 *
 * Create Device Detail String
 */
export function createDeviceDetailString({
  videoDeviceDetails,
  videoDeviceOs,
  isFull,
}: {
  videoDeviceDetails?: string;
  videoDeviceOs?: string;
  isFull?: boolean;
}) {
  if (
    !isFull &&
    videoDeviceDetails &&
    videoDeviceDetails.startsWith("Chrome")
  ) {
    videoDeviceDetails = videoDeviceDetails.substring(
      0,
      videoDeviceDetails.indexOf("."),
    );
  }

  return [videoDeviceOs, videoDeviceDetails]
    .filter((i) => !!i)
    .map((i) => i?.trim?.())
    .join(", ");
}
