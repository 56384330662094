import { useState } from "react";

import { FullscreenModal2 } from "@/components/Modal";
import Spinner from "@/components/Spinner";
import {
  PLAN_AGENCY,
  PLAN_PAYG,
  PLAN_PRO,
  PLAN_STARTER,
  PlanInfoBox,
} from "../../components/PlanInfoBox";
import { UserbrainPlans } from "../../components/UserbrainPlans";
import { BILLING_CYCLE_ANNUAL } from "../../helpers";
import { useCurrency } from "../../hooks/useCurrency";
import { usePricing } from "../../hooks/usePricing";

import styles from "./styles.module.css";

export function ExplorePlansModal({
  payAsYouGo,
  isActive,
  onClose,
  onClickChooseStarter,
  onClickChoosePro,
  onClickChooseAgency,
  onClickChoosePayg,
}) {
  return (
    <FullscreenModal2
      isActive={isActive}
      onClose={onClose}
      content={
        <ExplorePlansModalContent
          payAsYouGo={payAsYouGo}
          onClickChooseStarter={onClickChooseStarter}
          onClickChoosePro={onClickChoosePro}
          onClickChooseAgency={onClickChooseAgency}
          onClickChoosePayg={onClickChoosePayg}
        />
      }
    />
  );
}

function ExplorePlansModalContent(props) {
  const {
    payAsYouGo,
    onClickChooseStarter,
    onClickChoosePro,
    onClickChooseAgency,
    onClickChoosePayg,
  } = props;

  const [billingCycle, setBillingCycle] = useState(BILLING_CYCLE_ANNUAL);

  function handleChangeBillingCycle(e) {
    setBillingCycle(e.target.value);
  }

  const pricing = usePricing();
  const currency = useCurrency();

  function handleClickChooseStarter() {
    onClickChooseStarter(billingCycle);
  }

  function handleClickChoosePro() {
    onClickChoosePro(billingCycle);
  }

  function handleClickChooseAgency() {
    onClickChooseAgency(billingCycle);
  }

  function handleClickChoosePayg() {
    onClickChoosePayg();
  }

  const isLoading = pricing === null || currency === null;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {!isLoading && (
        <div className={styles.content}>
          <UserbrainPlans
            title={"Choose the right plan for you"}
            onChangeBillingCycle={handleChangeBillingCycle}
            billingCycle={billingCycle}
            twoColLayout={!!payAsYouGo}
            /*infoBox={<>📢 We’ll increase the prices for all Userbrain plans on October 11th. Save up to 20% by subscribing now. <ButtonLink href={'https://www.userbrain.com/blog/updates-to-userbrains-pricing'} target={'_blank'}>Learn more</ButtonLink></>}*/
          >
            {payAsYouGo && (
              <PlanInfoBox
                plan={PLAN_PAYG}
                paygPrice={pricing.payg / 100}
                currency={currency}
                onClickButton={handleClickChoosePayg}
                payAsYouGo={payAsYouGo}
              />
            )}
            <PlanInfoBox
              plan={PLAN_STARTER}
              monthlyPrice={pricing.starter / 100}
              annualPrice={pricing.starter_annual / 100}
              currency={currency}
              billingCycle={billingCycle}
              onClickButton={handleClickChooseStarter}
            />
            <PlanInfoBox
              plan={PLAN_PRO}
              monthlyPrice={pricing.pro / 100}
              annualPrice={pricing.pro_annual / 100}
              currency={currency}
              billingCycle={billingCycle}
              onClickButton={handleClickChoosePro}
            />
            <PlanInfoBox
              plan={PLAN_AGENCY}
              monthlyPrice={pricing.agency / 100}
              annualPrice={pricing.agency_annual / 100}
              currency={currency}
              billingCycle={billingCycle}
              onClickButton={handleClickChooseAgency}
            />
          </UserbrainPlans>
        </div>
      )}
    </>
  );
}
