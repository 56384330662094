import { useSelector } from "react-redux";

/**
 * @typedef UserbrainPricing
 * @type {object}
 * @property {number} payg - Price of one single user test
 * @property {Array[object]} payg_packages - Amounts of bonus_credits per quantity { from, bonus_credits }
 * @property {number} pro - Price of the pro plan
 * @property {number} agency - Price of the agency plan
 */

/**
 * Get pricing object
 * @returns {UserbrainPricing|null} Returns the pricing object
 */
export function usePricing() {
  return useSelector(({ user }) => (user.user ? user.user.pricing : null));
}
