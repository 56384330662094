import { Answer, ApiTestAnswer, ApiTestQeury, Question } from "./types";

export function parseApiQueryToScreenerQuestion(query: ApiTestQeury): Question {
  return {
    text: query.question,
    type: query.type,
    answers: query.answers.map((answer) =>
      parseApiAnswerToAnswer(answer, query.type === "single_choice"),
    ),
  };
}

export function parseApiAnswerToAnswer(
  apiAnswer: ApiTestAnswer,
  isSingleChoice: boolean,
): Answer {
  const answer: Answer = {
    text: apiAnswer.title,
  };

  if (isSingleChoice) {
    answer.typeSingleChoice = apiAnswer.type;
  } else {
    answer.typeMultipleChoice = apiAnswer.type;
  }

  return answer;
}
