import React from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import dayjs from "dayjs";
import relativeTime from "dayjs";
import { Link, useNavigate } from "react-router-dom";

import { Avatar, Tooltip } from "@/design-system";
import {
  durationToTimeString,
  formatDateWithoutTime,
  getTesterInitials,
  getTesterNameFromTester,
} from "../../helpers";
import { DeviceIconAndName } from "../DeviceIconAndName";
import { RatingStars } from "../RatingStars";
import { TesterTargetingSummary } from "../TesterTargetingSummary";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconClip } from "./icon-clip.svg";
import { ReactComponent as SvgIconNote } from "./icon-note.svg";

dayjs.extend(relativeTime);

export default function VideoListItem(props) {
  const { type, video, showTitle, className } = props;

  const testerName = video.tester?.name;
  const testerInitials = getTesterInitials(testerName);

  const rating = video.rating;
  const starred = !!video.starred;
  const videoUrl = `/video/${video.id}`;

  const navigate = useNavigate();

  function handleClickVideo() {
    if (type !== "testingVideo") {
      const selection = document.getSelection();
      if (selection.type !== "Range") {
        navigate(`${videoUrl}`);
      }
    }
  }

  let statusContent;
  switch (video.status) {
    case "uploading":
      statusContent = (
        <Tooltip
          content={
            "The tester has finished recording and the video is currently uploading."
          }
        >
          <FontAwesomeIcon
            className={styles.statusIcon}
            fixedWidth
            icon={solid("arrow-up")}
          />{" "}
          Uploading
        </Tooltip>
      );
      break;
    case "processing":
      statusContent = (
        <Tooltip
          content={
            "The video file is currently being processed and will be ready for you soon."
          }
        >
          <FontAwesomeIcon
            className={styles.statusIcon}
            fixedWidth
            icon={regular("server")}
          />{" "}
          Processing
        </Tooltip>
      );
      break;
    case "pending":
      statusContent = (
        <Tooltip
          content={
            "We’re manually reviewing this video. This might take a few hours."
          }
        >
          <FontAwesomeIcon
            className={styles.statusIcon}
            fixedWidth
            icon={solid("eye")}
          />{" "}
          In Review
        </Tooltip>
      );
      break;
    case "started":
    case "testing":
    default:
      statusContent = (
        <Tooltip
          content={
            "The tester has started the session and is currently recording."
          }
        >
          <FontAwesomeIcon
            className={styles.statusIcon}
            fixedWidth
            icon={solid("circle")}
          />{" "}
          Recording
        </Tooltip>
      );
      break;
  }

  return (
    <div
      data-testid={`test-video-card-${video.id}`}
      className={cn(
        className,
        type === "testingVideo" && styles.videoListItem_testing,
        showTitle && styles.videoListItem_withTitle,
        styles.videoListItem,
        video.new && styles.videoListItem_new,
      )}
      onClick={handleClickVideo}
    >
      <Avatar
        title={testerName}
        colorSeed={video.tester.id}
        initials={testerInitials}
        starred={starred}
      />

      <div className={styles.testDetails}>
        <div className={styles.testerDetails}>
          <div className={styles.testerName}>
            {getTesterNameFromTester(video.tester, true)}
          </div>
          <TesterTargetingSummary
            className={styles.targetingSummary}
            tester={video.tester}
          />
          {rating > 0 && (
            <RatingStars className={styles.ratingStars} rating={rating} />
          )}
        </div>

        {showTitle && (
          <div className={styles.title} title={video.title}>
            {video.title}
          </div>
        )}

        <DeviceIconAndName className={styles.device} device={video.device} />

        <div className={styles.date}>
          {formatDateWithoutTime(new Date(video.created_at))}
        </div>

        <Link
          onClick={
            (e) =>
              e.stopPropagation() /* Prevent calling onclick of whole list item */
          }
          className={styles.notesAndClips}
          title={"See all notes and clips for this test session"}
          to={`/video/${video.id}?notes`}
        >
          {video.count_notes > 0 && (
            <span className={styles.noteOrClipCount}>
              <SvgIconNote className={styles.noteOrClipIcon} />
              {video.count_notes}
            </span>
          )}
          {video.count_clips > 0 && (
            <span className={styles.noteOrClipCount}>
              <SvgIconClip className={styles.noteOrClipIcon} />
              {video.count_clips}
            </span>
          )}
        </Link>
      </div>

      {type === "testingVideo" && (
        <div
          className={cn(
            styles.statusInfo,
            (video.status === "testing" || video.status === "started") &&
              styles.statusInfo_recording,
          )}
        >
          {statusContent}
        </div>
      )}

      {type !== "testingVideo" && (
        <Link
          onClick={
            (e) =>
              e.stopPropagation() /* Prevent calling onclick of whole list item */
          }
          className={styles.buttonPlayVideo}
          title={`Play video`}
          to={videoUrl}
        >
          <FontAwesomeIcon
            className={styles.buttonPlayVideoIcon}
            icon={solid("play")}
          />{" "}
          {durationToTimeString(video.duration)}
        </Link>
      )}
    </div>
  );
}
