import { LoaderFunctionArgs } from "react-router-dom";

import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";

async function loader({ request }: LoaderFunctionArgs) {
  const search = new URL(request.url).searchParams.toString();

  const { testsRequestAction } = store.getState().tests as any;

  if (testsRequestAction?.search === search) {
    // If the search is the same, we can just re-request the tests.
    // loading will not be visible to the user and the browser back button
    // can restore the previous scroll position.
    store.dispatch({
      type: "TESTS_RE_REQUEST",
    });
  } else {
    store.dispatch({
      type: "TESTS_REQUEST",
      search,
    });
  }

  return null;
}

export default authRequiredWrapper(loader);
