import "normalize.css";
import "./design-system/global-variables.css";
import "./design-system/global.css";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";

import { enableTracking } from "@/helpers";
import { createRouter } from "@/routes";
import { store } from "@/store";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

declare global {
  interface Window {
    enableTracking: () => void;
  }
}

// Create the Stripe object
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PRIVATE_KEY ?? "",
);

if (process.env.REACT_APP_HOST_ENV === "live") {
  window.enableTracking = enableTracking;
} else {
  // When DEV MODE
  window.enableTracking = function () {
    console.log("Tracking activated!");
    window.enableTracking = function () {};
  };
}

const currentUrl = new URL(window.location.href);

if (process.env.REACT_APP_HOST_ENV === "live") {
  if (currentUrl.hostname === "dashboard.userbrain.net") {
    currentUrl.hostname = "dashboard.userbrain.com";
    window.location.assign(currentUrl);
  }
} else {
  if (currentUrl.hostname === "dashboard.dev.userbrain.net") {
    currentUrl.hostname = "dashboard.dev.userbrain.com";
    window.location.assign(currentUrl);
  }
}

if (
  document.cookie.indexOf("ncc=true") >= 0 ||
  document.cookie.indexOf("CookieConsent=true") >= 0 ||
  document.cookie.indexOf("cookie_consent=true") >= 0
) {
  window.enableTracking();
}

const router = createRouter();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  //<React.StrictMode>
  <Provider store={store}>
    <Elements options={{ locale: "en" }} stripe={stripePromise}>
      <RouterProvider router={router} />
    </Elements>
  </Provider>,
  //</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
