import React from "react";
import { concatClassNames } from "@system42/core";
import PropTypes from "prop-types";

import { extractContainerProps, noop } from "../../helpers";
import DropDown from "../DropDown";
import DropDownItem from "../DropDownItem";

import styles from "./styles.module.css";

class VideoCountSelect extends React.Component {
  static defaultProps = {
    selected: 1,
    countVisible: 5,
    onChange: noop,
    styles,
  };

  render() {
    const {
      className,
      description,
      max,
      min,
      maxAvailable,
      minAvailable,
      styles,
      selected,
      onChange,
    } = this.props;

    const numbers = [];
    for (let number = min; number <= max; number++) {
      numbers.push(number);
    }

    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(
          className,
          styles.dropDownVideoCountContainer,
        )}
      >
        <DropDown
          value={selected}
          className={styles.dropDownVideoCount}
          onChange={onChange}
          disabled={maxAvailable === 0}
          isOptionDisabled={(option) =>
            option.value < minAvailable || option.value > maxAvailable
          }
        >
          {numbers.map((item) => (
            <DropDownItem value={item} key={item}>
              {item}
            </DropDownItem>
          ))}
        </DropDown>{" "}
        <div className={styles.dropDownVideoCountLabel}>{description}</div>
      </div>
    );
  }
}

VideoCountSelect.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func,
};

export default VideoCountSelect;
