import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import { findDOMNode } from "react-dom";
import ReactPlayer from "react-player/lazy";
import screenfull from "screenfull";

import { md5 } from "@/helpers";
import { usePrevious } from "@/helpers-ts";
import { useScreenfullIsFullscreen } from "@/hooks/useScreenfullIsFullscreen";
import { useSessionStorage } from "@/hooks/useSessionStorage";
import useUserbrainPlayerHotkeys from "@/hooks/useUserbrainPlayerHotkeys";

import { PlayerControlBar } from "./PlayerControlBar";
import { VideoSubtitles } from "./VideoSubtitles";

import styles from "./styles.module.css";

export function createUserbrainPlayerApi(
  refReactPlayer,
  refVideoPlayer,
  playing,
  setPlaying,
  setAutomaticallyPauseAt,
) {
  const p = refReactPlayer.current;
  const v = refVideoPlayer.current;
  return {
    currentTime: (timestamp) => {
      if (typeof timestamp === "undefined") {
        return p?.getCurrentTime();
      }
      p?.seekTo(timestamp, "seconds");
    },
    play: () => setPlaying(true),
    playUntil: (timestampEnd) => {
      setAutomaticallyPauseAt(timestampEnd);
      setPlaying(true);
    },
    pause: () => setPlaying(false),
    togglePlay: () => setPlaying((playing) => !playing),
    playing: playing,
    isFullscreen: () => screenfull.isFullscreen,
    requestFullscreen: () => {
      if (screenfull.isEnabled && v) {
        screenfull.request(findDOMNode(v));
      } else {
        const video = p?.getInternalPlayer();
        if (video?.webkitSupportsFullscreen) {
          video.webkitEnterFullscreen();
        }
      }
    },
    exitFullscreen: () => {
      if (screenfull.isEnabled) {
        screenfull.exit();
      } else {
        const video = p?.getInternalPlayer();
        if (video?.webkitSupportsFullscreen) {
          video.webkitExitFullscreen();
        }
      }
    },
  };
}

export function VideoPlayer(props) {
  const {
    location,
    // sources, - not used yet, but will be used for different video formats
    type,
    autoplay,
    resumeKey,
    initialTime,
    vtt,
    poster,
    transcriptVttUrl,
    transcriptAvailable,
    thumbnailSprite,
    onPlay,
    onPause,
    onStarted,
    onEnded,
    onSecondChange,
    nextJumpPoint,
    previousJumpPoint,
    className,
    refPlayer,
    isVideoProcessing,
  } = props;

  const [userbrainPlayerApi, setUserbrainPlayerApi] = useState(null);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(null);
  const [volume, setVolume] = useSessionStorage("video-player-volume", 1);
  const [mouseOverPlayer, setMouseOverPlayer] = useState(false);
  const [showSubtitles, setShowSubtitles] = useSessionStorage(
    "video-player-show-subtitles",
    false,
  );
  const [userIdle, setUserIdle] = useState(false);
  const [playing, setPlaying] = useState(autoplay);
  const [automaticallyPauseAt, setAutomaticallyPauseAt] = useState(null);
  const [hasEnded, setHasEnded] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [playbackRate, setPlaybackRate] = useSessionStorage(
    "video-player-playback-rate",
    1,
  );
  const [currentSecond, setCurrentSecond] = useState(null);
  const prevCurrentSecond = usePrevious(currentSecond);
  const [playerSize, setPlayerSize] = useState({ width: 0, height: 0 });
  const [loop] = useState(false);
  const [isReady, setIsReady] = useState(false); // React player calls onReady every also after seeking, so we need to keep track of this

  // If there is a explicit initial time, use it, otherwise try to get it from local storage
  // If there is no initial time, start at 0
  const [effectiveInitialTime] = useState(() => {
    if (initialTime === 0 || initialTime > 0) {
      return initialTime;
    } else {
      if (resumeKey) {
        const initialTimeFromLocalStorage = localStorage.getItem(
          `video-resume-${md5(resumeKey)}`,
        );
        if (initialTimeFromLocalStorage > 0) {
          return initialTimeFromLocalStorage;
        }
      }
    }
    return 0;
  });

  const [airplayAvailable, setAirplayAvailable] = useState(false);

  const refReactPlayerWrapper = useRef();
  const refReactPlayer = useRef();
  const refVideoPlayer = useRef();
  const refUserIdleTimeout = useRef();

  const isFullscreen = useScreenfullIsFullscreen();

  // check if airplay is available and set airplayAvailable state
  useEffect(() => {
    const video = refReactPlayer.current?.getInternalPlayer();
    // Airplay
    if (video && window?.WebKitPlaybackTargetAvailabilityEvent) {
      function onWebkitplaybacktargetavailabilitychanged(event) {
        if (event.availability === "available") {
          setAirplayAvailable(true);
        } else {
          setAirplayAvailable(false);
        }
      }
      video.addEventListener(
        "webkitplaybacktargetavailabilitychanged",
        onWebkitplaybacktargetavailabilitychanged,
      );
      return () => {
        video.removeEventListener(
          "webkitplaybacktargetavailabilitychanged",
          onWebkitplaybacktargetavailabilitychanged,
        );
      };
    }
  }, []);

  useEffect(() => {
    // resize observer to update playerSize when refReactPlayerWrapper.current changes size
    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      setPlayerSize({ width, height });
    });
    resizeObserver.observe(refReactPlayerWrapper.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const userbrainPlayerApi = createUserbrainPlayerApi(
      refReactPlayer,
      refVideoPlayer,
      false, // Todo: this is not correct if ref changes while playing
      setPlaying,
      setAutomaticallyPauseAt,
    );
    refPlayer.current = userbrainPlayerApi;
    setUserbrainPlayerApi(userbrainPlayerApi);
  }, [refPlayer]);

  useEffect(() => {
    if (refPlayer.current) {
      refPlayer.current.playing = playing;
    }
  }, [playing, refPlayer]);

  useEffect(() => {
    if (isFullscreen) {
      setUserIdle(false);
      clearTimeout(refUserIdleTimeout.current);
      refUserIdleTimeout.current = setTimeout(() => {
        setUserIdle(true);
      }, 5000);
    }
  }, [isFullscreen]);

  useEffect(() => {
    // effectiveInitialTime is only set once and does never change
    // so this effect is only called when isReady changes
    if (isReady) {
      refReactPlayer.current.seekTo(effectiveInitialTime, "seconds");
    }
  }, [isReady, effectiveInitialTime]);

  useUserbrainPlayerHotkeys(
    userbrainPlayerApi,
    nextJumpPoint,
    previousJumpPoint,
  );

  const reactPlayerConfig = useMemo(() => {
    return {
      file: {
        attributes: {
          poster,
          playsInline: true,
          "x-webkit-airplay": "allow",
        },
      },
    };
  }, [poster]);

  function handleMouseEnter() {
    setMouseOverPlayer(true);
  }

  function handleMouseLeave() {
    setMouseOverPlayer(false);
  }

  function handleMouseMove() {
    setUserIdle(false);
    clearTimeout(refUserIdleTimeout.current);
    refUserIdleTimeout.current = setTimeout(() => {
      setUserIdle(true);
    }, 5000);
  }

  function handleClickAirplay() {
    const video = refReactPlayer.current?.getInternalPlayer();
    if (video) {
      video.webkitShowPlaybackTargetPicker();
    }
  }

  // React player callbacks

  function handleReady() {
    if (!isReady) {
      setIsReady(true);
    }
  }

  function handlePlay() {
    setPlaying(true);
    if (!hasStarted) {
      onStarted?.();
      setHasStarted(true);
    }

    // Reset idle timer when user clicks play
    setUserIdle(false);
    clearTimeout(refUserIdleTimeout.current);
    refUserIdleTimeout.current = setTimeout(() => {
      setUserIdle(true);
    }, 5000);

    onPlay?.();
  }

  function handlePause() {
    setPlaying(false);
    setAutomaticallyPauseAt(null);
    onPause();
  }

  function handleEnded() {
    setPlaying(loop);
    setHasEnded(!loop);
    setAutomaticallyPauseAt(null);
    onEnded();
  }

  function handleError(e) {
    // This is needed to correctly set playing state in case of an error
    // (this can happen if autoplay is not available and play ist set to true initially)
    setPlaying(false);
  }

  function handleDuration(duration) {
    setDuration(duration);
  }

  function handleChangePlaybackRate(rate) {
    setPlaybackRate(rate);
  }

  function handleProgress({ played, playedSeconds, loaded, loadedSeconds }) {
    const currentSecond = Math.floor(playedSeconds);

    if (automaticallyPauseAt !== null) {
      if (automaticallyPauseAt === currentSecond) {
        setPlaying(false);
        setAutomaticallyPauseAt(null);
      } else if (currentSecond > automaticallyPauseAt) {
        // Clear autopause if seek to a later time
        setAutomaticallyPauseAt(null);
      }
    }

    setCurrentSecond(currentSecond);
    setProgress(played);
  }

  useEffect(() => {
    if (playing || progress !== 1) {
      setHasEnded(false);
    }
  }, [playing, progress]);

  useEffect(() => {
    if (currentSecond !== null) {
      onSecondChange(currentSecond);
    }
  }, [currentSecond, onSecondChange]);

  // Save resume time to local storage
  useEffect(() => {
    // Only save if we have a resume key and a current second
    if (currentSecond === null || !resumeKey) {
      return;
    }

    if (currentSecond < duration - 1) {
      // Only save resume time if second changes to not override
      // resume time if video is just opened with a t= or clip= parameter
      const secondChanged =
        currentSecond !== prevCurrentSecond && prevCurrentSecond !== null;
      if (secondChanged === true) {
        localStorage.setItem(
          `video-resume-${md5(resumeKey)}`,
          currentSecond.toString(),
        );
      }
    } else {
      localStorage.removeItem(`video-resume-${md5(resumeKey)}`);
    }
  }, [currentSecond, prevCurrentSecond, resumeKey, duration]);

  function handleClickReactPlayer(e) {
    if (e.detail === 2) {
      if (isFullscreen) {
        refPlayer.current?.exitFullscreen();
      } else {
        refPlayer.current?.requestFullscreen();
      }
    }
    setPlaying((prev) => !prev); // On double click this is called twice (so nothing happens)
  }

  // Userbrain player controls
  function handleClickPlay() {
    setPlaying(true);
  }

  function handleClickPause() {
    setPlaying(false);
  }

  function handleChangeVolume(value) {
    setVolume(value);
  }

  function handleClickForward5s() {
    refReactPlayer.current?.seekTo(refReactPlayer.current.getCurrentTime() + 5);
  }

  function handleClickBack5s() {
    refReactPlayer.current?.seekTo(refReactPlayer.current.getCurrentTime() - 5);
  }

  function handleClickRewind() {
    setPlaying(true);
  }

  function handleClickEnterFullscreen() {
    userbrainPlayerApi.requestFullscreen();
  }

  function handleClickExitFullscreen() {
    userbrainPlayerApi.exitFullscreen();
  }

  function handleSeekTo(value) {
    setProgress(value);
    refReactPlayer.current?.seekTo(value);
  }

  function handleClickToggleSubtitles() {
    setShowSubtitles(!showSubtitles);
  }

  const handleChangeSeekThumbnailInfo = useCallback((tumbnailInfo) => {
    // console.log(tumbnailInfo);
    // thumbnailSprite,
    // height,
    // width,
    // offsetX,
    // offsetY,
  }, []);

  function handleClickSubtitles(e) {
    e.stopPropagation();
  }

  // const urls = sources.map(source => ({
  //   src: source.location,
  //   type: 'video/mp4'
  // }))

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      className={cn(className, styles.videoPlayer)}
      ref={refVideoPlayer}
    >
      <div className={styles.reactPlayerWrapper} ref={refReactPlayerWrapper}>
        {!isVideoProcessing && (
          <div
            className={styles.playerOverlay}
            onClick={handleClickReactPlayer}
          >
            {showSubtitles && (
              <VideoSubtitles
                className={styles.videoSubtitles}
                vttUrl={transcriptVttUrl}
                time={progress * duration}
                onClick={handleClickSubtitles}
              />
            )}
            {playing && (
              <div className={cn(styles.statusFlash, styles.statusFlash_play)}>
                Play
              </div>
            )}
            {!playing && hasStarted && (
              <div className={cn(styles.statusFlash, styles.statusFlash_pause)}>
                Pause
              </div>
            )}
          </div>
        )}
        {type === "userbrain" && !isVideoProcessing && (
          <ReactPlayer
            config={reactPlayerConfig}
            ref={refReactPlayer}
            playing={playing}
            url={location}
            loop={loop}
            muted={volume === 0}
            volume={volume}
            playbackRate={playbackRate}
            onReady={handleReady}
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
            onError={handleError}
            onDuration={handleDuration}
            progressInterval={100}
            onPlaybackRateChange={handleChangePlaybackRate}
            onProgress={handleProgress}
            height={playerSize.height}
            width={playerSize.width}
            // onClick={e => handleClickReactPlayer(e)} <-- this is buggy in react-player
          />
        )}
        {type !== "userbrain" && !isVideoProcessing && (
          <div style={{ textAlign: "center" }}>Video type not supported</div>
        )}
        {isVideoProcessing === true && (
          <div className={styles.videoProcessing}>
            <FontAwesomeIcon icon={regular("hourglass-half")} size="2x" />
            <div className={styles.videoProcessionText}>
              Video processing in progress. It'll be ready soon. Thanks for your
              patience!
            </div>
          </div>
        )}
      </div>

      <PlayerControlBar
        mouseOverPlayer={mouseOverPlayer}
        mouseIdle={userIdle}
        fixed={!isFullscreen}
        isFullscreen={isFullscreen}
        className={styles.playerControlBar}
        playing={playing}
        volume={volume}
        playbackRate={playbackRate}
        duration={duration}
        progress={progress}
        thumbnailSprite={thumbnailSprite}
        vttUrl={vtt}
        hasEnded={hasEnded}
        showSubtitles={showSubtitles}
        subtitlesAvailable={transcriptAvailable}
        airplayAvailable={airplayAvailable}
        onClickAirplay={handleClickAirplay}
        onChangePlaybackRate={handleChangePlaybackRate}
        onClickPlay={handleClickPlay}
        onClickPause={handleClickPause}
        onChangeVolume={handleChangeVolume}
        onClickForward5s={handleClickForward5s}
        onClickBack5s={handleClickBack5s}
        onClickRewind={handleClickRewind}
        onClickEnterFullscreen={handleClickEnterFullscreen}
        onClickExitFullscreen={handleClickExitFullscreen}
        onSeekTo={handleSeekTo}
        onChangeSeekThumbnailInfo={handleChangeSeekThumbnailInfo}
        onClickToggleSubtitles={handleClickToggleSubtitles}
      />
    </div>
  );
  /*  return <VideoJsEmbed
      className={className}
      autoplay={autoplay}
      subtitleVttUrl={transcriptVttUrl}
      initialTime={initialTime || initialTimeFromLocalStorage}
      location={location}
      onPlayerReady={handleVideoJsPlayerReady}
      onError={handleVideoJsPlayerError}
      vtt={vtt}
      thumbnailSprite={thumbnailSprite}
    />;*/
}
