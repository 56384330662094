import React from "react";
import PropTypes from "prop-types";

import { addPropsToChildrenIfValueEq } from "../../helpers";

import styles from "./styles.module.css";

class FormFieldRadioGroup extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, onChange, styles, value, ...containerProps } = this.props;
    const result = addPropsToChildrenIfValueEq(
      children,
      value,
      { checked: true },
      { onChange, checked: false },
    );
    const childrenWithProps = result.children;
    return <div {...containerProps}>{childrenWithProps}</div>;
  }
}

FormFieldRadioGroup.propTypes = {
  onChange: PropTypes.func,
};

export default FormFieldRadioGroup;
