import React, { useEffect } from "react";
import { concatClassNames as cn } from "@system42/core";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { PaymentForm } from "@/components/PaymentForm";
import CheckoutModal from "@/containers/CheckoutModal";
import {
  Button,
  ButtonLink,
  MainNavigation,
  MainNavigationContent,
  SubNavigation,
  SubNavigationContent,
} from "@/design-system";
import { paymentCardFromBilling } from "@/helpers-ts";
import { useModal2 } from "@/hooks/useModal2";
import { selectIsUserOwner, selectIsUserOwnerOrAdmin } from "@/selectors/user";
import { AccountDataBox } from "../../components/AccountDataBox";
import { ExplorePlansBox } from "../../components/ExplorePlansBox";
import { SubscriptionBox } from "../../components/SubscriptionBox";
import { UsageBoxBilling } from "../../components/UsageBoxBilling";
import {
  formatBillingAddress,
  formatDateWithoutTime,
  formatPrice,
  getPlanName,
  nl2br,
} from "../../helpers";
import { useFreeTrial } from "../../hooks/freeTrialHooks";
import { useBilling } from "../../hooks/useBilling";
import { useBillingInformation } from "../../hooks/useBillingInformation";
import { useCancelSubscriptionModal } from "../../hooks/useCancelSubscriptionModal";
import { useCreditsAvailable } from "../../hooks/useCreditsAvailable";
import { useEditBillingAddressModal } from "../../hooks/useEditBillingAddressModal";
import { useEditBillingEmailModal } from "../../hooks/useEditBillingEmailModal";
import { useEditPaymentCardModal } from "../../hooks/useEditPaymentCardModal";
import { useSessions } from "../../hooks/useSessions";
import { useSubscription } from "../../hooks/useSubscription";
import { EditSubscriptionModal } from "../EditSubscriptionModal";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

export default function AccountBilling() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const creditsAvailable = useCreditsAvailable();
  const subscription = useSubscription();
  const freeTrial = useFreeTrial();
  const sessions = useSessions();
  const billing = useBilling();
  const billingInformation = useBillingInformation();
  const billingInformationComplete = useSelector(
    (state) => state.user.user?.billinginformation_complete,
  );
  const isReactivateFetching = useSelector(
    (state) => state.reactivateSubscription.isFetching,
  );
  const isOwner = useSelector(selectIsUserOwner);
  const isOwnerOrAdmin = useSelector(selectIsUserOwnerOrAdmin);
  const accountEmail = useSelector((state) => state.user.user?.email);

  const isCancelled = !!subscription?.cancel_at_period_end;

  const planName = subscription
    ? getPlanName(subscription.plan, subscription.quantity)
    : "";

  // Load billing information
  useEffect(() => {
    dispatch({ type: "BILLING_INFORMATION_REQUEST" });
  }, [dispatch]);

  // For Invoices
  const invoices = useSelector((state) => state.invoices.invoices);

  // Load invoices if owner or admin
  useEffect(() => {
    if (isOwner) {
      dispatch({ type: "INVOICES_REQUEST" });
    }
  }, [dispatch, isOwner]);

  const { showCancelSubscriptionModal, cancelSubscriptionModal } =
    useCancelSubscriptionModal();

  const { showModal: showCheckoutModal, modal: checkoutModal } = useModal2(
    <CheckoutModal />,
  );

  const { showModal: showEditBillingEmail, modal: editBillingEmailModal } =
    useEditBillingEmailModal();

  const { showModal: showEditBillingAddress, modal: editBillingAddressModal } =
    useEditBillingAddressModal();

  const { showModal: showEditPaymentCardModal, modal: editPaymentCardModal } =
    useEditPaymentCardModal();

  // Same as above but with the useModal2 hook

  const {
    showModal: showEditSubscriptionModal,
    hideModal: hideEditSubscriptionModal,
    modal: editSubscription,
  } = useModal2(
    <EditSubscriptionModal
      onClickChooseStarter={handleClickChooseStarter}
      onClickChoosePro={handleClickChoosePro}
      onClickChooseAgency={handelClickChooseAgency}
      onClickCancel={handleClickCancelSubscription}
      showCancel={!isCancelled}
    />,
  );

  // Open edit subscription or explore plans modal when #show-plans-modal is set in the url
  useEffect(() => {
    if (subscription !== null && location.hash === "#show-plans-modal") {
      if (subscription === false) {
        dispatch({ type: "GLOBAL_MODAL_OPEN", modal: "explorePlans" });
      } else {
        showEditSubscriptionModal();
      }
      navigate(location.pathname + location.search, { replace: true });
    } else if (subscription !== null && location.hash === "#upgrade-yearly") {
      if (subscription !== false) {
        // The modal has a check for #upgrade-yearly as well and will switch to annual billing
        // and remove the hash from the url
        showEditSubscriptionModal();
      } else {
        navigate(location.pathname + location.search, { replace: true });
      }
    }
  }, [location, subscription, navigate, showEditSubscriptionModal, dispatch]);

  function handleClickChooseStarter(billingCycle) {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "starter",
      billingCycle,
    });
    // Can be clicked in explore plans or change plan modal, close both
    hideEditSubscriptionModal();
    showCheckoutModal();
  }

  function handleClickChoosePro(billingCycle) {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "pro",
      billingCycle,
    });
    // Can be clicked in explore plans or change plan modal, close both
    hideEditSubscriptionModal();
    showCheckoutModal();
  }

  function handelClickChooseAgency(billingCycle) {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "agency",
      billingCycle,
    });
    // Can be clicked in explore plans or change plan modal, close both
    hideEditSubscriptionModal();
    showCheckoutModal();
  }

  function handleClickExplorePlans() {
    dispatch({
      type: "GLOBAL_MODAL_OPEN",
      modal: "explorePlans",
    });
  }

  function handleClickEditSubscription() {
    showEditSubscriptionModal();
  }

  function handleClickCancelSubscription() {
    hideEditSubscriptionModal();
    showCancelSubscriptionModal();
  }

  function handleClickReactivateSubscription() {
    dispatch({ type: "REACTIVATE_SUBSCRIPTION_REQUEST" });
  }

  function handleClickUpdateCardDetails() {
    showEditPaymentCardModal();
  }

  function handleClickUpdateBillingAddress() {
    showEditBillingAddress();
  }

  function handleClickUpdateBillingEmail() {
    showEditBillingEmail();
  }

  return (
    <>
      <Helmet>
        <title>Usage &amp; Billing | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <SubNavigation>
            <SubNavigationAccount currentNavItem={"billing"} />
            <SubNavigationContent>
              <div className={styles.content}>
                {subscription === false && (
                  <ExplorePlansBox
                    className={styles.explorePlansBox}
                    onClickExplorePlans={handleClickExplorePlans}
                  />
                )}
                <UsageBoxBilling
                  className={styles.usageBox}
                  title={"Your usage"}
                  currentPeriodStart={subscription?.current_period_start}
                  currentPeriodEnd={subscription?.current_period_end}
                  creditsAvailable={creditsAvailable}
                  freeTrial={freeTrial}
                  testersAvailable={sessions?.available ?? null}
                  testersIncluded={sessions?.included ?? null}
                  testersUsedThisMonth={sessions?.used_this_month ?? null}
                  testersRollOverLimit={sessions?.included_max ?? null}
                  hasSubscription={!!subscription}
                  billingCycle={subscription?.billing_cycle}
                  isCancelled={isCancelled}
                  planName={planName}
                />
                {subscription && (
                  <SubscriptionBox
                    className={styles.subscriptionBox}
                    onClickEditSubscription={handleClickEditSubscription}
                    onClickReactivateSubscription={
                      handleClickReactivateSubscription
                    }
                    reactivateFetching={isReactivateFetching}
                    title={"Your subscription"}
                    subscription={subscription}
                    freeTrial={freeTrial}
                    isOwnerOrAdmin={isOwnerOrAdmin}
                  />
                )}

                {isOwner && (
                  <div className={styles.cardDetailsAndBillingAddress}>
                    <AccountDataBox
                      title={"Card details"}
                      buttonLabel={
                        billing && billing.card_last_four ? "Edit" : "Add"
                      }
                      onClickButton={handleClickUpdateCardDetails}
                    >
                      {billing && (
                        <PaymentForm card={paymentCardFromBilling(billing)} />
                      )}

                      {!billing && <div>No card added yet.</div>}
                    </AccountDataBox>
                    <AccountDataBox
                      title={"Billing address"}
                      buttonLabel={billingInformationComplete ? "Edit" : "Add"}
                      onClickButton={handleClickUpdateBillingAddress}
                    >
                      {/*{billingInformation && billingInformationComplete && <>
                    {billingInformation.billingEmail}<br />
                    <span className={styles.billingAddressCompanyName}>{billingInformation.organization}</span>
                  </>}*/}
                      {!billingInformationComplete && (
                        <div>No billing information added yet.</div>
                      )}
                      {billingInformationComplete && billingInformation && (
                        <p>{nl2br(formatBillingAddress(billingInformation))}</p>
                      )}
                    </AccountDataBox>
                  </div>
                )}

                {invoices && isOwner && (
                  <section className={cn("card", styles.invoicesBox)}>
                    <h1 className={styles.invoicesBoxTitle}>Invoices</h1>

                    {billingInformation?.billingEmail && (
                      <p>
                        Your invoices are sent to{" "}
                        <strong>{billingInformation.billingEmail}</strong>
                        .&nbsp;
                        <ButtonLink onClick={handleClickUpdateBillingEmail}>
                          Change
                        </ButtonLink>
                      </p>
                    )}

                    {!billingInformation?.billingEmail && (
                      <p>
                        Your invoices are sent to your{" "}
                        <abbr title={accountEmail}>account email</abbr>. &nbsp;
                        <ButtonLink onClick={handleClickUpdateBillingEmail}>
                          Change
                        </ButtonLink>
                      </p>
                    )}

                    <div className={styles.invoices}>
                      {invoices.map((invoice, index) => (
                        <div className={styles.invoice} key={index}>
                          <div className={styles.invoiceDetails}>
                            <div className={styles.invoiceDate}>
                              {formatDateWithoutTime(new Date(invoice.date))}
                            </div>
                            <div
                              className={styles.invoiceName}
                              title={invoice.name}
                            >
                              {invoice.name}
                            </div>
                            <div
                              className={styles.invoicePrice}
                              title={formatPrice(
                                invoice.amount,
                                invoice.currency,
                              )}
                            >
                              {formatPrice(invoice.amount, invoice.currency)}
                            </div>
                          </div>
                          <Button href={invoice.url}>Download</Button>
                        </div>
                      ))}
                    </div>
                  </section>
                )}
              </div>
            </SubNavigationContent>
          </SubNavigation>
        </MainNavigationContent>
      </MainNavigation>

      {cancelSubscriptionModal}

      {checkoutModal}

      {editSubscription}

      {editPaymentCardModal}

      {editBillingAddressModal}

      {editBillingEmailModal}
    </>
  );
}
