export function useJumpPoints(
  taskStepsWithResponse,
  currentVideoSecond,
  videoDuration,
) {
  const jumpPoints = [];
  for (const taskStepWithResponse of taskStepsWithResponse) {
    jumpPoints.push(taskStepWithResponse.timestampCompleted);
  }

  let nextJumpPoint = jumpPoints.reduce((bestJumpPoint, jumpPoint) => {
    // It is a better jump point if:
    const distance = jumpPoint - currentVideoSecond;
    const bestDistance = bestJumpPoint - currentVideoSecond;
    if (distance < bestDistance && distance > 0) {
      return jumpPoint;
    } else {
      return bestJumpPoint;
    }
  }, videoDuration || 0);

  let previousJumpPoint = jumpPoints.reduce((bestJumpPoint, jumpPoint) => {
    // It is a better jump point if:
    const distance = jumpPoint - currentVideoSecond;
    const bestDistance = bestJumpPoint - currentVideoSecond;
    if (distance > bestDistance && distance < 0) {
      return jumpPoint;
    } else {
      return bestJumpPoint;
    }
  }, 0);
  return { nextJumpPoint, previousJumpPoint };
}
