import React, { useState } from "react";
import { concatClassNames as cn } from "@system42/core";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import CheckoutModal from "@/containers/CheckoutModal";
import {
  Button,
  ButtonLink,
  Card,
  FormField,
  InputText,
  MainNavigation,
  MainNavigationContent,
  Stack,
  SubNavigation,
  SubNavigationContent,
} from "@/design-system";
import { useModal2 } from "@/hooks/useModal2";
import BuyCreditsGrid from "../../components/BuyCreditsGrid";
import ExplorePlansPromo from "../../components/ExplorePlansPromo";
import { Faqs } from "../../components/Faqs";
import Spinner from "../../components/Spinner";
import { useCreditsAvailable } from "../../hooks/useCreditsAvailable";
import { usePricing } from "../../hooks/usePricing";
import { useSubscription } from "../../hooks/useSubscription";
import Navigation from "../Navigation";
import Notifications from "../Notifications";
import SubNavigationAccount from "../SubNavigationAccount";

import styles from "./styles.module.css";

export default function AccountBuyCredits() {
  const [redeemGiftCodeActive, setRedeemGiftCodeActive] = useState(false);
  const [giftCode, setGiftCode] = useState("");

  const dispatch = useDispatch();
  const userIsLoaded = useSelector((state) => !!state.user.user);
  const pricing = usePricing();
  const currency = useSelector((state) => state.user.user?.currency);
  const taxPercent = useSelector((state) => state.user.user?.tax_percent || 0);
  const redeemGiftCodeFetching = useSelector(
    (state) => state.giftCode.redeemFetching,
  );
  const redeemGiftCodeFieldFeedback = useSelector(
    (state) => state.giftCode.redeemFieldFeedback,
  );
  const creditsAvailable = useCreditsAvailable();
  const subscription = useSubscription();

  const { showModal: showCheckoutModal, modal: checkoutModal } = useModal2(
    <CheckoutModal />,
  );

  function showCheckoutModalForBuyCredits() {
    showCheckoutModal();
  }

  function handleClickBuyCredits(quantity) {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: quantity,
      plan: "payg",
    });
    showCheckoutModalForBuyCredits();
  }

  function handleClickShowRedeemGiftCode() {
    setRedeemGiftCodeActive(true);
  }

  function handleClickRedeemGiftCode() {
    dispatch({ type: "GIFT_CODE_REDEEM_REQUEST", code: giftCode });
  }

  function handleChangeGiftCode(e) {
    setGiftCode(e.target.value);
  }

  function handleClickExplorePlans() {
    dispatch({
      type: "GLOBAL_MODAL_OPEN",
      modal: "explorePlans",
      modalOptions: { redirectToBillingAfterCheckout: true },
    });
  }

  return (
    <>
      <Helmet>
        <title>Buy Credits | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <SubNavigation>
            <SubNavigationAccount currentNavItem={"buy-credits"} />
            <SubNavigationContent>
              <div className={styles.content}>
                {userIsLoaded ? (
                  <>
                    <Stack spacing={"cards"}>
                      <Card className={styles.cardCreditsList}>
                        <div className={styles.headingAndCredits}>
                          <h1 className={styles.heading}>Credit Packs</h1>
                          <div className={styles.yourCredits}>
                            Your credits:{" "}
                            <span className={styles.creditsAvailable}>
                              {creditsAvailable}
                            </span>
                          </div>
                        </div>
                        <p>
                          1 credit = 1 Userbrain tester{" "}
                          <a
                            href={
                              "https://help.userbrain.com/help/what-is-a-test-credit"
                            }
                            target={"_blank"}
                            rel={"noreferrer"}
                          >
                            Learn more…
                          </a>
                        </p>
                        <p>
                          Buy credit packs and get testers for free. Credits
                          never expire.
                        </p>
                        <BuyCreditsGrid
                          className={styles.buyCreditsGrid}
                          currency={currency}
                          taxPercent={taxPercent}
                          pricing={pricing}
                          onClickBuyCredits={handleClickBuyCredits}
                        />
                      </Card>

                      {subscription === false && (
                        <ExplorePlansPromo
                          savePercent={44}
                          currency={currency}
                          startingAtPrice={pricing.starter / 100}
                          onClick={handleClickExplorePlans}
                        />
                      )}

                      {redeemGiftCodeActive ? (
                        <div className={cn(styles.cardRedeem, "card")}>
                          <Stack>
                            <FormField
                              label="Your gift code"
                              errorMessage={
                                redeemGiftCodeFieldFeedback.code || false
                              }
                            >
                              <InputText
                                onChange={handleChangeGiftCode}
                                value={giftCode}
                              />
                            </FormField>
                            <Button onClick={handleClickRedeemGiftCode}>
                              {redeemGiftCodeFetching
                                ? "Validating…"
                                : "Redeem"}
                            </Button>
                          </Stack>
                        </div>
                      ) : (
                        <div className={styles.giftCode}>
                          <ButtonLink
                            onClick={handleClickShowRedeemGiftCode}
                            className={styles.showRedeemGiftCode}
                          >
                            Redeem a gift code
                          </ButtonLink>
                        </div>
                      )}
                    </Stack>

                    {/*{taxPercent > 0 ?
                    <div className={styles.vatInfo}>
                      All prices include {taxPercent}% VAT.
                    </div>
                    :
                    <>
                      {!billingInformationComplete && <div className={styles.vatInfo}>
                        Value Added Tax (VAT) may be added for some EU countries.
                    </div>}
                    </>
                  }*/}

                    <Faqs />
                  </>
                ) : (
                  <Spinner />
                )}
              </div>

              {checkoutModal}
            </SubNavigationContent>
          </SubNavigation>
        </MainNavigationContent>
      </MainNavigation>
    </>
  );
}
