import { useDispatch, useSelector } from "react-redux";

import CheckoutModal from "@/containers/CheckoutModal";
import { useModal2 } from "@/hooks/useModal2";
import { CreateTestModal } from "../CreateTestModal";
import { ExplorePlansModal } from "../ExplorePlansModal";

export function GlobalModals() {
  const dispatch = useDispatch();
  const globalModal = useSelector((state) => state.globalModal.modal);
  const globalModalOptions = useSelector(
    (state) => state.globalModal.modalOptions,
  );

  const { showModal: showCheckoutModal, modal: checkoutModal } = useModal2(
    <CheckoutModal />,
  );

  function handleCloseGlobalModal() {
    dispatch({ type: "GLOBAL_MODAL_CLOSE" });
  }

  function handleClickChooseStarter(billingCycle) {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "starter",
      billingCycle,
      redirectToBillingAfterCheckout:
        globalModalOptions?.redirectToBillingAfterCheckout ?? false,
    });
    dispatch({ type: "GLOBAL_MODAL_CLOSE" });
    showCheckoutModal();
  }

  function handleClickChoosePro(billingCycle) {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "pro",
      billingCycle,
      redirectToBillingAfterCheckout:
        globalModalOptions?.redirectToBillingAfterCheckout ?? false,
    });
    dispatch({ type: "GLOBAL_MODAL_CLOSE" });
    showCheckoutModal();
  }

  function handelClickChooseAgency(billingCycle) {
    dispatch({
      type: "CHECKOUT_INIT",
      creditCount: 0,
      plan: "agency",
      billingCycle,
      redirectToBillingAfterCheckout:
        globalModalOptions?.redirectToBillingAfterCheckout ?? false,
    });
    dispatch({ type: "GLOBAL_MODAL_CLOSE" });
    showCheckoutModal();
  }

  return (
    <>
      <ExplorePlansModal
        onClickChooseStarter={handleClickChooseStarter}
        onClickChoosePro={handleClickChoosePro}
        onClickChooseAgency={handelClickChooseAgency}
        onClose={handleCloseGlobalModal}
        isActive={globalModal === "explorePlans"}
      />
      <CreateTestModal
        templateId={globalModalOptions?.templateId}
        onClose={() => dispatch({ type: "GLOBAL_MODAL_CLOSE" })}
        isActive={globalModal === "createTest"}
      />
      {checkoutModal}
    </>
  );
}
