import React from "react";
import { concatClassNames as cn } from "@system42/core";

// import { } from '../../system42'
import styles from "./styles.module.css";

import svgLockIcon from "./lock-icon.svg";

export function TabNavigation(props) {
  const {
    tabClassName,
    className,
    options,
    value,
    onChange,
    ...containerProps
  } = props;

  function handleClick(value) {
    onChange?.(value);
  }

  return (
    <div className={cn(className, styles.tabNavigation)} {...containerProps}>
      {options.map((tab, index) => (
        <button
          key={index}
          onClick={tab.isDisabled ? undefined : () => handleClick(tab.value)}
          className={cn(
            styles.tab,
            tab.value === value && styles.tab_active,
            tab.isDisabled === true && styles.tab_disabled,
            tab.isNotification === true && styles.tab_notification,
            tabClassName,
          )}
        >
          <i
            className={styles.icon}
            style={{ backgroundImage: `url(${tab.icon})` }}
          />
          <div className={styles.label}>{tab.label}</div>
          {tab.isLocked && (
            <i
              className={styles.lockIcon}
              style={{ backgroundImage: `url(${svgLockIcon})` }}
            />
          )}
        </button>
      ))}
    </div>
  );
}
