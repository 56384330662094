import { ButtonPrimary } from "@/design-system";
import { Modal2 } from "../Modal";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconClose } from "./close.svg";

export default function ModalFeaturePopup(props) {
  const { isActive, onDismiss, featurePopup } = props;

  function handleCloseModal(reason) {
    if (reason !== "clickOutside") {
      onDismiss();
    }
  }

  return (
    <Modal2
      maxWidth={"30rem"}
      overflowHidden
      isActive={isActive}
      onClose={handleCloseModal}
      content={
        featurePopup && (
          <ModalFeaturePopupContent
            onDismiss={onDismiss}
            key={featurePopup.version}
            title={featurePopup.title}
            overline={featurePopup.overline}
            description={featurePopup.description}
            image={featurePopup.image}
            version={featurePopup.version}
            linkText={featurePopup.link?.text}
            linkUrl={featurePopup.link?.url}
          />
        )
      }
    />
  );
}

function ModalFeaturePopupContent({
  onDismiss,
  title,
  overline,
  description,
  image,
  linkText,
  linkUrl,
}) {
  function handleClickCloseIcon() {
    onDismiss();
  }

  function handleClickGotIt() {
    onDismiss();
  }

  return (
    <>
      <button
        className={styles.closeButton}
        onClick={handleClickCloseIcon}
        type={"button"}
      >
        <SvgIconClose className={styles.closeButtonIcon} />
      </button>
      {image && <img className={styles.image} src={image} alt={title} />}
      <div className={styles.content}>
        {overline && <div className={styles.overline}>{overline}</div>}
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.description}>{description}</div>
        {linkUrl && (
          <a
            href={linkUrl}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {linkText ?? "Learn more"}
          </a>
        )}
        <div className={styles.buttons}>
          <ButtonPrimary onClick={handleClickGotIt}>Got it</ButtonPrimary>
        </div>
      </div>
    </>
  );
}
