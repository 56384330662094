import {
  createContext,
  forwardRef,
  ReactNode,
  useContext,
  useState,
} from "react";
import { concatClassNames as cn } from "@system42/core";

import { ControlledScroller } from "@/components/ControlledScroller";

import styles from "./styles.module.css";

const TabsContext = createContext(
  {} as {
    value: number;
    onChange: (value: number) => void;
  },
);

/**


  Tabs

*/
export function Tabs({
  value,
  onChange,
  children,
  className,
}: {
  onChange: (value: number) => void;
  value: number;
  children: ReactNode;
  className: string;
}) {
  return (
    <TabsContext.Provider
      value={{
        value: value,
        onChange: onChange,
      }}
    >
      <div className={cn(className, styles.tabs)}>{children}</div>
    </TabsContext.Provider>
  );
}

/**


  Tab List

*/
export function TabList({
  tabs,
  endComponent,
  className,
  buttonClassName,
}: {
  tabs: Omit<TabButtonProps, "className">[];
  endComponent?: ReactNode;
  className?: string;
  buttonClassName?: string;
}) {
  return (
    <div className={cn(className, styles.tabList)}>
      {tabs.map((tabProps, index) => (
        <TabButton key={index} {...tabProps} className={buttonClassName} />
      ))}
      {endComponent}
    </div>
  );
}

interface TabButtonProps {
  label: ReactNode | string;
  value: number;
  className?: string;
}

/**


  Tab Button

*/
function TabButton({ label, value, className }: TabButtonProps) {
  const context = useContext(TabsContext);

  return (
    <button
      className={cn(
        className,
        styles.tabButton,
        context.value === value && styles.tabButton_selected,
      )}
      onClick={() => context.onChange(value)}
    >
      {label}
    </button>
  );
}

interface TabContentProps {
  value: number;
  children: ReactNode;
  className?: string;
}

/**


 Tab Content

*/
export function TabContent({
  value,
  children,
  className,
  isFoster = false,
}: TabContentProps & {
  isFoster?: boolean;
}) {
  const context = useContext(TabsContext);

  const node = isFoster ? (
    <>{children}</>
  ) : (
    <div className={className}>{children}</div>
  );

  return context.value !== value ? null : node;
}

/**


 Tab Content Scroller

*/
export const TabContentScroller = forwardRef(
  ({ children, value, className }: TabContentProps & {}, ref) => {
    const [positionState, setPositionState] = useState(0);

    function handleScroll(position: number) {
      setPositionState(position);
    }

    return (
      <TabContent value={value} isFoster>
        <ControlledScroller
          ref={ref}
          onPreserveScroll={handleScroll}
          initialScrollPosition={positionState}
          className={className}
        >
          {children}
        </ControlledScroller>
      </TabContent>
    );
  },
);
