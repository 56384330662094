import React from "react";

import styles from "./brands.module.css";

import icoLushSvg from "./images/ico-lush.svg";
import icoRedbullSvg from "./images/ico-redbullmobile.svg";
import icoSolarcitySvg from "./images/ico-solarcity.svg";
import icoSpotifySvg from "./images/ico-spotify.svg";
import icoVirginSvg from "./images/ico-virgin.svg";

const Brands = () => (
  <>
    <div className={styles["brand-box"]}>
      <div className={styles["brand-list"]}>
        <div className={styles["brand-item"]}>
          <img src={icoSpotifySvg} alt="spotify" />
        </div>
        <div className={`${styles["brand-item"]} ${styles.virgin}"]}`}>
          <img src={icoVirginSvg} alt="virgin" />
        </div>
        <div className={styles["brand-item"]}>
          <img src={icoRedbullSvg} alt="redbullmobile" />
        </div>
        <div className={styles["brand-item"]}>
          <img src={icoSolarcitySvg} alt="solarcity" />
        </div>
        <div
          className={`${styles["brand-item"]} ${styles["brand-item--desktop"]}`}
        >
          <img src={icoLushSvg} alt="lush fresh handmade cosmetics" />
        </div>
      </div>
      <div className={styles["brand-title"]}>
        Trusted by UX designers &amp; product teams around the world.
      </div>
    </div>
  </>
);

export default Brands;
