import axiosLib from "axios";
import isObject from "lodash/isObject";
import isUndefined from "lodash/isUndefined";
import qs from "query-string";
import { eventChannel } from "redux-saga";
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
  throttle,
} from "redux-saga/effects";

import axios from "@/axios";
import {
  cookies,
  downloadBlobResponse,
  extractFieldFeedbackFromApiError,
  extractMessageFromApiError,
} from "@/helpers";
import { createReduxApiError } from "@/helpers-ts";
import {
  VIDEO_ENDED,
  VIDEO_PAUSED,
  VIDEO_SECOND_WATCHED,
  VideoStatsType,
} from "@/reducers/videoStatsReducer";
import { parseNdjson } from "@/utils/parseNdjson";

import { authentication } from "./authentication";
import { watchConfirmEmail } from "./confirmEmail";
import { watchDevTestFill } from "./devTestFill";
import { watchSharedClip } from "./sharedClip";
import { watchSharedVideo } from "./sharedVideo";
import { watchTestClips } from "./testClips";
import { watchTestInsights } from "./testInsights";
import { watchTestInsightsRefresh } from "./testInsightsRefresh";
import { watchShareVideo } from "./video";
import {
  watchVideoClipCreate,
  watchVideoClipDelete,
  watchVideoClipShare,
  watchVideoClipUpdate,
} from "./videoClip";
import {
  watchVideoNoteCreate,
  watchVideoNoteDelete,
  watchVideoNoteUpdate,
} from "./videoNote";

export const API_URL = process.env.REACT_APP_API_URL;
// const DEFAULT_COOKIE_DOMAIN = process.env.REACT_APP_DEFAULT_COOKIE_DOMAIN;

// watcher saga: watches for actions dispatched to the store, starts worker saga
export function* rootSaga() {
  yield all([
    watchBillingInformation(),
    watchBillingInformationUpdate(),
    watchReleaseNotes(),
    watchCancelSubscription(),
    watchConfirmEmailResend(),
    watchConfirmEmail(),
    watchDeliveryCreate(),
    watchDeliveryUpdate(),
    watchDeliveryStop(),
    watchDeliveryResume(),
    watchDownloadVideoClip(),
    watchDismissFeaturePopup(),
    watchGiftCodeRedeem(),
    watchInitCheckout(),
    watchDevTestFill(),
    watchDog(),
    watchDogAccountReset(),
    watchDogDeleteSubscription(),
    watchDogDeleteCredits(),
    watchDogCustomerDelete(),
    watchInvoices(),
    watchInvitation(),
    watchMembers(),
    watchMemberCreate(),
    watchMemberDelete(),
    watchMemberResend(),
    watchMemberUpdate(),
    watchOrderTestersTest(),
    watchOrderTests(),
    watchOrderTestTest(),
    watchOrderTestingTestTest(),
    watchOrderInvitationTestTest(),
    watchPasswordReset(),
    watchPasswordNew(),
    watchPaymentSetupIntent(),
    watchPaymentSetupCard(),
    watchReactivateSubscription(),
    watchUser(),
    watchUserUpdate(),
    watchUserUpdatePassword(),
    watchVideo(),
    watchSharedClip(),
    watchTranscript(),
    watchVideoDelete(),
    watchVideoUpdate(),
    watchVideoProblem(),
    watchVideoNoteCreate(),
    watchVideoNoteDelete(),
    watchVideoNoteUpdate(),
    watchVideoClipCreate(),
    watchVideoClipDelete(),
    watchShareVideo(),
    watchVideoClipUpdate(),
    watchVideoClipShare(),
    watchVideoSetNew(),
    watchVideoStats(),
    watchVideoAutomatedInsights(),
    watchVideos(),
    watchVideosReRequest(),
    watchVideosTests(),
    watchVideosStar(),
    watchVideosUnstar(),
    watchVideosRate(),
    watchWindowFocus(),
    watchSharedVideo(),
    watchTargeting(),
    watchTest(),
    watchTestReRequest(),
    watchTestReport(),
    watchTestClips(),
    watchTestInsights(),
    watchTestInsightsRefresh(),
    watchTestScreeners(),
    watchAllTestScreeners(),
    watchTestVideos(),
    watchTestVideosReRequest(),
    watchTestInvitation(),
    watchTestSetupLoad(),
    watchAiTestSetup(),
    watchTestSetupDuplicateLoad(),
    watchTestSetupTemplateLoad(),
    watchTestSetupSave(),
    watchTests(),
    watchTestsReRequest(),
    watchTestDelete(),
    watchTestArchive(),
    watchTestUnarchive(),
    watchSignUp(),
    watchAdminImpersonate(),
    authentication(),
    snackbarTimer(),
  ]);
}

// Release Notes

function* watchReleaseNotes() {
  yield takeEvery("RELEASE_NOTES_REQUEST", callReleaseNotesApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callReleaseNotesApi() {
  try {
    const response = yield call(getReleaseNotes);
    yield put({ type: "RELEASE_NOTES_SUCCESS", data: response.data });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "RELEASE_NOTES_FAILURE",
      error: createReduxApiError(error),
    });
  }
}

// function that makes the api request and returns a Promise for response
function getReleaseNotes() {
  return axiosLib({
    method: "get",
    url: "/releaseNotes.json",
  });
}

// Confirm email

function* watchConfirmEmailResend() {
  yield takeEvery("CONFIRM_EMAIL_RESEND_REQUEST", callConfirmEmailResendApi);
}

function* callConfirmEmailResendApi() {
  try {
    const response = yield call(postConfirmEmailResend);
    yield put({ type: "CONFIRM_EMAIL_RESEND_SUCCESS", data: response.data });

    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Verification email sent`,
    });
  } catch (error) {
    yield put({ type: "CONFIRM_EMAIL_RESEND_FAILURE", error });

    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "error",
      content: extractMessageFromApiError(error),
    });
  }
}

function postConfirmEmailResend() {
  return axios({
    method: "post",
    url: API_URL + "/user/confirm/resend",
  });
}

// Snackbar Saga

function* snackbarTimer() {
  yield takeLatest("SNACKBAR_ADD", startSnackbarTimer);
}

const getSnackbarQueue = (state) => state.notifications.snackbarQueue;

function* startSnackbarTimer() {
  let thereAreSnacks;
  do {
    const { timerPaused, timerFinished } = yield race({
      timerFinished: delay(5000),
      snackbarAdded: take("SNACKBAR_ADD"),
      snackbarDismissed: take("SNACKBAR_DISMISS"),
      timerPaused: take("SNACKBAR_TIMER_PAUSE"),
    });

    if (timerPaused) {
      yield take("SNACKBAR_TIMER_RESUME");
    } else if (timerFinished) {
      yield put({ type: "SNACKBAR_DISMISS" });
    }

    const snackbarQueue = yield select(getSnackbarQueue);
    thereAreSnacks = snackbarQueue.length > 0;
  } while (thereAreSnacks);
}

// ORDER TESTERS TEST
function* watchOrderTestersTest() {
  yield takeLatest(
    ["ORDER_TESTERS_TEST_REQUEST", "ORDER_TESTERS_INIT_AND_OPEN_MODAL"],
    callOrderTestersTestApi,
  );
}

// worker saga: makes the api call when watcher saga sees the action
function* callOrderTestersTestApi(action) {
  try {
    const response = yield call(fetchTest, action.testId);
    const test = response.data.data;
    yield put({ type: "ORDER_TESTERS_TEST_SUCCESS", test });
  } catch (error) {
    yield put({ type: "ORDER_TESTERS_TEST_FAILURE", error });
  }
}

// ORDER TESTS

function* watchOrderTests() {
  // takeLatest is key here because it stops a function that is still waiting for confirm
  yield takeLatest("ORDER_TESTS_REQUEST", callOrderTestsApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callOrderTestsApi(action) {
  try {
    const {
      quantity,
      plan,
      billingCycle,
      netPriceCharged,
      currency,
      paymentMethodId,
      deliveryRequest,
      isTermsAccepted,
    } = action;

    const quantityForAPI = quantity === 0 ? 1 : quantity;

    const response = yield call(
      postOrder,
      quantityForAPI,
      plan,
      billingCycle,
      deliveryRequest,
      paymentMethodId,
      isTermsAccepted,
    );

    let responseData = response.data;
    let responseStatus = responseData.status;

    while (responseStatus === "requires_action") {
      yield put({
        type: "ORDER_TESTS_WAITING_FOR_CONFIRM",
        clientSecret: responseData.client_secret,
      });

      const { confirmAction } = yield race({
        confirmAction: take("ORDER_TESTS_CONFIRM"),
        confirmFailedAction: take("ORDER_TESTS_CONFIRM_FAILED"),
      });

      if (confirmAction) {
        const response = yield call(
          postOrderConfirm,
          confirmAction.paymentIntentId,
          confirmAction.deliveryRequest,
        );
        responseData = response.data;
        responseStatus = responseData.status;
      } else {
        yield put({
          type: "ORDER_TESTS_FAILURE",
          error: true,
          errorMessage: "We are unable to authenticate your payment method.",
        });
        return;
      }
    }

    if (
      responseData.status === "success" ||
      responseData.order?.status === "success"
    ) {
      const orderResponse = responseData.order || responseData;

      if (responseData.delivery?.status === "error") {
        yield put({
          type: "ORDER_TESTS_SUCCESS",
          deliveryError: responseData.delivery,
          deliveryErrorMessage: responseData.delivery.message,
        });
      } else {
        yield put({
          type: "ORDER_TESTS_SUCCESS",
        });
      }

      if (orderResponse.type === "subscription_downgrade") {
        yield put({
          type: "SNACKBAR_ADD",
          notificationType: "success",
          content: `Downgrade completed`,
        });
      }

      // Send data to Google Analytics
      if (
        orderResponse.type === "single_purchase" ||
        orderResponse.type === "subscription_creation"
      ) {
        const expectedLifetimeInMonths = parseFloat(
          process.env.REACT_APP_EXPECTED_LIFETIME_IN_MONTHS,
        );
        const eurInUsd = parseFloat(process.env.REACT_APP_EUR_IN_USD);
        let currencyMultiplier;
        switch (currency) {
          case "eur":
            currencyMultiplier = eurInUsd; // EUR
            break;
          default:
            currencyMultiplier = 1; // USD
        }
        let valueUsd;
        switch (plan) {
          case "starter":
          case "pro":
          case "agency":
            valueUsd =
              netPriceCharged * expectedLifetimeInMonths * currencyMultiplier;
            break;
          case "payg":
          default:
            valueUsd = netPriceCharged * currencyMultiplier;
        }
        yield put({
          type: "ANALYTICS_CONVERSION",
          gtmMeta: {
            quantity,
            orderType: plan === "payg" ? "single" : "subscription",
            plan,
            billingCycle,
            valueUsd,
          },
        });
      }
      // Reload user model
      yield put({ type: "USER_REQUEST" });
    } else {
      if (responseData.status === "error") {
        const errorMessage = responseData.message;
        yield put({
          type: "ORDER_TESTS_FAILURE",
          error: true,
          errorMessage,
        });
      } else {
        yield put({
          type: "ORDER_TESTS_FAILURE",
          error: true,
          errorMessage: "Something went wrong. (Unknown response type)",
        });
      }
    }
  } catch (error) {
    const errorMessage = extractMessageFromApiError(error);

    if (errorMessage) {
      yield put({
        type: "NOTIFICATION_ADD",
        notificationType: "error",
        content: errorMessage,
      });
    }

    yield put({ type: "ORDER_TESTS_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postOrder(
  quantity,
  plan,
  billingCycle,
  deliveryRequest,
  paymentMethodId,
  isTermsAccepted,
) {
  return axios({
    method: "post",
    url: API_URL + "/order",
    data: {
      quantity,
      plan,
      billing_cycle: billingCycle,
      payment_method_id: paymentMethodId,
      terms_accepted: isTermsAccepted,
      delivery: deliveryRequest
        ? {
            test_id: deliveryRequest.testId,
            repeat_period:
              deliveryRequest.repeatType !== "single"
                ? deliveryRequest.repeatType
                : undefined,
            type: deliveryRequest.repeatType === "single" ? "single" : "repeat",
            video_count: deliveryRequest.videoCount,
            targeting: deliveryRequest.targeting,
          }
        : undefined,
    },
  });
}

function postOrderConfirm(paymentIntentId, deliveryRequest) {
  return axios({
    method: "post",
    url: API_URL + "/order/confirm",
    data: {
      payment_intent_id: paymentIntentId,
      delivery: deliveryRequest
        ? {
            test_id: deliveryRequest.testId,
            repeat_period:
              deliveryRequest.repeatType !== "single"
                ? deliveryRequest.repeatType
                : undefined,
            type: deliveryRequest.repeatType === "single" ? "single" : "repeat",
            video_count: deliveryRequest.videoCount,
            targeting: deliveryRequest.targeting,
          }
        : undefined,
    },
  });
}

// ORDER TESTS

function* watchOrderTestTest() {
  yield takeEvery("ORDER_TEST_TEST", callOrderTestTest);
}

function* callOrderTestTest(action) {
  try {
    const { testId, status, processing } = action;
    yield call(postOrderTestTest, testId, status, processing);
  } catch (e) {}
}

function postOrderTestTest(testId, status, processing) {
  return axios({
    method: "post",
    url: API_URL + "/test/" + testId + "/video",
    data: {
      status,
      processing: processing ? 1 : 0,
    },
  });
}

// ORDER TESTS

function* watchOrderTestingTestTest() {
  yield takeEvery("ORDER_TESTING_TEST_TEST", callOrderTestingTestTest);
}

function* callOrderTestingTestTest(action) {
  try {
    const { testId, status } = action;
    yield call(postOrderTestingTestTest, testId, status);
  } catch (e) {}
}

function postOrderTestingTestTest(testId, status) {
  return axios({
    method: "post",
    url: API_URL + "/test/" + testId + "/testing",
    data: {
      status,
    },
  });
}

// ORDER TESTS

function* watchOrderInvitationTestTest() {
  yield takeEvery("ORDER_INVITATION_TEST_TEST", callOrderInvitationTestTest);
}

function* callOrderInvitationTestTest(action) {
  try {
    const { testId } = action;
    yield call(postOrderInvitationTestTest, testId);
  } catch (e) {}
}

function postOrderInvitationTestTest(testId) {
  return axios({
    method: "post",
    url: API_URL + "/test/" + testId + "/invitation",
    data: {
      // device: desktop || tablet || mobile
    },
  });
}

// CANCEL_SUBSCRIPTION

function* watchCancelSubscription() {
  yield takeLatest("CANCEL_SUBSCRIPTION_REQUEST", callCancelSubscriptionApi);
}

function* callCancelSubscriptionApi(action) {
  try {
    const { reason, additionalDetails } = action;
    const response = yield call(
      deleteUserSubscription,
      reason,
      additionalDetails,
    );
    const user = response.data.data;
    yield put({ type: "CANCEL_SUBSCRIPTION_SUCCESS", data: { user } });
  } catch (error) {
    yield put({
      type: "CANCEL_SUBSCRIPTION_FAILURE",
      error: {
        message: extractMessageFromApiError(error),
        fieldFeedback: extractFieldFeedbackFromApiError(error),
      },
    });
  }
}

function deleteUserSubscription(reason, additionalDetails) {
  let reasonText;
  if (reason) {
    reasonText = reason + "\n\n" + additionalDetails;
  }
  return axios({
    method: "post",
    url: API_URL + "/subscription/cancel",
    data: {
      reason: reasonText,
    },
  });
}

// REACTIVATE_SUBSCRIPTION

function* watchReactivateSubscription() {
  yield takeLatest(
    "REACTIVATE_SUBSCRIPTION_REQUEST",
    callReactivateSubscriptionApi,
  );
}

function* callReactivateSubscriptionApi() {
  try {
    const response = yield call(postSubscriptionReactivate);
    const user = response.data.data;
    yield put({ type: "REACTIVATE_SUBSCRIPTION_SUCCESS", data: { user } });

    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Your subscription was reactivated`,
    });
  } catch (error) {
    yield put({
      type: "REACTIVATE_SUBSCRIPTION_FAILURE",
      error: {
        message: extractMessageFromApiError(error),
        fieldFeedback: extractFieldFeedbackFromApiError(error),
      },
    });
    yield put({
      type: "NOTIFICATION_ADD",
      notificationType: "error",
      content: extractMessageFromApiError(error),
    });
  }
}

function postSubscriptionReactivate() {
  return axios({
    method: "post",
    url: API_URL + "/subscription/reactivate",
  });
}

// DELIVERY CREATE

function* watchDeliveryCreate() {
  yield takeEvery("DELIVERY_CREATE_REQUEST", callDeliveryCreateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callDeliveryCreateApi(action) {
  try {
    const { testId, repeatType, videoCount, targeting } = action;
    const response = yield call(
      postDelivery,
      testId,
      repeatType,
      videoCount,
      targeting,
    );
    const { credits, sessions, delivery, status } = response.data;
    yield put({
      type: "DELIVERY_CREATE_SUCCESS",
      testId,
      delivery,
      status,
      credits,
      sessions,
      gtmMeta: { repeatType, videoCount },
    });
  } catch (error) {
    const { testId } = action;
    yield put({
      type: "DELIVERY_CREATE_FAILURE",
      testId,
      error,
    });
  }
}

// function that makes the api request and returns a Promise for response
function postDelivery(testId, repeatType, videoCount, targeting) {
  return axios({
    method: "post",
    url: API_URL + "/test/" + testId + "/delivery",
    data: {
      repeat_period: repeatType !== "single" ? repeatType : undefined,
      type: repeatType === "single" ? "single" : "repeat",
      video_count: videoCount,
      targeting: targeting,
    },
  });
}

// DELIVERY UPDATE

function* watchDeliveryUpdate() {
  yield takeEvery("DELIVERY_UPDATE_REQUEST", callDeliveryUpdateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callDeliveryUpdateApi(action) {
  try {
    const { deliveryId, videoCount, confirm } = action;
    const response = yield call(
      patchDelivery,
      deliveryId,
      undefined,
      undefined,
      videoCount,
      undefined,
      confirm,
    );
    const { credits, sessions, delivery, status } = response.data;
    yield put({
      type: "DELIVERY_UPDATE_SUCCESS",
      deliveryId,
      delivery,
      status,
      credits,
      sessions,
    });
  } catch (error) {
    const { deliveryId } = action;
    yield put({
      type: "DELIVERY_UPDATE_FAILURE",
      deliveryId,
      error:
        isObject(error.response) && error.response.data
          ? error.response.data
          : null,
    });
  }
}

// DELIVERY STOP

function* watchDeliveryStop() {
  yield takeEvery("DELIVERY_STOP_REQUEST", callDeliveryStopApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callDeliveryStopApi(action) {
  try {
    const { deliveryId } = action;
    const response = yield call(
      patchDelivery,
      deliveryId,
      undefined,
      undefined,
      undefined,
      "stopped",
    );
    const { credits, sessions, test, status } = response.data;
    yield put({
      type: "DELIVERY_STOP_SUCCESS",
      deliveryId,
      test,
      status,
      credits,
      sessions,
    });
  } catch (error) {
    const { deliveryId } = action;
    yield put({ type: "DELIVERY_STOP_FAILURE", deliveryId });
  }
}

// function that makes the api request and returns a Promise for response
function patchDelivery(
  deliveryId,
  repeatPeriod,
  type,
  videoCount,
  status,
  confirm,
) {
  if (!isUndefined(videoCount)) {
    videoCount = JSON.stringify({
      any: videoCount.any,
      desktop: videoCount.desktop,
      mobile: videoCount.mobile,
      tablet: videoCount.tablet,
    });
  }

  return axios({
    method: "patch",
    url: API_URL + "/delivery/" + deliveryId,
    data: {
      repeat_period: type === "repeat" ? repeatPeriod : undefined,
      type,
      video_count: videoCount,
      status,
      confirm: confirm ? true : undefined,
    },
  });
}

// DELIVERY RESUME

function* watchDeliveryResume() {
  yield takeEvery("DELIVERY_RESUME_REQUEST", callDeliveryResumeApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callDeliveryResumeApi(action) {
  try {
    const { deliveryId } = action;
    yield call(postDeliveryResume, deliveryId);
    yield put({ type: "DELIVERY_RESUME_SUCCESS" });
  } catch (error) {
    yield put({ type: "DELIVERY_RESUME_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postDeliveryResume(deliveryId) {
  return axios({
    method: "post",
    url: API_URL + "/delivery/" + deliveryId + "/resume",
  });
}

// DOG (Development only)

function* watchDog() {
  yield takeLatest("API_CALL_REQUEST", callDogApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callDogApi() {
  try {
    const response = yield call(fetchDog);
    const dog = response.data.message;

    yield put({ type: "API_CALL_SUCCESS", dog });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "API_CALL_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchDog() {
  // XXX: This will fail because the axios instance returns an error for non api requests
  return axios({
    method: "get",
    url: "https://dog.ceo/api/breeds/image/random",
  });
}

// DOG_ACCOUNT_RESET

function* watchDogAccountReset() {
  yield takeLatest("DOG_ACCOUNT_RESET_REQUEST", callAccountResetApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callAccountResetApi() {
  try {
    yield call(postAccountReset);
    yield put({ type: "DOG_ACCOUNT_RESET_SUCCESS" });
    // refresh page
    window?.location.reload();
  } catch (error) {
    yield put({ type: "DOG_ACCOUNT_RESET_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postAccountReset() {
  return axios({
    method: "post",
    url: API_URL + "/account/reset",
  });
}

// DOG_CUSTOMER_DELETE

function* watchDogCustomerDelete() {
  yield takeLatest("DOG_CUSTOMER_DELETE_REQUEST", callCustomerDeleteApi);
}

function* watchDownloadVideoClip() {
  yield takeEvery("DOWNLOAD_VIDEO_CLIP_REQUEST", callDownloadVideoClipApi);
}

let nextVideoClipDownloadId = 1;

function* callDownloadVideoClipApi(action) {
  const { videoId, timestampStart, timestampEnd } = action;
  const downloadId = nextVideoClipDownloadId;
  nextVideoClipDownloadId += 1;
  yield put({ type: "DOWNLOAD_VIDEO_CLIP_STARTED", downloadId });

  const channel = yield call(
    downloadVideoClip,
    downloadId,
    videoId,
    timestampStart,
    timestampEnd,
  );

  try {
    // take(END) will cause the saga to terminate by jumping to the finally block
    while (true) {
      // Remember, our helper only emits actions
      // Thus we can directly "put" them
      const action = yield take(channel);
      yield put(action);
    }
  } catch (error) {
    yield put({ type: "DOWNLOAD_VIDEO_CLIP_ERROR", downloadId, error });
  }
}

function downloadVideoClip(downloadId, videoId, timestampStart, timestampEnd) {
  return eventChannel((emitter) => {
    function handleDownloadProgress(e) {
      const { loaded, total, progress } = e;
      emitter({
        type: "DOWNLOAD_VIDEO_CLIP_PROGRESS",
        downloadId,
        loaded,
        total,
        progress,
      });
    }

    const controller = new AbortController();

    axios({
      method: "get",
      responseType: "blob",
      url: `${API_URL}/video/${videoId}/clip/${timestampStart}-${timestampEnd}`,
      signal: controller.signal,
      onDownloadProgress: handleDownloadProgress,
    })
      .then((response) => {
        emitter({ type: "DOWNLOAD_VIDEO_CLIP_SUCCESS", downloadId });
        downloadBlobResponse(
          response,
          `clip-${videoId}-${timestampStart}-${timestampEnd}.mp4`,
        );
      })
      .catch((error) => {
        throw error;
      });

    return () => {
      controller.abort();
    };
  });
}

function* callCustomerDeleteApi() {
  try {
    yield call(postCustomerDelete);
    yield put({ type: "DOG_CUSTOMER_DELETE_SUCCESS" });
    yield put({ type: "USER_REQUEST" });
  } catch (error) {
    yield put({
      type: "DOG_CUSTOMER_DELETE_FAILURE",
      error: {
        message: extractMessageFromApiError(error),
        fieldFeedback: extractFieldFeedbackFromApiError(error),
      },
    });
  }
}

function postCustomerDelete() {
  return axios({
    method: "post",
    url: API_URL + "/customer/delete",
  });
}

// DOG_DELETE_SUBSCRIPTION

function* watchDogDeleteSubscription() {
  yield takeLatest(
    "DOG_DELETE_SUBSCRIPTION_REQUEST",
    callDeleteSubscriptionApi,
  );
}

function* callDeleteSubscriptionApi() {
  try {
    yield call(postDeleteSubscription);
    yield put({ type: "DOG_DELETE_SUBSCRIPTION_SUCCESS" });
    yield put({ type: "USER_REQUEST" }); // Update User
  } catch (error) {
    yield put({ type: "DOG_DELETE_SUBSCRIPTION_FAILURE", error });
  }
}

function postDeleteSubscription() {
  return axios({
    method: "post",
    url: API_URL + "/subscription/delete",
  });
}

// DOG_DELETE_CREDITS

function* watchDogDeleteCredits() {
  yield takeLatest("DOG_DELETE_CREDITS_REQUEST", callDeleteCreditsApi);
}

function* callDeleteCreditsApi() {
  try {
    yield call(postDeleteCredits);
    yield put({ type: "DOG_DELETE_CREDITS_SUCCESS" });
    yield put({ type: "USER_REQUEST" }); // Update User
  } catch (error) {
    yield put({ type: "DOG_DELETE_CREDITS_FAILURE", error });
  }
}

function postDeleteCredits() {
  return axios({
    method: "post",
    url: API_URL + "/credits/delete",
  });
}

// USER

function* watchUser() {
  yield takeLatest("USER_REQUEST", callUserApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callUserApi() {
  try {
    const currentRefreshHash = yield select(
      (state) => state.user.userRefreshHash,
    );
    const response = yield call(fetchUser, currentRefreshHash);
    const user = response.data.data;
    const refreshHash = response.data.meta?.refresh_hash;
    yield put({ type: "USER_SUCCESS", user, refreshHash });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "USER_FAILURE", error: createReduxApiError(error) });
  }
}

// function that makes the api request and returns a Promise for response
function fetchUser(refreshHash) {
  return axios({
    method: "get",
    url:
      API_URL + "/user" + (refreshHash ? "?refresh_hash=" + refreshHash : ""),
  });
}

// FEATURE POPUP

function watchDismissFeaturePopup() {
  return takeLatest("FEATURE_POPUP_DISMISS_REQUEST", callUserFeaturePopupApi);
}

function* callUserFeaturePopupApi(action) {
  try {
    const { version } = action;
    const response = yield call(patchUserFeaturePopup, {
      version,
    });
    if (response.data.status === "success") {
      yield put({ type: "FEATURE_POPUP_DISMISS_SUCCESS", version });
    } else {
      throw new Error("Failed to dismiss feature popup");
    }
  } catch (error) {
    yield put({ type: "FEATURE_POPUP_DISMISS_ERROR", error });
  }
}

function patchUserFeaturePopup({ version }) {
  return axios({
    method: "patch",
    url: API_URL + "/user/feature-popup",
    data: {
      version,
    },
  });
}

// UPDATE USER

function* watchUserUpdate() {
  yield takeLatest("USER_UPDATE_REQUEST", callUserUpdateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callUserUpdateApi(action) {
  try {
    const response = yield call(patchUser, {
      email: action.email,
      name: action.name,
    });
    const user = response.data.data;

    if (user) {
      yield put({ type: "USER_UPDATE_SUCCESS", user });
    } else {
      yield put({ type: "USER_UPDATE_SUCCESS" });
    }

    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Saved successfully`,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "USER_UPDATE_FAILURE",
      error: createReduxApiError(error),
    });
  }
}

function* watchUserUpdatePassword() {
  yield takeLatest("USER_UPDATE_PASSWORD_REQUEST", callUserUpdatePasswordApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callUserUpdatePasswordApi(action) {
  try {
    const response = yield call(patchUser, {
      new_password: action.newPassword,
      new_password_confirmation: action.newPasswordConfirmation,
      current_password: action.currentPassword,
    });
    const user = response.data.data;

    if (user) {
      yield put({ type: "USER_UPDATE_PASSWORD_SUCCESS", user });
    } else {
      yield put({ type: "USER_UPDATE_PASSWORD_SUCCESS" });
      yield put({
        type: "SNACKBAR_ADD",
        notificationType: "success",
        content: `Password updated`,
      });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "USER_UPDATE_PASSWORD_FAILURE",
      error: createReduxApiError(error),
    });
  }
}

// function that makes the api request and returns a Promise for response
function patchUser(data) {
  return axios({
    method: "patch",
    url: API_URL + "/user",
    data,
  });
}

// BILLING INFORMATION

function extractBillingInformationFromApiResponse(billingInformationData) {
  return {
    firstName: billingInformationData.first_name,
    lastName: billingInformationData.last_name,
    organization: billingInformationData.organization,
    street: billingInformationData.street,
    postcode: billingInformationData.postcode,
    city: billingInformationData.city,
    country: billingInformationData.country,
    vatId: billingInformationData.vatno,
    billingEmail: billingInformationData.billing_email,
    taxPercent: billingInformationData.tax_percent,
  };
}

function* watchBillingInformation() {
  yield takeLatest("BILLING_INFORMATION_REQUEST", callBillingInformationApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callBillingInformationApi() {
  try {
    const response = yield call(fetchBillingInformation);
    const billingInformationData = response.data.data;
    const billingInformation = extractBillingInformationFromApiResponse(
      billingInformationData,
    );
    const vatRates = billingInformationData.vat_rates;
    const vatCountries = Object.keys(vatRates).map((key) => {
      return { country: key, rate: vatRates[key] };
    });

    yield put({
      type: "BILLING_INFORMATION_SUCCESS",
      billingInformation,
      complete: billingInformationData.complete,
      vatCountries,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "BILLING_INFORMATION_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchBillingInformation() {
  return axios({
    method: "get",
    url: API_URL + "/subscription/billinginformation",
  });
}

// UPDATE BILLING INFORMATION

function* watchBillingInformationUpdate() {
  yield takeLatest(
    "BILLING_INFORMATION_UPDATE_REQUEST",
    callBillingInformationUpdateApi,
  );
}

// worker saga: makes the api call when watcher saga sees the action
function* callBillingInformationUpdateApi(action) {
  try {
    const response = yield call(patchBillingInformation, {
      first_name: action.firstName,
      last_name: action.lastName,
      organization: action.organization,
      street: action.street,
      postcode: action.postcode,
      city: action.city,
      country: action.country,
      vatno: action.vatId,
      billing_email: action.billingEmail,
    });

    yield put({ type: "USER_REQUEST" }); // Update currency

    const billingInformationData = response.data.data;
    const billingInformation = extractBillingInformationFromApiResponse(
      billingInformationData,
    );

    yield put({
      type: "BILLING_INFORMATION_UPDATE_SUCCESS",
      billingInformation,
      complete: billingInformationData.complete,
    });

    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Billing information saved`,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "BILLING_INFORMATION_UPDATE_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function patchBillingInformation(data) {
  return axios({
    method: "patch",
    url: API_URL + "/subscription/billinginformation",
    data,
  });
}

// VIDEO

function* watchVideo() {
  yield takeLatest("VIDEO_REQUEST", callVideoApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideoApi(action) {
  try {
    const response = yield call(fetchVideo, action.id, action.devTestVideo);
    const video = response.data.data;

    yield put({ type: "VIDEO_SUCCESS", video });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEO_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchVideo(id, devTestVideo) {
  const suffix = devTestVideo ? "?videojs" : "";
  return axios({
    method: "get",
    url: API_URL + "/video/" + id + suffix,
  });
}

// VIDEO UPDATE

function* watchVideoUpdate() {
  yield takeLatest("VIDEO_UPDATE_REQUEST", callVideoUpdateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideoUpdateApi(action) {
  const videoId = action.id;
  try {
    const { shared } = action;
    const response = yield call(patchVideo, videoId, { shared });
    const video = response.data.video;
    yield put({ type: "VIDEO_UPDATE_SUCCESS", video });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEO_UPDATE_FAILURE", error, videoId });
  }
}

// VIDEO PROBLEM

function* watchVideoProblem() {
  yield takeEvery("VIDEO_PROBLEM_REQUEST", callVideoProblemApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideoProblemApi(action) {
  try {
    yield call(
      postVideoProblem,
      action.id,
      action.description,
      action.problemType,
      action.resolution,
    );
    yield put({ type: "VIDEO_PROBLEM_SUCCESS" });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEO_PROBLEM_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postVideoProblem(id, description, problemType, resolution) {
  return axios({
    method: "post",
    url: API_URL + "/video/" + id + "/problem",
    data: {
      description,
      type: problemType,
      resolution,
    },
  });
}

// VIDEO TRANSCRIPT

function* watchTranscript() {
  yield takeLatest("VIDEO_TRANSCRIPT_REQUEST", callVideoTranscriptApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideoTranscriptApi(action) {
  const { videoId } = action;
  try {
    const response = yield call(postVideoTranscript, videoId);
    const video = response.data.data;
    yield put({ type: "VIDEO_TRANSCRIPT_SUCCESS", video });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEO_TRANSCRIPT_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postVideoTranscript(videoId) {
  return axios({
    method: "post",
    url: API_URL + "/video/" + videoId + "/transcript",
  });
}

// VIDEO AUTOMATED INSIGHTS

function* watchVideoAutomatedInsights() {
  yield takeLatest("VIDEO_AI_REQUEST", callVideoAutomatedInsightsApi);
}

function* callVideoAutomatedInsightsApi(action) {
  const { videoId } = action;
  try {
    const response = yield call(postVideoInsights, videoId);
    const { status } = response.data;
    if (status === "success") {
      yield put({
        type: "VIDEO_AI_SUCCESS",
        status,
        videoId,
        video: response.data.video,
      });
    } else if (status === "processing") {
      yield put({ type: "VIDEO_AI_SUCCESS", status, videoId });
    } else {
      throw new Error(response.data.message ?? "Transcript error");
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEO_AI_FAILURE", error });
  }
}

function postVideoInsights(videoId) {
  return axios({
    method: "post",
    url: API_URL + "/video/" + videoId + "/insights",
  });
}

// VIDEO DELETE

function* watchVideoDelete() {
  yield takeLatest("VIDEO_DELETE_REQUEST", callVideoDeleteApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideoDeleteApi(action) {
  try {
    yield call(postVideoDelete, action.id, action.confirmation);
    yield put({ type: "VIDEO_DELETE_SUCCESS" });
    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Video deleted`,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEO_DELETE_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postVideoDelete(id, confirmation) {
  return axios({
    method: "post",
    url: API_URL + "/video/" + id + "/delete",
    data: {
      confirmation,
    },
  });
}

// VIDEO SET NEW

function* watchVideoSetNew() {
  yield takeLatest("VIDEO_SET_NEW", callVideoSetNewApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideoSetNewApi(action) {
  try {
    const response = yield call(patchVideo, action.id, {
      new: action.new === true ? 1 : 0,
    });
    const video = response.data.video;
    const newVideoCount = response.data.new_video_count;
    yield put({ type: "VIDEO_SET_NEW_SUCCESS", video });
    yield put({ type: "SET_NEW_VIDEO_COUNT", newVideoCount });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEO_SET_NEW_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function patchVideo(id, data) {
  return axios({
    method: "patch",
    url: API_URL + "/video/" + id,
    data,
  });
}

// VIDEO STATS

const getVideoStatsItems = (state) => state.videoStats.items;

function* watchVideoStats() {
  while (true) {
    const { secondPlayedAction, pausedAction, endedAction } = yield race({
      secondPlayedAction: take(VIDEO_SECOND_WATCHED),
      pausedAction: take(VIDEO_PAUSED),
      endedAction: take(VIDEO_ENDED),
    });

    if (secondPlayedAction) {
      const videoStatsItems = yield select(getVideoStatsItems);
      const countTimestamps =
        videoStatsItems.find(
          (item) =>
            item.id === secondPlayedAction.id &&
            item.type === secondPlayedAction.videoStatsType,
        )?.timestamps.length ?? 0;
      if (countTimestamps > 5) {
        yield call(sendVideoStats);
      }
    }

    if (pausedAction || endedAction) {
      yield call(sendVideoStats);
    }
  }
}

function* sendVideoStats() {
  const videoStatsItems = yield select(getVideoStatsItems);
  for (const videoStatsItem of videoStatsItems) {
    const { timestamps, id, type } = videoStatsItem;
    if (timestamps.length > 0) {
      try {
        if (type === VideoStatsType.Video) {
          yield call(postVideoStats, id, timestamps);
        } else if (type === VideoStatsType.SharedVideo) {
          yield call(postSharedVideoStats, id, timestamps);
        } else if (type === VideoStatsType.SharedClip) {
          yield call(postSharedClipStats, id, timestamps);
        } else {
          throw new Error("Invalid video stats type");
        }
        yield put({ type: "VIDEO_STATS_SENT", sentItem: videoStatsItem });
      } catch (error) {
        yield put({ type: "VIDEO_STATS_SEND_FAILURE", error });
      }
    }
  }
}

// function that makes the api request and returns a Promise for response
function postVideoStats(id, timestamps) {
  return axios({
    method: "post",
    url: API_URL + "/video/" + id + "/stats",
    data: {
      timestamps,
    },
  });
}

// function that makes the api request and returns a Promise for response
function postSharedVideoStats(hash, timestamps) {
  return axios({
    method: "post",
    url: API_URL + "/shared/" + hash + "/stats",
    data: {
      timestamps,
    },
  });
}

// function that makes the api request and returns a Promise for response
function postSharedClipStats(hash, timestamps) {
  return axios({
    method: "post",
    url: API_URL + "/sharedclip/" + hash + "/stats",
    data: {
      timestamps,
    },
  });
}

// VIDEOS

function* watchVideos() {
  yield throttle(1000, "VIDEOS_REQUEST", callVideosApi);
}

function* watchVideosReRequest() {
  yield takeLatest("VIDEOS_RE_REQUEST", videosReRequest);
}

function* videosReRequest() {
  const requestAction = yield select(
    (state) => state.videos.videosRequestAction,
  );
  const refreshHash = yield select((state) => state.videos.videosRefreshHash);
  const fetching = yield select((state) => state.videos.fetching);
  if (requestAction && !fetching) {
    const actionToDispatch = {
      ...requestAction,
      refreshHash,
      silent: true,
    };
    yield put(actionToDispatch);
  }
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideosApi(action) {
  try {
    const response = yield call(fetchVideos, action.search, action.refreshHash);

    const videos = response.data.data;

    if (videos) {
      const totalCount = response.data.meta.total;
      const perPage = response.data.meta.per_page;
      const totalCountVideos = response.data.meta.total_videos;
      const totalCountNew = response.data.meta.total_new;
      const totalCountStarred = response.data.meta.total_starred;
      const refreshHash = response.data.meta.refresh_hash;
      yield put({
        type: "VIDEOS_SUCCESS",
        videos,
        totalCount,
        perPage,
        totalCountVideos,
        totalCountNew,
        totalCountStarred,
        refreshHash,
        requestAction: action,
      });
    } else {
      // Nothing new
      yield put({
        type: "VIDEOS_SUCCESS",
        refreshHash: action.refreshHash,
      });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEOS_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchVideos(query, refreshHash) {
  const parsedQuery = qs.parse(query);
  if (refreshHash) {
    parsedQuery.refresh_hash = refreshHash;
  }
  return axios({
    method: "get",
    url: API_URL + "/videos?" + qs.stringify(parsedQuery),
  });
}

// VIDEOS

function* watchVideosTests() {
  yield takeLatest("VIDEOS_TESTS_REQUEST", callVideosTestsApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideosTestsApi() {
  try {
    const response = yield call(fetchVideosTests);
    const tests = response.data;
    yield put({ type: "VIDEOS_TESTS_SUCCESS", tests });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEOS_TESTS_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchVideosTests() {
  return axios({
    method: "get",
    url: API_URL + "/videos/tests",
  });
}

// VIDEOS STAR / UNSTAR

function* watchVideosStar() {
  yield takeLatest("VIDEOS_STAR_REQUEST", callVideosStarApi);
}

function* watchVideosUnstar() {
  yield takeLatest("VIDEOS_UNSTAR_REQUEST", callVideosUnstarApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideosStarApi(action) {
  try {
    const response = yield call(patchVideo, action.id, { starred: 1 });
    const video = response.data.video;
    yield put({ type: "VIDEOS_STAR_SUCCESS", video, id: action.id });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEOS_STAR_FAILURE", error, id: action.id });
  }
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideosUnstarApi(action) {
  try {
    const response = yield call(patchVideo, action.id, { starred: 0 });
    const video = response.data.video;
    yield put({ type: "VIDEOS_UNSTAR_SUCCESS", video, id: action.id });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEOS_UNSTAR_FAILURE", error, id: action.id });
  }
}

// VIDEOS RATE

function* watchVideosRate() {
  yield takeLatest("VIDEOS_RATE_REQUEST", callVideosRateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callVideosRateApi(action) {
  try {
    const response = yield call(patchVideo, action.id, {
      rating: action.rating,
    });
    const video = response.data.video;
    yield put({
      type: "VIDEOS_RATE_SUCCESS",
      video,
      id: action.id,
      rating: action.rating,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "VIDEOS_RATE_FAILURE", error, id: action.id });
  }
}

function* watchWindowFocus() {
  yield takeLatest("WINDOW_FOCUS", windowFocusWorker);
}

function* windowFocusWorker() {
  const signedIn = yield select((state) => state.user.signedIn);
  if (signedIn) {
    yield put({ type: "USER_REQUEST" });
  }
}

// GIFT CODE REDEEM

function* watchGiftCodeRedeem() {
  yield takeLatest("GIFT_CODE_REDEEM_REQUEST", callGiftCodeRedeemApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callGiftCodeRedeemApi(action) {
  try {
    const response = yield call(postGiftCodeRedeem, action.code);
    yield put({ type: "GIFT_CODE_REDEEM_SUCCESS" });
    if (response.data && response.data.message) {
      yield put({
        type: "NOTIFICATION_ADD",
        notificationType: "success",
        content: response.data.message,
      });
    }
    // to reload account balance
    yield put({ type: "USER_REQUEST" });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "GIFT_CODE_REDEEM_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postGiftCodeRedeem(code) {
  return axios({
    method: "post",
    url: API_URL + "/giftcode/redeem",
    data: { code },
  });
}

// Init Checkout

function* watchInitCheckout() {
  // This was previously used to store information in a cookie
}

// MEMBERS

function* watchMembers() {
  yield takeLatest("MEMBERS_REQUEST", callMembersApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callMembersApi() {
  try {
    const response = yield call(fetchMembers);
    const members = response.data.data;
    yield put({ type: "MEMBERS_SUCCESS", members });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "MEMBERS_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchMembers() {
  return axios({
    method: "get",
    url: API_URL + "/subscription/members",
  });
}

// MEMBER CREATE

function* watchMemberCreate() {
  yield takeEvery("MEMBER_CREATE_REQUEST", callMemberCreateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callMemberCreateApi(action) {
  try {
    const { email, role, message } = action;
    const reponse = yield call(postMember, email, role, message);
    yield put({
      type: "MEMBER_CREATE_SUCCESS",
      token: reponse.data?.token,
    });
    yield put({ type: "MEMBERS_REQUEST" });
  } catch (error) {
    yield put({
      type: "MEMBER_CREATE_FAILURE",
      error,
    });
  }
}

// function that makes the api request and returns a Promise for response
function postMember(email, role, message) {
  return axios({
    method: "post",
    url: API_URL + "/subscription/member",
    data: {
      email,
      role,
      message,
    },
  });
}

// MEMBER UPDATE

function* watchMemberUpdate() {
  yield takeEvery("MEMBER_UPDATE_REQUEST", callMemberUpdateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callMemberUpdateApi(action) {
  try {
    const { id, role } = action;
    yield call(patchMember, id, role);
    yield put({
      type: "MEMBER_UPDATE_SUCCESS",
      id,
      role,
    });
    yield put({ type: "MEMBERS_REQUEST" });
  } catch (error) {
    yield put({
      type: "MEMBER_UPDATE_FAILURE",
      error,
    });
  }
}

// function that makes the api request and returns a Promise for response
function patchMember(id, role) {
  return axios({
    method: "patch",
    url: API_URL + `/subscription/member/${id}`,
    data: {
      role,
    },
  });
}

// MEMBER DELETE

function* watchMemberDelete() {
  yield takeEvery("MEMBER_DELETE_REQUEST", callMemberDeleteApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callMemberDeleteApi(action) {
  try {
    const { id } = action;
    yield call(deleteMember, id);
    yield put({
      type: "MEMBER_DELETE_SUCCESS",
    });
    yield put({ type: "MEMBERS_REQUEST" });
  } catch (error) {
    yield put({
      type: "MEMBER_DELETE_FAILURE",
      error,
    });
  }
}

// function that makes the api request and returns a Promise for response
function deleteMember(id) {
  return axios({
    method: "delete",
    url: API_URL + `/subscription/member/${id}`,
  });
}

// MEMBER RESEND

function* watchMemberResend() {
  yield takeEvery("MEMBER_RESEND_REQUEST", callMemberResendApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callMemberResendApi(action) {
  try {
    const { id } = action;
    yield call(resendMember, id);
    yield put({
      type: "MEMBER_RESEND_SUCCESS",
    });
  } catch (error) {
    yield put({
      type: "MEMBER_RESEND_FAILURE",
      error,
    });
  }
}

// function that makes the api request and returns a Promise for response
function resendMember(id) {
  return axios({
    method: "post",
    url: API_URL + `/subscription/member/${id}/resend`,
  });
}

// INVOICES

function* watchInvoices() {
  yield takeLatest("INVOICES_REQUEST", callInvoicesApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callInvoicesApi() {
  try {
    const response = yield call(fetchInvoices);
    const invoices = response.data.data;
    const totalCount = 1; //response.data.meta.total;
    const perPage = 1; //response.data.meta.per_page;
    yield put({ type: "INVOICES_SUCCESS", invoices, totalCount, perPage });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "INVOICES_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchInvoices() {
  return axios({
    method: "get",
    url: API_URL + "/subscription/invoices",
  });
}

// INVITATION

function* watchInvitation() {
  yield takeLatest("INVITATION_REQUEST", callInvitationApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callInvitationApi(action) {
  try {
    const { token } = action;
    const response = yield call(postInvitation, token);
    const { email, owner, existing } = response.data;
    yield put({
      type: "INVITATION_SUCCESS",
      invitation: { email, owner, existing },
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "INVITATION_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postInvitation(token) {
  return axios({
    method: "post",
    url: API_URL + "/subscription/token",
    data: {
      token,
    },
  });
}

// TARGETING

function* watchTargeting() {
  yield takeLatest("TARGETING_REQUEST", callTargetingApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callTargetingApi(action) {
  try {
    const {
      testId,
      gender,
      region,
      age,
      language,
      device,
      screener,
      screener_active,
    } = action;
    const response = yield call(
      postTargeting,
      testId,
      gender,
      region,
      age,
      language,
      device,
      screener,
      screener_active,
    );
    const { show_warning: showWarning } = response.data;
    yield put({ type: "TARGETING_SUCCESS", showWarning });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "TARGETING_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postTargeting(
  testId,
  gender,
  region,
  age,
  language,
  device,
  screener,
  screener_active,
) {
  return axios({
    method: "post",
    url: API_URL + "/test/" + testId + "/targeting",
    data: {
      gender: gender ? gender : undefined,
      region: region ? region : undefined,
      age: age ? age : undefined,
      language: language ? language : undefined,
      device: device ? device : undefined,
      screener: screener ? screener : undefined,
      screener_active: !!screener_active,
    },
  });
}

// TESTS

function* watchTests() {
  yield throttle(1000, "TESTS_REQUEST", callTestsApi);
}

function* watchTestsReRequest() {
  yield takeLatest("TESTS_RE_REQUEST", testsReRequest);
}

function* testsReRequest() {
  const requestAction = yield select((state) => state.tests.testsRequestAction);
  const refreshHash = yield select((state) => state.tests.testsRefreshHash);
  const fetching = yield select((state) => state.tests.fetching);
  if (requestAction && !fetching) {
    const actionToDispatch = {
      ...requestAction,
      refreshHash,
      silent: true,
    };
    yield put(actionToDispatch);
  }
}

// worker saga: makes the api call when watcher saga sees the action
function* callTestsApi(action) {
  try {
    const response = yield call(fetchTests, action.search, action.refreshHash);

    const tests = response.data.data;

    if (tests) {
      const totalCount = response.data.meta.total;
      const totalCountTests = response.data.meta.total_tests;
      const totalCountDraft = response.data.meta.total_draft;
      const totalCountArchived = response.data.meta.total_archived;
      const perPage = response.data.meta.per_page;
      const refreshHash = response.data.meta.refresh_hash;
      yield put({
        type: "TESTS_SUCCESS",
        tests,
        totalCount,
        totalCountTests,
        totalCountDraft,
        totalCountArchived,
        perPage,
        refreshHash,
        requestAction: action,
      });
    } else {
      // Nothing new
      yield put({ type: "TESTS_SUCCESS" });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "TESTS_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchTests(query, refreshHash) {
  const parsedQuery = qs.parse(query);
  if (refreshHash) {
    parsedQuery.refresh_hash = refreshHash;
  }
  return axios({
    method: "get",
    url: API_URL + "/tests?" + qs.stringify(parsedQuery),
  });
}

// TEST

function* watchTest() {
  yield takeLatest("TEST_REQUEST", callTestApi);
}

function* watchTestReRequest() {
  yield takeLatest("TEST_RE_REQUEST", testReRequest);
}

function* testReRequest(action) {
  const requestAction = yield select((state) => state.test.testRequestAction);
  const refreshHash = yield select((state) => state.test.testRefreshHash);
  const fetching = yield select((state) => state.test.fetching);
  if (requestAction && !fetching) {
    const actionToDispatch = {
      ...requestAction,
      refreshHash,
      silent: action.silent ?? true,
    };
    yield put(actionToDispatch);
  }
}

// worker saga: makes the api call when watcher saga sees the action
function* callTestApi(action) {
  try {
    const response = yield call(fetchTest, action.id, action.refreshHash);
    const test = response.data.data;
    if (test) {
      const refreshHash = response.data.meta.refresh_hash;
      yield put({
        type: "TEST_SUCCESS",
        test,
        requestAction: action,
        refreshHash,
      });
    } else {
      yield put({ type: "TEST_SUCCESS" });
    }
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "TEST_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchTest(id, refreshHash) {
  return axios({
    method: "get",
    url:
      API_URL +
      "/test/" +
      id +
      (refreshHash ? "?refresh_hash=" + refreshHash : ""),
  });
}

// TEST REPORT

function* watchTestReport() {
  yield takeLatest("TEST_REPORT_REQUEST", callTestReportApi);
}

function* callTestReportApi(action) {
  try {
    const response = yield call(
      fetchTestReport,
      action.testId,
      action.refreshHash,
    );
    const report = response.data.data;
    if (report) {
      yield put({ type: "TEST_REPORT_SUCCESS", report, requestAction: action });
    } else {
      // Nothing New
      yield put({ type: "TEST_REPORT_SUCCESS" });
    }
  } catch (error) {
    yield put({ type: "TEST_REPORT_FAILURE", error });
  }
}

function fetchTestReport(testId) {
  return axios({
    method: "get",
    url: API_URL + "/test/" + testId + "/report",
  });
}

// TEST REPORT

function* watchTestScreeners() {
  yield takeLatest("TEST_SCREENERS_REQUEST", callTestScreenersApi);
}

function* callTestScreenersApi(action) {
  try {
    const response = yield call(fetchTestScreeners, action.testId);
    const data = response.data.data;
    yield put({ type: "TEST_SCREENERS_SUCCESS", data });
  } catch (error) {
    yield put({
      type: "TEST_SCREENERS_FAILURE",
      error: {
        message: extractMessageFromApiError(error),
        fieldFeedback: extractFieldFeedbackFromApiError(error),
      },
    });
  }
}

function fetchTestScreeners(testId) {
  return axios({
    method: "get",
    url: API_URL + "/test/" + testId + "/screeners",
  });
}

// ALL TEST SCREENERS

function* watchAllTestScreeners() {
  yield takeLatest("ALL_TEST_SCREENERS_REQUEST", callAllTestScreenersApi);
}

function* callAllTestScreenersApi() {
  try {
    const response = yield call(fetchAllTestScreeners);
    const data = response.data;
    yield put({ type: "ALL_TEST_SCREENERS_SUCCESS", data });
  } catch (error) {
    yield put({
      type: "ALL_TEST_SCREENERS_FAILURE",
      error: {
        message: extractMessageFromApiError(error),
        fieldFeedback: extractFieldFeedbackFromApiError(error),
      },
    });
  }
}

function fetchAllTestScreeners() {
  return axios({
    method: "get",
    url: API_URL + "/screeners",
  });
}

// TEST VIDEOS

function* watchTestVideos() {
  yield throttle(1000, "TEST_VIDEOS_REQUEST", callTestVideosApi);
}

function* watchTestVideosReRequest() {
  yield takeLatest("TEST_VIDEOS_RE_REQUEST", testVideosReRequest);
}

function* testVideosReRequest() {
  const requestAction = yield select((state) => state.test.videosRequestAction);
  const refreshHash = yield select((state) => state.test.videosRefreshHash);
  const fetching = yield select((state) => state.test.videosFetching);
  if (requestAction && !fetching) {
    const actionToDispatch = {
      ...requestAction,
      refreshHash,
      silent: true,
    };
    yield put(actionToDispatch);
  }
}

function* callTestVideosApi(action) {
  try {
    const response = yield call(
      fetchTestVideos,
      action.testId,
      action.search,
      action.refreshHash,
    );
    const videos = response.data.data;
    if (videos) {
      const totalCount = response.data.meta.total;
      const perPage = response.data.meta.per_page;
      const refreshHash = response.data.meta.refresh_hash;
      yield put({
        type: "TEST_VIDEOS_SUCCESS",
        videos,
        totalCount,
        perPage,
        requestAction: action,
        refreshHash,
      });
    } else {
      // Nothing New
      yield put({ type: "TEST_VIDEOS_SUCCESS" });
    }
  } catch (error) {
    yield put({ type: "TEST_VIDEOS_FAILURE", error });
  }
}

function fetchTestVideos(testId, query, refreshHash) {
  const parsedQuery = qs.parse(query);
  if (refreshHash) {
    parsedQuery.refresh_hash = refreshHash;
  }
  return axios({
    method: "get",
    url: API_URL + "/test/" + testId + "/videos?" + qs.stringify(parsedQuery),
  });
}

// TEST INVITATION

function* watchTestInvitation() {
  yield takeLeading(
    "TEST_INVITATION_UPDATE_STATUS",
    callUpdateTestInvitationApi,
  );
}

function* callUpdateTestInvitationApi(action) {
  const { testId, active } = action;
  try {
    const response = yield call(patchTestInvitation, testId, active);
    const test = response.data.data;
    yield put({ type: "TEST_INVITATION_UPDATE_STATUS_SUCCESS", test });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "TEST_INVITATION_UPDATE_STATUS_FAILURE", testId, error });
  }
}

// function that makes the api request and returns a Promise for response
function patchTestInvitation(id, active) {
  return axios({
    method: "patch",
    url: API_URL + "/test/" + id + "/invitation",
    data: {
      active,
    },
  });
}

// TEST SETUP DUPLICATE LOAD

function cleanTestForDuplicate(test) {
  const cleanTask = test.task.map((taskItem) => {
    const cleanTaskItem = {
      ...taskItem,
      id: null,
      localId: taskItem.id,
      response_count: null,
    };
    return cleanTaskItem;
  });

  const cleanTest = {
    ...test,
    title: test.title + " (Copy)",
    task: cleanTask,
  };
  return cleanTest;
}

function* watchTestSetupDuplicateLoad() {
  yield takeLeading("TEST_SETUP_DUPLICATE_LOAD", callTestSetupDuplicateLoadApi);
}

function* callTestSetupDuplicateLoadApi(action) {
  try {
    const response = yield call(fetchTestEdit, action.id);
    const test = response.data.data;
    const cleanTest = cleanTestForDuplicate(test);
    yield put({
      type: "TEST_SETUP_DUPLICATE_LOAD_SUCCESS",
      testDuplicate: cleanTest,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "TEST_SETUP_DUPLICATE_LOAD_FAILURE", error });
  }
}

// TEST SETUP TEMPLATE LOAD

function* watchTestSetupTemplateLoad() {
  yield takeLeading("TEST_SETUP_TEMPLATE_LOAD", callTestSetupTemplateLoadApi);
}

function* callTestSetupTemplateLoadApi(action) {
  try {
    const response = yield call(fetchTemplate, action.templateId);
    const template = response.data.data;
    yield put({ type: "TEST_SETUP_TEMPLATE_LOAD_SUCCESS", template });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "TEST_SETUP_TEMPLATE_LOAD_FAILURE", error });
  }
}

function fetchTemplate(sharedHashId) {
  return axios({
    method: "get",
    url: API_URL + "/template/" + sharedHashId,
  });
}

function* watchAiTestSetup() {
  yield takeLatest("TEST_SETUP_AI_REQUEST", callAiTestSetup);
}

function* callAiTestSetup(action) {
  const channel = yield call(createAiTestSetupChannel, action.prompt);

  try {
    while (true) {
      const response = yield take(channel);
      yield put(response);
    }
  } catch (error) {
    yield put({ type: "TEST_SETUP_AI_FAILURE", error });
  } finally {
    channel.close();
  }
}

// Parse the perhaps overly complex response from the AiTestSetup API.
// It's complex because there are two paths for error responses, and
// a successful result is returned in Ndjson format. So we need to first
// check which path has the response code.
function parseAiTestSetupStreamEvent(response) {
  const codePathA = response?.currentTarget?.status;
  const codePathB = response?.response?.status;

  if (typeof codePathA === "number") {
    // Successful response will return Ndjson
    if (codePathA === 200) {
      const result = parseNdjson(response.currentTarget.responseText);

      return {
        code: codePathA,
        result,
      };
    } else {
      const { errors, message } = JSON.parse(
        response.currentTarget.responseText,
      );

      return {
        code: codePathA,
        errorMessage: message,
        fieldFeedback: errors,
      };
    }
  } else if (typeof codePathB === "number") {
    // XXX: A successful response should never come from this path.
    if (codePathB === 200) {
      return {
        code: codePathA,
        errorMessage: "An unexpected error occurred.",
      };
    }

    return {
      code: codePathB,
      errorMessage: response.response.data.message,
      fieldFeedback: response.response.data.errors,
    };
  } else {
    return {
      code: null,
      errorMessage: "An unexpected error occurred.",
    };
  }
}

// Parse the Ndjson response from the AiTestSetup API.
// It's a collection that will also contain the aiTestId
// if the test creation was successful and at the end of the stream.
function parseTaskReponse(result) {
  const withLocalId = result.map((r, i) => ({ ...r, localId: i }));
  const task = withLocalId.filter(
    (jsonObject) => jsonObject.aitest_id === undefined,
  );

  let aiTestId = null;
  if (task.length !== withLocalId.length) {
    const _id = withLocalId.reverse()[0]?.aitest_id;
    if (_id !== undefined) {
      aiTestId = _id;
    }
  }

  return {
    task,
    aiTestId,
  };
}

function createAiTestSetupChannel(prompt) {
  const controller = new AbortController();
  return eventChannel((emitter) => {
    const fetchStream = async () => {
      let progressError;
      try {
        const response = await axios({
          method: "POST",
          url: API_URL + "/aitest/",
          data: prompt,
          signal: controller.signal,
          responseType: "stream",
          // Returns a native ProgressEvent
          onDownloadProgress: (progressEvent) => {
            const { code, result, errorMessage, fieldFeedback } =
              parseAiTestSetupStreamEvent(progressEvent);

            if (code !== 200) {
              progressError = {
                message: errorMessage,
                fieldFeedback,
                status: code,
              };
              throw new Error(errorMessage);
            } else {
              const { task, aiTestId } = parseTaskReponse(result);

              // The associated reducer will replace the current state with
              // this new state, which is the current full state of the test,
              // and not just the latest changes.
              emitter({
                type: "TEST_SETUP_AI_PROGRESS",
                result: task,
                aiTestSetupId: aiTestId,
              });
            }
          },
        });

        if (response.status !== 200) {
          throw new Error(response.statusText);
        } else {
          // We don't need to emit anything here, as the progress events
          // provides the full current state of the test creation.
          emitter({ type: "TEST_SETUP_AI_SUCCESS" });
        }
      } catch (error) {
        const apiError = createReduxApiError(error);
        emitter({
          type: "TEST_SETUP_AI_FAILURE",
          errorMessage: apiError.message,
          fieldFeedback: progressError?.fieldFeedback,
        });
      }
    };

    fetchStream();

    return () => {
      controller.abort();
    };
  });
}

// TEST SETUP LOAD

function* watchTestSetupLoad() {
  yield takeLeading("TEST_SETUP_LOAD", callTestSetupLoadApi);
}

function* callTestSetupLoadApi(action) {
  try {
    const response = yield call(fetchTestEdit, action.id);
    const test = response.data.data;
    yield put({ type: "TEST_SETUP_LOAD_SUCCESS", test });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({ type: "TEST_SETUP_LOAD_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchTestEdit(id) {
  return axios({
    method: "get",
    url: API_URL + "/test/" + id + "/edit",
  });
}

// TEST SETUP SAVE

function* watchTestSetupSave() {
  yield takeLeading("TEST_SETUP_SAVE", callTestSetupSaveApi);
}

function* callTestSetupSaveApi(action) {
  const {
    id,
    language,
    task,
    title,
    testType,
    url,
    urlChecked,
    overwriteUrlCheck,
    aitest_id,
    creation_type,
  } = action;
  try {
    if (id) {
      const data = {
        language,
        type: testType,
        task,
        title,
        url,
        url_checked: urlChecked,
        url_check_overwritten: overwriteUrlCheck,
        aitest_id,
      };
      yield call(patchTest, id, data);
      yield put({ type: "TEST_SETUP_SAVE_SUCCESS", testId: id });
    } else {
      const response = yield call(
        postTest,
        testType,
        language,
        task,
        title,
        url,
        urlChecked,
        overwriteUrlCheck,
        aitest_id,
        creation_type,
      );
      const test = response.data.data;
      yield put({ type: "ANALYTICS_TEST_CREATED" });
      yield put({ type: "TEST_SETUP_SAVE_SUCCESS", test, testId: test.id });
    }
  } catch (error) {
    yield put({ type: "TEST_SETUP_SAVE_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function postTest(
  testType,
  language,
  task,
  title,
  url,
  urlChecked,
  overwriteUrlCheck,
  aitest_id,
  creation_type,
) {
  return axios({
    method: "post",
    url: API_URL + "/test",
    data: {
      type: testType,
      language,
      task,
      title,
      url,
      url_checked: urlChecked,
      url_check_overwritten: overwriteUrlCheck,
      aitest_id,
      creation_type,
    },
  });
}

// function that makes the api request and returns a Promise for response
function patchTest(id, data) {
  // Convert objects to json strings
  const dataWithStringifiedObjects = {};
  const dataKeys = Object.keys(data);
  for (const key of dataKeys) {
    const value = data[key];
    if (key === "task") {
      dataWithStringifiedObjects["task"] = value;
    } else if (isObject(value)) {
      dataWithStringifiedObjects[key] = JSON.stringify(value);
    } else {
      dataWithStringifiedObjects[key] = value;
    }
  }

  return axios({
    method: "patch",
    url: API_URL + "/test/" + id,
    data: dataWithStringifiedObjects,
  });
}

// TEST DELETE

function* watchTestDelete() {
  yield takeEvery("TEST_DELETE", callTestDeleteApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callTestDeleteApi(action) {
  try {
    const { id } = action;
    yield call(deleteTest, id);
    yield put({ type: "TEST_DELETE_SUCCESS", id });

    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Test deleted`,
    });
  } catch (error) {
    yield put({ type: "TEST_DELETE_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function deleteTest(id) {
  return axios({
    method: "delete",
    url: API_URL + "/test/" + id,
  });
}

// TEST ARCHIVE

function* watchTestArchive() {
  yield takeEvery("TEST_ARCHIVE", callTestArchiveApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callTestArchiveApi(action) {
  try {
    const { id, title } = action;
    yield call(patchTestArchived, id, true);
    yield put({ type: "TEST_ARCHIVE_SUCCESS", id });
    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `${title ? title : "Test"} has been archived`,
    });
  } catch (error) {
    yield put({ type: "TEST_ARCHIVE_FAILURE", error });
  }
}

// function that makes the api request and returns a Promise for response
function patchTestArchived(id, archived) {
  return axios({
    method: "patch",
    url: API_URL + "/test/" + id + "/archived",
    data: { archived },
  });
}

// TEST ARCHIVE

function* watchTestUnarchive() {
  yield takeEvery("TEST_UNARCHIVE", callTestUnarchiveApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callTestUnarchiveApi(action) {
  try {
    const { id, title } = action;
    yield call(patchTestArchived, id, false);
    yield put({ type: "TEST_UNARCHIVE_SUCCESS", id });
    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `${title ? title : "Test"} has been unarchived`,
    });
  } catch (error) {
    yield put({ type: "TEST_UNARCHIVE_FAILURE", error });
  }
}

// PASSWORD RESET

function* watchPasswordReset() {
  yield takeEvery("PASSWORD_RESET_REQUEST", callPasswordResetApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callPasswordResetApi(action) {
  try {
    const response = yield call(
      postPasswordEmail,
      action.email,
      action.password,
    );
    yield put({
      type: "PASSWORD_RESET_SUCCESS",
      message: response.data.message,
    });
  } catch (error) {
    yield put({
      type: "PASSWORD_RESET_FAILURE",
      error: createReduxApiError(error),
    });
  }
}

// function that makes the api request and returns a Promise for response
function postPasswordEmail(email) {
  return axios({
    method: "post",
    url: API_URL + "/password/email",
    data: {
      email,
    },
  });
}

// PASSWORD NEW

function* watchPasswordNew() {
  yield takeEvery("PASSWORD_NEW_REQUEST", callPasswordNewApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callPasswordNewApi(action) {
  try {
    const response = yield call(
      postPasswordReset,
      action.email,
      action.password,
      action.passwordConfirmation,
      action.token,
    );
    yield put({ type: "PASSWORD_NEW_SUCCESS", message: response.data.message });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "PASSWORD_NEW_FAILURE",
      error: createReduxApiError(error),
    });
  }
}

// function that makes the api request and returns a Promise for response
function postPasswordReset(email, password, password_confirmation, token) {
  return axios({
    method: "post",
    url: API_URL + "/password/reset",
    data: {
      email,
      password,
      password_confirmation,
      token,
    },
  });
}

function* watchPaymentSetupIntent() {
  yield takeEvery("PAYMENT_SETUP_INTENT_REQUEST", callPaymentSetupIntentApi);
}

function* callPaymentSetupIntentApi() {
  try {
    const response = yield call(getSetupIntent);
    yield put({
      type: "PAYMENT_SETUP_INTENT_SUCCESS",
      clientSecret: response.data.client_secret,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "PAYMENT_SETUP_INTENT_FAILURE",
      error: extractMessageFromApiError(error),
    });
  }
}

function getSetupIntent() {
  return axios({
    method: "get",
    url: API_URL + "/setup-intent",
  });
}

function* watchPaymentSetupCard() {
  yield takeEvery("PAYMENT_SETUP_CARD_REQUEST", callPaymentSetupCardApi);
}

function* callPaymentSetupCardApi(action) {
  try {
    const response = yield call(postPaymentMethod, action.paymentMethod);
    yield put({ type: "PAYMENT_SETUP_CARD_SUCCESS" });
    if (response.data && response.data.user) {
      yield put({ type: "USER_SUCCESS", user: response.data.user });
    }
    yield put({
      type: "SNACKBAR_ADD",
      notificationType: "success",
      content: `Card saved`,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: "PAYMENT_SETUP_CARD_FAILURE",
      error: extractMessageFromApiError(error),
    });
  }
}

function postPaymentMethod(paymentMethod) {
  return axios({
    method: "post",
    url: API_URL + "/payment-method",
    data: {
      payment_method_id: paymentMethod,
    },
  });
}

// REGISTRATION

function* watchSignUp() {
  yield takeLatest("SIGN_UP", callSignUpApi);
}

function* callSignUpApi(action) {
  try {
    yield call(
      postUserSignup,
      action.email,
      action.password,
      action.acceptInvitation,
      action.acceptInvitationToken,
      action.sourceSelfReported,
      action.isTermsAccepted,
    );
    yield put({ type: "SIGN_UP_SUCCESS" });
    yield put({
      type: "SIGN_IN",
      credentials: { username: action.email, password: action.password },
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    // const error = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message;
    yield put({ type: "SIGN_UP_FAILURE", error: createReduxApiError(error) });
  }
}

function postUserSignup(
  email,
  password,
  acceptInvitation,
  acceptInvitationToken,
  sourceSelfReported,
  isTermsAccepted,
) {
  return axios({
    method: "post",
    url: API_URL + "/user/signup",
    data: {
      email,
      password,
      region: cookies.get("region") || "other",
      source: cookies.get("ub_src"),
      plan: cookies.get("ub_plan"),
      accept_invitation: !!acceptInvitation,
      token: acceptInvitationToken,
      source_self_reported: sourceSelfReported,
      terms_accepted: isTermsAccepted,
    },
  });
}

// ADMIN IMPERSONATE

function* watchAdminImpersonate() {
  yield takeLatest("ADMIN_IMPERSONATE", callAdminImpersonateApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callAdminImpersonateApi(action) {
  try {
    const response = yield call(fetchImpersonate, action.userId);
    const accessToken = response.data.accessToken;

    yield put({ type: "ADMIN_IMPERSONATE_SUCCESS", accessToken });
  } catch (error) {
    // dispatch a failure action to the store with the error
    // yield put({ type: 'SUBSCRIPTIONS_FAILURE', error });
  }
}

// function that makes the api request and returns a Promise for response
function fetchImpersonate(userIdOrEmail) {
  const isEmail = userIdOrEmail.indexOf("@") !== -1;

  return axios({
    method: "post",
    url: API_URL + "/impersonate",
    data: {
      user_id: !isEmail ? userIdOrEmail : undefined,
      email: isEmail ? userIdOrEmail : undefined,
    },
  });
}

/*function* watchAuth() {
  let token = yield call(getAuthToken);
  token.expires_in = 0;

  while (true) {
    if (!token) {
      const { credentials } = yield take('SIGN_IN');
      token = yield call(authorize, credentials)
    }
    // authorization failed, wait for the next SIGN_IN
    if(!token) {
      continue;
    }

    let userSignedOut;
    while( !userSignedOut ) {
      const {expired} = yield race({
        expired : delay(token.expires_in),
        signout : take('SIGN_OUT')
      });

      // token expired first
      if(expired) {
        token = yield call(authorize, token);
        // authorization failed, either by the server or the user signout
        if(!token) {
          userSignedOut = true; // breaks the loop
          yield call(signout)
        }
      }
      // user signed out before token expiration
      else {
        userSignedOut = true; // breaks the loop
        yield call(signout)
      }
    }
  }
  //yield takeLatest(['SIGN_IN', 'SIGN_OUT'], authentication);
}

function* authorize(credentialsOrToken) {
  const {response} = yield race({
    response: call(authService, credentialsOrToken),
    signout : take(SIGN_OUT)
  });

  // server responded (with Success) before user signed out
  if(response && response.token) {
    yield call(setAuthToken, response.token) // save to local storage
    yield put(authSuccess, response.token)
    return response.token
  }
  // user signed out before server response OR server responded first but with error
  else {
    yield call(signout, response ? response.error : 'User signed out')
    return null
  }
}

function* signout(error) {
  yield call(removeAuthToken); // remove the token from localStorage
  // notify the store
  // yield put()
}*/
