import React from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames } from "@system42/core";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class OverflowMenu extends React.Component {
  static defaultProps = { styles };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.buttonRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleMouseUp);
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleMouseUp);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleMouseUp = (e) => {
    // Not sure if props are even needed. Would there
    // ever be case where this would not be desired?
    this.props.preventMouseEventDefault && e.preventDefault();
    this.props.preventMousePropagation && e.stopPropagation();
    if (
      this.state.open === true &&
      this.buttonRef.current &&
      !this.buttonRef.current.contains(e.target)
    ) {
      this.close();
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27 /* ESC */) {
      this.close();
    }
  };

  handleClickButton = (e) => {
    this.props.preventMouseEventDefault && e.preventDefault();
    this.props.preventMousePropagation && e.stopPropagation();
    this.state.open ? this.close() : this.open();
  };

  close = () => {
    this.props.onClose?.();
    this.setState({ open: false });
  };

  open = () => {
    this.props.onOpen?.();
    this.setState({ open: true });
  };

  render() {
    const { children, className, styles, customTriggerButton } = this.props;

    const TriggerButtonTag =
      customTriggerButton ||
      (({ innerRef, onClick }) => (
        <button className={styles.button} ref={innerRef} onClick={onClick}>
          <FontAwesomeIcon icon={regular("ellipsis-h")} />
        </button>
      ));

    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(className, styles.root)}
      >
        <TriggerButtonTag
          onClick={this.handleClickButton}
          innerRef={this.buttonRef}
          isOpen={this.state.open}
        />
        <div
          className={concatClassNames(
            styles.menu,
            this.state.open && styles["menu--active"],
          )}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default OverflowMenu;
