import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button, ButtonPrimary, Stack } from "@/design-system";
import { noop } from "../../helpers";
import BillingInformationForm from "../BillingInformationForm";
import ButtonGroup from "../ButtonGroup";

import styles from "./styles.module.css";

class UpdateBillingInformationForm extends Component {
  static defaultProps = {
    fieldFeedback: {},
    defaultValues: {},
    availableCountries: [],
    vatCountries: [],
    onChangeCountry: noop,
    onClickCancel: noop,
    showCancelButton: false,
  };

  constructor(props) {
    super(props);
    const { defaultValues } = props;
    this.state = {
      firstName: defaultValues.firstName ? defaultValues.firstName : "",
      lastName: defaultValues.lastName ? defaultValues.lastName : "",
      organization: defaultValues.organization
        ? defaultValues.organization
        : "",
      street: defaultValues.street ? defaultValues.street : "",
      postcode: defaultValues.postcode ? defaultValues.postcode : "",
      city: defaultValues.city ? defaultValues.city : "",
      country: defaultValues.country ? defaultValues.country : null,
      vatId: defaultValues.vatId ? defaultValues.vatId : "",
    };
  }

  save() {
    if (typeof this.props.onSave === "function") {
      const {
        firstName,
        lastName,
        organization,
        street,
        postcode,
        city,
        country,
        vatId,
      } = this.state;
      this.props.onSave({
        firstName,
        lastName,
        organization,
        street,
        postcode,
        city,
        country,
        vatId,
      });
    }
  }

  onChangeBillingInformation = (billingInformation) => {
    const countryOld = this.state.country;
    const {
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    } = billingInformation;

    if (country !== countryOld) {
      this.props.onChangeCountry(country);
    }

    this.setState({
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.save();
  };

  render() {
    const {
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    } = this.state;
    const billingInformationFieldValues = {
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    };

    const {
      errorMessage,
      fieldFeedback,
      availableCountries,
      saving,
      vatCountries,
      showCancelButton,
      onClickCancel,
      fullWidth,
    } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <Stack>
          <h2 className={styles.cardHeading1}>Billing address</h2>
          <BillingInformationForm
            errorMessage={errorMessage}
            fieldFeedback={fieldFeedback}
            fieldValues={billingInformationFieldValues}
            availableCountries={availableCountries}
            vatCountries={vatCountries}
            onChange={this.onChangeBillingInformation}
            fullWidth={fullWidth}
          />
        </Stack>

        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          <ButtonPrimary disabled={!!saving} type={"submit"}>
            {saving ? "Saving changes…" : "Save changes"}
          </ButtonPrimary>

          {showCancelButton && (
            <Button onClick={onClickCancel} disabled={!!saving}>
              Cancel
            </Button>
          )}
        </ButtonGroup>
      </form>
    );
  }
}

UpdateBillingInformationForm.propTypes = {
  onChangeCountry: PropTypes.func,
  onSave: PropTypes.func,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([false]),
  ]),
  vatCountries: PropTypes.array,
  availableCountries: PropTypes.array,
  fieldFeedback: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool.isRequired,
};

export default UpdateBillingInformationForm;
