import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";

/**
 * Loads global user data asynchronously.
 */
async function loader() {
  store.dispatch({
    type: "USER_REQUEST",
  });
  return null;
}

export default authRequiredWrapper(loader);
