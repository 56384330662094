import { useState } from "react";

import { ButtonLinkAlt } from "@/design-system";

import { BrowseTestsModal } from "./BrowseTestsModal";
import { Question } from "./types";

import styles from "./styles.module.css";

export type SelectOption = {
  value: number;
  label: string;
};

export type OnSelectProps = {
  questions: Question[];
};

/**


  Select existing test's questions using a select
  dropdown that fetches results from remote.

*/

type SelectExistingTestProps = {
  testId: number;
  onSelect: (props: OnSelectProps) => void;
};

export function SelectExistingTest({
  testId,
  onSelect,
}: SelectExistingTestProps) {
  const [browseTestsModalOpen, setBrowseTestsModalOpen] = useState(false);

  function handleSelectTest(props: OnSelectProps) {
    onSelect(props);
    setBrowseTestsModalOpen(false);
  }

  return (
    <div className={styles.selectExistingTest}>
      Copy questions from an existing test?{" "}
      <ButtonLinkAlt
        className={styles.browseTestsButton}
        onClick={() => {
          setBrowseTestsModalOpen(true);
        }}
      >
        Browse tests
      </ButtonLinkAlt>
      <BrowseTestsModal
        testId={testId}
        isOpen={browseTestsModalOpen}
        onClose={() => {
          setBrowseTestsModalOpen(false);
        }}
        onSelect={handleSelectTest}
      />
      {/* <Select<SelectOption>
        className={styles.select}
        isLoading={fetching}
        isSearchable
        noOptionsMessage={() => <>You don't have any tests</>}
        onMenuOpen={handleMenuOpen}
        value={parsedValue()}
        placeholder={"Select test"}
        name={INPUT_NAME}
        onChange={handleChange}
        styles={dropDownStyles}
        options={options}
      /> */}
    </div>
  );
}
