import { ReactNode, useState } from "react";
import isUndefined from "lodash/isUndefined";
import { useDispatch } from "react-redux";

import UpgradeNowMessage from "@/containers/UpgradeNowMessage";
import { Button, TogglePlayButton } from "@/design-system";
import { useFeatures } from "@/hooks/useFeatures";
import { usePersistentState } from "@/hooks/usePersistentState";
import { VIDEO_CLIP_CREATE_REQUEST } from "@/reducers/videoClipCreate";
import { VIDEO_NOTE_CREATE_REQUEST } from "@/reducers/videoNoteCreate";

import { NoteOrClipControl } from "./Control";
import { Tabs } from "./Control/Tabs";
import { SvgIconPlus, SvgIconScissors } from "./icons";
import { NoteOrClipPrototypeProps, NoteType } from "./types";
import { evaluateApiNoteTimestamps } from "./utils/evaluateApiNoteTimestamps";
import { useNoteReducerFeedback } from "./utils/useNoteReducerFeedback";

import styles from "../../styles.module.css";

interface AddNoteProps {
  videoId: number;
  isPlaying: boolean;
  onClickPause: () => void;
  onClickPlay: () => void;
  onClickTime: (timestamp: number) => void;
  onClickTimeRange: (timestamp: number, timestampEnd: number) => void;
  currentVideoSecond: number;
  videoDuration: number;
  onSaveRequested: () => void;
  activeNotesFilterType: NoteType | null;
  resetNotesFilterType: () => void;
  refTextarea: React.RefObject<HTMLTextAreaElement>;
}

/**
 *
 * Add Note
 */
export function AddNoteOrClip({
  videoId,
  isPlaying,
  currentVideoSecond,
  videoDuration,
  onSaveRequested,
  onClickTime,
  onClickTimeRange,
  resetNotesFilterType,
  activeNotesFilterType,
  onClickPause,
  onClickPlay,
  refTextarea,
}: AddNoteProps) {
  const dispatch = useDispatch();
  const features = useFeatures();

  const [noteOrClip, setNoteOrClip] = useState<NoteOrClipPrototypeProps>({
    type: "note",
    content: "",
  });

  const isClip = noteOrClip.type === "clip";
  const isTimestampStartDefined = !isUndefined(noteOrClip.timestampStart);
  const isTimestampTypingStartDefined = !isUndefined(
    noteOrClip.timestampTypingStart,
  );

  const [isPlayingOnAutoPause, setIsPlayingOnAutoPause] = useState(false);
  const [isPauseWhileTyping, setIsPauseWhileTyping] = usePersistentState(
    "video-player-pause-while-typing",
    false,
  );

  const { isSaving } = useNoteReducerFeedback({
    isUpdate: false,
    isClip: isClip,
    onSuccess: reset,
  });

  function save() {
    const { timestamp, timestamp_end } = evaluateApiNoteTimestamps({
      noteOrClipPrototype: noteOrClip,
      currentVideoSecond: currentVideoSecond,
      videoDuration: videoDuration,
    });

    if (isClip) {
      dispatch({
        type: VIDEO_CLIP_CREATE_REQUEST,
        videoId,
        note: noteOrClip.content,
        timestamp,
        timestamp_end,
      });
    } else {
      dispatch({
        type: VIDEO_NOTE_CREATE_REQUEST,
        videoId,
        note: noteOrClip.content,
        timestamp,
      });
    }

    if (activeNotesFilterType !== noteOrClip.type) {
      resetNotesFilterType();
    }

    autoPlayback({
      isCreate: false,
    });

    onSaveRequested();
  }

  function reset() {
    setNoteOrClip({
      type: noteOrClip.type,
      content: "",
    });
  }

  function handleKeyDownEnterContent() {
    save();
  }

  function handleEscapeNoteControlContentInput() {
    if (!noteOrClip.content) {
      reset();
    }
  }

  function autoPlayback({ isCreate }: { isCreate: boolean }) {
    if (isCreate) {
      if (isPauseWhileTyping && isPlaying) {
        onClickPause();
        setIsPlayingOnAutoPause(true);
      }
    } else {
      if (isPlayingOnAutoPause && !isPlaying && isPauseWhileTyping) {
        onClickPlay();
        setIsPlayingOnAutoPause(false);
      }
    }
  }

  function handleKeyDownCaptureContainer(
    e: React.KeyboardEvent<HTMLDivElement>,
  ) {
    // If not content in note, space can be used to play/pause video.
    if (!noteOrClip.content) {
      if (e.code === "Space") {
        e.preventDefault();
        if (isPlaying) {
          onClickPause();
        } else {
          onClickPlay();
        }
      }
    }
  }

  function handleChangeIsPauseWhileTyping(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    const state = !!e.target.checked;
    if (noteOrClip.content) {
      if (state) {
        isPlaying && onClickPause();
      }
    }
    setIsPauseWhileTyping(state);
  }

  // onClickReplay should always play regardless of isPauseWhileTyping.
  function handleClickReplay() {
    if (noteOrClip.timestampStart !== undefined) {
      if (noteOrClip.timestampEnd !== undefined) {
        onClickTimeRange(noteOrClip.timestampStart, noteOrClip.timestampEnd);
      } else {
        onClickTime(noteOrClip.timestampStart);
      }
    }
  }

  function handleFocusContent() {
    if (isPauseWhileTyping && noteOrClip.content) {
      isPlaying && onClickPause();
    }
  }

  function handleChangeTabs(value: NoteType) {
    setNoteOrClip({ ...noteOrClip, type: value });
  }

  function handleChangeContent(value: string) {
    if (!value) {
      return reset();
    }

    const mutation = { ...noteOrClip, content: value };

    // If a new note was just created.
    if (!noteOrClip.content && value) {
      autoPlayback({
        isCreate: true,
      });
    }

    if (isClip) {
      if (!isTimestampTypingStartDefined) {
        mutation.timestampTypingStart = currentVideoSecond;
      }
    } else {
      if (!isTimestampStartDefined) {
        mutation.timestampStart = currentVideoSecond;
      }
    }

    setNoteOrClip(mutation);
  }

  return (
    <Container onKeyDownCapture={handleKeyDownCaptureContainer}>
      <Tabs value={noteOrClip.type} onClick={handleChangeTabs} />
      {noteOrClip.type === "clip" && features?.clips === false ? (
        <UpgradeNowMessage className={styles.upgradeNowMessageAddNote}>
          You can create clips with one of our monthly & yearly plans.
        </UpgradeNowMessage>
      ) : (
        <>
          <NoteOrClipControl
            noteOrClip={noteOrClip}
            onChangeNoteOrClip={setNoteOrClip}
            videoDuration={videoDuration}
            currentVideoSecond={currentVideoSecond}
            refContentInput={refTextarea}
            onClickReplay={handleClickReplay}
            isShowReplayButton
            contentInputProps={{
              isDisabled: isSaving,
              onChange: handleChangeContent,
              onEscape: handleEscapeNoteControlContentInput,
              onEnter: handleKeyDownEnterContent,
              onFocus: handleFocusContent,
            }}
          />
          <Footer
            isSaving={isSaving}
            isClip={isClip}
            onClickAdd={save}
            isAddButtonDisabled={false}
            isPauseWhileTyping={isPauseWhileTyping}
            onChangeIsPauseWhileTyping={handleChangeIsPauseWhileTyping}
          />
        </>
      )}
    </Container>
  );
}

/**
 *
 * Container
 */
function Container({
  children,
  onKeyDownCapture,
}: {
  children: ReactNode;
  onKeyDownCapture: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}) {
  return (
    <div className={styles.addNote} onKeyDownCapture={onKeyDownCapture}>
      {children}
    </div>
  );
}

interface FooterProps {
  isSaving: boolean;
  isClip: boolean;
  onClickAdd: () => void;
  isAddButtonDisabled: boolean;
  isPauseWhileTyping: boolean;
  onChangeIsPauseWhileTyping: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 *
 * Footer
 */
function Footer({
  isSaving,
  isClip,
  onClickAdd,
  isAddButtonDisabled,
  isPauseWhileTyping,
  onChangeIsPauseWhileTyping,
}: FooterProps) {
  return (
    <div className={styles.addNoteFooter}>
      <TogglePlayButton
        checked={isPauseWhileTyping}
        onChange={onChangeIsPauseWhileTyping}
      >
        Pause video while typing
      </TogglePlayButton>
      {isClip ? (
        <Button onClick={onClickAdd} disabled={isSaving || isAddButtonDisabled}>
          <SvgIconScissors className={styles.squareIcon20Inline} />{" "}
          {isSaving ? "Creating…" : "Create clip"}
        </Button>
      ) : (
        <Button onClick={onClickAdd} disabled={isSaving || isAddButtonDisabled}>
          <SvgIconPlus className={styles.squareIcon20Inline} />{" "}
          {isSaving ? "Adding…" : "Add note"}
        </Button>
      )}
    </div>
  );
}
