import {
  forwardRef,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { concatClassNames as cn } from "@system42/core";
import isUndefined from "lodash/isUndefined";
import { useDispatch } from "react-redux";

import {
  Button,
  InlineIcon,
  OverflowMenu,
  OverflowMenuItem,
} from "@/design-system";
import { useFeatures } from "@/hooks/useFeatures";
import { VIDEO_CLIP_CREATE_REQUEST } from "@/reducers/videoClipCreate";
import { VIDEO_CLIP_UPDATE_REQUEST } from "@/reducers/videoClipUpdate";
import { VIDEO_NOTE_CREATE_REQUEST } from "@/reducers/videoNoteCreate";
import { VIDEO_NOTE_UPDATE_REQUEST } from "@/reducers/videoNoteUpdate";
import {
  SvgIconClip,
  SvgIconModifyClip,
  SvgIconModifyNote,
  SvgIconNote,
} from "../../icons";
import {
  NoteOrClipControl,
  // TODO potentially update design system
} from "../Control";
import { SvgIconPreview } from "../icons";
import { SvgIconNoteDelete } from "../ListItem/icons";
import { NoteOrClipPrototypeProps } from "../types";
import { evaluateApiNoteTimestamps } from "../utils/evaluateApiNoteTimestamps";
import { useNoteReducerFeedback } from "../utils/useNoteReducerFeedback";

import styles from "./styles.module.css";

export interface EditNoteOrClipProps {
  videoId: number;
  initialNoteOrClipPrototype: NoteOrClipPrototypeProps;
  onClickCancel: () => void;
  onClickDelete: () => void;
  onClickTime: (timestamp: number) => void;
  onClickTimeRange: (timestamp: number, timestampEnd: number) => void;
  videoDuration: number;
  currentVideoSecond: number;
  className?: string;
}

export function EditNoteOrClip({
  videoId,
  onClickCancel,
  onClickTimeRange,
  onClickTime,
  initialNoteOrClipPrototype,
  videoDuration,
  currentVideoSecond,
  onClickDelete,
  className,
}: EditNoteOrClipProps) {
  const dispatch = useDispatch();

  const [noteOrClipPrototype, setNoteOrClipPrototype] = useState(
    initialNoteOrClipPrototype,
  );

  const isPrototypeClip = noteOrClipPrototype.type === "clip";
  const isPrototypeNote = noteOrClipPrototype.type === "note";

  const refContainer = useRef<HTMLDivElement | null>(null);
  const refInputContent = useRef<HTMLTextAreaElement | null>(null);

  const { isSaving } = useNoteReducerFeedback({
    isUpdate: true,
    isClip: isPrototypeClip,
  });

  function save() {
    const { timestamp, timestamp_end } = evaluateApiNoteTimestamps({
      noteOrClipPrototype,
      currentVideoSecond,
      videoDuration,
    });

    if (isPrototypeClip && noteOrClipPrototype.originalClipId) {
      // Prototype is a clip and it has an originalClipId, so we are updating an existing clip
      dispatch({
        type: VIDEO_CLIP_UPDATE_REQUEST,
        videoId,
        clipId: noteOrClipPrototype.originalClipId,
        note: noteOrClipPrototype.content,
        timestamp,
        timestamp_end,
      });
    } else if (isPrototypeNote && noteOrClipPrototype.originalNoteId) {
      // Prototype is a note and it has an originalNoteId, so we are updating an existing note
      dispatch({
        type: VIDEO_NOTE_UPDATE_REQUEST,
        videoId,
        noteId: noteOrClipPrototype.originalNoteId,
        note: noteOrClipPrototype.content,
        timestamp,
      });
    } else if (isPrototypeClip && noteOrClipPrototype.originalNoteId) {
      // Prototype is a clip but it has an originalNoteId: Convert a note into a clip
      dispatch({
        type: VIDEO_CLIP_CREATE_REQUEST,
        videoId,
        convertNoteId: noteOrClipPrototype.originalNoteId,
        note: noteOrClipPrototype.content,
        timestamp,
        timestamp_end,
      });
    } else if (isPrototypeNote && noteOrClipPrototype.originalClipId) {
      // Prototype is a note but it has an originalClipId: Convert a clip into a note
      dispatch({
        type: VIDEO_NOTE_CREATE_REQUEST,
        videoId,
        convertClipId: noteOrClipPrototype.originalClipId,
        note: noteOrClipPrototype.content,
        timestamp,
      });
    }
  }

  function handleChangeContent(value: string) {
    setNoteOrClipPrototype({
      ...noteOrClipPrototype,
      content: value,
    });
  }

  function handleEscapeNoteControlContentInput() {
    onClickCancel();
  }

  function handleKeyDownEnterContent() {
    save();
  }

  function play() {
    const { timestampStart, timestampEnd } = noteOrClipPrototype;
    if (!isUndefined(timestampStart)) {
      if (!isUndefined(timestampEnd)) {
        onClickTimeRange(timestampStart, timestampEnd);
      } else {
        onClickTime(timestampStart);
      }
    }
  }

  function handleClickPreview() {
    play();
  }

  function handleClickReplay() {
    play();
  }

  function handleClickTurnIntoClip() {
    setNoteOrClipPrototype({
      ...noteOrClipPrototype,
      type: "clip",
    });
  }

  function handleClickTurnIntoNote() {
    setNoteOrClipPrototype({
      ...noteOrClipPrototype,
      type: "note",
    });
  }

  useEffect(() => {
    refContainer.current?.scrollIntoView({ block: "nearest" });
    refInputContent.current?.select();
  }, []);

  return (
    <Container className={className} ref={refContainer}>
      <Header
        isDisabled={isSaving}
        onClickTurnIntoClip={handleClickTurnIntoClip}
        onClickTurnIntoNote={handleClickTurnIntoNote}
        isClip={isPrototypeClip}
        onClickDelete={onClickDelete}
      />
      <NoteOrClipControl
        noteOrClip={noteOrClipPrototype}
        onChangeNoteOrClip={setNoteOrClipPrototype}
        videoDuration={videoDuration}
        currentVideoSecond={currentVideoSecond}
        refContentInput={refInputContent}
        onClickReplay={handleClickReplay}
        isShowReplayButton={false}
        contentInputProps={{
          isDisabled: isSaving,
          onChange: handleChangeContent,
          onEscape: handleEscapeNoteControlContentInput,
          onEnter: handleKeyDownEnterContent,
        }}
      />
      <Footer
        isSaving={isSaving}
        isClip={isPrototypeClip}
        onClickSave={save}
        onClickCancel={onClickCancel}
        onClickPreview={handleClickPreview}
      />
    </Container>
  );
}

export const Container = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    className?: string;
  }
>(({ children, className }, ref) => {
  return (
    <div className={cn(styles.container, className)} ref={ref}>
      {children}
    </div>
  );
});

interface HeaderProps {
  isDisabled: boolean;
  isClip: boolean;
  onClickTurnIntoNote: () => void;
  onClickTurnIntoClip: () => void;
  onClickDelete: () => void;
}

export function Header({
  isClip,
  isDisabled,
  onClickTurnIntoNote,
  onClickTurnIntoClip,
  onClickDelete,
}: HeaderProps & MenuProps) {
  return (
    <div className={styles.header}>
      {!isClip && (
        <div className={cn(styles.headerTitle, styles.headerTitle_note)}>
          <SvgIconModifyNote />
          Edit Note
        </div>
      )}

      {isClip && (
        <div className={styles.headerTitle}>
          <SvgIconModifyClip />
          Edit Clip
        </div>
      )}

      <Menu
        isClip={isClip}
        isDisabled={isDisabled}
        onClickTurnIntoNote={onClickTurnIntoNote}
        onClickTurnIntoClip={onClickTurnIntoClip}
        onClickDelete={onClickDelete}
      />
    </div>
  );
}

type MenuProps = {
  isClip: boolean;
  isDisabled: boolean;
  onClickDelete: () => void;
  onClickTurnIntoNote: () => void;
  onClickTurnIntoClip: () => void;
};

export function Menu({
  isClip,
  onClickDelete,
  onClickTurnIntoNote,
  onClickTurnIntoClip,
  isDisabled,
}: MenuProps) {
  const features = useFeatures();

  return (
    <OverflowMenu
      className={styles.overflowMenu}
      customTriggerButton={({
        innerRef,
        onClick,
        isOpen,
      }: {
        innerRef: MutableRefObject<null>;
        onClick: () => void;
        isOpen: boolean;
      }) => (
        <button
          className={cn(
            styles.overflowMenuTriggerButton,
            isOpen && styles.overflowMenuTriggerButton_open,
          )}
          ref={innerRef}
          onClick={onClick}
          disabled={isDisabled}
        >
          <FontAwesomeIcon icon={solid("ellipsis-h")} />
        </button>
      )}
    >
      {features?.clips !== false && (
        <OverflowMenuItem
          onClick={isClip ? onClickTurnIntoNote : onClickTurnIntoClip}
        >
          <InlineIcon Svg={isClip ? SvgIconNote : SvgIconClip} /> Turn into{" "}
          {isClip ? "note" : "clip"}
        </OverflowMenuItem>
      )}
      <OverflowMenuItem onClick={onClickDelete}>
        <InlineIcon Svg={SvgIconNoteDelete} /> Delete {isClip ? "clip" : "note"}
      </OverflowMenuItem>
    </OverflowMenu>
  );
}

interface FooterProps {
  isClip: boolean;
  onClickSave: () => void;
  onClickCancel: () => void;
  onClickPreview: () => void;
  isSaving: boolean;
}

function Footer({
  isClip,
  onClickSave,
  onClickCancel,
  onClickPreview,
  isSaving,
}: FooterProps) {
  return (
    <div className={styles.footer}>
      <button
        disabled={isSaving}
        className={styles.footerPreviewButton}
        onClick={onClickPreview}
      >
        <InlineIcon Svg={SvgIconPreview} />
        &nbsp;Preview
      </button>
      <div className={styles.footerActions}>
        <button
          disabled={isSaving}
          className={styles.footerCancel}
          onClick={onClickCancel}
        >
          Cancel
        </button>
        <Button onClick={onClickSave} disabled={isSaving} type="button">
          {isSaving ? "Saving…" : isClip ? "Save clip" : "Save note"}
        </Button>
      </div>
    </div>
  );
}
