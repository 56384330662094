import { FunctionComponent, ReactNode, SVGAttributes } from "react";
import { concatClassNames as cn } from "@system42/core";

import { ButtonPrimary, ErrorMessage, Stack } from "@/design-system";

import styles from "./styles.module.css";

/**


  Sidebar Message Props

*/
interface IconTextMessageWithButtonProps {
  SvgIcon: FunctionComponent<SVGAttributes<SVGElement>>;
  children: ReactNode | string;
  title?: string;
  className?: string;
  buttonText: string;
  onClick: () => void;
  errorMessage?: string;
  onClickHide?: () => void;
}

/**


  Sidebar Message

*/
export function IconTextMessageWithButton({
  SvgIcon,
  children,
  title,
  className,
  buttonText,
  onClick,
  errorMessage,
  onClickHide,
}: IconTextMessageWithButtonProps) {
  return (
    <div className={cn(styles.iconTextMessageWithButton, className)}>
      {onClickHide && (
        <button className={styles.close} onClick={onClickHide}>
          Close
        </button>
      )}
      <Stack spacing={"small"}>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <div className={styles.iconAndText}>
          {SvgIcon && (
            <div className={styles.iconWrapper}>
              <SvgIcon className={styles.icon} />
            </div>
          )}
          <div>
            {title && <h3 className={styles.title}>{title}</h3>}
            <div className={styles.text}>{children}</div>
          </div>
        </div>
        <ButtonPrimary onClick={onClick} fullWidth>
          {buttonText}
        </ButtonPrimary>
      </Stack>
    </div>
  );
}
