import { createContext, MutableRefObject, RefObject } from "react";

export type ModalContextType = {
  level: number | null;
  isActive: boolean | null;
  refModalContainer: RefObject<HTMLDivElement>;
  refSubmodals: MutableRefObject<ModalInfo[]>;
};

export type ModalInfo = {
  isActive: boolean;
};

export const ModalContext = createContext<ModalContextType>({
  refSubmodals: { current: [] },
  refModalContainer: { current: null },
  isActive: null,
  level: null,
});
