import React from "react";

import styles from "./styles.module.css";

export default function TabbedModalHeader({
  children,
  heading,
  onClickCloseIcon,
}) {
  return (
    <header className={styles.modalHeader}>
      {onClickCloseIcon && (
        <button onClick={onClickCloseIcon} className={styles.buttonCloseIcon}>
          Close
        </button>
      )}
      <h1 className={styles.heading}>{heading}</h1>
      <div className={styles.tabs} role="tablist">
        {children}
      </div>
    </header>
  );
}
