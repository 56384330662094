import React from "react";
import { concatClassNames as cn } from "@system42/core";

import styles from "./styles.module.css";

export default function ButtonGroup({
  className,
  children,
  align,
  ...containerProps
}) {
  return (
    <div
      className={cn(
        align === "right" && styles.buttonGroup_alignRight,
        styles.buttonGroup,
        className,
      )}
      {...containerProps}
    >
      {children}
    </div>
  );
}
