import capitalize from "lodash/capitalize";

import templates from "./templates.json";

// TODO: Confirm @receter that Default = website
type TemplateTaskId =
  | "taskDefaultEn"
  | "taskDefaultDe"
  | "taskDefaultEs"
  | "taskPrototypeEn"
  | "taskPrototypeDe"
  | "taskPrototypeEs"
  | "taskAppEn"
  | "taskAppDe"
  | "taskAppEs";

// TODO: Reconcile types
type TemplateTaskLanguage = "en" | "de" | "es";
type TemplateTaskType = "website" | "app" | "prototype" | "blank";

export function getTaskTemplate({
  type,
  language,
}: {
  type: TemplateTaskType;
  language: TemplateTaskLanguage;
}) {
  if (type === "blank") {
    return [
      {
        type: "task",
        task: "",
        options: {
          ask_completed: false,
        },
        localId: 0,
      },
    ];
  } else {
    const localType = type === "website" ? "Default" : capitalize(type);
    const id = `task${localType}${capitalize(language)}` as TemplateTaskId;
    return templates
      .find((task) => task.id === id)
      ?.task.map((step, index) => ({ ...step, localId: index }));
  }
}
