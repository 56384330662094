import { Stat } from "..";

import { ReactComponent as SvgIconAvgTestDuration } from "./avg-test-duration.svg";
import { ReactComponent as SvgIconDateOfTest } from "./date-of-test.svg";
import { ReactComponent as SvgIconTaskSuccessRate } from "./task-success-rate.svg";
import { ReactComponent as SvgIconTasks } from "./tasks.svg";

export function getStatIcon(stat: Stat) {
  switch (stat) {
    case "taskCount":
      return SvgIconTasks;
    case "taskSuccessRate":
      return SvgIconTaskSuccessRate;
    case "avgTestDuration":
      return SvgIconAvgTestDuration;
    case "dateOfTest":
      return SvgIconDateOfTest;
    default:
      throw new Error(`Unknown stat: ${stat}`);
  }
}
