import { ReactNode, useCallback, useState } from "react";
import produce from "immer";
import isEqual from "lodash/isEqual";
import { DragDropContext, DropResult } from "react-beautiful-dnd";

import ConfirmModal from "@/components/ConfirmModal";
import { ButtonPrimary, InlineIcon } from "@/design-system";
import { useModal2 } from "@/hooks/useModal2";
import { copyArrayAndReplaceAt } from "../../helpers";

import { ScreenerQuestion } from "./ScreenerQuestion";
import { OnSelectProps, SelectExistingTest } from "./SelectExistingTest";
import { Answer, Question, Screener } from "./types";
import {
  isScreenerValid,
  ScreenerValidationResult,
} from "./utils/isScreenerValid";
import { isUpdatedAnswerVirgin } from "./utils/isUpdatedAnswerVirgin";
import { updateQuestionType } from "./utils/updateQuestionType";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconPlus } from "./icon-plus.svg";

const DEFAULT_QUESTION: Question = {
  type: "single_choice",
  answers: [],
  answerTypeVirgin: true,
};

const DEFAULT_SCREENER: Screener = {
  questions: [DEFAULT_QUESTION],
};

const MAX_SCREENER_QUESTIONS = 2;

/**


  Screen testers based on user defined criteria.

*/
export function ScreenerModalContent({
  testId,
  initialScreener,
  onClickDone,
}: {
  testId: number;
  initialScreener: Screener;
  onClickDone: (screener: Screener) => void;
}) {
  const [screener, setScreener] = useState(initialScreener || DEFAULT_SCREENER);
  const [selectedExistingTestState, setSelectedExistingTestState] =
    useState<OnSelectProps | null>(null);

  const [errorState, setErrorState] = useState(
    null as null | ScreenerValidationResult,
  );

  function handleChangeScreener(newScreener: Screener) {
    setScreener(newScreener);
  }

  function handleClickSave() {
    const result = isScreenerValid(screener);

    if (result.status === "error") {
      setErrorState(result);
    } else {
      setErrorState(null);
      onClickDone(screener);
    }
  }

  function handleClickAddAnotherQuestion() {
    setScreener({
      ...screener,
      questions: [...screener.questions, DEFAULT_QUESTION],
    });
  }

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, type } = result;
      // Dropped outside the list
      if (!destination) {
        return;
      }
      if (
        type.indexOf("QUESTION-") !== -1 &&
        source.droppableId === destination.droppableId
      ) {
        const questionIndex = Number(destination.droppableId.substring(9));
        const originalQuestion = screener.questions[questionIndex];
        const updatedAnswers = originalQuestion.answers.slice();
        const [answerToMove] = updatedAnswers.splice(source.index, 1);
        updatedAnswers.splice(destination.index, 0, answerToMove);
        const updatedQuestion = {
          ...originalQuestion,
          answers: updatedAnswers,
        };
        setScreener({
          ...screener,
          questions: copyArrayAndReplaceAt(
            screener.questions,
            questionIndex,
            updatedQuestion,
          ),
        });
      }
    },
    [screener],
  );

  // Where is the best place to put this util?
  function replaceScreener(questions: Question[]) {
    setErrorState(null);
    setScreener({ questions: questions });
  }

  function handleConfirmSelectExistingTest() {
    if (selectedExistingTestState?.questions.length) {
      replaceScreener(selectedExistingTestState.questions);
      hideConfirmSwitchScreenerModal();
    }
  }

  function handleCancelSelectExistingTest() {
    hideConfirmSwitchScreenerModal();
    setSelectedExistingTestState(null);
  }

  function handleSelectExistingTest(selection: OnSelectProps) {
    const isScreenerModified = !isEqual(screener, DEFAULT_SCREENER);
    setSelectedExistingTestState(selection);
    if (isScreenerModified) {
      showConfirmSwitchScreenerModal();
    } else {
      replaceScreener(selection.questions);
    }
  }

  const {
    hideModal: hideConfirmSwitchScreenerModal,
    showModal: showConfirmSwitchScreenerModal,
    modal: confirmSwitchScreenerModal,
  } = useModal2(
    <ConfirmModal
      isActive={true} /* Todo: this should not have to be defined here...*/
      labelConfirm="Replace screener"
      onConfirm={handleConfirmSelectExistingTest}
      onClose={handleCancelSelectExistingTest}
    >
      Are you sure you want to replace your current screener with the selected
      one?
    </ConfirmModal>,
  );

  return (
    <>
      <Container onDragEnd={handleDragEnd}>
        <SelectExistingTest
          testId={testId}
          onSelect={handleSelectExistingTest}
        />
        <QuestionsList
          screener={screener}
          errorState={errorState}
          onChangeScreener={handleChangeScreener}
          onClearErrors={() => setErrorState(null)}
        />
        {screener.questions.length < MAX_SCREENER_QUESTIONS && (
          <AddAnotherQuestionButton onClick={handleClickAddAnotherQuestion} />
        )}
        <SaveButton onClick={handleClickSave} />
      </Container>
      {confirmSwitchScreenerModal}
    </>
  );
}

function Container({
  children,
  onDragEnd,
}: {
  children: ReactNode;
  onDragEnd: (result: DropResult) => void;
}) {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.screenerModalContent}>{children}</div>
    </DragDropContext>
  );
}

function SaveButton({ onClick }: { onClick: () => void }) {
  return (
    <div className={styles.footer}>
      <ButtonPrimary
        style={{ marginTop: "1rem", marginLeft: "auto" }}
        onClick={onClick}
      >
        Save
      </ButtonPrimary>
    </div>
  );
}

function AddAnotherQuestionButton({ onClick }: { onClick: () => void }) {
  return (
    <div style={{ textAlign: "center" }}>
      <button className={styles.addAnotherQuestionButton} onClick={onClick}>
        <InlineIcon Svg={SvgIconPlus} />
        &nbsp;Add another question
      </button>
    </div>
  );
}

function QuestionsList({
  screener,
  errorState,
  onChangeScreener,
  onClearErrors,
}: {
  screener: Screener;
  errorState: null | ScreenerValidationResult;
  onChangeScreener: (screener: Screener) => void;
  onClearErrors: () => void;
}) {
  function handleChangeQuestionText(newText: string, index: number) {
    onChangeScreener({
      ...screener,
      questions: copyArrayAndReplaceAt(
        screener.questions,
        index,
        (question: Question) => ({
          ...question,
          text: newText,
        }),
      ),
    });
  }

  function handleChangeQuestionType(newType: Question["type"], index: number) {
    onChangeScreener({
      ...screener,
      questions: copyArrayAndReplaceAt(
        screener.questions,
        index,
        (question: Question) => updateQuestionType(question, newType),
      ),
    });
  }

  function handleRemoveQuestion(index: number) {
    const updatedQuestions = [...screener.questions];
    updatedQuestions.splice(index, 1);
    const screenerMutation = {
      ...screener,
      questions: updatedQuestions,
    };
    onClearErrors();
    onChangeScreener(screenerMutation);
  }

  function handleDeleteAnswer(questionIndex: number, answerIndex: number) {
    const updatedScreener = produce(screener, (draft) => {
      const question = draft.questions[questionIndex];
      question.answers.splice(answerIndex, 1);
    });
    onChangeScreener(updatedScreener);
  }

  function handleChangeAnswer(
    originalAnswer: Answer,
    updatedAnswer: Answer,
    answerIndex: number,
    questionIndex: number,
  ) {
    const originalQuestion = screener.questions[questionIndex];
    const answerTypeVirgin = isUpdatedAnswerVirgin(
      originalQuestion,
      originalAnswer,
      updatedAnswer,
    );

    const updatedQuestion: Question = {
      ...originalQuestion,
      answers: copyArrayAndReplaceAt(
        originalQuestion.answers,
        answerIndex,
        updatedAnswer,
      ),
      answerTypeVirgin,
    };

    onChangeScreener({
      ...screener,
      questions: copyArrayAndReplaceAt(
        screener.questions,
        questionIndex,
        updatedQuestion,
      ),
    });
  }

  function handleAddAnswer(answer: Answer, questionIndex: number) {
    const originalQuestion = screener.questions[questionIndex];
    const updatedQuestion = {
      ...originalQuestion,
      answers: [...(originalQuestion.answers ?? []), answer],
    };
    onChangeScreener({
      ...screener,
      questions: copyArrayAndReplaceAt(
        screener.questions,
        questionIndex,
        updatedQuestion,
      ),
    });
  }

  return (
    <>
      {screener &&
        screener.questions.map((question, index) => (
          <ScreenerQuestion
            className={styles.question}
            key={index}
            questionIndex={index}
            question={question}
            showRemoveButton={screener.questions.length > 1}
            onRemoveQuestion={() => handleRemoveQuestion(index)}
            errors={errorState?.questionResults[index]}
            onChangeQuestionType={(newType) =>
              handleChangeQuestionType(newType, index)
            }
            onChangeQuestionText={(newText) =>
              handleChangeQuestionText(newText, index)
            }
            onDeleteAnswer={(answerIndex) =>
              handleDeleteAnswer(index, answerIndex)
            }
            onChangeAnswer={(...args) => handleChangeAnswer(...args, index)}
            onAddAnswer={(newAnswer) => handleAddAnswer(newAnswer, index)}
          />
        ))}
    </>
  );
}
