import produce from "immer";
import update from "immutability-helper";
import isUndefined from "lodash/isUndefined";

import {
  extractFieldFeedbackFromApiError,
  extractMessageFromApiError,
  transformVideoAwsUrls,
} from "../helpers";

import { VIDEO_CLIP_CREATE_SUCCESS } from "./videoClipCreate";
import { VIDEO_CLIP_DELETE_SUCCESS } from "./videoClipDelete";
import { VIDEO_CLIP_GET_SUCCESS } from "./videoClipGet";
import { VIDEO_CLIP_SHARE_SUCCESS } from "./videoClipShare";
import { VIDEO_CLIP_UPDATE_SUCCESS } from "./videoClipUpdate";
import { VIDEO_NOTE_CREATE_SUCCESS } from "./videoNoteCreate";
import { VIDEO_NOTE_DELETE_SUCCESS } from "./videoNoteDelete";
import { VIDEO_NOTE_UPDATE_SUCCESS } from "./videoNoteUpdate";
import { VIDEO_SHARE_SUCCESS } from "./videoShare";
import {
  VIDEOS_RATE_FAILURE,
  VIDEOS_RATE_REQUEST,
  VIDEOS_RATE_SUCCESS,
  VIDEOS_STAR_SUCCESS,
  VIDEOS_UNSTAR_SUCCESS,
} from "./videosReducer";

/* P.S. We’re not gonna use action creators in this app. For something so
simple, they may muddy the waters. Also, you’ll see how redux-saga handles
and dispatches actions more clearly (in my opinion) without them. */

// VIDEO
const VIDEO_REQUEST = "VIDEO_REQUEST";
const VIDEO_FAILURE = "VIDEO_FAILURE";
const VIDEO_SUCCESS = "VIDEO_SUCCESS";

const VIDEO_DELETE_REQUEST = "VIDEO_DELETE_REQUEST";
const VIDEO_DELETE_FAILURE = "VIDEO_DELETE_FAILURE";
const VIDEO_DELETE_SUCCESS = "VIDEO_DELETE_SUCCESS";
const VIDEO_DELETE_CLEAR_ERROR = "VIDEO_DELETE_CLEAR_ERROR";

const VIDEO_UPDATE_REQUEST = "VIDEO_UPDATE_REQUEST";
const VIDEO_UPDATE_FAILURE = "VIDEO_UPDATE_FAILURE";
const VIDEO_UPDATE_SUCCESS = "VIDEO_UPDATE_SUCCESS";

const VIDEO_PROBLEM_REQUEST = "VIDEO_PROBLEM_REQUEST";
const VIDEO_PROBLEM_FAILURE = "VIDEO_PROBLEM_FAILURE";
const VIDEO_PROBLEM_SUCCESS = "VIDEO_PROBLEM_SUCCESS";

const VIDEO_TRANSCRIPT_REQUEST = "VIDEO_TRANSCRIPT_REQUEST";
const VIDEO_TRANSCRIPT_FAILURE = "VIDEO_TRANSCRIPT_FAILURE";
const VIDEO_TRANSCRIPT_SUCCESS = "VIDEO_TRANSCRIPT_SUCCESS";

const VIDEO_AI_REQUEST = "VIDEO_AI_REQUEST";
const VIDEO_AI_FAILURE = "VIDEO_AI_FAILURE";
const VIDEO_AI_SUCCESS = "VIDEO_AI_SUCCESS";

// const VIDEO_SET_NEW = "VIDEO_SET_NEW";
const VIDEO_SET_NEW_SUCCESS = "VIDEO_SET_NEW_SUCCESS";

// reducer with initial state
const initialStateVideo = {
  fetching: false,
  fetchingRate: false,
  video: null,
  error: null,
  problemFetching: false,
  problemSuccess: null,
  problemError: null,
  updateFetching: false,
  updateFetchingEnableShare: false,
  updateFetchingDisableShare: false,
  updateError: null,
  deleteFetching: false,
  deleteSuccess: false,
  deleteError: null,
  transcriptFetching: false,
  transcriptVideoId: null,
  transcriptSuccess: null,
  transcriptError: null,
  transcriptErrorMessage: null,
  aiFetching: false,
  aiVideoId: null,
  aiSuccess: null,
  aiError: null,
  aiErrorMessage: null,
  deleteFieldFeedback: {},
  lastCreatedNoteId: null,
  lastCreatedClipId: null,
};

export function videoReducer(state = initialStateVideo, action) {
  switch (action.type) {
    case VIDEO_SHARE_SUCCESS: {
      const { video } = action.data;
      return {
        ...state,
        video: transformVideoAwsUrls(video),
      };
    }
    case VIDEOS_STAR_SUCCESS:
      return { ...state, video: update(state.video, { starred: { $set: 1 } }) };
    case VIDEOS_UNSTAR_SUCCESS:
      return { ...state, video: update(state.video, { starred: { $set: 0 } }) };
    case VIDEOS_RATE_REQUEST:
      return { ...state, fetchingRate: action.rating };
    case VIDEOS_RATE_FAILURE:
      return { ...state, fetchingRate: false };
    case VIDEOS_RATE_SUCCESS:
      if (action.id === state.video.id) {
        return {
          ...state,
          fetchingRate: false,
          video: update(state.video, { rating: { $set: action.video.rating } }),
        };
      }
      return { ...state, fetchingRate: false };
    case VIDEO_REQUEST:
      return { ...state, fetching: true, error: null };
    case VIDEO_SUCCESS:
      return {
        ...state,
        fetching: false,
        video: transformVideoAwsUrls(action.video),
      };
    case VIDEO_FAILURE:
      return { ...state, fetching: false, video: null, error: action.error };
    case VIDEO_UPDATE_REQUEST:
      return {
        ...state,
        updateFetching: true,
        updateFetchingEnableShare: action.shared === true,
        updateFetchingDisableShare: action.shared === false,
        updateError: null,
      };
    case VIDEO_UPDATE_SUCCESS: {
      const { video } = action;
      return {
        ...state,
        updateFetching: false,
        updateError: false,
        updateFetchingEnableShare: false,
        updateFetchingDisableShare: false,
        video: transformVideoAwsUrls(video),
      };
    }
    case VIDEO_UPDATE_FAILURE:
      return {
        ...state,
        updateFetching: false,
        updateFetchingEnableShare: false,
        updateFetchingDisableShare: false,
        updateError: true,
      };
    case VIDEO_NOTE_CREATE_SUCCESS: {
      const { videoId, note, convertClipId } = action.data;
      if (videoId === state.video.id) {
        return produce(state, (draftState) => {
          draftState.lastCreatedNoteId = note.id;
          draftState.video.notes.push(note);
          // If convertClipId is set, remove the converted Clip
          if (!isUndefined(convertClipId)) {
            draftState.video.clips = draftState.video.clips.filter(
              (clip) => clip.id !== convertClipId,
            );
          }
        });
      }
      break;
    }
    case VIDEO_NOTE_DELETE_SUCCESS: {
      const { noteId } = action.data;
      return produce(state, (draftState) => {
        if (draftState.video) {
          draftState.video.notes = draftState.video.notes.filter(
            (note) => note.id !== noteId,
          );
        }
      });
    }
    case VIDEO_CLIP_CREATE_SUCCESS: {
      const { videoId, clip, convertNoteId } = action.data;
      if (videoId === state.video.id) {
        return produce(state, (draftState) => {
          draftState.lastCreatedClipId = clip.id;
          draftState.video.clips.push(clip);
          // If convertNoteId is set, remove the converted Note
          if (!isUndefined(convertNoteId)) {
            draftState.video.notes = draftState.video.notes.filter(
              (note) => note.id !== convertNoteId,
            );
          }
        });
      }
      break;
    }
    case VIDEO_CLIP_DELETE_SUCCESS: {
      const { clipId } = action.data;
      return produce(state, (draftState) => {
        if (draftState.video) {
          draftState.video.clips = draftState.video.clips.filter(
            (clip) => clip.id !== clipId,
          );
        }
      });
    }
    case VIDEO_SET_NEW_SUCCESS:
      return { ...state, video: transformVideoAwsUrls(action.video) };
    case VIDEO_PROBLEM_REQUEST:
      const { problemError } = initialStateVideo;
      return {
        ...state,
        problemFetching: true,
        problemSuccess: null,
        problemError,
      };
    case VIDEO_PROBLEM_SUCCESS:
      return { ...state, problemFetching: false, problemSuccess: true };
    case VIDEO_PROBLEM_FAILURE:
      return {
        ...state,
        problemFetching: false,
        problemSuccess: false,
        problemError: action.error,
      };
    case VIDEO_DELETE_REQUEST: {
      const { deleteSuccess, deleteError, deleteFieldFeedback } =
        initialStateVideo;
      return {
        ...state,
        deleteFetching: true,
        deleteSuccess,
        deleteError,
        deleteFieldFeedback,
      };
    }
    case VIDEO_DELETE_CLEAR_ERROR: {
      const { deleteError, deleteFieldFeedback } = initialStateVideo;
      return { ...state, deleteError, deleteFieldFeedback };
    }
    case VIDEO_DELETE_FAILURE:
      const deleteError = action.error;
      const deleteFieldFeedback = extractFieldFeedbackFromApiError(deleteError);
      return {
        ...state,
        deleteFetching: false,
        deleteSuccess: false,
        deleteError,
        deleteFieldFeedback,
      };
    case VIDEO_DELETE_SUCCESS:
      return { ...state, deleteFetching: false, deleteSuccess: true };
    case VIDEO_NOTE_UPDATE_SUCCESS:
      return produce(state, (draftState) => {
        // If the clip is in the current video, update it
        const { note: updatedNote, videoId } = action.data;
        if (draftState.video?.id === videoId) {
          draftState.video.notes = draftState.video.notes.map((clip) =>
            clip.id === updatedNote.id ? updatedNote : clip,
          );
        }
      });
    case VIDEO_CLIP_SHARE_SUCCESS:
    case VIDEO_CLIP_GET_SUCCESS:
    case VIDEO_CLIP_UPDATE_SUCCESS:
      return produce(state, (draftState) => {
        // If the clip is in the current video, update it
        const { clip: updatedClip, videoId } = action.data;
        if (draftState.video?.id === videoId) {
          draftState.video.clips = draftState.video.clips.map((clip) =>
            clip.id === updatedClip.id ? updatedClip : clip,
          );
        }
      });
    case VIDEO_TRANSCRIPT_REQUEST: {
      const { transcriptSuccess, transcriptError, transcriptErrorMessage } =
        initialStateVideo;
      return {
        ...state,
        transcriptFetching: true,
        transcriptSuccess,
        transcriptError,
        transcriptErrorMessage,
        transcriptVideoId: action.videoId,
      };
    }
    case VIDEO_TRANSCRIPT_FAILURE:
      return {
        ...state,
        transcriptFetching: false,
        transcriptSuccess: false,
        transcriptError: action.error,
        transcriptErrorMessage: extractMessageFromApiError(action.error),
      };
    case VIDEO_TRANSCRIPT_SUCCESS:
      return {
        ...state,
        transcriptFetching: false,
        transcriptSuccess: true,
        video:
          state.video.id === state.transcriptVideoId
            ? transformVideoAwsUrls(action.video)
            : state.video,
      };
    case VIDEO_AI_REQUEST: {
      const { aiSuccess, aiError, aiErrorMessage } = initialStateVideo;
      return {
        ...state,
        aiFetching: true,
        aiSuccess,
        aiError,
        aiErrorMessage,
        aiVideoId: action.videoId,
      };
    }
    case VIDEO_AI_FAILURE:
      return {
        ...state,
        aiFetching: false,
        aiSuccess: false,
        aiError: action.error,
        aiErrorMessage: extractMessageFromApiError(action.error),
      };
    case VIDEO_AI_SUCCESS:
      const { video, videoId, status } = action;
      if (status === "processing") {
        return {
          ...state,
          aiFetching: false,
          aiSuccess: true,
          video:
            state.video.id === videoId
              ? update(state.video, { ai_status: { $set: status } })
              : state.video,
        };
      } else if (status === "success") {
        return {
          ...state,
          aiFetching: false,
          aiSuccess: true,
          video:
            state.video.id === videoId
              ? transformVideoAwsUrls(video)
              : state.video,
        };
      } else {
        // Should never happen
        return {
          ...state,
          aiFetching: false,
          aiSuccess: true,
        };
      }
    default:
      return state;
  }
}
