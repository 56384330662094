import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { usePrevious } from "@/helpers";
import { useAppSelector } from "@/store";

export function useNoteReducerFeedback({
  onSuccess,
  isUpdate,
  isClip,
}: {
  isUpdate: boolean;
  isClip: boolean;
  onSuccess?: () => void;
}): {
  isSaving: boolean;
} {
  const dispatch = useDispatch();

  const { isError, isFetching } = useAppSelector((state) => {
    let responsibleReducer;
    if (isUpdate) {
      if (isClip) {
        responsibleReducer = state.videoClipUpdate;
      } else {
        responsibleReducer = state.videoNoteUpdate;
      }
    } else {
      if (isClip) {
        responsibleReducer = state.videoClipCreate;
      } else {
        responsibleReducer = state.videoNoteCreate;
      }
    }
    const { isError, isFetching } = responsibleReducer;
    return { isError, isFetching };
  });
  const prevIsFetching = usePrevious(isFetching);

  useEffect(() => {
    if (isFetching === false && prevIsFetching === true) {
      if (!isError) {
        onSuccess?.();
      } else {
        dispatch({
          type: "SNACKBAR_ADD",
          notificationType: "error",
          content: `Failed to ${isUpdate ? "edit" : "create"} ${
            isClip ? "clip" : "note"
          }.`,
        });
      }
    }
  }, [
    dispatch,
    isError,
    isFetching,
    isUpdate,
    onSuccess,
    prevIsFetching,
    isClip,
  ]);

  return {
    isSaving: isFetching,
  };
}
