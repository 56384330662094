import React, { Component } from "react";

import styles from "./styles.module.css";

// Todo: this is not a
class Spinner extends Component {
  render() {
    return (
      <div className={styles.root}>
        <div className={styles.spinner}>
          <div className={styles.doubleBounce1}>&nbsp;</div>
          <div className={styles.doubleBounce2}>&nbsp;</div>
        </div>
      </div>
    );
  }
}

export default Spinner;
