import { LoaderFunctionArgs } from "react-router-dom";

import { store } from "@/store";

import { authRequiredWrapper } from "./_authRequiredWrapper";

async function loader({ params, request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams;
  const pageFromSearchParams = searchParams.get("page");
  const currentPage =
    pageFromSearchParams === null ? 1 : parseInt(pageFromSearchParams);

  store.dispatch({
    type: "TEST_VIDEOS_REQUEST",
    testId: params.testId,
    search: "page=" + currentPage,
  });
  return null;
}

export default authRequiredWrapper(loader);
