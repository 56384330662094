import React from "react";
import { concatClassNames } from "@system42/core";
import { Link } from "react-router-dom";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class MainNavigationNav extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, className, logo, styles } = this.props;
    return (
      <nav
        {...extractContainerProps(this.props)}
        className={concatClassNames(className, styles.root)}
      >
        <Link to={"/"} className={styles.logo}>
          <img src={logo} alt="Logo" />
        </Link>
        {children}
      </nav>
    );
  }
}

export default MainNavigationNav;
