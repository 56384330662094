import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles.module.css";

class SuccessMessage extends React.Component {
  static defaultProps = { styles };

  render() {
    const { children, message, className, styles, icon } = this.props;

    return (
      <div className={`${styles.root}${className ? " " + className : ""}`}>
        {icon && (
          <div className={styles.icon}>
            <FontAwesomeIcon icon={icon} />
          </div>
        )}
        <div className={styles.message}>
          {message}
          {children}
        </div>
      </div>
    );
  }
}

export default SuccessMessage;
