// XXX: The video context could be used more globally when using the player for now I made it just for this component

import { createContext } from "react";

export type VideoPlayerContextType = {
  type: "userbrain" | "wistia";
  autoplay: boolean;
  initialTime: number | null;
  location: string | null;
  sources?: string;
  vtt?: string;
  poster?: string;
  transcriptVttUrl?: string;
  transcriptAvailable: boolean;
  thumbnailSprite?: string;
  nextJumpPoint?: string;
  previousJumpPoint?: string;
  resumeKey: string | null;
  refPlayer: any;
  isVideoProcessing: boolean;
  onSecondChange?: (second: number) => void;
  onPause?: () => void;
  onEnded?: () => void;
  onStarted?: () => void;
  onPlay?: () => void;
} | null;

export const VideoPlayerContext = createContext<VideoPlayerContextType>(null);
