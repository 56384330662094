import { useMemo } from "react";
import { useSelector } from "react-redux";

import { BILLING_CYCLE_ANNUAL } from "@/helpers";

/**
 * @typedef UserbrainSubscription
 * @type {object}
 * @property {number} quantity
 * @property {string} billing_cycle
 * @property {Date} current_period_start
 * @property {Date} current_period_end
 * @property {boolean} cancel_at_period_end
 * @property {boolean} plan_up_to_date
 * @property {number} plan_price
 * @property {string} plan_currency
 * @property {string} plan
 * @property {string} additional_credit_price
 * @property {number} tax_percent
 * @property {string} type
 * @property {boolean} contactSupportToEdit - If true, the user needs to contact support to edit the subscription
 *
 */

/**
 * Get the subscription
 * @returns {UserbrainSubscription|false|null} Returns the current subscription
 */
export function useSubscription() {
  let subscription = useSelector(({ user }) =>
    user.user ? user.user.subscription || false : null,
  );
  // Todo: useMemo has no referential guarantee, so return values reference may change even though user.user.subscription does not
  return useMemo(() => {
    if (subscription) {
      const contactSupportToEdit =
        subscription.plan === "enterprise" ||
        subscription.billing_cycle === BILLING_CYCLE_ANNUAL;

      return {
        ...subscription,
        current_period_start: new Date(subscription.current_period_start),
        current_period_end: new Date(subscription.current_period_end),
        contactSupportToEdit,
      };
    } else {
      return subscription;
    }
  }, [subscription]);
}
