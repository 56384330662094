import React, { Component } from "react";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from "./styles.module.css";

class VideoRating extends Component {
  constructor(props) {
    super(props);
    this.state = { hoveredRating: 0, justRated: false };
  }

  onClick = (rating) => {
    if (typeof this.props.onClick === "function") {
      this.props.onClick(Math.max(1, Math.min(5, rating)));
    }
    this.setState({ justRated: rating });
  };

  render() {
    const { hoveredRating, justRated } = this.state;
    const { rating, className } = this.props;
    const ratingHtml = [];
    let visibleRating = rating;

    let label;

    if (rating === 0) {
      label = "Please rate this tester";
    } else {
      if (justRated) {
        label = "Thanks!";
      }

      if (justRated === 5) {
        label = "Perfect!";
      }
    }

    if (!justRated) {
      if (hoveredRating > 0) {
        visibleRating = hoveredRating;
        label = "Click to rate";
      }
    }

    for (let i = 1; i <= 5; i++) {
      let icon;
      if (i > visibleRating) {
        icon = <FontAwesomeIcon icon={regular("star")} key={i} />;
      } else {
        icon = <FontAwesomeIcon icon={solid("star")} key={i} />;
      }
      ratingHtml.push(
        <span
          key={i}
          className={styles.star}
          onMouseOver={() => {
            this.setState({
              hoveredRating: i,
              justRated:
                i === this.state.justRated ? this.state.justRated : false,
            });
          }}
          onClick={() => this.onClick(i)}
        >
          {icon}
        </span>,
      );
    }

    return (
      <div
        className={`${className ? className + " " : ""}${styles.root}`}
        onMouseLeave={() => this.setState({ hoveredRating: 0 })}
      >
        {ratingHtml} <span>{label}</span>
      </div>
    );
  }
}

export default VideoRating;
