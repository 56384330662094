import React from "react";

import { Tooltip } from "@/design-system";
import { advertedPoolSize, pluralizer } from "../../helpers";

function getIncludedTestersLi(billingCycle, testersPerMonth) {
  if (billingCycle === "annual") {
    return (
      <li>
        <strong>{testersPerMonth * 12} testers included</strong> each{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          year{" "}
          <Tooltip
            content={
              <>
                You get {testersPerMonth * 12}&nbsp;testers each year from our{" "}
                {advertedPoolSize}&nbsp;pool of qualified testers. All testers
                are available upfront. Unused testers roll over until you
                cancel.
              </>
            }
          />
        </span>
      </li>
    );
  } else {
    return (
      <li>
        <strong>{testersPerMonth} testers included</strong> each{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          month{" "}
          <Tooltip
            content={
              <>
                You get {testersPerMonth}&nbsp;testers each month from our{" "}
                {advertedPoolSize}&nbsp;pool of qualified testers. Unused
                testers roll over until you cancel.
              </>
            }
          />
        </span>
      </li>
    );
  }
}

export const mainFeaturesDefault = (
  billingCycle,
  testerCount,
  collaboratorCount,
  adminCount,
) => (
  <>
    {getIncludedTestersLi(billingCycle, testerCount)}
    <li>
      AI Insights and time-stamped notes{" "}
      <Tooltip
        content={
          "Get instant AI insights to reveal key results from your user tests, based on AI-enhanced transcripts and time-stamped annotations."
        }
      />
    </li>
    <li>
      Automated test reports{" "}
      <Tooltip
        content={
          "Share a comprehensive summary of your tasks, questions, and notes with your team in a flash."
        }
      />
    </li>
    <li>
      Create and share highlight clips{" "}
      <Tooltip
        content={
          "Create short video clips of your key findings and share them with your team and stakeholders."
        }
      />
    </li>
    <li>
      Test with your own users{" "}
      <Tooltip
        content={
          "Get up to 100 test sessions with your own customers, users, or team members every month. Refills monthly."
        }
      />
    </li>
    <li>
      {collaboratorCount} collaborator seats{" "}
      <Tooltip
        content={
          "Collaborators can create tests and order testers. They can also make notes, create clips, and share test results."
        }
      />
    </li>
  </>
);

export const mainFeaturesLegacy = (subscriptionQuantity) => (
  <>
    {subscriptionQuantity > 0 && (
      <li>{pluralizer("tester")(subscriptionQuantity, true)} per month</li>
    )}
    <li>
      {subscriptionQuantity === 0 ? 30 : subscriptionQuantity} sessions with
      your own testers
    </li>
  </>
);
