import { concatClassNames as cn } from "@system42/core";
import { Link } from "react-router-dom";

import { Avatar } from "@/design-system";
import { TestInsightApi } from "@/entities/test/insights";
import { getTesterInitials } from "@/helpers";
import { toHHMMSS } from "../../../../../helpers-ts";
import { SvgIconClip, SvgIconNote } from "../icons";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconPlay } from "./icon-play.svg";

export function NoteOrClipItem({
  noteOrClip,
  className,
}: {
  noteOrClip: TestInsightApi["notes"][0] | TestInsightApi["clips"][0];
  className?: string;
}) {
  const isClip = "timestamp_end" in noteOrClip;
  const VideoMetaIcon = isClip ? SvgIconClip : SvgIconNote;

  // TODO: Types
  // @ts-ignore
  const linkTo = `/video/${noteOrClip.video_id}?t=${
    noteOrClip.timestamp
  }&autoplay=1&${isClip ? "clip" : "note"}=${noteOrClip.id}`;

  return (
    <Link
      draggable="false"
      to={linkTo}
      onClick={(e) => {
        if (window.getSelection()?.toString()) {
          e.preventDefault();
        }
      }}
      className={cn(styles.noteOrClipItem, className)}
    >
      <Avatar
        colorSeed={noteOrClip.tester.id}
        initials={getTesterInitials(noteOrClip.tester.name)}
        title={"Play video"}
        initialsClassName={styles.avatarInitials}
      >
        <SvgIconPlay className={styles.avatarPlayIcon} />
      </Avatar>
      <div className={styles.content}>
        {noteOrClip.note}
        <div className={styles.videoMeta}>
          <VideoMetaIcon className={styles.videoMetaIcon} />
          <div className={styles.videoMetaTimestamp}>
            {toHHMMSS(noteOrClip.timestamp)}
            {noteOrClip.timestamp_end
              ? ` - ${toHHMMSS(noteOrClip.timestamp_end)}`
              : ""}
          </div>
          {noteOrClip.automated_insight && (
            <span className={styles.ai}>AI</span>
          )}
        </div>
      </div>
    </Link>
  );
}
