import React, { Fragment } from "react";
import { concatClassNames } from "@system42/core";
import PropTypes from "prop-types";

import { extractContainerProps } from "../../helpers";

import styles from "./styles.module.css";

class Pagination extends React.Component {
  static defaultProps = { styles, countPages: 10, currentPage: 1 };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { styles, currentPage, countPages, className } = this.props;
    if (countPages <= 1) {
      return null;
    }

    const maxPagesVisible = 8;

    const pages = [];
    for (let i = 0; i < countPages; i++) {
      const pageNumber = i + 1;
      const diffCurrentPage = pageNumber - currentPage;
      if (
        countPages <= maxPagesVisible ||
        pageNumber === 1 ||
        pageNumber === countPages ||
        pageNumber === currentPage ||
        (diffCurrentPage >= -2 && diffCurrentPage <= 2)
      ) {
        pages.push({ pageNumber, label: pageNumber });
      }
    }

    let lastPage = 0;
    return (
      <div
        {...extractContainerProps(this.props)}
        className={concatClassNames(className, styles.root)}
      >
        {pages.map((page) => {
          const showPlaceholder =
            countPages > maxPagesVisible && page.pageNumber > lastPage + 1;
          lastPage = page.pageNumber;
          const pageJsx =
            currentPage === page.pageNumber ? (
              <span
                key={page.pageNumber}
                className={`${styles.page} ${styles["page--current"]}`}
              >
                {page.label}
              </span>
            ) : (
              <button
                key={page.pageNumber}
                className={styles.page}
                onClick={() => this.props.onClickPage(page.pageNumber)}
              >
                {page.label}
              </button>
            );
          if (showPlaceholder) {
            return (
              <Fragment key={"placeholder"}>
                <span
                  className={`${styles.page} ${styles["page--placeholder"]}`}
                  key={page.pageNumber - 1}
                >
                  …
                </span>
                {pageJsx}
              </Fragment>
            );
          } else {
            return pageJsx;
          }
        })}
      </div>
    );
  }
}

Pagination.propTypes = {
  onClick: PropTypes.func,
};

export default Pagination;
