import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import { Card, MainNavigation, MainNavigationContent } from "@/design-system";
import Spinner from "../../components/Spinner";
import Navigation from "../Navigation";
import Notifications from "../Notifications";

import styles from "./styles.module.css";

export default function ReleaseNotes() {
  const releaseNotes = useSelector((state) => state.releaseNotes.data);
  // const releaseNotesFetching = useSelector(state => state.releaseNotes.fetching);
  const dispatch = useDispatch();

  useEffect(() => {
    // componentDidMount
    dispatch({ type: "RELEASE_NOTES_REQUEST" });
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Release Notes | Userbrain</title>
      </Helmet>
      <MainNavigation>
        <Navigation />
        <MainNavigationContent>
          <Notifications />
          <div className={styles.content}>
            {releaseNotes ? (
              <Card className={styles.card}>
                <h1 className={styles.cardHeading}>Release Notes</h1>
                {releaseNotes.releases.map((historyItem, index) => (
                  <div className={styles.releaseNotesItem} key={index}>
                    <h2>v{historyItem.version}</h2>
                    <ul>
                      {historyItem.changes.map((change, index) => (
                        <li key={index}>{change}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </Card>
            ) : (
              <Spinner />
            )}
          </div>
        </MainNavigationContent>
      </MainNavigation>
    </>
  );
}
