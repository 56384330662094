import { useContext } from "react";
import { Link } from "react-router-dom";

import { NoteWithHashtags } from "@/components/NoteWithHashtags";
import { Button, InlineIcon } from "@/design-system";
import { useAppSelector } from "@/store";
import { SharedClipContextStyles } from "..";

import styles from "./styles.module.css";

import { ReactComponent as SvgIconClip } from "../icons/icon-clip.svg";
import { ReactComponent as SvgIconWatch } from "../icons/icon-watch.svg";

export function Sidebar({
  hasFullAccess,
  isLoggedIn,
  videoId,
  clipId,
}: {
  hasFullAccess: boolean;
  isLoggedIn: boolean;
  videoId?: number;
  clipId?: number;
}) {
  return (
    <div className={styles.sidebar}>
      <Header isDisplayLoginButton={!isLoggedIn} />
      <Body />
      {hasFullAccess && <Footer videoId={videoId} clipId={clipId} />}
    </div>
  );
}

function Header({ isDisplayLoginButton }: { isDisplayLoginButton: boolean }) {
  const sharedClipStyles = useContext(SharedClipContextStyles);

  return (
    <div className={styles.header}>
      <div className={styles.clipLabeledIcon}>
        <SvgIconClip />
        Clip
      </div>
      {isDisplayLoginButton && (
        <Button
          to="/login"
          customTag={Link}
          className={sharedClipStyles.buttonDarkmode}
        >
          Log in
        </Button>
      )}
    </div>
  );
}

function Body() {
  const clip = useAppSelector((state) => state.sharedClip.data);

  return (
    <div className={styles.sidebarBody}>
      <div className={styles.clipNote}>
        <NoteWithHashtags
          note={clip?.note ?? ""}
          classNameHashtag={styles.clipTag}
        />
      </div>
    </div>
  );
}

function Footer({ videoId, clipId }: { videoId?: number; clipId?: number }) {
  const sharedClipStyles = useContext(SharedClipContextStyles);
  return (
    <div className={styles.sidebarFooter}>
      <Button
        customTag={Link}
        to={`/video/${videoId}?clip=${clipId}`}
        className={sharedClipStyles.buttonDarkmode}
      >
        <InlineIcon Svg={SvgIconWatch} /> Watch entire video
      </Button>
      <div className={styles.watchEntireVideoDescription}>
        You can see this button because you are logged in and have full access
        to this video.
      </div>
    </div>
  );
}
