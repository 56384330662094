import React from "react";

import { urlContact, urlKnowledgeBase } from "@/helpers-ts";

import styles from "./styles.module.css";

import logoAmericanExpress from "./american-express.svg";
import logoChinaUnionPay from "./china-union-pay.svg";
import logoDinersClub from "./diners-club.svg";
import logoDiscover from "./discover.svg";
import logoJcb from "./jcb.svg";
import logoMastercard from "./mastercard.svg";
import logoVisa from "./visa.svg";

export function Faqs() {
  return (
    <div className={styles.faqs}>
      <h2 className={styles.faqs__title}>Frequently asked questions</h2>
      <div className={styles.faqs__col}>
        <h3 className={styles.faqs__heading}>What is a user test?</h3>
        <p>
          A user test is a 5-20 minute screen recording including audio from one
          of our testers using your site on their own devices.
        </p>
        <p>
          Our testers will follow the activities set by you while thinking out
          aloud. You’ll see where they struggle and hear what they think.
        </p>
        <h3 className={styles.faqs__heading}>
          How many different tasks can I set?
        </h3>
        <p>
          We don’t limit the amount of tasks you can ask our testers to perform
          on your site. However, your set of tasks should be doable within 20
          minutes.
        </p>
        <h3 className={styles.faqs__heading}>What can I test?</h3>
        <p>
          If you can link to it, you can test it. Test an array of live
          websites, those under development, early prototypes or even
          competitor's sites.
        </p>
        <p>
          Our testers use either their smartphones, desktops or tablet devices.
        </p>
        <h3 className={styles.faqs__heading}>Can I invite my own testers?</h3>
        <p>
          Yes, you can test with your own panel of users on all monthly plans.
        </p>
      </div>
      <div className={styles.faqs__col}>
        <h3 className={styles.faqs__heading}>
          Who and where is your panel from?
        </h3>
        <p>
          Our testing panel is made of everyday people, from all around the
          world. You can target our testers based on their language, region,
          gender, age and device type.
        </p>
        <h3 className={styles.faqs__heading}>How many user tests do I need?</h3>
        <p>
          We recommend testing in bunches of 5-8 testers per testing session.
          Repeat your user tests at least every two weeks and start as early as
          possible in the process.
        </p>
        <h3 className={styles.faqs__heading}>
          Which payment methods do you accept?
        </h3>
        <p>
          We accept all major credit cards, including VISA, Mastercard, American
          Express and more. Our payment provider Stripe is certified to the
          highest industry standards.
        </p>
        <div className={styles.faqs__logos}>
          <img src={logoVisa} alt="Visa" width="42" height="23" />
          <img src={logoMastercard} alt="Mastercard" width="84" height="23" />
          <img
            src={logoAmericanExpress}
            alt="AmericanExpress"
            width="59"
            height="23"
          />
          <img src={logoDiscover} alt="Discover" width="55" height="23" />
          <img src={logoJcb} alt="Jcb" width="26" height="23" />
          <img src={logoDinersClub} alt="DinersClub" width="27" height="23" />
          <img
            src={logoChinaUnionPay}
            alt="ChinaUnionPay"
            width="31"
            height="23"
          />
        </div>
        <h3 className={styles.faqs__heading}>
          What is the 100% Satisfaction Guarantee?
        </h3>
        <p>
          If you’re not happy with one of the tests, we’ll send you a new one
          without any costs. No questions asked.
        </p>
        <h3 className={styles.faqs__heading}>
          What if I still have questions?
        </h3>
        <p>
          If you still have questions regarding our service or pricing, you can{" "}
          <a href={urlContact} target="_blank" rel="noopener noreferrer">
            contact us
          </a>{" "}
          directly or browse our{" "}
          <a href={urlKnowledgeBase} target="_blank" rel="noopener noreferrer">
            knowledge base
          </a>
          .
        </p>
      </div>
    </div>
  );
}
