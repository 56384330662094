import { TaskTimelineProps } from "@/components/TaskTimeline";

import { TranscriptProps } from "./Sidebar/Transcript";
import { Video } from "./types";

export enum SharedVideoTabName {
  Details,
  Task,
  Transcript,
}

export type TranscriptTabProps = Omit<
  TranscriptProps,
  "isCurrentItemVisible" | "onChangeCurrentItemVisible" | "scrollerRef"
>;

export type TaskTabProps = Omit<
  TaskTimelineProps,
  | "preventScrollIntoView"
  | "scrollerRef"
  | "onChangeCurrentTimelineItemVisible"
  | "className"
>;

export type DetailsTabProps = {
  videoTitle: Video["title"];
  videoTestUrl: Video["test_url"];
};
