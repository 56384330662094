import { useEffect, useState } from "react";

import { Button, ButtonPrimary, ErrorMessage } from "@/design-system";
import { usePrevious } from "../../helpers";
import ButtonGroup from "../ButtonGroup";
import { Modal2 } from "../Modal";
import ModalHeader from "../ModalHeader";

import styles from "./styles.module.css";

export default function RevokeInviteModal({ isActive, onClose, ...restProps }) {
  return (
    <Modal2
      isActive={isActive}
      onClose={onClose}
      content={<RevokeInviteModalContent onClose={onClose} {...restProps} />}
    />
  );
}

function RevokeInviteModalContent({
  member,
  onClose,
  fetching,
  error,
  errorMessage,
  onRevoke,
}) {
  const { invitation_email: email } = member;
  const [revokedSuccessfully, setRevokedSuccessfully] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const prevFetching = usePrevious(fetching);
  useEffect(() => {
    if (prevFetching === true && fetching === false) {
      if (!error) {
        setRevokedSuccessfully(true);
      } else {
        setDisplayError(true);
      }
    }
  }, [prevFetching, fetching, error]);

  function handleClickRevoke() {
    onRevoke(member);
  }
  return revokedSuccessfully ? (
    <>
      <ModalHeader heading={"Invite revoked"} onClickCloseIcon={onClose} />
      <div className={styles.modalContent}>
        <p>Your invite was revoked from {email}.</p>
      </div>
      <div className={styles.footer}>
        <ButtonGroup align={"right"}>
          <ButtonPrimary onClick={onClose}>Close</ButtonPrimary>
        </ButtonGroup>
      </div>
    </>
  ) : (
    <>
      <ModalHeader heading={"Revoke invite"} onClickCloseIcon={onClose} />
      <div className={styles.modalContent}>
        {errorMessage && displayError && (
          <ErrorMessage message={errorMessage} />
        )}

        <p>Are you sure you want to revoke your invite from {email}?</p>
        <p className={styles.subline}>
          The invitation link that was sent will no longer be valid.
        </p>
      </div>
      <div className={styles.footer}>
        <ButtonGroup align={"right"}>
          <ButtonPrimary onClick={handleClickRevoke} disabled={fetching}>
            {fetching ? "Revoking…" : "Yes, revoke invite"}
          </ButtonPrimary>
          <Button onClick={onClose}>Cancel</Button>
        </ButtonGroup>
      </div>
    </>
  );
}
