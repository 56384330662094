import { LoaderFunctionArgs } from "react-router-dom";

import { store } from "@/store";

async function loader({ params }: LoaderFunctionArgs) {
  const token = params.token;
  store.dispatch({ type: "CONFIRM_EMAIL_REQUEST", token });
  return null;
}

export default loader;
