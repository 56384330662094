import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BasicModalControlProps, Modal2 } from "@/components/Modal";
import Spinner from "@/components/Spinner";
import { GenericApiState } from "@/reducers/genericApiSlice";
import { parseApiQueryToScreenerQuestion } from "../parsers";
import { ApiTest } from "../types";

import styles from "./styles.module.css";

// NOTE - this is temporary.
type RootState = {
  allTestScreeners: GenericApiState<ApiTest[]>;
};

type BrowseModalProps = {
  onSelect: (props: any) => void;
  testId: number;
} & BasicModalControlProps;

type TestToDisplay = {
  testsDataIndex: number;
  label: string;
};

export function BrowseTestsModal({
  onClose,
  isOpen,
  onSelect,
  testId,
}: BrowseModalProps) {
  return (
    <Modal2
      isActive={isOpen}
      onClose={onClose}
      maxWidth={"23rem"}
      centered
      content={<BrowseTestsModalContent onSelect={onSelect} testId={testId} />}
    />
  );
}

function BrowseTestsModalContent({
  onSelect,
  testId,
}: {
  onSelect: (props: any) => void;
  testId: number;
}) {
  const dispatch = useDispatch();

  const { data: testsData, isFetching } = useSelector(
    (state: RootState) => state.allTestScreeners,
  );

  useEffect(() => {
    dispatch({ type: "ALL_TEST_SCREENERS_REQUEST" });
  }, [dispatch]);

  function testDataIsDisplayed(testData: ApiTest) {
    return testData.test.id !== testId && testData.queries.length;
  }

  const testsToDisplay: TestToDisplay[] =
    testsData
      ?.filter(testDataIsDisplayed)
      .sort(sortTestsData)
      .map((testsDataItem, index) => ({
        testsDataIndex: index,
        label: `${testsDataItem.test.title} | ${testsDataItem.test.type}`,
      })) ?? [];

  function handleSelect(testsDataIndex: number) {
    const testData = testsData?.[testsDataIndex];
    if (!testData) return;
    onSelect({
      questions: testData.queries.map(parseApiQueryToScreenerQuestion),
    });
  }

  if (isFetching) {
    return <Spinner />;
  }

  return (
    <div className={styles.content}>
      {testsToDisplay.map((test) => (
        <button
          key={test.testsDataIndex}
          className={styles.test}
          onClick={() => handleSelect(test.testsDataIndex)}
        >
          {test.label}
        </button>
      ))}
    </div>
  );
}

function sortTestsData(a: ApiTest, b: ApiTest) {
  return (
    new Date(b.test.updated_at).getTime() -
    new Date(a.test.updated_at).getTime()
  );
}
