import React from "react";

import { Stack } from "@/design-system";
import { Modal2 } from "../components/Modal";
import { ModalContent } from "../components/ModalContent";
import UpdateCreditCardForm from "../containers/UpdateCreditCardForm";

import { useModal2 } from "./useModal2";

export function useEditPaymentCardModal() {
  const { modal, showModal, hideModal } = useModal2(
    <Modal2
      content={
        <ModalContent>
          <Stack>
            <h1 style={{ margin: "0", fontSize: "1rem" }}>Card details</h1>
            <UpdateCreditCardForm
              onSuccess={handleSuccess}
              onClickCancel={handleCancel}
              showCancelButton
            />
          </Stack>
        </ModalContent>
      }
    />,
  );

  function handleSuccess() {
    hideModal();
  }

  function handleCancel() {
    hideModal();
  }

  return {
    modal,
    showModal,
    hideModal,
  };
}
