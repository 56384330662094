import { NoteOrClipPrototypeProps } from "../types";

const DEFAULT_CLIP_DURATION = 30;

type ApiTimestamps = {
  timestamp: number;
  timestamp_end?: number;
};

export function evaluateApiNoteTimestamps({
  noteOrClipPrototype,
  currentVideoSecond,
  videoDuration,
}: {
  noteOrClipPrototype: NoteOrClipPrototypeProps;
  currentVideoSecond: number;
  videoDuration: number;
}): ApiTimestamps {
  const apiTimestamps: ApiTimestamps = {
    timestamp: 0,
  };

  // If any timestamp has been manually set by the user,
  // the timestampStart or -End will be defined, and
  // should always be used.

  function getNoteTimestampStart(): number {
    if (noteOrClipPrototype.timestampStart !== undefined) {
      return noteOrClipPrototype.timestampStart;
    } else {
      return currentVideoSecond;
    }
  }

  function getClipTimestampStart(): number {
    if (noteOrClipPrototype.timestampStart !== undefined) {
      return noteOrClipPrototype.timestampStart;
    } else if (noteOrClipPrototype.timestampEnd !== undefined) {
      if (currentVideoSecond < noteOrClipPrototype.timestampEnd) {
        return currentVideoSecond;
      } else {
        return Math.max(
          0,
          noteOrClipPrototype.timestampEnd - DEFAULT_CLIP_DURATION,
        );
      }
    } else if (noteOrClipPrototype.timestampTypingStart !== undefined) {
      return Math.max(
        0,
        noteOrClipPrototype.timestampTypingStart - DEFAULT_CLIP_DURATION / 2,
      );
    } else {
      return Math.max(0, currentVideoSecond - DEFAULT_CLIP_DURATION / 2);
    }
  }

  function getClipTimestampEnd(): number {
    if (noteOrClipPrototype.timestampEnd !== undefined) {
      return noteOrClipPrototype.timestampEnd;
    } else {
      if (
        noteOrClipPrototype.timestampStart !== undefined &&
        currentVideoSecond > noteOrClipPrototype.timestampStart
      ) {
        return currentVideoSecond;
      } else {
        return Math.min(
          videoDuration,
          apiTimestamps.timestamp + DEFAULT_CLIP_DURATION,
        );
      }
    }
  }

  if (noteOrClipPrototype.type === "clip") {
    apiTimestamps.timestamp = getClipTimestampStart();
    apiTimestamps.timestamp_end = getClipTimestampEnd();
  } else {
    apiTimestamps.timestamp = getNoteTimestampStart();
  }

  return apiTimestamps;
}
