import { z } from "zod";

import { addSignatureToUrl, TESTER_NAME_ANONYMOUS } from "@/helpers";
import { UrlOrFalseSchema } from "../common";

//
// Zod schemas
//

// Zod is used to parse data we do not trust or "sort of" trust,
// and we use types for everything else for now

export const VideoClipFullApiSchema = z.object({
  id: z.number(),
  video_id: z.number(),
  note: z.string().nullable(),
  timestamp: z.number(),
  timestamp_end: z.number(),
  status: z.enum(["created", "processing", "finished", "error"]).nullable(),
  location: UrlOrFalseSchema,
  // status: null can only happen for old clips in dev
  thumb: UrlOrFalseSchema,
  poster: UrlOrFalseSchema.nullable(),
  signature: z.object({
    policy: z.string().nullable(),
    signature: z.string().nullable(),
    key_pair_id: z.string().nullable(),
  }),
  user: z.string(),
  tester_name: z.string().nullable(),
  is_mine: z.boolean(),
  automated_insight: z.boolean(),
  shared: z.boolean(),
  shared_hash: z.string(),
  download_clip_url: z.string().url().nullable().optional(),
  editable: z.boolean(),
  deletable: z.boolean(),
  edited_at: z.date().nullable(),
  preview: z.object({
    vtt: UrlOrFalseSchema,
    image: UrlOrFalseSchema,
  }),
});

export const VideoClipOverviewApiSchema = VideoClipFullApiSchema.omit({
  editable: true,
  deletable: true,
  edited_at: true,
});

export const VideoClipSharedAuthenticatedApiSchema =
  VideoClipFullApiSchema.omit({
    user: true,
    tester_name: true,
    is_mine: true,
    automated_insight: true,
    shared: true,
    shared_hash: true,
    editable: true,
    deletable: true,
    edited_at: true,
    download_clip_url: true,
  });

export const VideoClipSharedApiSchema =
  VideoClipSharedAuthenticatedApiSchema.omit({
    id: true,
    video_id: true,
  });

export const VideoClipVideoApiSchema = VideoClipFullApiSchema;

//
// Types
//

// Api (parsed)
export type VideoClipFullApi = z.infer<typeof VideoClipFullApiSchema>;
export type VideoClipOverviewApi = z.infer<typeof VideoClipOverviewApiSchema>;
export type VideoClipSharedAuthenticatedApi = z.infer<
  typeof VideoClipSharedAuthenticatedApiSchema
>;
export type VideoClipSharedApi = z.infer<typeof VideoClipSharedApiSchema>;
export type VideoClipVideoApi = z.infer<typeof VideoClipVideoApiSchema>;

// Local
type VideoClipBase = {
  note: string;
  /**
   * Both the thumbnail and poster are the same image
   * but the thumbnail is a smaller version of the poster.
   * They will only be set when the video is ready.
   */
  videoThumbnailUrl: string | null;
  videoPosterUrl: string | null;
  timestampStart: number;
  timestampEnd: number;
  isVideoReady: boolean;
  location: string | null;
};

export type VideoClipOverview = VideoClipBase & {
  id: number;
  videoId: number;
  tester: string;
  isPublic: boolean;
  author: string;
  sharedHash?: string;
  downloadClipUrl: string | null;
};

export type VideoClipShared = VideoClipBase & {
  previewImage: string | null;
  previewVtt: string | null;
};

export type VideoClipSharedAuthenticated = VideoClipShared & {
  id: number;
  videoId: number;
};

export type VideoClipVideo = VideoClipBase &
  Omit<VideoClipOverview, "tester" | "videoId" | "downloadClipUrl">;

//
// parsing and mapping functions
//

function mapVideoClipApiToClipBase(
  parsedApiData: VideoClipOverviewApi | VideoClipSharedApi | VideoClipVideoApi,
): VideoClipBase {
  const {
    note,
    timestamp,
    timestamp_end,
    poster,
    status,
    location,
    thumb,
    signature,
  } = parsedApiData;

  function awsUrlFilter(url: string | false | null): string | null {
    if (!url) {
      return null;
    }
    return addSignatureToUrl(url, signature);
  }

  const mappedData: VideoClipBase = {
    note: note ?? "",
    videoThumbnailUrl: awsUrlFilter(thumb),
    videoPosterUrl: awsUrlFilter(poster),
    timestampStart: timestamp,
    timestampEnd: timestamp_end,
    isVideoReady: status === "finished",
    location: awsUrlFilter(location),
  };

  return mappedData;
}

export function parseVideoClipOverviewApi(
  apiResponseData: VideoClipOverviewApi,
): VideoClipOverview {
  const parsedApiData = VideoClipOverviewApiSchema.parse(apiResponseData);

  const clipBase = mapVideoClipApiToClipBase(parsedApiData);

  const {
    id,
    video_id,
    tester_name,
    user,
    shared,
    shared_hash,
    download_clip_url,
  } = parsedApiData;

  const clip: VideoClipOverview = {
    ...clipBase,
    id: id,
    videoId: video_id,
    tester: tester_name ?? TESTER_NAME_ANONYMOUS,
    author: user,
    isPublic: shared,
    sharedHash: shared_hash,
    downloadClipUrl: download_clip_url ?? null,
  };

  return clip;
}

export function parseVideoClipSharedApi(
  apiResponseData: VideoClipSharedApi,
): VideoClipShared {
  const parsedApiData = VideoClipSharedApiSchema.parse(apiResponseData);
  const clipBase = mapVideoClipApiToClipBase(parsedApiData);
  const { preview } = parsedApiData;
  return {
    ...clipBase,
    previewImage: addSignatureToUrl(preview.image, parsedApiData.signature),
    previewVtt: addSignatureToUrl(preview.vtt, parsedApiData.signature),
  };
}

export function parseVideoClipSharedAuthenticatedApi(
  apiResponseData: VideoClipSharedAuthenticatedApi,
): VideoClipSharedAuthenticated {
  const parsedApiData =
    VideoClipSharedAuthenticatedApiSchema.parse(apiResponseData);
  const clipBase = mapVideoClipApiToClipBase(parsedApiData);
  const { video_id, id, preview } = parsedApiData;
  return {
    ...clipBase,
    previewImage: addSignatureToUrl(preview.image, parsedApiData.signature),
    previewVtt: addSignatureToUrl(preview.vtt, parsedApiData.signature),
    id,
    videoId: video_id,
  };
}

export function parseVideoClipVideoApi(
  apiResponseData: VideoClipVideoApi,
): VideoClipVideo {
  const { user, shared, shared_hash, id, ...parsedRest } =
    VideoClipVideoApiSchema.parse(apiResponseData);

  const clipBase = mapVideoClipApiToClipBase(parsedRest);

  const clip: VideoClipVideo = {
    ...clipBase,
    id: id,
    author: user,
    isPublic: shared,
    sharedHash: shared_hash,
  };

  return clip;
}
