import React, { useState } from "react";

import {
  Button,
  ButtonPrimary,
  ErrorMessage,
  FormField,
  RadioSelect,
  RadioSelectItem,
  Stack,
  TextArea,
} from "@/design-system";
import { pluralizer, shuffleArray } from "../../helpers";
import ButtonGroup from "../ButtonGroup";

import styles from "./styles.module.css";

const options = [
  "Not using it enough (or at all)",
  "Still evaluating",
  "Missing features we need",
  "Too expensive",
  "Switching to another product",
  "Shutting the business down",
];
shuffleArray(options);
options.push("Other");

const refTextarea = React.createRef();

export default function CancelSubscriptionForm(props) {
  const {
    cancelSubscriptionFetching,
    onCancel,
    errorMessage,
    onGoBack,
    isOutdatedPlan,
    sessionsAvailable,
  } = props;

  const [confirmedLosingAvailableTesters, setConfirmedLosingAvailableTesters] =
    useState(false);
  const [confirmedOutdatedPlan, setConfirmedOutdatedPlan] = useState(false);
  const [reason, setReason] = useState(null);
  const [additionalDetails, setAdditionalDetails] = useState("");

  function handleChangeReason(e) {
    setReason(e.target.value);
    refTextarea.current.focus();
  }

  function handleChangeAdditionalDetails(e) {
    setAdditionalDetails(e.target.value);
  }

  function handleClickCancel() {
    onCancel(reason, additionalDetails);
  }

  function handleClickGoBack() {
    onGoBack();
  }

  function handleClickConfirmOutdatedPlan() {
    setConfirmedOutdatedPlan(true);
  }

  function handleClickConfirmLosingAvailableTesters() {
    setConfirmedLosingAvailableTesters(true);
  }

  if (
    !confirmedLosingAvailableTesters &&
    !isOutdatedPlan &&
    sessionsAvailable > 0
  ) {
    return (
      <div className={`${styles.form1}`}>
        <h1 className={styles.title}>Cancel your subscription?</h1>
        <p>
          Before you go, please use any unused testers you may have. If you
          cancel, you’ll lose your unused testers at the end of your billing
          period.
        </p>
        <p>
          You still have {sessionsAvailable} unused{" "}
          {pluralizer("tester")(sessionsAvailable)}
        </p>
        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          <ButtonPrimary onClick={handleClickGoBack}>
            Keep my subscription
          </ButtonPrimary>
          <Button onClick={handleClickConfirmLosingAvailableTesters}>
            Continue cancellation
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  if (isOutdatedPlan && !confirmedOutdatedPlan) {
    return (
      <div className={`${styles.form1}`}>
        <h1 className={styles.title}>Cancel your current subscription?</h1>
        <p>
          You are currently using an outdated plan and can’t go back if you
          cancel it now.
        </p>
        <ButtonGroup align={"right"} className={styles.buttonGroup}>
          <ButtonPrimary onClick={handleClickConfirmOutdatedPlan}>
            OK, cancel my plan
          </ButtonPrimary>
          <Button onClick={handleClickGoBack}>Nevermind</Button>
        </ButtonGroup>
      </div>
    );
  }

  return (
    <div className={`${styles.form2}`}>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <strong>
        Please let us know why you're cancelling so we can improve. Thank you!
      </strong>
      <Stack>
        <RadioSelect
          onChange={handleChangeReason}
          value={reason}
          className={styles.options}
        >
          {options.map((option, index) => (
            <RadioSelectItem value={option} key={index}>
              {option}
            </RadioSelectItem>
          ))}
        </RadioSelect>
        <FormField
          className={styles.additionalDetails}
          label={"Mind adding some additional details?"}
        >
          <TextArea
            value={additionalDetails}
            onChange={handleChangeAdditionalDetails}
            innerRef={refTextarea}
            autoSize
            fullWidth
          />
        </FormField>
      </Stack>

      <ButtonGroup align={"right"} className={styles.buttonGroup}>
        <ButtonPrimary
          onClick={handleClickCancel}
          disabled={cancelSubscriptionFetching}
        >
          {cancelSubscriptionFetching
            ? "Cancelling subscription…"
            : "Complete cancellation"}
        </ButtonPrimary>
        <Button onClick={handleClickGoBack}>Go Back</Button>
      </ButtonGroup>
    </div>
  );
}
