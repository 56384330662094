import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import { TEST_INSIGHTS_SET } from "@/reducers/testInsights";
import {
  TEST_INSIGHTS_REFRESH_FAILURE,
  TEST_INSIGHTS_REFRESH_REQUEST,
  TEST_INSIGHTS_REFRESH_SUCCESS,
} from "@/reducers/testInsightsRefresh";
import { RootState } from "@/store";
import { API_URL } from ".";

export function* watchTestInsightsRefresh() {
  yield takeLatest(TEST_INSIGHTS_REFRESH_REQUEST, callTestInsightsRefreshApi);
}

function* callTestInsightsRefreshApi(action: any): SagaIterator {
  const { testId } = action;
  try {
    const response = yield call(fetchTestInsightsRefresh, testId);

    yield put({
      type: TEST_INSIGHTS_REFRESH_SUCCESS,
    });

    const testInsightsData: RootState["testInsights"]["data"] = yield select(
      (state) => state.testInsights.data,
    );

    if (testInsightsData?.testId === testId) {
      yield put({
        type: TEST_INSIGHTS_SET,
        data: {
          insights: response.data.data,
          testId,
          refreshHash: undefined,
        },
      });
    }
  } catch (error) {
    yield put({
      type: TEST_INSIGHTS_REFRESH_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

function fetchTestInsightsRefresh(testId: number) {
  return axios({
    method: "post",
    url: `${API_URL}/test/${testId}/insights/refresh`,
  });
}
