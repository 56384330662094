import { Reducer } from "@reduxjs/toolkit";
import produce from "immer";

import { parseVideoClipOverviewApi } from "@/entities/video/clip";
import { TestClipsApiParsedData } from "@/sagas/parsers";

import { TEST_CLIPS_API_SUCCESS } from "./testClipsApi";
import { VIDEO_CLIP_GET_SUCCESS } from "./videoClipGet";
import { VIDEO_CLIP_SHARE_SUCCESS } from "./videoClipShare";

// reducer with initial state
const initialState: TestClipsReducerState = {
  testId: null,
  page: null,
  selectedTesterIds: null,
  selectedTaskIds: null,
  selectedTags: null,
  isUntaggedSelected: null,
  clips: [],
  testers: [],
  tasks: [],
  tags: [],
  clipsRemaining: 0,
  total: 0,
  perPage: 0,
  unfilteredTotal: 0,
  tagless: 0,
};

export type TestClipsReducerState = {
  testId: number | null;
  page: number | null;
  selectedTesterIds: string[] | null;
  selectedTaskIds: string[] | null;
  selectedTags: string[] | null;
  isUntaggedSelected: boolean | null;
} & TestClipsApiParsedData;

/**
 * Filter out non-existing filter options.
 *
 * Non-existing filter options might surface
 * in scenarios like the following:
 *
 * 1. Create a clip with a tag.
 * 2. Filter by the tag.
 * 3. Edit the clip and remove the tag.
 * 4. Go back in browser history.
 * 5. Deleted tag still exist in URL.
 */
function filterExistingFilterOptions(
  selectedIds: string[],
  options: { id: string }[],
) {
  const optionIds = options?.map((option) => option.id);
  return (
    selectedIds?.filter((selectedId) => optionIds?.includes(selectedId)) ?? null
  );
}

export const testClipsReducer: Reducer<TestClipsReducerState> = function (
  state = initialState,
  action,
): TestClipsReducerState {
  switch (action.type) {
    case TEST_CLIPS_API_SUCCESS:
      return produce(state, (draft) => {
        const {
          testId,
          page,
          selectedTesterIds,
          selectedTaskIds,
          selectedTags,
          isUntaggedSelected,
          clips,
          testers,
          tasks,
          tags,
          clipsRemaining,
          total,
          perPage,
          unfilteredTotal,
          tagless,
        } = action.data;
        draft.testId = Number(testId);
        draft.page = page;
        draft.selectedTesterIds = filterExistingFilterOptions(
          selectedTesterIds,
          testers,
        );
        draft.selectedTaskIds = filterExistingFilterOptions(
          selectedTaskIds,
          tasks,
        );
        draft.selectedTags = filterExistingFilterOptions(selectedTags, tags);
        draft.isUntaggedSelected = isUntaggedSelected;
        draft.clips = clips;
        draft.testers = testers;
        draft.tasks = tasks;
        draft.tags = tags;
        draft.clipsRemaining = clipsRemaining;
        draft.total = total;
        draft.perPage = perPage;
        draft.unfilteredTotal = unfilteredTotal;
        draft.tagless = tagless;
      });
    case VIDEO_CLIP_SHARE_SUCCESS:
    case VIDEO_CLIP_GET_SUCCESS:
      return produce(state, (draft) => {
        const { clip: updatedClip } = action.data;
        draft.clips = draft.clips.map((clip) =>
          //XXX: parseVideoClipOverviewApi should be called in the saga, but this
          // can not be done yet as other reducers need the data unparsed
          // but this could just be passed with the action as well, so maybe
          // we can just chnange this.
          clip.id === updatedClip.id
            ? parseVideoClipOverviewApi(updatedClip)
            : clip,
        );
      });
    default:
      return state;
  }
};
