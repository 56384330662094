import { Screener } from "../types";

import { ValidatedQuestionResults, validateQuestion } from "./validateQuestion";

export type ScreenerValidationResult = {
  status: "error" | "success";
  questionResults: ValidatedQuestionResults[];
};

export function isScreenerValid(screener: Screener): ScreenerValidationResult {
  const questionResults = screener.questions.map(validateQuestion);

  return {
    status: questionResults.some((result) => result.length > 0)
      ? "error"
      : "success",
    questionResults: questionResults,
  };
}
