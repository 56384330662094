import { Reducer } from "@reduxjs/toolkit";
import produce from "immer";

import {
  DELIVERY_CREATE_SUCCESS,
  DELIVERY_STOP_SUCCESS,
  DELIVERY_UPDATE_SUCCESS,
} from ".";

import { ApiError } from "./types";

export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_OUT = "SIGN_OUT";
export const SIGNED_IN = "SIGNED_IN";
export const SIGNED_OUT = "SIGNED_OUT";

export const USER_REQUEST = "USER_REQUEST";
export const USER_FAILURE = "USER_FAILURE";
export const USER_SUCCESS = "USER_SUCCESS";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";

export const USER_UPDATE_PASSWORD_REQUEST = "USER_UPDATE_PASSWORD_REQUEST";
export const USER_UPDATE_PASSWORD_FAILURE = "USER_UPDATE_PASSWORD_FAILURE";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_CLEAR = "PASSWORD_RESET_CLEAR";

export const PASSWORD_NEW_REQUEST = "PASSWORD_NEW_REQUEST";
export const PASSWORD_NEW_FAILURE = "PASSWORD_NEW_FAILURE";
export const PASSWORD_NEW_SUCCESS = "PASSWORD_NEW_SUCCESS";
export const PASSWORD_NEW_CLEAR = "PASSWORD_NEW_CLEAR";

export const SET_NEW_VIDEO_COUNT = "SET_NEW_VIDEO_COUNT";

// reducer with initial state
const initialStateUser: UserState = {
  signingIn: false,
  signedIn: false,
  signInIsError: false,
  signInError: null,
  signOutSuccess: false,
  signOutReason: "",
  signInRedirectTo: null,

  signingUp: false,
  signUpIsError: false,
  signUpError: null,

  userFetching: false,
  userIsError: false,
  userError: null,
  user: null,
  userRefreshHash: null,
  userLoginEmail: null,

  updateFetching: false,
  updateError: null,
  updateIsError: false,

  updatePasswordFetching: false,
  updatePasswordIsError: false,
  updatePasswordError: null,

  passwordResetFetching: false,
  passwordResetIsError: false,
  passwordResetError: null,
  passwordResetSuccessMessage: null,

  passwordNewFetching: false,
  passwordNewIsError: false,
  passwordNewError: null,
  passwordNewSuccessMessage: null,
};

type UserState = {
  signingIn: boolean;
  signedIn: boolean;
  signInIsError: boolean;
  signInError: ApiError | null;
  signOutSuccess: boolean;
  signOutReason: string;
  signInRedirectTo: string | null;

  signingUp: boolean;
  signUpIsError: boolean;
  signUpError: ApiError | null;

  userFetching: boolean;
  userIsError: boolean;
  userError: null | ApiError;
  user: any;
  userRefreshHash: string | null;
  userLoginEmail: string | null;

  updateFetching: boolean;
  updateIsError: boolean;
  updateError: ApiError | null;

  updatePasswordFetching: boolean;
  updatePasswordIsError: boolean;
  updatePasswordError: ApiError | null;

  passwordResetFetching: boolean;
  passwordResetIsError: boolean;
  passwordResetError: ApiError | null;
  passwordResetSuccessMessage: string | null;

  passwordNewFetching: boolean;
  passwordNewIsError: boolean;
  passwordNewError: ApiError | null;
  passwordNewSuccessMessage: string | null;
};

export const userReducer: Reducer<UserState> = function (
  state = initialStateUser,
  action,
): UserState {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        signingIn: true,
        signOutSuccess: false,
        signInIsError: false,
        signInError: null,
        signInRedirectTo: action.redirectTo ?? null,
        userLoginEmail: action.credentials.username,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        signingIn: false,
        signInIsError: false,
        signInError: action.error,
      };
    case SIGNED_IN:
      return {
        ...state,
        signingIn: false,
        signedIn: true,
        signingUp: false,
        signInIsError: false,
        signInError: null,
      };
    case SIGNED_OUT:
      if (action.error) {
        return {
          ...state,
          signedIn: false,
          signingIn: false,
          signOutSuccess: true,
          signOutReason: "error",
        };
      } else {
        return {
          ...state,
          signedIn: false,
          signingIn: false,
          signOutSuccess: true,
          signOutReason: "user",
        };
      }

    case SIGN_UP:
      return {
        ...state,
        signingUp: true,
        signUpIsError: false,
        signUpError: null,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signingUp: false,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        signingUp: false,
        signUpIsError: true,
        signUpError: action.error,
      };

    case USER_REQUEST: {
      const { userIsError, userError } = initialStateUser;
      return { ...state, userFetching: true, userIsError, userError };
    }
    case USER_SUCCESS:
      const { user, refreshHash } = action;
      if (!user) {
        return { ...state, userFetching: false };
      }
      return {
        ...state,
        userFetching: false,
        user,
        userRefreshHash: refreshHash,
      };
    case USER_FAILURE: {
      const { userRefreshHash, user } = initialStateUser;
      return {
        ...state,
        userFetching: false,
        user,
        userRefreshHash,
        userIsError: true,
        userError: action.error,
      };
    }
    case USER_UPDATE_REQUEST:
      return {
        ...state,
        updateFetching: true,
        updateIsError: false,
        updateError: null,
      };
    case USER_UPDATE_FAILURE:
      return {
        ...state,
        updateFetching: false,
        updateIsError: true,
        updateError: action.error,
      };
    case USER_UPDATE_SUCCESS:
      const newState = {
        ...state,
        updateFetching: false,
        updateIsError: false,
        updateError: null,
      };
      if (action.user) {
        newState.user = action.user;
      }
      return newState;

    case USER_UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        updatePasswordFetching: true,
        updatePasswordIsError: false,
        updatePasswordError: null,
      };
    case USER_UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatePasswordFetching: false,
        updatePasswordIsError: true,
        updatePasswordError: action.error,
      };
    case USER_UPDATE_PASSWORD_SUCCESS: {
      const newState = {
        ...state,
        updatePasswordFetching: false,
        updatePasswordIsError: false,
        updatePasswordError: null,
      };
      if (action.user) {
        newState.user = action.user;
      }
      return newState;
    }
    case PASSWORD_RESET_REQUEST:
      return {
        ...state,
        passwordResetFetching: true,
        passwordResetIsError: false,
        passwordResetError: null,
        passwordResetSuccessMessage: null,
      };
    case PASSWORD_RESET_FAILURE: {
      return {
        ...state,
        passwordResetFetching: false,
        passwordResetIsError: true,
        passwordResetError: action.error,
        passwordResetSuccessMessage: null,
      };
    }
    case PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        passwordResetFetching: false,
        passwordResetIsError: false,
        passwordResetError: null,
        passwordResetSuccessMessage: action.message,
      };
    case PASSWORD_RESET_CLEAR: {
      const {
        passwordResetFetching,
        passwordResetError,
        passwordResetIsError,
        passwordResetSuccessMessage,
      } = initialStateUser;
      return {
        ...state,
        passwordResetFetching,
        passwordResetIsError,
        passwordResetError,
        passwordResetSuccessMessage,
      };
    }
    case PASSWORD_NEW_REQUEST:
      return {
        ...state,
        passwordNewFetching: true,
        passwordNewIsError: false,
        passwordNewError: null,
        passwordNewSuccessMessage: null,
      };
    case PASSWORD_NEW_FAILURE: {
      return {
        ...state,
        passwordNewFetching: false,
        passwordNewIsError: true,
        passwordNewError: action.error,
        passwordNewSuccessMessage: null,
      };
    }
    case PASSWORD_NEW_SUCCESS:
      return {
        ...state,
        passwordNewFetching: false,
        passwordNewIsError: false,
        passwordNewError: null,
        passwordNewSuccessMessage: action.message,
      };
    case PASSWORD_NEW_CLEAR: {
      const {
        passwordNewFetching,
        passwordNewIsError,
        passwordNewError,
        passwordNewSuccessMessage,
      } = initialStateUser;
      return {
        ...state,
        passwordNewFetching,
        passwordNewIsError,
        passwordNewError,
        passwordNewSuccessMessage,
      };
    }

    case DELIVERY_CREATE_SUCCESS:
    case DELIVERY_UPDATE_SUCCESS:
    case DELIVERY_STOP_SUCCESS:
      if (!state.user) {
        return state;
      }
      return produce(state, (draft) => {
        draft.user.credits = { ...draft.user.credits, ...action.credits };
        draft.user.sessions = { ...draft.user.sessions, ...action.sessions };
      });
    case "FEATURE_POPUP_DISMISS_REQUEST":
      // Set the version to the current version no matter if the request is successful or not.
      if (!state.user) {
        return state;
      }
      return produce(state, (draft) => {
        draft.user.feature_popup_version = action.version;
      });
    case "REACTIVATE_SUBSCRIPTION_SUCCESS":
    case "CANCEL_SUBSCRIPTION_SUCCESS":
      // userRefreshHash needs to be set to null because if the user would change back to the state before
      // the old refresh hash would lead to not receiving new data. It might be hard to understand :-D
      //
      return produce(state, (draft) => {
        draft.user = action.data.user;
        draft.userRefreshHash = null;
      });
    case SET_NEW_VIDEO_COUNT:
      return produce(state, (draft) => {
        draft.user.new_video_count = action.newVideoCount;
      });
    default:
      return state;
  }
};
