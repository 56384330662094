import {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

export interface ControlledScrollerProps {
  children: ReactNode;
  onPreserveScroll: (position: number) => void;
  className?: string;
  initialScrollPosition: number;
}

/**


  Content scroller with default initialScrollPosition and
  onPreserveScroll callback called when scrollPosition should be preserved.

*/
export const ControlledScroller = forwardRef(
  (
    {
      children,
      onPreserveScroll,
      className,
      initialScrollPosition,
    }: ControlledScrollerProps,
    ref,
  ) => {
    const localRef = useRef<HTMLDivElement>(null);
    const [initialScrollPositionState] = useState(initialScrollPosition);

    useImperativeHandle(ref, () => localRef.current);

    // Set div's scrollTop to scrollPosition on mount
    useLayoutEffect(() => {
      if (initialScrollPositionState && localRef.current) {
        localRef.current.scrollTop = initialScrollPositionState;
      }
    }, [initialScrollPositionState]);

    // Report scrollTop on preserve
    useLayoutEffect(() => {
      const div = localRef.current;
      return () => {
        div && onPreserveScroll(div.scrollTop);
      };
    }, [onPreserveScroll]);

    return (
      <div ref={localRef} className={className}>
        {children}
      </div>
    );
  },
);
