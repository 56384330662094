import React, { Fragment } from "react";
import { concatClassNames as cn } from "@system42/core";

// import { } from '../../system42'
import styles from "./styles.module.css";

export function BarGraph(props) {
  const { className, bars, ...containerProps } = props;

  bars.sort((a, b) => b.value - a.value);

  const max = bars.reduce((acc, cur) => (cur.value > acc ? cur.value : acc), 0);

  return (
    <div className={cn(className, styles.barGraph)} {...containerProps}>
      <div className={styles.barsGrid}>
        {bars.map((bar, index) => (
          <Fragment key={index}>
            <div className={styles.barLabel}>{bar.label}</div>
            <div className={styles.bar}>
              {bar.value > 0 && (
                <div
                  className={styles.barBar}
                  style={{ width: `${(100 / max) * bar.value}%` }}
                />
              )}
              <div className={styles.barValue}>{bar.value}</div>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
