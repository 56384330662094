import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";

import axios from "@/axios";
import { createReduxApiError } from "@/helpers-ts";
import {
  DEV_TEST_FILL_FAILURE,
  DEV_TEST_FILL_REQUEST,
  DEV_TEST_FILL_SUCCESS,
} from "@/reducers/devTestFill";
import { API_URL } from ".";

export function* watchDevTestFill() {
  yield takeLatest(DEV_TEST_FILL_REQUEST, callDevTestFillApi);
}

// worker saga: makes the api call when watcher saga sees the action
function* callDevTestFillApi(action: any): SagaIterator {
  try {
    const { testId } = action;
    yield call(postTestFill, testId);

    yield put({
      type: DEV_TEST_FILL_SUCCESS,
      data: true,
    });
  } catch (error) {
    // dispatch a failure action to the store with the error
    yield put({
      type: DEV_TEST_FILL_FAILURE,
      error: createReduxApiError(error as AxiosError),
    });
  }
}

// function that makes the api request and returns a Promise for response
function postTestFill(testId: number) {
  return axios({
    method: "post",
    url: API_URL + "/test/" + testId + "/fill",
  });
}
