import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useSendVideoStats } from "@/hooks/useSendVideoStats";
import {
  VIDEO_ENDED,
  VIDEO_PAUSED,
  VideoStatsType,
} from "@/reducers/videoStatsReducer";

import { VideoPlayer } from "./components/VideoPlayer";
import { UserbrainVideoPlayerApi } from "./components/VideoPlayer/types";
import { Video } from "./types";

import styles from "./styles.module.css";

export function Player({
  video,
  refPlayer,
  previousJumpPoint,
  nextJumpPoint,
  onChangeIsPlaying,
  onChangeCurrentVideoSecond,
  timestampStartHighlightedNotesAndClipsItem,
  isAutoplay,
}: {
  video: Video;
  refPlayer: React.MutableRefObject<UserbrainVideoPlayerApi | null>;
  previousJumpPoint: number;
  nextJumpPoint: number;
  onChangeIsPlaying?: (isPlaying: boolean) => void;
  onChangeCurrentVideoSecond?: (second: number) => void;
  timestampStartHighlightedNotesAndClipsItem: number | undefined;
  isAutoplay: boolean;
}) {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const searchParamValueT = searchParams.get("t");
  const [currentSecond, setCurrentSecond] = useState<number>();

  const handleVideoStarted = function () {
    if (video.new === 1) {
      dispatch({ type: "VIDEO_SET_NEW", id: video?.id, new: false });
    }
  };

  const handleVideoPlay = function () {
    onChangeIsPlaying?.(true);
  };

  const handleVideoPause = function () {
    onChangeIsPlaying?.(false);
    dispatch({ type: VIDEO_PAUSED });
  };

  const handleVideoEnded = function () {
    dispatch({ type: VIDEO_ENDED });
  };

  const handleVideoSecondChange = useCallback(
    (second: number) => {
      setCurrentSecond(second);
      onChangeCurrentVideoSecond?.(second);
    },
    [onChangeCurrentVideoSecond],
  );

  useSendVideoStats(video.id, VideoStatsType.Video, currentSecond, refPlayer);

  let initialTime;
  if (searchParamValueT) {
    initialTime = Math.min(video.duration, Number(searchParamValueT));
  } else if (
    typeof timestampStartHighlightedNotesAndClipsItem !== "undefined"
  ) {
    initialTime = timestampStartHighlightedNotesAndClipsItem;
  } else {
    initialTime = null;
  }

  return (
    <VideoPlayer
      className={styles.videoPlayer}
      type={video.type}
      autoplay={isAutoplay}
      initialTime={initialTime}
      location={video.location}
      sources={video.sources}
      vtt={video.preview?.vtt}
      poster={video.poster}
      transcriptVttUrl={video.transcript_vtt_url}
      transcriptAvailable={video.transcript?.length > 0}
      thumbnailSprite={video.preview?.image}
      nextJumpPoint={nextJumpPoint}
      previousJumpPoint={previousJumpPoint}
      onPlay={handleVideoPlay}
      onPause={handleVideoPause}
      onEnded={handleVideoEnded}
      onStarted={handleVideoStarted}
      onSecondChange={handleVideoSecondChange}
      resumeKey={video.id}
      refPlayer={refPlayer}
    />
  );
}
