import React from "react";
import { concatClassNames as cn } from "@system42/core";

import { ButtonPrimary } from "@/design-system";

import styles from "./styles.module.css";

export function ExplorePlansBox(props) {
  const { className, onClickExplorePlans, ...containerProps } = props;

  function handleClickExplorePlans() {
    onClickExplorePlans();
  }

  return (
    <section
      className={cn(className, styles.explorePlansBox)}
      {...containerProps}
    >
      <h1>Get more out of Userbrain</h1>
      <p>
        Whether you’re a designer in a startup or part of a larger team or
        organization, we’ve got the perfect plan for you.
      </p>
      <ButtonPrimary
        className={styles.button}
        onClick={handleClickExplorePlans}
      >
        Explore our plans
      </ButtonPrimary>
    </section>
  );
}
