import { useEffect, useRef } from "react";
import React from "react";
import dayjs from "dayjs";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import padStart from "lodash/padStart";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import validUrl from "valid-url";

export const isDevelopment = process.env.REACT_APP_HOST_ENV === "development";

export const SHOW_DEV_TOOLS =
  process.env.REACT_APP_HOST_ENV === "development" &&
  process.env.REACT_APP_DEV_FEATURES_ENABLED === "true";

export const BILLING_CYCLE_ANNUAL = "annual";
export const BILLING_CYCLE_MONTHLY = "monthly";

export const textCantChangeTaskType =
  "You cannot change the type once a tester has completed this step.";
export const textCantEditTest = "Can’t be edited while waiting for testers";
// TODO: Consider if href and copy should not be in seperate files.
export const textFigmaShareLinkInaccessible =
  "It looks like this site is offline. Please check if your Figma share link is accessible to anyone.";
export const hrefHelpFigmaPrototype =
  "https://help.userbrain.com/help/testing-your-figma-prototype-with-userbrain";

export function pluralizer(singular, plural = (singular) => singular + "s") {
  return function (count, includeCount = false) {
    let result;
    if (count === 1) {
      result = singular;
    } else {
      result = typeof plural === "function" ? plural(singular) : plural;
    }
    if (includeCount) {
      result = `${count} ${result}`;
    }
    return result;
  };
}

const languageNamesByCode = {
  en: "English",
  de: "German",
  es: "Spanish",
};

export function getLanguageNameByCode(code) {
  return languageNamesByCode[code];
}

export function getSubscriptionName(quantity) {
  if (quantity === 0) {
    return "Bring your own testers";
  }
  return `${pluralizer("tester")(quantity, true)} per month`;
}

export function getPlanName(plan, quantity) {
  switch (plan) {
    case "legacy":
      return getSubscriptionName(quantity);
    default:
      return capitalize(plan);
  }
}

export function getSumFromVideoCount(videoCount) {
  const keys = Object.keys(videoCount);
  return keys.reduce((prevVal, key) => prevVal + videoCount[key], 0);
}

export function getDevicesFromVideoCount(videoCount) {
  return Object.entries(videoCount)
    .filter((item) => item[1] > 0)
    .map((item) => item[0]);
}

const PARTICIPATE_URL = process.env.REACT_APP_PARTICIPATE_URL;

// Custom Hooks
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export function extractMessageFromApiError(
  error,
  removeGeneralFeedbackIfFieldFeedback = true,
) {
  /* Remove general feedback if field feedback */
  if (
    removeGeneralFeedbackIfFieldFeedback &&
    !isEmpty(extractFieldFeedbackFromApiError(error))
  ) {
    return "";
  }

  return error?.response?.data?.message || error?.message || "";
}

export function extractFirstFieldMessageFromApiError(error) {
  return extractFirstFieldMessageFromFieldFeedback(
    extractFieldFeedbackFromApiError(error),
  );
}

export function extractFirstFieldMessageFromFieldFeedback(fieldFeedback) {
  const fieldFeedbackEntries = Object.entries(fieldFeedback);
  return fieldFeedbackEntries[0]?.[1]?.[0] ?? "";
}

export function extractFieldFeedbackFromApiError(error) {
  let fieldFeedback = {};
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors
  ) {
    if (isObject(error.response.data.errors)) {
      fieldFeedback = error.response.data.errors;
    }
  }
  return fieldFeedback;
}

export function formatDateWithoutTime(date) {
  return date?.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function getDateName(date) {
  const now = dayjs(new Date());
  date = dayjs(date);
  if (date.format("MM-DD-YYYY") === now.format("MM-DD-YYYY")) {
    return "Today";
  } else if (
    date.format("MM-DD-YYYY") === now.subtract(1, "day").format("MM-DD-YYYY")
  ) {
    return "Yesterday";
  } else {
    return date.format("MMM D, YYYY");
  }
}

/* This should go to design system toLocaleStringSupportsLocales countDecimals formatNumber formatPrice formatPriceWithBillingCycle*/
const toLocaleStringSupportsLocales = (() => {
  const number = 0;
  try {
    number.toLocaleString("i");
  } catch (e) {
    return e.name === "RangeError";
  }
  return false;
})();

const countDecimals = function (value) {
  if (Math.floor(value) === value) return 0;
  return value.toString().split(".")[1]?.length || 0;
};

export function formatNumber(number) {
  return number.toLocaleString();
}

export function formatPrice(number, currency) {
  number = Math.round(parseFloat(number) * 100) / 100;
  currency = currency ? currency : "USD";
  if (toLocaleStringSupportsLocales) {
    return number.toLocaleString(undefined, {
      style: "currency",
      currency: currency.toUpperCase(),
      minimumFractionDigits: countDecimals(number) > 0 ? 2 : 0,
    });
  } else {
    if (currency.toUpperCase() === "EUR") {
      return number.toLocaleString() + "€";
    } else {
      return "$" + number.toLocaleString();
    }
  }
}

export function formatPriceWithBillingCycle(number, currency, billingCycle) {
  switch (billingCycle) {
    case "annual":
      return `${formatPrice(number, currency)} per year`;
    case "monthly":
      return `${formatPrice(number, currency)} per month`;
    default:
      return `${formatPrice(number, currency)} per period`;
  }
}

export function formatCardExpiry(month, year) {
  return (
    padStart(month, 2, "0") +
    "/" +
    padStart((year ? "" + year : "").substr(2), 2, "0")
  );
}

export function formatBillingAddress(billingInformation) {
  let result = "";

  if (isObject(billingInformation)) {
    const {
      firstName,
      lastName,
      organization,
      street,
      postcode,
      city,
      country,
      vatId,
    } = billingInformation;
    const fullName = (firstName + " " + lastName).trim();
    const cityAndPostcode = (postcode + " " + city).trim();
    const addressArray = [];
    if (organization) {
      addressArray.push(organization);
    }
    if (fullName) {
      addressArray.push(fullName);
    }
    if (street) {
      addressArray.push(street);
    }
    if (cityAndPostcode) {
      addressArray.push(cityAndPostcode);
    }
    if (country) {
      const countryItem = listOfAllCountries.find(
        (e) => e.isoAlpha2 === country,
      );
      if (countryItem) {
        addressArray.push(countryItem.name);
      } else {
        addressArray.push(country);
      }
    }
    if (vatId) {
      addressArray.push("VATIN:" + vatId);
    }
    result = addressArray.join("\n");
  }

  return result;
}

export function getPeriodNameByIdendtifier(identifier) {
  switch (identifier) {
    case "weekly":
      return "every week";
    case "biweekly":
      return "every 2 weeks";
    case "monthly":
      return "once a month";
    default:
      return identifier;
  }
}

export function isVatNeeded(countryCode, vatCountries) {
  if (countryCode === "AT") {
    return false;
  }
  return (
    typeof vatCountries.find((country) => country.country === countryCode) ===
    "object"
  );
}

export function vatRateForCountry(countryCode, vatCountries) {
  const foundElement = vatCountries.find(
    (country) => country.country === countryCode,
  );
  if (typeof foundElement === "object") {
    return foundElement.rate;
  }
  return 0;
}

export function getWordingForTestType(testType) {
  switch (testType) {
    case "app":
      return "mobile app";
    default:
      return testType;
  }
}

export function getWordingForScreenerChoice(answerType, questionType) {
  if (questionType === "single_choice" || questionType === "multiple_choice") {
    switch (answerType) {
      case "accept":
        return "Approve";
      case "may_select":
        return "Ignore answer";
      case "must_select":
        return "Approve";
      case "reject":
        return "Reject tester";
      default:
    }
  }
  return "Unknown";
}

export function getWordingForTargetingValue(domain, value) {
  switch (domain) {
    case "device-alt":
      switch (value) {
        case null:
          return "Any device";
        case "desktop":
          return "Computer";
        case "tablet":
          return "Tablet";
        case "mobile":
          return "Mobile";
        default:
          return value;
      }
    case "device":
      switch (value) {
        case null:
          return "Any device";
        case "desktop":
          return "Computers (desktop or laptop)";
        case "tablet":
          return "Tablets";
        case "mobile":
          return "Mobile phones";
        default:
          return value;
      }
    case "age":
      switch (value.toString()) {
        case "1":
          return "18–34 years";
        case "2":
          return "35–50 years";
        case "3":
          return "51 and older";
        default:
          return value;
      }
    case "gender":
      switch (value) {
        case "M":
          return "Male";
        case "F":
          return "Female";
        default:
          return value;
      }
    case "region":
      switch (value) {
        case "DE":
          return "Germany";
        case "AT":
          return "Austria";
        case "US":
          return "United States of America";
        case "CA":
          return "Canada";
        case "GB":
          return "United Kingdom";
        case "AU":
          return "Australia";
        case "ZA":
          return "South Africa";
        default:
          return value;
      }
    default:
      return value;
  }
}

export const noop = () => {};

export const cookies = new Cookies();

export function nl2br(str) {
  let i = 0;
  return str
    .split("\n")
    .reduce((acc, curr) => [
      acc,
      <br key={i++} />,
      <React.Fragment key={i++}>{curr}</React.Fragment>,
    ]);
}

export const listOfAllCountries = [
  { name: "Afghanistan", isoAlpha2: "AF" },
  { name: "Åland Islands", isoAlpha2: "AX" },
  { name: "Albania", isoAlpha2: "AL" },
  { name: "Algeria", isoAlpha2: "DZ" },
  { name: "American Samoa", isoAlpha2: "AS" },
  { name: "Andorra", isoAlpha2: "AD" },
  { name: "Angola", isoAlpha2: "AO" },
  { name: "Anguilla", isoAlpha2: "AI" },
  { name: "Antarctica", isoAlpha2: "AQ" },
  { name: "Antigua and Barbuda", isoAlpha2: "AG" },
  { name: "Argentina", isoAlpha2: "AR" },
  { name: "Armenia", isoAlpha2: "AM" },
  { name: "Aruba", isoAlpha2: "AW" },
  { name: "Australia", isoAlpha2: "AU" },
  { name: "Austria", isoAlpha2: "AT" },
  { name: "Azerbaijan", isoAlpha2: "AZ" },
  { name: "Bahamas", isoAlpha2: "BS" },
  { name: "Bahrain", isoAlpha2: "BH" },
  { name: "Bangladesh", isoAlpha2: "BD" },
  { name: "Barbados", isoAlpha2: "BB" },
  { name: "Belarus", isoAlpha2: "BY" },
  { name: "Belgium", isoAlpha2: "BE" },
  { name: "Belize", isoAlpha2: "BZ" },
  { name: "Benin", isoAlpha2: "BJ" },
  { name: "Bermuda", isoAlpha2: "BM" },
  { name: "Bhutan", isoAlpha2: "BT" },
  { name: "Bolivia (Plurinational State of)", isoAlpha2: "BO" },
  { name: "Bonaire, Sint Eustatius and Saba", isoAlpha2: "BQ" },
  { name: "Bosnia and Herzegovina", isoAlpha2: "BA" },
  { name: "Botswana", isoAlpha2: "BW" },
  { name: "Bouvet Island", isoAlpha2: "BV" },
  { name: "Brazil", isoAlpha2: "BR" },
  { name: "British Indian Ocean Territory", isoAlpha2: "IO" },
  { name: "Brunei Darussalam", isoAlpha2: "BN" },
  { name: "Bulgaria", isoAlpha2: "BG" },
  { name: "Burkina Faso", isoAlpha2: "BF" },
  { name: "Burundi", isoAlpha2: "BI" },
  { name: "Cabo Verde", isoAlpha2: "CV" },
  { name: "Cambodia", isoAlpha2: "KH" },
  { name: "Cameroon", isoAlpha2: "CM" },
  { name: "Canada", isoAlpha2: "CA" },
  { name: "Cayman Islands", isoAlpha2: "KY" },
  { name: "Central African Republic", isoAlpha2: "CF" },
  { name: "Chad", isoAlpha2: "TD" },
  { name: "Chile", isoAlpha2: "CL" },
  { name: "China", isoAlpha2: "CN" },
  { name: "Christmas Island", isoAlpha2: "CX" },
  { name: "Cocos (Keeling) Islands", isoAlpha2: "CC" },
  { name: "Colombia", isoAlpha2: "CO" },
  { name: "Comoros", isoAlpha2: "KM" },
  { name: "Congo (the Democratic Republic of the)", isoAlpha2: "CD" },
  { name: "Congo", isoAlpha2: "CG" },
  { name: "Cook Islands", isoAlpha2: "CK" },
  { name: "Costa Rica", isoAlpha2: "CR" },
  { name: "Côte d'Ivoire", isoAlpha2: "CI" },
  { name: "Croatia", isoAlpha2: "HR" },
  { name: "Cuba", isoAlpha2: "CU" },
  { name: "Curaçao", isoAlpha2: "CW" },
  { name: "Cyprus", isoAlpha2: "CY" },
  { name: "Czechia", isoAlpha2: "CZ" },
  { name: "Denmark", isoAlpha2: "DK" },
  { name: "Djibouti", isoAlpha2: "DJ" },
  { name: "Dominica", isoAlpha2: "DM" },
  { name: "Dominican Republic", isoAlpha2: "DO" },
  { name: "Ecuador", isoAlpha2: "EC" },
  { name: "Egypt", isoAlpha2: "EG" },
  { name: "El Salvador", isoAlpha2: "SV" },
  { name: "Equatorial Guinea", isoAlpha2: "GQ" },
  { name: "Eritrea", isoAlpha2: "ER" },
  { name: "Estonia", isoAlpha2: "EE" },
  { name: "Eswatini", isoAlpha2: "SZ" },
  { name: "Ethiopia", isoAlpha2: "ET" },
  { name: "Falkland Islands", isoAlpha2: "FK" },
  { name: "Faroe Islands", isoAlpha2: "FO" },
  { name: "Fiji", isoAlpha2: "FJ" },
  { name: "Finland", isoAlpha2: "FI" },
  { name: "France", isoAlpha2: "FR" },
  { name: "French Guiana", isoAlpha2: "GF" },
  { name: "French Polynesia", isoAlpha2: "PF" },
  { name: "French Southern Territories", isoAlpha2: "TF" },
  { name: "Gabon", isoAlpha2: "GA" },
  { name: "Gambia", isoAlpha2: "GM" },
  { name: "Georgia", isoAlpha2: "GE" },
  { name: "Germany", isoAlpha2: "DE" },
  { name: "Ghana", isoAlpha2: "GH" },
  { name: "Gibraltar", isoAlpha2: "GI" },
  { name: "Greece", isoAlpha2: "GR" },
  { name: "Greenland", isoAlpha2: "GL" },
  { name: "Grenada", isoAlpha2: "GD" },
  { name: "Guadeloupe", isoAlpha2: "GP" },
  { name: "Guam", isoAlpha2: "GU" },
  { name: "Guatemala", isoAlpha2: "GT" },
  { name: "Guernsey", isoAlpha2: "GG" },
  { name: "Guinea", isoAlpha2: "GN" },
  { name: "Guinea-Bissau", isoAlpha2: "GW" },
  { name: "Guyana", isoAlpha2: "GY" },
  { name: "Haiti", isoAlpha2: "HT" },
  { name: "Heard Island and McDonald Islands", isoAlpha2: "HM" },
  { name: "Holy See", isoAlpha2: "VA" },
  { name: "Honduras", isoAlpha2: "HN" },
  { name: "Hong Kong", isoAlpha2: "HK" },
  { name: "Hungary", isoAlpha2: "HU" },
  { name: "Iceland", isoAlpha2: "IS" },
  { name: "India", isoAlpha2: "IN" },
  { name: "Indonesia", isoAlpha2: "ID" },
  { name: "Iran", isoAlpha2: "IR" },
  { name: "Iraq", isoAlpha2: "IQ" },
  { name: "Ireland", isoAlpha2: "IE" },
  { name: "Isle of Man", isoAlpha2: "IM" },
  { name: "Israel", isoAlpha2: "IL" },
  { name: "Italy", isoAlpha2: "IT" },
  { name: "Jamaica", isoAlpha2: "JM" },
  { name: "Japan", isoAlpha2: "JP" },
  { name: "Jersey", isoAlpha2: "JE" },
  { name: "Jordan", isoAlpha2: "JO" },
  { name: "Kazakhstan", isoAlpha2: "KZ" },
  { name: "Kenya", isoAlpha2: "KE" },
  { name: "Kiribati", isoAlpha2: "KI" },
  { name: "Korea (the Democratic People's Republic of)", isoAlpha2: "KP" },
  { name: "Korea (the Republic of)", isoAlpha2: "KR" },
  { name: "Kuwait", isoAlpha2: "KW" },
  { name: "Kyrgyzstan", isoAlpha2: "KG" },
  { name: "Lao People's Democratic Republic", isoAlpha2: "LA" },
  { name: "Latvia", isoAlpha2: "LV" },
  { name: "Lebanon", isoAlpha2: "LB" },
  { name: "Lesotho", isoAlpha2: "LS" },
  { name: "Liberia", isoAlpha2: "LR" },
  { name: "Libya", isoAlpha2: "LY" },
  { name: "Liechtenstein", isoAlpha2: "LI" },
  { name: "Lithuania", isoAlpha2: "LT" },
  { name: "Luxembourg", isoAlpha2: "LU" },
  { name: "Macao", isoAlpha2: "MO" },
  { name: "Macedonia", isoAlpha2: "MK" },
  { name: "Madagascar", isoAlpha2: "MG" },
  { name: "Malawi", isoAlpha2: "MW" },
  { name: "Malaysia", isoAlpha2: "MY" },
  { name: "Maldives", isoAlpha2: "MV" },
  { name: "Mali", isoAlpha2: "ML" },
  { name: "Malta", isoAlpha2: "MT" },
  { name: "Marshall Islands", isoAlpha2: "MH" },
  { name: "Martinique", isoAlpha2: "MQ" },
  { name: "Mauritania", isoAlpha2: "MR" },
  { name: "Mauritius", isoAlpha2: "MU" },
  { name: "Mayotte", isoAlpha2: "YT" },
  { name: "Mexico", isoAlpha2: "MX" },
  { name: "Micronesia", isoAlpha2: "FM" },
  { name: "Moldova (the Republic of)", isoAlpha2: "MD" },
  { name: "Monaco", isoAlpha2: "MC" },
  { name: "Mongolia", isoAlpha2: "MN" },
  { name: "Montenegro", isoAlpha2: "ME" },
  { name: "Montserrat", isoAlpha2: "MS" },
  { name: "Morocco", isoAlpha2: "MA" },
  { name: "Mozambique", isoAlpha2: "MZ" },
  { name: "Myanmar", isoAlpha2: "MM" },
  { name: "Namibia", isoAlpha2: "NA" },
  { name: "Nauru", isoAlpha2: "NR" },
  { name: "Nepal", isoAlpha2: "NP" },
  { name: "Netherlands", isoAlpha2: "NL" },
  { name: "New Caledonia", isoAlpha2: "NC" },
  { name: "New Zealand", isoAlpha2: "NZ" },
  { name: "Nicaragua", isoAlpha2: "NI" },
  { name: "Niger", isoAlpha2: "NE" },
  { name: "Nigeria", isoAlpha2: "NG" },
  { name: "Niue", isoAlpha2: "NU" },
  { name: "Norfolk Island", isoAlpha2: "NF" },
  { name: "Northern Mariana Islands", isoAlpha2: "MP" },
  { name: "Norway", isoAlpha2: "NO" },
  { name: "Oman", isoAlpha2: "OM" },
  { name: "Pakistan", isoAlpha2: "PK" },
  { name: "Palau", isoAlpha2: "PW" },
  { name: "Palestine, State of", isoAlpha2: "PS" },
  { name: "Panama", isoAlpha2: "PA" },
  { name: "Papua New Guinea", isoAlpha2: "PG" },
  { name: "Paraguay", isoAlpha2: "PY" },
  { name: "Peru", isoAlpha2: "PE" },
  { name: "Philippines", isoAlpha2: "PH" },
  { name: "Pitcairn", isoAlpha2: "PN" },
  { name: "Poland", isoAlpha2: "PL" },
  { name: "Portugal", isoAlpha2: "PT" },
  { name: "Puerto Rico", isoAlpha2: "PR" },
  { name: "Qatar", isoAlpha2: "QA" },
  { name: "Réunion", isoAlpha2: "RE" },
  { name: "Romania", isoAlpha2: "RO" },
  { name: "Russian Federation", isoAlpha2: "RU" },
  { name: "Rwanda", isoAlpha2: "RW" },
  { name: "Saint Barthélemy", isoAlpha2: "BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", isoAlpha2: "SH" },
  { name: "Saint Kitts and Nevis", isoAlpha2: "KN" },
  { name: "Saint Lucia", isoAlpha2: "LC" },
  { name: "Saint Martin (French part)", isoAlpha2: "MF" },
  { name: "Saint Pierre and Miquelon", isoAlpha2: "PM" },
  { name: "Saint Vincent and the Grenadines", isoAlpha2: "VC" },
  { name: "Samoa", isoAlpha2: "WS" },
  { name: "San Marino", isoAlpha2: "SM" },
  { name: "Sao Tome and Principe", isoAlpha2: "ST" },
  { name: "Saudi Arabia", isoAlpha2: "SA" },
  { name: "Senegal", isoAlpha2: "SN" },
  { name: "Serbia", isoAlpha2: "RS" },
  { name: "Seychelles", isoAlpha2: "SC" },
  { name: "Sierra Leone", isoAlpha2: "SL" },
  { name: "Singapore", isoAlpha2: "SG" },
  { name: "Sint Maarten (Dutch part)", isoAlpha2: "SX" },
  { name: "Slovakia", isoAlpha2: "SK" },
  { name: "Slovenia", isoAlpha2: "SI" },
  { name: "Solomon Islands", isoAlpha2: "SB" },
  { name: "Somalia", isoAlpha2: "SO" },
  { name: "South Africa", isoAlpha2: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", isoAlpha2: "GS" },
  { name: "South Sudan", isoAlpha2: "SS" },
  { name: "Spain", isoAlpha2: "ES" },
  { name: "Sri Lanka", isoAlpha2: "LK" },
  { name: "Sudan", isoAlpha2: "SD" },
  { name: "Suriname", isoAlpha2: "SR" },
  { name: "Svalbard and Jan Mayen", isoAlpha2: "SJ" },
  { name: "Sweden", isoAlpha2: "SE" },
  { name: "Switzerland", isoAlpha2: "CH" },
  { name: "Syrian Arab Republic", isoAlpha2: "SY" },
  { name: "Taiwan", isoAlpha2: "TW" },
  { name: "Tajikistan", isoAlpha2: "TJ" },
  { name: "Tanzania, United Republic of", isoAlpha2: "TZ" },
  { name: "Thailand", isoAlpha2: "TH" },
  { name: "Timor-Leste", isoAlpha2: "TL" },
  { name: "Togo", isoAlpha2: "TG" },
  { name: "Tokelau", isoAlpha2: "TK" },
  { name: "Tonga", isoAlpha2: "TO" },
  { name: "Trinidad and Tobago", isoAlpha2: "TT" },
  { name: "Tunisia", isoAlpha2: "TN" },
  { name: "Turkey", isoAlpha2: "TR" },
  { name: "Turkmenistan", isoAlpha2: "TM" },
  { name: "Turks and Caicos Islands", isoAlpha2: "TC" },
  { name: "Tuvalu", isoAlpha2: "TV" },
  { name: "Uganda", isoAlpha2: "UG" },
  { name: "Ukraine", isoAlpha2: "UA" },
  { name: "United Arab Emirates", isoAlpha2: "AE" },
  { name: "United Kingdom", isoAlpha2: "GB" },
  { name: "United States Minor Outlying Islands", isoAlpha2: "UM" },
  { name: "United States", isoAlpha2: "US" },
  { name: "Uruguay", isoAlpha2: "UY" },
  { name: "Uzbekistan", isoAlpha2: "UZ" },
  { name: "Vanuatu", isoAlpha2: "VU" },
  { name: "Venezuela", isoAlpha2: "VE" },
  { name: "Viet Nam", isoAlpha2: "VN" },
  { name: "Virgin Islands (British)", isoAlpha2: "VG" },
  { name: "Virgin Islands (U.S.)", isoAlpha2: "VI" },
  { name: "Wallis and Futuna", isoAlpha2: "WF" },
  { name: "Western Sahara", isoAlpha2: "EH" },
  { name: "Yemen", isoAlpha2: "YE" },
  { name: "Zambia", isoAlpha2: "ZM" },
  { name: "Zimbabwe", isoAlpha2: "ZW" },
];

export function getNetPricePerCreditEffective(user) {
  if (user) {
    if (user.subscription) {
      return user.subscription.additional_credit_price / 100;
    } else {
      return user.pricing.single / 100;
    }
  }
  return 0;
}

export function getNetPriceForZeroSubscription(user) {
  if (user) {
    return user.pricing.iyop / 100;
  }
  return 0;
}

export function getNetPricePerCreditSingle(user) {
  if (user) {
    return user.pricing.single / 100;
  }
  return 0;
}

export function getNetPricePerCreditSubscription(user) {
  if (user) {
    return user.pricing.subscription / 100;
  }
  return 0;
}

export function getNetPriceSubscription(user, subscription) {
  if (subscription === 0) {
    return getNetPriceForZeroSubscription(user);
  } else {
    return getNetPricePerCreditSubscription(user) * subscription;
  }
}

export function getPrice(netPricePerUnit, taxPercent, units = 1) {
  return units * netPricePerUnit * (1 + taxPercent / 100);
}

export function participateUrl(test) {
  return `${PARTICIPATE_URL}/${test?.invitation?.code}`;
}

export function getAdditionalTesterInfos(tester) {
  const testerCountry = listOfAllCountries.find(
    (c) => c.isoAlpha2 === tester.country_of_residence,
  );
  const additionalTesterInfosArray = [];
  if (tester.type === "invitation") {
    additionalTesterInfosArray.push(`You’ve invited this participant`);
  }
  if (tester.age) {
    additionalTesterInfosArray.push(`Age: ${tester.age}`);
  }
  if (testerCountry) {
    additionalTesterInfosArray.push(testerCountry.name);
  }
  if (additionalTesterInfosArray.length > 0) {
    return additionalTesterInfosArray.join(", ");
  } else {
    return "";
  }
}

function fallbackCopyTextToClipboard(inputRef) {
  try {
    inputRef.focus();
    inputRef.select();
    const range = document.createRange();
    const readOnly = inputRef.readOnly;
    const contentEditable = inputRef.contentEditable;
    inputRef.readOnly = false;
    inputRef.contentEditable = true;
    range.selectNodeContents(inputRef);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
    inputRef.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
    document.execCommand("copy");
    inputRef.blur();
    inputRef.readOnly = readOnly;
    inputRef.contentEditable = contentEditable;
    return true;
  } catch (err) {
    return false;
  }
}

export function copyTextToClipboard(inputRef, dispatch) {
  const messageSuccess = "Copied to clipboard!";
  const messageError = "Can’t copy to clipboard, use strg + C.";
  if (!window.navigator.clipboard) {
    if (typeof dispatch === "function") {
      if (fallbackCopyTextToClipboard(inputRef)) {
        dispatch({
          type: "SNACKBAR_ADD",
          notificationType: "success",
          content: messageSuccess,
        });
      } else {
        dispatch({
          type: "SNACKBAR_ADD",
          notificationType: "error",
          content: messageError,
        });
      }
    }
    return;
  }
  window.navigator.clipboard.writeText(inputRef.value).then(
    () => {
      if (typeof dispatch === "function") {
        dispatch({
          type: "SNACKBAR_ADD",
          notificationType: "success",
          content: messageSuccess,
        });
      }
    },
    () => {
      if (typeof dispatch === "function") {
        dispatch({
          type: "SNACKBAR_ADD",
          notificationType: "error",
          content: messageError,
        });
      }
    },
  );
}

export function getIncludedTestersForPlan(plan) {
  switch (plan) {
    case "starter":
      return 3;
    case "pro":
      return 10;
    case "agency":
      return 30;
    default:
      return 0;
  }
}

export function createCheckoutTexts(args) {
  const {
    netPriceCharged,
    vatCharged,
    netPriceChargedNext,
    vatChargedNext,
    formatPrice,
    creditCount,
    checkoutPlan,
    currentSubscription,
    billingCycle,
    deliveryRequest,
  } = args;

  const totalPriceCharged = netPriceCharged + vatCharged;
  const totalPriceChargedNext = netPriceChargedNext + vatChargedNext;

  const priceChargedVatInfo =
    vatCharged > 0 ? (
      <>
        {" "}
        ({formatPrice(netPriceCharged)} + {formatPrice(vatCharged)}&nbsp;VAT)
      </>
    ) : (
      ""
    );
  const priceChargedNextVatInfo =
    vatChargedNext > 0 ? (
      <>
        {" "}
        ({formatPrice(netPriceChargedNext)} + {formatPrice(vatChargedNext)}
        &nbsp;VAT)
      </>
    ) : (
      ""
    );

  const switchingToAnnual =
    currentSubscription?.billing_cycle === BILLING_CYCLE_MONTHLY &&
    billingCycle === BILLING_CYCLE_ANNUAL;

  const switchingToMonthly =
    currentSubscription?.billing_cycle === BILLING_CYCLE_MONTHLY &&
    billingCycle === BILLING_CYCLE_ANNUAL;

  const todayPlusOneBillingCycle = dayjs(new Date())
    .add(1, billingCycle === BILLING_CYCLE_MONTHLY ? "month" : "year")
    .toDate();

  function createReturnValue(options) {
    const {
      thankYouHeading = "",
      thankYouText = "",
      thankYouSubline = "",
      modalHeadingStepCreditCard = "",
      additionalInfoCharge = "",
      confirmButtonLabel = "",
      skipLastStep = false,
      isContactSupportForChanges = false,
      nextChargeDate = null,
      creditsWordingPluralizer = undefined,
    } = options;
    return {
      thankYouHeading,
      thankYouText,
      thankYouSubline,
      modalHeadingStepCreditCard,
      additionalInfoCharge,
      confirmButtonLabel,
      skipLastStep,
      nextChargeDate,
      isContactSupportForChanges,
      creditsWordingPluralizer,
    };
  }

  let checkoutCase;

  const BUYING_CREDITS_OR_TESTERS = "BUYING_CREDITS_OR_TESTERS";
  const CONTACT_SUPPORT = "CONTACT_SUPPORT";
  const UPGRADE_AND_SWITCH_TO_ANNUAL = "UPGRADE_AND_SWITCH_TO_ANNUAL";
  const UPGRADE_AND_SWITCH_TO_MONTHLY = "UPGRADE_AND_SWITCH_TO_MONTHLY";
  const UPGRADE_SAME_BILLING_CYCLE = "UPGRADE_SAME_BILLING_CYCLE";
  const DOWNGRADE_AND_SWITCH_TO_ANNUAL = "DOWNGRADE_AND_SWITCH_TO_ANNUAL";
  const DOWNGRADE_AND_SWITCH_TO_MONTHLY = "DOWNGRADE_AND_SWITCH_TO_MONTHLY";
  const DOWNGRADE_SAME_BILLING_CYCLE = "DOWNGRADE_SAME_BILLING_CYCLE";
  const SWITCH_TO_ANNUAL = "SWITCH_TO_ANNUAL";
  const NEW_SUBSCRIPTION = "NEW_SUBSCRIPTION";

  if (checkoutPlan === "payg") {
    checkoutCase = BUYING_CREDITS_OR_TESTERS;
  } else if (
    currentSubscription?.billingCycle === BILLING_CYCLE_ANNUAL ||
    currentSubscription?.plan === "enterprise"
  ) {
    // 1. Existing plan with annual billing or enterprise
    checkoutCase = CONTACT_SUPPORT;
  } else if (
    (currentSubscription.plan === "starter" && checkoutPlan === "agency") ||
    (currentSubscription.plan === "pro" && checkoutPlan === "agency") ||
    (currentSubscription.plan === "starter" && checkoutPlan === "pro")
  ) {
    // 2. Upgrade
    if (switchingToAnnual) {
      checkoutCase = UPGRADE_AND_SWITCH_TO_ANNUAL;
    } else if (switchingToMonthly) {
      checkoutCase = UPGRADE_AND_SWITCH_TO_MONTHLY;
    } else {
      checkoutCase = UPGRADE_SAME_BILLING_CYCLE;
    }
  } else if (
    (currentSubscription.plan === "agency" && checkoutPlan === "pro") ||
    (currentSubscription.plan === "agency" && checkoutPlan === "starter") ||
    (currentSubscription.plan === "pro" && checkoutPlan === "starter")
  ) {
    // 3. Downgrade
    if (switchingToAnnual) {
      checkoutCase = DOWNGRADE_AND_SWITCH_TO_ANNUAL;
    } else if (switchingToMonthly) {
      checkoutCase = DOWNGRADE_AND_SWITCH_TO_MONTHLY;
    } else {
      checkoutCase = DOWNGRADE_SAME_BILLING_CYCLE;
    }
  } else if (currentSubscription?.plan === checkoutPlan && switchingToAnnual) {
    // 4. Update to annual billing
    checkoutCase = SWITCH_TO_ANNUAL;
  } else if (!currentSubscription) {
    // 5. Checkout a new subscription/plan
    checkoutCase = NEW_SUBSCRIPTION;
  } else {
    // 6. Unknown case
    checkoutCase = CONTACT_SUPPORT;
  }

  switch (checkoutCase) {
    case BUYING_CREDITS_OR_TESTERS: {
      let modalHeadingStepCreditCard;
      let additionalInfoCharge;
      let creditsWordingPluralizer;
      if (deliveryRequest) {
        const testerCount = deliveryRequest.videoCount - creditCount;
        modalHeadingStepCreditCard = `Get ${pluralizer("tester")(
          deliveryRequest.videoCount,
          true,
        )}`;
        additionalInfoCharge = `${pluralizer("tester is", "testers are")(
          testerCount,
          true,
        )} already covered by your plan/credits.`;
        creditsWordingPluralizer = pluralizer("additional tester");
      } else {
        modalHeadingStepCreditCard = `Get ${pluralizer("credit")(
          creditCount,
          true,
        )}`;
        additionalInfoCharge = "";
        creditsWordingPluralizer = undefined;
      }
      return createReturnValue({
        modalHeadingStepCreditCard,
        additionalInfoCharge,
        confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading: "Payment successful",
        thankYouText: "Thanks for your order!",
        thankYouSubline: "You will receive your invoice via email.",
        creditsWordingPluralizer,
      });
    }
    case UPGRADE_AND_SWITCH_TO_ANNUAL: {
      const nextChargeDate = todayPlusOneBillingCycle;
      return createReturnValue({
        modalHeadingStepCreditCard: `Upgrade to ${getPlanName(
          checkoutPlan,
        )} yearly`,
        additionalInfoCharge: (
          <>
            Get {getIncludedTestersForPlan(checkoutPlan) * 12} testers every
            year for {formatPrice(totalPriceCharged)}/year{priceChargedVatInfo}.
            <br />
            <br />
            Your first {getIncludedTestersForPlan(checkoutPlan) * 12} testers
            are available right after your purchase.
          </>
        ),
        confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading: "Payment successful",
        thankYouText: `Thanks for upgrading to ${getPlanName(
          checkoutPlan,
        )} yearly!`,
        thankYouSubline: "You will receive your invoice via email.",
        nextChargeDate,
      });
    }
    case UPGRADE_SAME_BILLING_CYCLE: {
      const nextChargeDate = todayPlusOneBillingCycle;
      return createReturnValue({
        modalHeadingStepCreditCard: `Upgrade to ${getPlanName(checkoutPlan)}`,
        additionalInfoCharge: (
          <>
            You upgrade to {getIncludedTestersForPlan(checkoutPlan)} testers per
            month for {formatPrice(totalPriceCharged)}/
            {billingCycle === BILLING_CYCLE_MONTHLY ? "month" : "year"}
            {priceChargedVatInfo}.
            <br />
            <br />
            Your first {getIncludedTestersForPlan(checkoutPlan)} testers are
            available right after your purchase.
          </>
        ),
        confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading: "Payment successful",
        thankYouText: `Thanks for upgrading to ${getPlanName(checkoutPlan)}!`,
        thankYouSubline: "You will receive your invoice via email.",
        nextChargeDate,
      });
    }
    case DOWNGRADE_AND_SWITCH_TO_ANNUAL: {
      const nextChargeDate = todayPlusOneBillingCycle;
      return createReturnValue({
        modalHeadingStepCreditCard: `Update to ${getPlanName(
          checkoutPlan,
        )} yearly`,
        additionalInfoCharge: (
          <>
            Get {getIncludedTestersForPlan(checkoutPlan) * 12} testers every
            year for {formatPrice(totalPriceCharged)}/year
            {priceChargedVatInfo}.
            <br />
            <br />
            Your first {getIncludedTestersForPlan(checkoutPlan) * 12} testers
            are available right after your purchase.
          </>
        ),
        confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading: `Payment successful`,
        thankYouText: `Thanks for signing up to ${getPlanName(
          checkoutPlan,
        )} yearly!`,
        thankYouSubline: `You will receive your invoice via email.`,
        nextChargeDate,
      });
    }
    case DOWNGRADE_SAME_BILLING_CYCLE: {
      const nextChargeDate = currentSubscription?.current_period_end;
      return createReturnValue({
        modalHeadingStepCreditCard: `Downgrade to ${getPlanName(checkoutPlan)}`,
        confirmButtonLabel: "Confirm Downgrade",
        additionalInfoCharge: (
          <>
            Your next payment will be {formatPrice(totalPriceChargedNext)}
            {priceChargedNextVatInfo} for {getPlanName(checkoutPlan)} on{" "}
            {formatDateWithoutTime(nextChargeDate)}. All existing testers from
            your previous plan remain available.
          </>
        ),
        skipLastStep: true,
      });
    }
    case SWITCH_TO_ANNUAL: {
      const nextChargeDate = todayPlusOneBillingCycle;
      return createReturnValue({
        modalHeadingStepCreditCard: `Update to yearly billing`,
        additionalInfoCharge: (
          <>
            Get {getIncludedTestersForPlan(checkoutPlan) * 12} testers every
            year for {formatPrice(totalPriceCharged)}/year
            {priceChargedVatInfo}.
            <br />
            <br />
            Your first {getIncludedTestersForPlan(checkoutPlan) * 12} testers
            are available right after your purchase.
          </>
        ),
        confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading: "Payment successful",
        thankYouText: `Thanks for signing up to ${getPlanName(
          checkoutPlan,
        )} yearly!`,
        thankYouSubline: "You will receive your invoice via email.",
        nextChargeDate,
      });
    }
    case NEW_SUBSCRIPTION: {
      const nextChargeDate = todayPlusOneBillingCycle;
      const isAnnual = billingCycle === BILLING_CYCLE_ANNUAL;
      return createReturnValue({
        modalHeadingStepCreditCard: `Subscribe to ${getPlanName(checkoutPlan)}`,
        additionalInfoCharge: isAnnual ? (
          <>
            Get {getIncludedTestersForPlan(checkoutPlan) * 12} testers every
            year for {formatPrice(totalPriceCharged)}/year
            {priceChargedVatInfo}.
            <br />
            <br />
            Your first {getIncludedTestersForPlan(checkoutPlan) * 12} testers
            are available right after your purchase.
          </>
        ) : (
          <>
            Get {getIncludedTestersForPlan(checkoutPlan)} testers every month
            for {formatPrice(totalPriceCharged)}/month
            {priceChargedVatInfo}.
            <br />
            <br />
            Your first {getIncludedTestersForPlan(checkoutPlan)} testers are
            available right after your purchase.
          </>
        ),
        confirmButtonLabel: `Pay ${formatPrice(totalPriceCharged)} now`,
        thankYouHeading: "Payment successful",
        thankYouText: `Thanks for signing up to ${getPlanName(checkoutPlan)}!`,
        thankYouSubline: "You will receive your invoice via email.",
        nextChargeDate,
      });
    }
    case UPGRADE_AND_SWITCH_TO_MONTHLY: // This case is not possible currently
    case DOWNGRADE_AND_SWITCH_TO_MONTHLY: // This case is not possible currently
    default: {
      return createReturnValue({
        isContactSupportForChanges: true,
      });
    }
  }
}

export function durationToTimeString(duration) {
  if (duration > 3600) {
    return "> 1h";
  }
  return `${Math.floor(duration / 60)
    .toString()
    .padStart(2, "0")}:${(duration % 60).toString().padStart(2, "0")}`;
}

export function toHMSText(input) {
  const sec_num = parseInt(input, 10); // don't forget the second param
  if (sec_num === 0) {
    return "0s";
  }
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let seconds = sec_num - hours * 3600 - minutes * 60;
  return [
    hours && `${hours}h`,
    minutes && `${minutes}m`,
    seconds && `${seconds}s`,
  ]
    .filter((i) => !!i)
    .join(" ");
}

export function copyArrayAndReplaceAt(array, index, value) {
  const ret = array.slice(0);
  if (typeof value === "function") {
    ret[index] = value(ret[index]);
  } else {
    ret[index] = value;
  }
  return ret;
}

export function addHttp(url) {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = "http://" + url;
  }
  return url;
}

/*const validURLPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator*/

// This was too slow for test-test-test-test-test-test-test-test-test.web.app

export function isValidURL(str) {
  return !!validUrl.isWebUri(str);
}

function convertScreenerAnswerToApiFormat(answer, questionType) {
  return {
    title: answer.text,
    type:
      questionType === "single_choice"
        ? answer.typeSingleChoice
        : answer.typeMultipleChoice,
  };
}

function convertApiScreenerAnswerToScreenerAnswer(apiAnswer) {
  return {
    text: apiAnswer.title,
    typeMultipleChoice: apiAnswer.type,
    typeSingleChoice: apiAnswer.type,
  };
}

export function convertScreenerToApiFormat(screener) {
  return (
    screener?.questions.map((question) => ({
      question: question.text,
      type: question.type,
      answers: question.answers.map((answer) =>
        convertScreenerAnswerToApiFormat(answer, question.type),
      ),
    })) || null
  );
}

export function convertApiScreenerToScreener(apiScreener) {
  return apiScreener
    ? {
        questions: apiScreener.map((apiQuestion) => ({
          text: apiQuestion.question,
          type: apiQuestion.type,
          answers: apiQuestion.answers.map((apiAnswer) =>
            convertApiScreenerAnswerToScreenerAnswer(apiAnswer),
          ),
        })),
      }
    : null;
}

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export const advertedPoolSize = "130k+";

export const TESTER_NAME_ANONYMOUS = "Anonymous";

export function getTesterNameFromTester(
  tester,
  preferEmailForInvitation = false,
) {
  let testerName;

  if (tester.type === "invitation") {
    if (preferEmailForInvitation) {
      testerName = tester.email || tester.tester;
    } else {
      testerName = tester.tester || tester.email;
    }
  } else {
    testerName = tester.name;
  }

  return testerName || TESTER_NAME_ANONYMOUS;
}

export function getTesterInitials(testerName) {
  if (
    testerName === TESTER_NAME_ANONYMOUS ||
    typeof testerName !== "string" ||
    testerName.length < 2
  ) {
    return "?";
  }
  const indexOfSpace = testerName.indexOf(" ");
  if (indexOfSpace === -1 && testerName[indexOfSpace + 1]) {
    return (testerName[0] + testerName[1]).toLocaleUpperCase();
  } else {
    return (
      testerName[0] + testerName[indexOfSpace + 1] ?? ""
    ).toLocaleUpperCase();
  }
}

export function downloadBlobResponse(response, filename) {
  const data = response?.data;
  if (data instanceof Blob) {
    // create file link in browser's memory
    const href = URL.createObjectURL(data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }
}

//  A formatted version of a popular md5 implementation.
//  Original copyright (c) Paul Johnston & Greg Holt.
//  The function itself is now 42 lines long.

// https://stackoverflow.com/questions/1655769/fastest-md5-implementation-in-javascript

// Another absolutely irrelevant fact: it's 4000 bytes long. It may seem pointless, but it feels good to just know.
// You look at it and you know. You belong to the secret club of Those Who Just Know. Life will never be the same.
// Trust me.

export function md5(inputString) {
  inputString = `${inputString}`; // Added to make sure it is a string, numbers will always return the same hash
  var hc = "0123456789abcdef";
  function rh(n) {
    var j,
      s = "";
    for (j = 0; j <= 3; j++)
      s +=
        hc.charAt((n >> (j * 8 + 4)) & 0x0f) + hc.charAt((n >> (j * 8)) & 0x0f);
    return s;
  }
  function ad(x, y) {
    var l = (x & 0xffff) + (y & 0xffff);
    var m = (x >> 16) + (y >> 16) + (l >> 16);
    return (m << 16) | (l & 0xffff);
  }
  function rl(n, c) {
    return (n << c) | (n >>> (32 - c));
  }
  function cm(q, a, b, x, s, t) {
    return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
  }
  function ff(a, b, c, d, x, s, t) {
    return cm((b & c) | (~b & d), a, b, x, s, t);
  }
  function gg(a, b, c, d, x, s, t) {
    return cm((b & d) | (c & ~d), a, b, x, s, t);
  }
  function hh(a, b, c, d, x, s, t) {
    return cm(b ^ c ^ d, a, b, x, s, t);
  }
  function ii(a, b, c, d, x, s, t) {
    return cm(c ^ (b | ~d), a, b, x, s, t);
  }
  function sb(x) {
    var i;
    var nblk = ((x.length + 8) >> 6) + 1;
    var blks = new Array(nblk * 16);
    for (i = 0; i < nblk * 16; i++) blks[i] = 0;
    for (i = 0; i < x.length; i++)
      blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
    blks[i >> 2] |= 0x80 << ((i % 4) * 8);
    blks[nblk * 16 - 2] = x.length * 8;
    return blks;
  }
  var i,
    x = sb(inputString),
    a = 1732584193,
    b = -271733879,
    c = -1732584194,
    d = 271733878,
    olda,
    oldb,
    oldc,
    oldd;
  for (i = 0; i < x.length; i += 16) {
    olda = a;
    oldb = b;
    oldc = c;
    oldd = d;
    a = ff(a, b, c, d, x[i + 0], 7, -680876936);
    d = ff(d, a, b, c, x[i + 1], 12, -389564586);
    c = ff(c, d, a, b, x[i + 2], 17, 606105819);
    b = ff(b, c, d, a, x[i + 3], 22, -1044525330);
    a = ff(a, b, c, d, x[i + 4], 7, -176418897);
    d = ff(d, a, b, c, x[i + 5], 12, 1200080426);
    c = ff(c, d, a, b, x[i + 6], 17, -1473231341);
    b = ff(b, c, d, a, x[i + 7], 22, -45705983);
    a = ff(a, b, c, d, x[i + 8], 7, 1770035416);
    d = ff(d, a, b, c, x[i + 9], 12, -1958414417);
    c = ff(c, d, a, b, x[i + 10], 17, -42063);
    b = ff(b, c, d, a, x[i + 11], 22, -1990404162);
    a = ff(a, b, c, d, x[i + 12], 7, 1804603682);
    d = ff(d, a, b, c, x[i + 13], 12, -40341101);
    c = ff(c, d, a, b, x[i + 14], 17, -1502002290);
    b = ff(b, c, d, a, x[i + 15], 22, 1236535329);
    a = gg(a, b, c, d, x[i + 1], 5, -165796510);
    d = gg(d, a, b, c, x[i + 6], 9, -1069501632);
    c = gg(c, d, a, b, x[i + 11], 14, 643717713);
    b = gg(b, c, d, a, x[i + 0], 20, -373897302);
    a = gg(a, b, c, d, x[i + 5], 5, -701558691);
    d = gg(d, a, b, c, x[i + 10], 9, 38016083);
    c = gg(c, d, a, b, x[i + 15], 14, -660478335);
    b = gg(b, c, d, a, x[i + 4], 20, -405537848);
    a = gg(a, b, c, d, x[i + 9], 5, 568446438);
    d = gg(d, a, b, c, x[i + 14], 9, -1019803690);
    c = gg(c, d, a, b, x[i + 3], 14, -187363961);
    b = gg(b, c, d, a, x[i + 8], 20, 1163531501);
    a = gg(a, b, c, d, x[i + 13], 5, -1444681467);
    d = gg(d, a, b, c, x[i + 2], 9, -51403784);
    c = gg(c, d, a, b, x[i + 7], 14, 1735328473);
    b = gg(b, c, d, a, x[i + 12], 20, -1926607734);
    a = hh(a, b, c, d, x[i + 5], 4, -378558);
    d = hh(d, a, b, c, x[i + 8], 11, -2022574463);
    c = hh(c, d, a, b, x[i + 11], 16, 1839030562);
    b = hh(b, c, d, a, x[i + 14], 23, -35309556);
    a = hh(a, b, c, d, x[i + 1], 4, -1530992060);
    d = hh(d, a, b, c, x[i + 4], 11, 1272893353);
    c = hh(c, d, a, b, x[i + 7], 16, -155497632);
    b = hh(b, c, d, a, x[i + 10], 23, -1094730640);
    a = hh(a, b, c, d, x[i + 13], 4, 681279174);
    d = hh(d, a, b, c, x[i + 0], 11, -358537222);
    c = hh(c, d, a, b, x[i + 3], 16, -722521979);
    b = hh(b, c, d, a, x[i + 6], 23, 76029189);
    a = hh(a, b, c, d, x[i + 9], 4, -640364487);
    d = hh(d, a, b, c, x[i + 12], 11, -421815835);
    c = hh(c, d, a, b, x[i + 15], 16, 530742520);
    b = hh(b, c, d, a, x[i + 2], 23, -995338651);
    a = ii(a, b, c, d, x[i + 0], 6, -198630844);
    d = ii(d, a, b, c, x[i + 7], 10, 1126891415);
    c = ii(c, d, a, b, x[i + 14], 15, -1416354905);
    b = ii(b, c, d, a, x[i + 5], 21, -57434055);
    a = ii(a, b, c, d, x[i + 12], 6, 1700485571);
    d = ii(d, a, b, c, x[i + 3], 10, -1894986606);
    c = ii(c, d, a, b, x[i + 10], 15, -1051523);
    b = ii(b, c, d, a, x[i + 1], 21, -2054922799);
    a = ii(a, b, c, d, x[i + 8], 6, 1873313359);
    d = ii(d, a, b, c, x[i + 15], 10, -30611744);
    c = ii(c, d, a, b, x[i + 6], 15, -1560198380);
    b = ii(b, c, d, a, x[i + 13], 21, 1309151649);
    a = ii(a, b, c, d, x[i + 4], 6, -145523070);
    d = ii(d, a, b, c, x[i + 11], 10, -1120210379);
    c = ii(c, d, a, b, x[i + 2], 15, 718787259);
    b = ii(b, c, d, a, x[i + 9], 21, -343485551);
    a = ad(a, olda);
    b = ad(b, oldb);
    c = ad(c, oldc);
    d = ad(d, oldd);
  }
  return rh(a) + rh(b) + rh(c) + rh(d);
}

export const getCues = (url) => {
  const vid = document.createElement("video");
  vid.crossOrigin = "anonymous"; // Fixes CORS issue
  const track = document.createElement("track");
  track.default = true;
  track.kind = "metadata"; // Required for Safari iOS otherwise it will not load the cues if subtitles are not enabled in the native player
  vid.append(track);
  return new Promise((res, rej) => {
    track.onload = (evt) => res([...vid.textTracks[0].cues]);
    track.onerror = (evt) => rej("invalid url");
    track.src = url;
  });
};

// Randomize array in-place using Durstenfeld shuffle algorithm
// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

const hashtagSplitRule = /([#][^\s]+)/g;
const defaultHashtagRenderer = (hashtag, chunkIndex) => {
  return <span key={chunkIndex}>{hashtag}</span>;
};
export function renderHashtags(text, renderer = defaultHashtagRenderer) {
  return text.split(hashtagSplitRule).map((chunk, chunkIndex) => {
    if (chunk.match(hashtagSplitRule)) {
      return renderer(chunk, chunkIndex);
    }
    return chunk;
  });
}

export const allowedTestTypes = ["website", "prototype", "app"];

export function enableTracking() {
  /* eslint-disable eqeqeq */
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    // @ts-ignore
    j.async = true;
    // @ts-ignore
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-NB7SLF4");
  // The gitBranch is set by the marketing site and as both sites are in the same Analytics, this needs to have
  // its own custom dimension if used for the dashboard.
  // @ts-ignore
  window.dataLayer.push({
    event: "pageview",
    gitBranch: process.env.REACT_APP_BRANCH,
  });
  window.enableTracking = function () {};
}

export function addSignatureToUrl(url, signature) {
  const { signature: key, policy, key_pair_id } = signature;
  return url + `?Signature=${key}&Policy=${policy}&Key-Pair-Id=${key_pair_id}`;
}

// XXX: transformVideoAwsUrls is not ideal I think
export function transformVideoAwsUrls(video) {
  const signatureObject =
    typeof video.signature === "object" ? video.signature : {};
  const { signature, policy, key_pair_id } = signatureObject;
  if (signature && policy && key_pair_id) {
    video = { ...video };
    if (video.preview) {
      video.preview = {
        ...video.preview,
        image: addSignatureToUrl(video.preview.image, signatureObject),
        vtt: addSignatureToUrl(video.preview.vtt, signatureObject),
      };
    }
    if (video.sources) {
      video.sources = video.sources.map((source) => {
        return {
          ...source,
          location: addSignatureToUrl(source.location, signatureObject),
        };
      });
    }
    if (video.poster) {
      video.poster = addSignatureToUrl(video.poster, signatureObject);
    }
    if (video.location) {
      video.location = addSignatureToUrl(video.location, signatureObject);
    }
  }
  return video;
}

export function getFromYourPlanWording(hasUserInvoices, isFreeTrial) {
  // https://3.basecamp.com/3583262/buckets/37130327/todos/7336729123
  if (isFreeTrial) {
    if (!hasUserInvoices) {
      return "From your free trial";
    }
    return "From your plan/trial";
  }
  return "From your plan";
}
