import { useCallback, useState } from "react";

export function useModal2Controller() {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return {
    isOpen,
    setIsOpen,
    open,
    close,
  };
}
