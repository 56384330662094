import { useContext, useEffect, useReducer, useRef } from "react";
import { createPortal } from "react-dom";

import { ModalContext, ModalInfo } from "./context";

export function ModalContainer({
  element,
  isActive,
}: {
  element: JSX.Element;
  isActive: boolean;
}) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const refSubmodals = useRef<ModalInfo[]>([]);
  const modalContext = useContext(ModalContext);
  const { refModalContainer, refSubmodals: parentSubmodals } = modalContext;

  // If the container for the portal was not added to the DOM in this render,
  // render again. This might happen if the container ref was just created
  // in this render. In this case the container will be in the DOM in the next
  // render.
  // This is only needed once for a given ref
  const refPortalCreated = useRef(false);
  useEffect(() => {
    if (refPortalCreated.current === false) {
      forceUpdate();
    }
  }, [refModalContainer]);

  const modalContextValue = {
    ...modalContext,
    refSubmodals,
    isActive,
    level: modalContext.level === null ? 0 : modalContext.level + 1,
  };

  useEffect(() => {
    if (parentSubmodals.current !== null) {
      // Add modalInfo ref to the list of sub modals
      const modalInfo: ModalInfo = {
        isActive,
      };
      parentSubmodals.current.push(modalInfo);
      return () => {
        // Remove modalInfo ref from the list of sub modals
        if (parentSubmodals.current !== null) {
          parentSubmodals.current = parentSubmodals.current.filter(
            (item) => item !== modalInfo,
          );
        }
      };
    }
  }, [parentSubmodals, isActive]);

  if (refModalContainer.current === null) {
    refPortalCreated.current = false;
    return null;
  } else {
    refPortalCreated.current = true;
    return createPortal(
      <ModalContext.Provider value={modalContextValue}>
        {element}
      </ModalContext.Provider>,
      refModalContainer.current,
    );
  }
}
