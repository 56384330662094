import { concatClassNames as cn } from "@system42/core";

import styles from "./styles.module.css";

export function Meter({
  min,
  max,
  value,
  title,
  className,
}: {
  min?: number;
  max: number;
  value: number;
  title: string;
  className?: string;
}) {
  return (
    <div className={cn(styles.meter, className)}>
      <meter min={min ?? 0} max={max} value={value} title={title} />
    </div>
  );
}
