import { concatClassNames as cn } from "@system42/core";
import { Link } from "react-router-dom";

import styles from "./styles.module.css";

export function NavChips({ children }) {
  return <div className={styles.navChips}>{children}</div>;
}

function Item({ to, isActive, children, count }) {
  return (
    <Link to={to} className={cn(styles.item, isActive && styles.item_active)}>
      <span>{children}</span>
      {count !== null && <span className={styles.count}>{count}</span>}
    </Link>
  );
}

NavChips.Item = Item;
