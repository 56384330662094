import { VideoClip } from "@/containers/Video/types";
import { VideoNote } from "@/types/business/video";

export function isApiNoteClip(note: VideoNote): boolean {
  return false;
}

export function isApiNoteOrClipAi(noteOrClip: VideoNote | VideoClip): boolean {
  return noteOrClip.automated_insight === true;
}
