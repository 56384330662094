import {
  VideoClipShared,
  VideoClipSharedAuthenticated,
} from "@/entities/video/clip";

import createGenericApiSlice from "./genericApiSlice";

const slice = createGenericApiSlice<
  (VideoClipShared | VideoClipSharedAuthenticated) & { refreshHash: string }
>("SHARED_CLIP", { storeRequestAction: true });

export const sharedClipReducer = slice.reducer;

export const SHARED_CLIP_REQUEST = slice.actionTypes.REQUEST;
export const SHARED_CLIP_SUCCESS = slice.actionTypes.SUCCESS;
export const SHARED_CLIP_FAILURE = slice.actionTypes.FAILURE;
export const SHARED_CLIP_RE_REQUEST = "SHARED_CLIP_RE_REQUEST";
